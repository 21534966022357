<div class="cc-h" *ngIf="!deviceXs">
  <div class="row">
    <!-- <span class=""><input type="radio" /></span> -->
    <img
      src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
      class="Group-9614"
    />
    <span class="col-md-11">
      In how many years are you looking to start earning?
    </span>
  </div>
  <div class="row">
    <form [formGroup]="form" (ngSubmit)="showOptions()">
      <div class="col-12 mt-4">
        <mat-form-field appearance="outline">
          <mat-select
            (selectionChange)="triggerSubMenu2()"
            placeholder="Select"
            class="selectFontText"
            formControlName="years"
          >
            <mat-option value="0" class="selectFontText"
              >After 10th class</mat-option
            >
            <mat-option value="1" class="selectFontText"
              >After 10+2/PUC/Intermediate</mat-option
            >
            <mat-option value="2" class="selectFontText"
              >After Degree</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="afterDegree pl-3" *ngIf="form.value.years === '2'">
        The list of careers you will see are available to a student with any
        degree. To find out careers related to your degree particularly, you can
        search under “Courses”
        <div class="row mt-4 pl-3">
          <button class="loginBtn" mat-raised-button type="submit">Next</button>
        </div>
      </div>
      <div class="col-12 mt-2" *ngIf="form.value.years !== '2'">
        <div class="row" *ngIf="adapter != null">
          <!-- <span><input type="radio" /></span> -->
          <img
            src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
            class="Group-9614"
          />
          <span class="pl-2">Please select the suitable:</span>
        </div>
      </div>
      <div
        class="col-12 mt-2"
        *ngIf="adapter != null && form.value.years !== '2'"
      >
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Select"
            class="selectFontText"
            formControlName="subYears"
          >
            <mat-option *ngFor="let choice of adapter" [value]="choice">{{
              choice
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="row mt-3 pl-3">
          <button class="loginBtn" mat-raised-button type="submit">Next</button>
        </div>
      </div>

      <div class="col-12 mt-2" *ngIf="form.value.years == '1'">
        <div class="row" *ngIf="adapter1 == true">
          <img
            src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
            class="Group-9614"
          />
          <span class="pl-2">Please select the suitable:</span>
        </div>
      </div>

      <div
        class="col-12 mt-2"
        *ngIf="adapter == null && form.value.years == '1'"
      >
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Select"
            class="selectFontText"
            formControlName="subYears"
          >
            <mat-option [value]="0">Maths, Physics, Chemistry</mat-option>
            <mat-option [value]="1">Biology, Physics, Chemistry</mat-option>
            <mat-option [value]="2">Commerce, Economics, Civics</mat-option>
            <mat-option [value]="3">History, Economics, Civics</mat-option>
            <mat-option [value]="5">Maths, Economics, Commerce</mat-option>
            <mat-option [value]="4">Vocational</mat-option>
            <mat-option [value]="9">Any Group</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="row mt-3 pl-3">
          <button class="loginBtn" mat-raised-button type="submit">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- For Mobile App -->

<div class="cc-h1" *ngIf="deviceXs">
  <div class="row">
    <!-- <span class=""><input type="radio" /></span> -->
    <img
      src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
      class="Group-9614 mt-3"
    />
    <span class="col pt-2">
      In how many years are you looking to start earning?
    </span>
  </div>
  <div class="row">
    <form [formGroup]="form" (ngSubmit)="showOptions()">
      <div class="col-12 mt-3">
        <mat-form-field appearance="outline">
          <mat-select
            (selectionChange)="triggerSubMenu2()"
            placeholder="Select"
            class="selectFontText"
            formControlName="years"
          >
            <mat-option value="0" class="selectFontText"
              >After 10th class</mat-option
            >
            <mat-option value="1" class="selectFontText"
              >After 10+2/PUC/Intermediate</mat-option
            >
            <mat-option value="2" class="selectFontText"
              >After Degree</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="afterDegree pl-3" *ngIf="form.value.years === '2'">
        The list of careers you will see are available to a student with any
        degree. To find out careers related to your degree particularly, you can
        search under “Courses”
        <div class="row mt-4 pl-3">
          <button class="loginBtn" mat-raised-button type="submit">Next</button>
        </div>
      </div>
      <div class="col-12 mt-2" *ngIf="form.value.years !== '2'">
        <div class="row" *ngIf="adapter != null">
          <!-- <span><input type="radio" /></span> -->
          <img
            src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
            class="Group-9614"
          />
          <span class="pl-2">Please select the suitable:</span>
        </div>
      </div>
      <div
        class="col-12 mt-2"
        *ngIf="adapter != null && form.value.years !== '2'"
      >
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Select"
            class="selectFontText"
            formControlName="subYears"
          >
            <mat-option *ngFor="let choice of adapter" [value]="choice">{{
              choice
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="row mt-2 pb-4 pl-3">
          <button class="loginBtn" mat-raised-button type="submit">Next</button>
        </div>
      </div>

      <div class="col-12 mt-2" *ngIf="form.value.years == '1'">
        <div class="row" *ngIf="adapter1 == true">
          <img
            src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
            class="Group-9614"
          />
          <span class="pl-2">Please select the suitable:</span>
        </div>
      </div>

      <div
        class="col-12 mt-2"
        *ngIf="adapter == null && form.value.years == '1'"
      >
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Select"
            class="selectFontText"
            formControlName="subYears"
          >
            <mat-option [value]="0">Maths, Physics, Chemistry</mat-option>
            <mat-option [value]="1">Biology, Physics, Chemistry</mat-option>
            <mat-option [value]="2">Commerce, Economics, Civics</mat-option>
            <mat-option [value]="3">History, Economics, Civics</mat-option>
            <mat-option [value]="5">Maths, Economics, Commerce</mat-option>
            <mat-option [value]="4">Vocational</mat-option>
            <mat-option [value]="9">Any Group</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="row mt-2 pl-3 mb-3">
          <button class="loginBtn" mat-raised-button type="submit">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>
