<div id="wrapper" class="d-flex">
  <div id="page-content-wrapper" class="dash-nav">
    <div class="d-flex borderStyling">
      <div class="col-md-8 align-items-center cwikitxt">CAP Admin Portal</div>
      <div class="col-md-4 d-flex cwikitxt justify-content-end">
        <span class="bold ml-5"> Welcome Admin</span>
        <!-- </div>
      <div class="col-md-2 d-flex cwikitxt justify-content-end"> -->
        <mat-icon
          class="logoutStyling ml-5"
          aria-hidden="false"
          aria-label="Example home icon"
          >logout</mat-icon
        >

        <span
          class="logoutStyling ml-3"
          (click)="logout()"
          style="cursor: pointer"
        >
          Logout</span
        >
      </div>
    </div>
    <div class="route-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- <div>
  <div class="d-flex" id="wrapper">
    <div class="col-md-8 align-items-center cwikitxt">Career Wiki</div>
    <div class="col-md-4 d-flex cwikitxt justify-content-end">
      <span class="bold ml-5"> Logout</span>
    </div>
  </div>
  <div class="route-content">
    <router-outlet></router-outlet>
  </div>
</div> -->
