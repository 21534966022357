<div class="row">
  <div class="col-12 d-flex justify-content-center cent">
    <img src="/assets/Auth/welcomesignup.png" style="padding-right: 33px" />
  </div>
  <div class="col-12 loginText mt-2" *ngIf="stepTracker !== 3">Signup</div>
  <div class="col-12 loginText mt-2" *ngIf="stepTracker == 3">Final Step</div>

  <!-- * Removed as per client's suggestion -->
  <!-- <div class="col-md-12 d-flex justify-content-center mt-4">
    <img [src]="step1" />
    <img [src]="step2" />
    <img [src]="step3" />
  </div> -->

  <!-- * Removed as per client's suggestion -->
  <!-- <div class="col-12 fieldsMarked d-flex justify-content-center">
    <p
      class="pr-3 mt-1 stepText xsText"
      [ngStyle]="{ color: goldText1 ? '#00cc92' : '#00b1e3' }"
    >
      Personal Info
    </p>
    <p
      class="pr-3 mt-1 stepText xsText"
      [ngStyle]="{
        color: goldText2
          ? '#00cc92'
          : stepTracker == '1'
          ? '#00b1e3'
          : '#b3e8f7'
      }"
    >
      Academic Info
    </p>
    <p
      class="mt-1 stepText xsText"
      [ngStyle]="{
        color: goldText3
          ? '#00cc92'
          : stepTracker == '2'
          ? '#00b1e3'
          : '#b3e8f7'
      }"
    >
      Residence Info
    </p>
  </div> -->

  <div class="col-12 fieldsMarked d-flex justify-content-center mt-2">
    <span class="newMemberTxt2"
      >The fields marked with <span class="bold"> * </span> are mandatory to
      fill</span
    >
  </div>
  <!-- new form changes -->
  <div
    id="signupForm"
    class="col-12 mcv formHolder"
    *ngIf="stepTracker == null"
  >
    <form [formGroup]="form1" (ngSubmit)="onSubmit()">
      <div class="row">
        <!-- ** Form  1 -->
        <div class="col-xl-5 col-l-5 col-sm-12">
          <!-- ** Name Div -->
          <div class="row mt-3 justify-content-center">
            <mat-form-field appearance="outline" class="signupInput">
              <mat-label class="selectFontText">Name*</mat-label>
              <input matInput placeholder="Name*" formControlName="name" />
              <mat-hint
                *ngIf="
                  (form1.controls.name.touched || formGroupValidator) &&
                  form1.controls.name.invalid
                "
                class="red"
                >Please enter name</mat-hint
              >
            </mat-form-field>
          </div>
          <!-- ** Email Div -->
          <div class="row justify-content-center mt-4">
            <mat-form-field appearance="outline" class="signupInput">
              <mat-label class="selectFontText">Email Id</mat-label>
              <input
                matInput
                placeholder="Email Id"
                formControlName="email"
                (blur)="emailVerification()"
              />
              <img
                class="bottompadding"
                src="/assets/careerDetail/tick.png"
                matSuffix
                style="align-items: right"
                *ngIf="form1.controls.email.valid && emailNotExists"
              />
              <mat-hint
                *ngIf="form1.controls.email.valid && emailExists"
                class="red"
                >{{ emailErrorMessage }}</mat-hint
              >
              <mat-hint *ngIf="form1.controls.email.invalid" class="red">{{
                emailErrorMessage
              }}</mat-hint>
              <!-- <mat-hint *ngIf="form1.controls.email.invalid && !emailExists" class="red">{{
                emailErrorMessage
              }}</mat-hint> -->
            </mat-form-field>
          </div>

          <!-- ** Phone and gender div -->
          <div [class]="addCapacitorClasses()">
            <!-- <div class="row"> -->
            <div class="">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">Phone*</mat-label>
                <input
                  matInput
                  placeholder="Phone Number*"
                  formControlName="phone"
                />
                <mat-hint
                  *ngIf="
                    (form1.controls.phone.touched || formGroupValidator) &&
                    form1.controls.phone.invalid
                  "
                  class="red"
                  >Enter valid phone no</mat-hint
                >
              </mat-form-field>
            </div>
            <div class="">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">Gender*</mat-label>
                <mat-select
                  placeholder="Gender*"
                  formControlName="gender"
                  autoWidth="true"
                  class="selectFontText"
                >
                  <mat-option value="0" class="selectFontText">Male</mat-option>
                  <mat-option value="1" class="selectFontText"
                    >Female</mat-option
                  >
                  <mat-option value="2" class="selectFontText"
                    >Other</mat-option
                  >
                </mat-select>
                <mat-hint
                  *ngIf="
                    (form1.controls.gender.touched || formGroupValidator) &&
                    form1.controls.gender.invalid
                  "
                  class="red"
                  >Select Gender</mat-hint
                >
              </mat-form-field>
            </div>
            <!-- </div> -->
            <!-- <div class="col-6">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">Birthday*</mat-label>
                <input
                  matInput
                  [matDatepicker]="dp"
                  placeholder="Birthday*"
                  formControlName="dob"
                  class="selectFontText"
                  [max]="maxDate"
                  [readonly]="true"
                />

                (click)="dp.open()"
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dp"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
                <mat-hint
                  *ngIf="
                    (form1.controls.dob.touched || formGroupValidator) &&
                    form1.controls.dob.invalid
                  "
                  class="red"
                  >Select Date Of Birth</mat-hint
                >
              </mat-form-field>
            </div> -->
          </div>

          <!-- ** Current Highest Qualification -->
          <div class="row justify-content-center mt-4">
            <mat-form-field appearance="outline" class="signupInput">
              <mat-label class="selectFontText"
                >Highest/Current Qualification*</mat-label
              >
              <mat-select
                placeholder="Highest/Current Qualification*"
                formControlName="qualification"
                (selectionChange)="scrollForMobile('qualification')"
                class="selectFontText"
              >
                <mat-option value="0" class="selectFontText"
                  >Below 10th</mat-option
                >
                <mat-option value="1" class="selectFontText"
                  >Studying 10th</mat-option
                >
                <mat-option value="2" class="selectFontText"
                  >Completed 10th</mat-option
                >
                <mat-option value="3" class="selectFontText"
                  >Studying Inter/12th/PUC</mat-option
                >
                <mat-option value="4" class="selectFontText"
                  >Completed Inter/12th/PUC</mat-option
                >
                <mat-option value="5" class="selectFontText"
                  >Studying ITI</mat-option
                >
                <mat-option value="6" class="selectFontText"
                  >Completed ITI</mat-option
                >
                <mat-option value="7" class="selectFontText"
                  >Studying Diploma</mat-option
                >
                <mat-option value="8" class="selectFontText"
                  >Completed Diploma'</mat-option
                >
                <mat-option value="9" class="selectFontText"
                  >Studying Graduation</mat-option
                >
                <mat-option value="10" class="selectFontText"
                  >Completed Graduation</mat-option
                >
                <mat-option value="11" class="selectFontText"
                  >Studying Technical Graduation</mat-option
                >
                <mat-option value="12" class="selectFontText"
                  >Completed Technical Graduation</mat-option
                >
                <mat-option value="13" class="selectFontText"
                  >Studying PG</mat-option
                >
                <mat-option value="14" class="selectFontText"
                  >Completed PG</mat-option
                >
                <mat-option value="15" class="selectFontText">Other</mat-option>
              </mat-select>
              <mat-hint
                *ngIf="
                  (form1.controls.qualification.touched ||
                    formGroupValidator) &&
                  form1.controls.qualification.invalid
                "
                class="red"
                >Select highest qualification</mat-hint
              >
            </mat-form-field>
          </div>

          <div class="col-12 d-flex justify-content-center mt-4">
            <div class="row">
              <!-- <div class="col-6">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">Gender*</mat-label>
                <mat-select
                  placeholder="Gender*"
                  formControlName="gender"
                  autoWidth="true"
                  class="selectFontText"
                >
                  <mat-option value="0" class="selectFontText">Male</mat-option>
                  <mat-option value="1" class="selectFontText"
                    >Female</mat-option
                  >
                  <mat-option value="2" class="selectFontText"
                    >Other</mat-option
                  >
                </mat-select>
                <mat-hint
                  *ngIf="
                    (form1.controls.gender.touched || formGroupValidator) &&
                    form1.controls.gender.invalid
                  "
                  class="red"
                  >Select Gender</mat-hint
                >
              </mat-form-field>
            </div> -->
              <!-- <div class="col-6">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">Disability*</mat-label>
                <mat-select
                  placeholder="Disability*"
                  formControlName="disability"
                  class="selectFontText"
                >
                  <mat-option value="0" class="selectFontText"
                    >No disability</mat-option
                  >
                  <mat-option value="1" class="selectFontText"
                    >Visually handicapped</mat-option
                  >
                  <mat-option value="2" class="selectFontText"
                    >Hearing impaired</mat-option
                  >
                  <mat-option value="3" class="selectFontText"
                    >Orthopedically handicapped</mat-option
                  >
                </mat-select>
                <mat-hint
                  *ngIf="
                    (form1.controls.disability.touched || formGroupValidator) &&
                    form1.controls.disability.invalid
                  "
                  class="red"
                  >Select disability</mat-hint
                >
              </mat-form-field>
            </div> -->
            </div>
          </div>
          <!-- ** Removed to maintain only one set of login and singup buttons -->
          <!-- <div class="col-12 d-flex justify-content-center mt-5">
          <button mat-raised-button class="loginBtn">Next</button>
        </div>
        <div class="col-12 loginText mt-2">
          <span class="newMemberTxt" routerLink="/auth"
            >Already a member? Click to Login</span
          >
        </div> -->
        </div>
        <div class="col-xl-2 col-l-2"></div>
        <!-- ** Form  3 -->
        <div class="col-xl-5 col-l-5 col-sm-12">
          <!-- <div class="col-12 d-flex justify-content-center mt-4">
            <mat-form-field appearance="outline" class="signupInput">
              <mat-label class="selectFontText">Residence Category*</mat-label>
              <mat-select
                formControlName="residenceCat"
                placeholder="Residence Category*"
                class="selectFontText"
              >
                <mat-option value="0" class="selectFontText"
                  >Village</mat-option
                >
                <mat-option value="1" class="selectFontText">Town</mat-option>
                <mat-option value="2" class="selectFontText">City</mat-option>
              </mat-select>
              <mat-hint
                *ngIf="
                  (form3.controls.residenceCat.touched ||
                    formGroupValidator3) &&
                  form3.controls.residenceCat.invalid
                "
                class="red"
                >Enter residence category
              </mat-hint>
            </mat-form-field>
          </div> -->
          <!-- ** Village/city/town name div -->
          <div class="row justify-content-center mt-3">
            <mat-form-field appearance="outline" class="signupInput">
              <mat-label class="selectFontText"
                >Village/Town/City Name*</mat-label
              >
              <input
                formControlName="villageTownCityName"
                matInput
                placeholder="Village/Town/City Name*"
              />
              <mat-hint
                *ngIf="
                  (form1.controls.villageTownCityName.touched ||
                    formGroupValidator) &&
                  form1.controls.villageTownCityName.invalid
                "
                class="red"
                >Enter Village/Town/City Name</mat-hint
              >
            </mat-form-field>
          </div>
          <!-- <div class="col-12 d-flex justify-content-center mt-4">
            <mat-form-field appearance="outline" class="signupInput">
              <mat-label class="selectFontText">Taluk/Mandal Name*</mat-label>
              <input
                formControlName="talukMandalName"
                matInput
                placeholder="Taluk/Mandal Name*"
              />
              <mat-hint
                *ngIf="
                  (form3.controls.talukMandalName.touched ||
                    formGroupValidator3) &&
                  form3.controls.talukMandalName.invalid
                "
                class="red"
                >Enter Taluk/Mandal Name</mat-hint
              >
            </mat-form-field>
          </div> -->
          <!-- ** State and District (State/District) div -->
          <div [class]="addCapacitorClasses()">
            <!-- <div class="row"> -->
            <div class="">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">State*</mat-label>
                <mat-select
                  formControlName="state"
                  placeholder="State*"
                  class="selectFontText"
                  (selectionChange)="checkStatus1()"
                >
                  <!-- <mat-option value="0" class="selectFontText"
                    >National</mat-option
                > -->
                  <span *ngFor="let state of states">
                    <mat-option
                      [value]="state.state_id"
                      (click)="getAllDistricts(state.state_id)"
                      *ngIf="state.state_id != 0"
                      >{{ state.name }}</mat-option
                    >
                  </span>
                </mat-select>

                <mat-hint
                  *ngIf="
                    (form1.controls.state.touched || formGroupValidator) &&
                    form1.controls.state.invalid
                  "
                  class="red"
                  >Select State</mat-hint
                >
              </mat-form-field>
            </div>
            <div class="" *ngIf="form3.value.state === 99">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">District*</mat-label>
                <input
                  formControlName="district"
                  matInput
                  placeholder="District*"
                />

                <mat-hint
                  *ngIf="
                    (form1.controls.district.touched || formGroupValidator) &&
                    form1.controls.district.invalid
                  "
                  class="red"
                  >Enter District</mat-hint
                >
              </mat-form-field>
            </div>
            <div class="" *ngIf="form3.value.state !== 99">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">District*</mat-label>
                <mat-select
                  formControlName="district_id"
                  placeholder="District*"
                  class="selectFontText"
                >
                  <span *ngFor="let district of districts">
                    <mat-option value="{{ district.district_id }}">{{
                      district.name
                    }}</mat-option>
                  </span>
                </mat-select>
                <mat-hint
                  *ngIf="
                    (form1.controls.district_id.touched ||
                      formGroupValidator) &&
                    form1.controls.district_id.invalid
                  "
                  class="red"
                  >Select District</mat-hint
                >
              </mat-form-field>
            </div>
            <!-- </div> -->
          </div>
          <!-- ** Employment div -->
          <div class="row justify-content-center mt-4 pr-0">
            <mat-form-field appearance="outline" class="signupInput">
              <mat-label class="selectFontText">Are you employed ?*</mat-label>
              <mat-select
                placeholder="Employment Status*"
                formControlName="status"
                (selectionChange)="checkStatus()"
                class="selectFontText"
              >
                <mat-option value="1" class="selectFontText">Yes</mat-option>
                <mat-option value="0" class="selectFontText">No</mat-option>
                <!-- ** Mandate the option (yes or no) for working status -->
                <!-- <mat-option value="2" class="selectFontText"
                >None of the above</mat-option
              > -->
              </mat-select>
              <mat-hint
                *ngIf="
                  (form1.controls.status.touched || formGroupValidator) &&
                  form1.controls.status.invalid
                "
                class="red"
                >Select status</mat-hint
              >
            </mat-form-field>
          </div>

          <!-- ** If employed (Working) -->
          <div
            class="col-12 d-flex justify-content-center mt-4"
            *ngIf="form1.value.status === '1'"
          >
            <mat-form-field appearance="outline" class="signupInput">
              <mat-label class="selectFontText"
                >Profession*/Organization *</mat-label
              >
              <input
                matInput
                placeholder="Profession*/Organization*"
                formControlName="professionOrOrganization"
              />
              <mat-hint
                *ngIf="
                  (form1.controls.professionOrOrganization.touched ||
                    formGroupValidator) &&
                  form1.controls.professionOrOrganization.invalid
                "
                class="red"
                >Please enter profession</mat-hint
              >
            </mat-form-field>
          </div>

          <!-- ** If Not yet employed (Studying) -->
          <div
            class="col-12 d-flex justify-content-center mt-4"
            *ngIf="form1.value.status === '0'"
          >
            <mat-form-field appearance="outline" class="signupInput">
              <mat-label class="selectFontText">School/College *</mat-label>
              <input
                matInput
                placeholder="School/College*"
                formControlName="schoolOrCollege"
              />
              <mat-hint
                *ngIf="
                  (form1.controls.schoolOrCollege.touched ||
                    formGroupValidator) &&
                  form1.controls.schoolOrCollege.invalid
                "
                class="red"
                >Please enter the name of the organisation you work
                for</mat-hint
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <div class="col-12 mcv" *ngIf="stepTracker == 1">
    <form [formGroup]="form2" (ngSubmit)="onSubmitStep2()">
      <div class="row">
        <div class="col-12 d-flex justify-content-center mt-4">
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText"
              >Highest/Current Qualification*</mat-label
            >
            <mat-select
              placeholder="Highest/Current Qualification*"
              formControlName="qualification"
              class="selectFontText"
            >
              <mat-option value="0" class="selectFontText"
                >Below 10th</mat-option
              >
              <mat-option value="1" class="selectFontText"
                >Studying 10th</mat-option
              >
              <mat-option value="2" class="selectFontText"
                >Completed 10th</mat-option
              >
              <mat-option value="3" class="selectFontText"
                >Studying Inter/12th/PUC</mat-option
              >
              <mat-option value="4" class="selectFontText"
                >Completed Inter/12th/PUC</mat-option
              >
              <mat-option value="5" class="selectFontText"
                >Studying ITI</mat-option
              >
              <mat-option value="6" class="selectFontText"
                >Completed ITI</mat-option
              >
              <mat-option value="7" class="selectFontText"
                >Studying Diploma</mat-option
              >
              <mat-option value="8" class="selectFontText"
                >Completed Diploma'</mat-option
              >
              <mat-option value="9" class="selectFontText"
                >Studying Graduation</mat-option
              >
              <mat-option value="10" class="selectFontText"
                >Completed Graduation</mat-option
              >
              <mat-option value="11" class="selectFontText"
                >Studying Technical Graduation</mat-option
              >
              <mat-option value="12" class="selectFontText"
                >Completed Technical Graduation</mat-option
              >
              <mat-option value="13" class="selectFontText"
                >Studying PG</mat-option
              >
              <mat-option value="14" class="selectFontText"
                >Completed PG</mat-option
              >
              <mat-option value="15" class="selectFontText">Other</mat-option>
            </mat-select>
            <mat-hint
              *ngIf="
                (form2.controls.qualification.touched || formGroupValidator2) &&
                form2.controls.qualification.invalid
              "
              class="red"
              >Select highest qualification</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="col-12 d-flex justify-content-center mt-4">
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">Current Status*</mat-label>
            <mat-select
              placeholder="Current Status*"
              formControlName="status"
              (selectionChange)="checkStatus()"
              class="selectFontText"
            >
              <mat-option value="0" class="selectFontText">Studying</mat-option>
              <mat-option value="1" class="selectFontText">Working</mat-option>
              <mat-option value="2" class="selectFontText"
                >None of the above</mat-option
              >
            </mat-select>
            <mat-hint
              *ngIf="
                (form2.controls.status.touched || formGroupValidator2) &&
                form2.controls.status.invalid
              "
              class="red"
              >Select status</mat-hint
            >
          </mat-form-field>
        </div>
        <div
          class="col-12 d-flex justify-content-center mt-4"
          *ngIf="form2.value.status !== '1'"
        >
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">Institute Category</mat-label>
            <mat-select
              placeholder="Institute Category"
              formControlName="instituteCat"
              [disabled]="studyEnabler"
              [ngClass]="form2.value.status !== '0' ? 'disabledInput' : ''"
              class="selectFontText"
            >
              <mat-option value="0" class="selectFontText"
                >Government Day Scholar</mat-option
              >
              <mat-option value="1" class="selectFontText"
                >Government Residential</mat-option
              >
              <mat-option value="2" class="selectFontText"
                >Government Aided</mat-option
              >
              <mat-option value="3" class="selectFontText">Private</mat-option>
            </mat-select>
            <mat-hint
              *ngIf="
                (form2.controls.instituteCat.touched || formGroupValidator2) &&
                form2.controls.instituteCat.invalid
              "
              class="red"
              >Select institute category</mat-hint
            >
          </mat-form-field>
        </div>
        <div
          class="col-12 d-flex justify-content-center mt-4"
          *ngIf="form2.value.status !== '1'"
        >
          <div class="row">
            <div class="col-6">
              <mat-form-field
                appearance="outline"
                class="authInput"
                [ngClass]="form2.value.status !== '0' ? 'disabledInput' : ''"
              >
                <mat-label class="selectFontText">Institute Name</mat-label>
                <input
                  matInput
                  placeholder="Institute Name"
                  formControlName="instituteName"
                  [readonly]="studyEnabler"
                />
                <mat-hint
                  *ngIf="
                    (form2.controls.instituteName.touched ||
                      formGroupValidator2) &&
                    form2.controls.instituteName.invalid
                  "
                  class="red"
                  >Enter institute name</mat-hint
                >
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field
                appearance="outline"
                class="authInput"
                [ngClass]="form2.value.status !== '0' ? 'disabledInput' : ''"
              >
                <mat-label class="selectFontText">Course Pursued</mat-label>
                <input
                  matInput
                  placeholder="Course Pursued"
                  formControlName="instituteCourse"
                  [readonly]="studyEnabler"
                />
                <mat-hint
                  *ngIf="
                    (form2.controls.instituteCourse.touched ||
                      formGroupValidator2) &&
                    form2.controls.instituteCourse.invalid
                  "
                  class="red"
                  >Enter course name</mat-hint
                >
              </mat-form-field>
            </div>
          </div>
        </div>

        <div
          class="col-12 d-flex justify-content-center mt-3"
          *ngIf="form2.value.status === '1'"
        >
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">Profession*</mat-label>
            <input
              matInput
              placeholder="Profession*"
              formControlName="professional"
            />
            <mat-hint
              *ngIf="
                (form2.controls.professional.touched || formGroupValidator2) &&
                form2.controls.professional.invalid
              "
              class="red"
              >Please enter profession</mat-hint
            >
          </mat-form-field>
        </div>

        <div
          class="col-12 d-flex justify-content-center mt-3"
          *ngIf="form2.value.status === '1'"
        >
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">Organization*</mat-label>
            <input
              matInput
              placeholder="Organization*"
              formControlName="organization"
            />
            <mat-hint
              *ngIf="
                (form2.controls.organization.touched || formGroupValidator2) &&
                form2.controls.organization.invalid
              "
              class="red"
              >Please enter the name of the organisation you work for</mat-hint
            >
          </mat-form-field>
        </div>

        <div class="col-12 d-flex justify-content-center mt-4">
          <button
            class="loginBtn mx-4"
            (click)="gotoStep(null)"
            type="button"
            mat-raised-button
          >
            Back
          </button>
          <button class="loginBtn mx-4" mat-raised-button type="submit">
            Next
          </button>
        </div>
        <div class="col-12 loginText mt-3">
          <span class="newMemberTxt" routerLink="/auth"
            >Already a member? Click to Login</span
          >
        </div>
      </div>
    </form>
  </div> -->

  <div class="col-12 mcv" *ngIf="stepTracker == 2">
    <form [formGroup]="form3" (ngSubmit)="onSubmitStep3()">
      <div class="row">
        <div class="col-12 d-flex justify-content-center mt-4">
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">Residence Category*</mat-label>
            <mat-select
              formControlName="residenceCat"
              placeholder="Residence Category*"
              class="selectFontText"
            >
              <mat-option value="0" class="selectFontText">Village</mat-option>
              <mat-option value="1" class="selectFontText">Town</mat-option>
              <mat-option value="2" class="selectFontText">City</mat-option>
            </mat-select>
            <mat-hint
              *ngIf="
                (form3.controls.residenceCat.touched || formGroupValidator3) &&
                form3.controls.residenceCat.invalid
              "
              class="red"
              >Enter residence category
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-12 d-flex justify-content-center mt-4">
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText"
              >Village/Town/City Name*</mat-label
            >
            <input
              formControlName="villageTownCityName"
              matInput
              placeholder="Village/Town/City Name*"
            />
            <mat-hint
              *ngIf="
                (form3.controls.villageTownCityName.touched ||
                  formGroupValidator3) &&
                form3.controls.villageTownCityName.invalid
              "
              class="red"
              >Enter Village/Town/City Name</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="col-12 d-flex justify-content-center mt-4">
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">Taluk/Mandal Name*</mat-label>
            <input
              formControlName="talukMandalName"
              matInput
              placeholder="Taluk/Mandal Name*"
            />
            <mat-hint
              *ngIf="
                (form3.controls.talukMandalName.touched ||
                  formGroupValidator3) &&
                form3.controls.talukMandalName.invalid
              "
              class="red"
              >Enter Taluk/Mandal Name</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="col-12 d-flex justify-content-center mt-4">
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">State*</mat-label>
                <mat-select
                  formControlName="state"
                  placeholder="State*"
                  class="selectFontText"
                  (selectionChange)="checkStatus1()"
                >
                  <!-- <mat-option value="0" class="selectFontText"
                    >National</mat-option
                > -->
                  <span *ngFor="let state of states">
                    <mat-option
                      [value]="state.state_id"
                      (click)="getAllDistricts(state.state_id)"
                      *ngIf="state.state_id != 0"
                      >{{ state.name }}</mat-option
                    >
                  </span>
                </mat-select>

                <mat-hint
                  *ngIf="
                    (form3.controls.state.touched || formGroupValidator3) &&
                    form3.controls.state.invalid
                  "
                  class="red"
                  >Select State</mat-hint
                >
              </mat-form-field>
            </div>
            <div class="col-6" *ngIf="form3.value.state === 99">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">District*</mat-label>
                <input
                  formControlName="district"
                  matInput
                  placeholder="District*"
                />

                <mat-hint
                  *ngIf="
                    (form3.controls.district.touched || formGroupValidator3) &&
                    form3.controls.district.invalid
                  "
                  class="red"
                  >Enter District</mat-hint
                >
              </mat-form-field>
            </div>
            <div class="col-6" *ngIf="form3.value.state !== 99">
              <mat-form-field appearance="outline" class="authInput">
                <mat-label class="selectFontText">District*</mat-label>
                <mat-select
                  formControlName="district_id"
                  placeholder="District*"
                  class="selectFontText"
                >
                  <span *ngFor="let district of districts">
                    <mat-option value="{{ district.district_id }}">{{
                      district.name
                    }}</mat-option>
                  </span>
                </mat-select>
                <mat-hint
                  *ngIf="
                    (form3.controls.district_id.touched ||
                      formGroupValidator3) &&
                    form3.controls.district_id.invalid
                  "
                  class="red"
                  >Select District</mat-hint
                >
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center mt-3">
          <button
            class="loginBtn mx-4"
            (click)="gotoStep(1)"
            type="button"
            mat-raised-button
          >
            Back
          </button>
          <button class="loginBtn mx-4" mat-raised-button>Next</button>
        </div>
        <div class="col-12 loginText mt-3">
          <span class="newMemberTxt" routerLink="/auth"
            >Already a member? Click to Login</span
          >
        </div>
      </div>
    </form>
  </div>

  <div class="col-12 mcv" *ngIf="stepTracker == 3">
    <form [formGroup]="form4">
      <div class="row">
        <div class="col-12 d-flex justify-content-center mb-2 abc">
          <mat-form-field
            appearance="outline"
            style="border-color: green !important"
            [ngClass]="usernameNotExists ? 'success' : ''"
            class="signupInput1"
          >
            <mat-label class="selectFontText">Select Username*</mat-label>
            <input
              matInput
              placeholder="Select Username*"
              formControlName="username"
              (blur)="usernameVerification()"
              (input)="tool = false"
            />

            <img
              class="bottompadding"
              src="/assets/careerDetail/tick.png"
              matSuffix
              style="align-items: right"
              *ngIf="usernameNotExists"
            />
            <img
              class="bottompadding"
              src="/assets/careerDetail/error.png"
              matSuffix
              style="align-items: right"
              *ngIf="
                (!usernameNotExists &&
                  (form4.controls.username.touched || formGroupValidator4) &&
                  form4.controls.username.invalid) ||
                tool
              "
              (click)="tool ? (tool = false) : (tool = true)"
              [matTooltip]="'Username must be Alphanumeric'"
            />

            <mat-hint
              *ngIf="
                (form4.controls.username.touched || formGroupValidator4) &&
                form4.controls.username.invalid
              "
              [ngClass]="usernameNotExists == true ? 'green' : 'red'"
              >{{ usernameErrorMessage }}
            </mat-hint>

            <mat-hint
              *ngIf="
                usernameNotExists &&
                !(
                  (form4.controls.username.touched || formGroupValidator4) &&
                  form4.controls.username.invalid
                )
              "
              class="green"
              >username available
            </mat-hint>
          </mat-form-field>
        </div>
        <div
          *ngIf="deviceXs && tool"
          #tooltip
          class="fakeTooltip"
          style="margin-left: auto"
        >
          Username must be Alphanumeric
        </div>

        <div
          class="col-md-12 d-flex justify-content-center mt-4 mb-2 xyz"
          style="padding-inline-start: 55px"
          *ngIf="!deviceXs"
        >
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">New Password*</mat-label>
            <input
              formControlName="password"
              matInput
              placeholder="Password"
              [type]="hide ? 'password' : 'text'"
            />
            <!-- <mat-icon matSuffix class="eyeOfPa">
          <img src="/assets/Auth/eye.svg" />
        </mat-icon> -->
            <i class="material-icons" matSuffix (click)="hide = !hide">
              {{ hide ? "visibility" : "visibility_off" }}
            </i>

            <mat-hint
              *ngIf="
                (form4.controls.password.touched || formGroupValidator4) &&
                form4.controls.password.invalid
              "
              class="red"
              >Please enter password</mat-hint
            >
          </mat-form-field>
          <img
            src="assets/Auth/information.svg"
            alt="info"
            style="width: 24px; margin-left: 15px"
            [matTooltip]="'Password must be minimum 6 characters long.'"
          />
        </div>

        <div
          class="col-md-12 d-flex justify-content-center mt-4 mb-2 xyz"
          *ngIf="!deviceXs"
        >
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">Re-enter New Password*</mat-label>
            <input
              formControlName="password2"
              matInput
              placeholder="Password"
              [type]="hide2 ? 'password' : 'text'"
            />
            <!-- <mat-icon matSuffix class="eyeOfPa">
          <img src="/assets/Auth/eye.svg" />
        </mat-icon> -->
            <i class="material-icons" matSuffix (click)="hide2 = !hide2">
              {{ hide2 ? "visibility" : "visibility_off" }}
            </i>

            <mat-hint
              *ngIf="
                ((form4.controls.password2.touched || formGroupValidator4) &&
                  form4.controls.password2.invalid) ||
                this.form4.controls.password.value !=
                  this.form4.controls.password2.value
              "
              class="red"
              >Please enter password</mat-hint
            >
          </mat-form-field>
          <!-- <img
            src="assets/Auth/information.svg"
            alt="info"
            style="width: 24px; margin-left: 15px; visibility: hidden"
          /> -->
          <!-- <div class="row">
        <button type="button" class="btn">
          <mat-icon class="sizepx">
            info
          </mat-icon>
      </button>
      </div> -->
        </div>

        <!-- for mobile app -->

        <div
          #tooltipp
          class="fakeTooltip"
          style="margin-left: auto"
          *ngIf="tool1 && deviceXs"
        >
          Password must be minimum 6 characters long.
          <!-- <ol style="list-style: none;">
        <li style="color: white;">
          1 Uppercase
        </li>
        <li style="color: white;">
          1 Lowercase
        </li>
        <li style="color: white;">
          1 Number
        </li>
        <li style="color: white;">
          1 Special Character
        </li>
        <li style="color: white;">
          8-16 Characters
        </li>
      </ol> -->
        </div>

        <div
          class="col-md-12 d-flex justify-content-center mt-4 xyz"
          style="padding-inline-start: 55px"
          *ngIf="deviceXs"
        >
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">New Password*</mat-label>
            <input
              formControlName="password"
              matInput
              placeholder="Password"
              [type]="hide ? 'password' : 'text'"
            />
            <!-- <mat-icon matSuffix class="eyeOfPa">
        <img src="/assets/Auth/eye.svg" />
      </mat-icon> -->
            <i class="material-icons" matSuffix (click)="hide = !hide">
              {{ hide ? "visibility" : "visibility_off" }}
            </i>

            <mat-hint
              *ngIf="
                (form4.controls.password.touched || formGroupValidator4) &&
                form4.controls.password.invalid
              "
              class="red"
              >Please enter password</mat-hint
            >
          </mat-form-field>

          <img
            (click)="tooltipp()"
            src="assets/Auth/information.svg"
            alt="info"
            style="width: 24px; margin-left: 15px"
            [matTooltip]="'Password must be minimum 6 characters long.'"
          />
        </div>

        <div
          class="col-md-12 d-flex justify-content-center mt-4 xyz"
          *ngIf="deviceXs"
        >
          <mat-form-field appearance="outline" class="signupInput">
            <mat-label class="selectFontText">Re-enter New Password*</mat-label>
            <input
              formControlName="password2"
              matInput
              placeholder="Password"
              [type]="hide2 ? 'password' : 'text'"
            />
            <!-- <mat-icon matSuffix class="eyeOfPa">
        <img src="/assets/Auth/eye.svg" />
      </mat-icon> -->
            <i class="material-icons" matSuffix (click)="hide2 = !hide2">
              {{ hide2 ? "visibility" : "visibility_off" }}
            </i>

            <mat-hint
              *ngIf="
                ((form4.controls.password2.touched || formGroupValidator4) &&
                  form4.controls.password2.invalid) ||
                this.form4.controls.password.value !=
                  this.form4.controls.password2.value
              "
              class="red"
              >Please enter password</mat-hint
            >
          </mat-form-field>

          <!-- <div class="row">
      <button type="button" class="btn">
        <mat-icon class="sizepx">
          info
        </mat-icon>
    </button>
    </div> -->
        </div>
      </div>
    </form>
  </div>
  <!-- <div class="col-12 d-flex justify-content-center mt-3">
    ** Removed back button because there is only one form now!!
    <button
      class="loginBtn mx-4"
      (click)="gotoStep(1)"
      type="button"
      mat-raised-button
    >
      Back
    </button>
    <button class="loginBtn mx-4" (click)="onSubmit()" mat-raised-button>
      Next
    </button>
  </div> -->
  <!-- <div class="col-12 loginText mt-3">
    <span class="newMemberTxt" routerLink="/auth"
      >Already a member? Click to Login</span
    >
  </div> -->
  <div class="col-12 d-flex justify-content-center mt-4">
    <button
      *ngIf="stepTracker === 3"
      class="loginBtn mx-4"
      (click)="gotoStep(null)"
      type="button"
      mat-raised-button
    >
      Back
    </button>
    <button class="loginBtn mx-4" mat-raised-button (click)="submitFinal()">
      Next
    </button>
  </div>
  <div class="col-12 loginText mt-3">
    <span class="newMemberTxt" routerLink="/auth"
      >Already a member? Click to Login</span
    >
  </div>
</div>
