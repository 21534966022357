<div class="pt-3">
  <div class="d-flex totalHolder">
    <div class="d-flex fixedHolder">
      <div class="d-flex titleAndBackHolder">
        <div class="d-flex titleAndBackSection">
          <mat-icon
            class="backArrow cursor-pointer"
            routerLink="/adminDashboard"
            >arrow_back</mat-icon
          >
          <div class="">Colleges Stream Filters</div>
        </div>
        <div class="d-flex displayOptions">
          <div class="cursor-pointer">Hide Options</div>
          <mat-slide-toggle color="primary" [(ngModel)]="hideOptions">
          </mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="!hideOptions" class="d-flex optionsHolder">
        <div class="d-flex align-center">
          <input
            class="searchInput"
            (keyup)="getCollegesStreamFilterData(currentPage, true)"
            placeholder="Search Course Mappings"
            [formControl]="searchFormControl"
          />
          <mat-icon
            *ngIf="searchFormControl.value?.length > 0"
            class="exportClearBtn"
            (click)="clearSearch()"
            >close</mat-icon
          >
        </div>
        <div class="filterHolder d-flex">
          <div *ngFor="let filters of filtersList">
            <mat-form-field class="filterFormField">
              <mat-label class="filterLabel">{{
                filters.filterName
              }}</mat-label>
              <mat-select
                [placeholder]="filters.filterName"
                [formControl]="filters.filterFormControl"
                (selectionChange)="
                  getCollegesStreamFilterData(currentPage, false)
                "
              >
                <mat-option
                  *ngFor="let filterOption of filters.filterOptions"
                  [value]="filterOption"
                  >{{ filterOption.filterOptionValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <div [ngClass]="hideOptions ? 'tableHolder-full' : 'tableHolder'">
          <table
            mat-table
            [dataSource]="collegesStreamFilterArr"
            class="mat-elevation-z8 w-full"
          >
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="college">
              <th mat-header-cell *matHeaderCellDef>College</th>
              <td mat-cell *matCellDef="let element">
                {{ element.college }}
              </td>
            </ng-container>

            <ng-container matColumnDef="college_stream_filter_id">
              <th mat-header-cell *matHeaderCellDef>
                College Stream Filter Id
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.college_stream_filter_id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="stream_filter">
              <th mat-header-cell *matHeaderCellDef>Stream Filter</th>
              <td mat-cell *matCellDef="let element">
                {{ element.stream_filter }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ngb-pagination
    class="d-flex justify-content-center pagination_theme"
    [maxSize]="5"
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    (pageChange)="getCollegesStreamFilterData(currentPage, false)"
  >
    <ng-template ngbPaginationPrevious>
      <img
        src="/assets/Dashboard/pageArrowPrev.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
    <ng-template ngbPaginationNext>
      <img
        src="/assets/Dashboard/pageArrowNext.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
  </ngb-pagination>
</div>
