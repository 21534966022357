import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiCallsService } from '../api-calls.service';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-college-detail',
  templateUrl: './college-detail.component.html',
  styleUrls: ['./college-detail.component.css'],
})
export class CollegeDetailComponent implements OnInit {
  @Input() deviceXs: boolean;

  queryParamId = null;
  collegeDetailResponse: any;
  alternateCourses: any;
  collegepathurl = null;
  mediaSub: Subscription;
  collegeCourses: any;
  streamFilterSub: Subscription;
  stateFilterSub: Subscription;
  instituteTypeFilterSub: Subscription;
  courseFilterSub: Subscription;
  genderFilterSub: Subscription;
  districtFilterSub: Subscription;
  instituteProminenceFilterSub: Subscription;
  categoryFilterSub: Subscription;
  hostelFacilityFilterSub: Subscription;
  queryParam: Subscription;

  streamFilter: any = '';
  stateFilter: any = '';
  instituteFilter = '';
  courseFilter: any = '';
  genderFilter: any = '';
  districtFilter = '';
  intituteProvinanceFilter: any = '';
  CategoryFilter: any = '';
  HostelFilter: any = '';

  // groupValues = {
  //     0: "Government",
  //     1: "Private",
  //     2: "NGO",
  //     3: "Aided",
  // }

  groupCategoryValues = {
    0: 'Vocational',
    1: 'Professional',
    2: 'Both',
  };

  groupProminanceValues = {
    0: 'State Level',
    1: 'National Level',
  };

  groupHostelValues = {
    0: 'Boys Only',
    1: 'Girls Only',
    3: 'Both Boys and Girls',
  };

  groupGenderValues = {
    1: 'Female Only',
    0: 'Male Only',
    3: 'Mixed',
  };

  groupStateValues = {
    1: 'Andhra Pradesh',
    2: 'Telangana',
    3: 'Karnataka',
  };

  constructor(
    private activateRoute: ActivatedRoute,
    private api: ApiCallsService,
    public dialog: MatDialog,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService
  ) {}

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.activateRoute.queryParams.subscribe((params) => {
      console.log(params['id']);
      this.queryParamId = params['id'];
    });
    this.streamFilterSub = this.globalSservice
      .getStreamCollegeFilter()
      .subscribe((value) => (this.streamFilter = value));
    this.stateFilterSub = this.globalSservice
      .getStateCollegeFilter()
      .subscribe((value) => (this.stateFilter = value));
    this.instituteTypeFilterSub = this.globalSservice
      .getInstituteTypeCollegeFilter()
      .subscribe((value) => (this.instituteFilter = value));
    this.courseFilterSub = this.globalSservice
      .getCourseCollegeFilter()
      .subscribe((value) => (this.courseFilter = value));
    this.genderFilterSub = this.globalSservice
      .getGenderCollegeFilter()
      .subscribe((value) => (this.genderFilter = value));
    this.districtFilterSub = this.globalSservice
      .getDistrictCollegeFilter()
      .subscribe((value) => (this.districtFilter = value));
    this.instituteProminenceFilterSub = this.globalSservice
      .getInstituteProminenceCollegeFilter()
      .subscribe((value) => (this.intituteProvinanceFilter = value));
    this.categoryFilterSub = this.globalSservice
      .getCategoryCollegeFilter()
      .subscribe((value) => (this.CategoryFilter = value));
    this.hostelFacilityFilterSub = this.globalSservice
      .getHostelFacilityCollegeFilter()
      .subscribe((value) => (this.HostelFilter = value));
    this.getcollegeDetailApi();
  }

  getcollegeDetailApi() {
    this.api.getcollegeDetail(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        console.log(res['college_courses']);
        this.collegeCourses = res['college_courses'];
        this.collegeDetailResponse = res;
        let altC = res['eligible_courses'];
        // this.alternateCourses = altC.split(',');
        console.log(this.collegeDetailResponse?.website?.length);
        console.log(this.alternateCourses);
        if (this.collegeDetailResponse['college_path']) {
          // this.roadmapdisabled = false
          this.collegepathurl = this.collegeDetailResponse['college_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  webLink(url) {
    // window.location.href='www.cnn.com/';

    if (url !== null && url !== undefined) {
      if (url.includes('https')) {
        window.open(url, '_blank');
      } else {
        url = '//' + url;
        window.open(url, '_blank');
      }
    }
  }

  ngOnDestroy() {
    this.streamFilterSub.unsubscribe();
    this.stateFilterSub.unsubscribe();
    this.instituteTypeFilterSub.unsubscribe();
    this.courseFilterSub.unsubscribe();
    this.genderFilterSub.unsubscribe();
    this.districtFilterSub.unsubscribe();
    this.instituteProminenceFilterSub.unsubscribe();
    this.categoryFilterSub.unsubscribe();
    this.hostelFacilityFilterSub.unsubscribe();
  }
}
