import { Component, Input, OnInit } from '@angular/core';
import { mixinDisabled } from '@angular/material/core';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-choose-career',
  templateUrl: './choose-career.component.html',
  styleUrls: ['./choose-career.component.css'],
})
export class ChooseCareerComponent implements OnInit {
  @Input() deviceXs: boolean;
  constructor(private router: Router, public mediaObserver: MediaObserver) {}

  //Vars
  options = ['Take Psychometric Test', 'Continue Searching'];
  selectedOptions = [];
  studyEnabler = true;
  routerSubscription: Subscription;
  mediaSub: Subscription;
  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
  }

  handleLogic(j) {
    console.log(j);
    this.selectedOptions = [j];
    this.studyEnabler = false;
  }

  HandleNext() {
    if (this.selectedOptions[0] == 0) {
      console.log('hello');
      window.open('http://bestcareer.vidyahelpline.org/#/login');
    }
    if (this.selectedOptions[0] == 1) {
      console.log('hello');
      // alert("Will fo to careers page")
      this.router.navigate(['/dashboard/careerList']);
    }
    if (this.selectedOptions[0] !== 0 && this.selectedOptions[0] !== 1) {
    }
  }
}
