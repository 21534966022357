<div class="container-fluid fill-auth root-auth">
  <div class="leftTopLogo">
    <img class="" src="/assets/Auth/leftTopLogo.jpg" />
  </div>
  <div class="row">
    <div
      class="col-md-6 left-block"
    >
      <!-- <div class="row left-block-row"> -->
      <!-- ** has mt-3 -->
      <!-- ** has mt-3 -->
      <div class="vrglad-auth">
        <span class="capTxt-auth"> Career Advancement Platform(CAP) </span>
      </div>
      <!-- ** has mt-5 -->
      <div class="vrglad-auth">
        <span class="vrgladChild-auth">
          <span class="primaryText">{{ isSignup ? "Signup" : "Login" }} </span>
          and explore about careers, courses, scholarships, <br />
          colleges and more important information. <br />
          Build your career road map now.
        </span>
      </div>
      <!-- ** has mt-4 -->
      <!-- <div class=""> -->
      <button
        class="signUpBtn-auth"
        [routerLink]="isSignup ? '/auth' : '/auth/signup'"
        (click)="loginScroll()"
        mat-raised-button
      >
        {{ isSignup ? "Login" : "Signup" }}
      </button>
      <!-- </div> -->
      <!-- ** has mt-5 -->
      <div class="">
        <img
          class="imageAlign"
          src="/assets/Auth/leftBanner.svg"
          height="214px"
        />
      </div>
      <div class="logoutAndTollfreeBlock">
        <div class="tollFreeBlock d-flex">
          <div class="">Toll Free Career Helpline :</div>
          <div class="tollFreeNumber">&nbsp; 1800 425 2425</div>
        </div>
        <div class="logoutBlock">
          <div class="poweredBy">
            <span>Powered by</span>
          </div>
          <div class="">
            <span class="px-2">
              <img src="/assets/Auth/vidya.png" />
            </span>
            <span class="px-2">
              <img src="/assets/Auth/vmware.png" />
            </span>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <!-- <div class=""> -->
    <div
      class="col-md-6 d-flex justify-content-center right-block"
      id="my_anchor"
    >
      <div class="boxShadowStyling">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
