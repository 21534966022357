<div class="row p-0 m-0">
  <div class="col-md-12">
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/mentorship/"
        >arrow_back</mat-icon
      >
      <span class="backTitle">Dashboard</span>
    </div>
  </div>
</div>

<div class="col-12">
  <div
    class="row p-0 m-0"
    style="
      background-color: white;
      padding: 36px 30px 20px 29px !important;
      border-radius: 6px;
    "
  >
    <img src="/assets/mentorship/mentordashboard.png" class="responsive" />
  </div>
</div>
