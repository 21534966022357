<div class="col-12 mt-3 dashboardTxt">Get Started</div>
<div class="hells pl-4 pt-3 pb-3" *ngIf="isGetStarted">
  Choose one of the options above to get started...
</div>

<div class="col-12 mt-2 dashboardTxt" *ngIf="!deviceXs">
  <div class="row d-flex justify-content-around" style="padding-right: 24px">
    <div class="col-md-6 col-lg-3 px-0 mb-3">
      <div
        class="cardx"
        (click)="goToChooseCareer()"
        [ngStyle]="{
          'box-shadow': isCurrent ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">1</div>
        <div class="cardInfo px-3">“I do not know what career to choose”</div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 px-0 mb-3">
      <div
        class="cardx"
        (click)="goToFavoriteSubject()"
        [ngStyle]="{
          'box-shadow': isCurrent2 ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">2</div>
        <div class="cardInfo px-3">
          “I have a favourite subject and I want to pick a career suited to
          that”
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 px-0 mb-3">
      <div
        class="cardx"
        (click)="goToStepsToFollow()"
        [ngStyle]="{
          'box-shadow': isCurrent4 ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">3</div>
        <div class="cardInfo px-3">
          “I know what career professional I want to become. What steps should I
          follow?”
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 px-0 mb-3">
      <div
        class="cardx"
        (click)="goToStartEarningNow()"
        [ngStyle]="{
          'box-shadow': isCurrent3 ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">4</div>
        <div class="cardInfo px-3">
          “I want to start earning as soon as possible”
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex p-3">
    <router-outlet></router-outlet>
  </div>
</div>

<!-- for mobile app -->

<div class="col-12 mt-4 dashboardTxt" *ngIf="deviceXs">
  <div class="row d-flex justify-content-around" style="padding-right: 24px">
    <div class="col-md-6 col-lg-3 px-0 mb-3">
      <div
        class="cardx"
        (click)="goToChooseCareer()"
        [ngStyle]="{
          'box-shadow': isCurrent ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">1</div>
        <div class="cardInfo px-3">“I do not know what career to choose”</div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 px-0 mb-3" *ngIf="optionActive > 1">
      <div
        class="cardx"
        (click)="goToFavoriteSubject()"
        [ngStyle]="{
          'box-shadow': isCurrent2 ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">2</div>
        <div class="cardInfo px-3">
          “I have a favourite subject and I want to pick a career suited to
          that”
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 px-0 mb-3" *ngIf="optionActive > 2">
      <div
        class="cardx"
        (click)="goToStepsToFollow()"
        [ngStyle]="{
          'box-shadow': isCurrent4 ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">3</div>
        <div class="cardInfo px-3">
          “I know what career professional I want to become. What steps should I
          follow?”
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 px-0 mb-3" *ngIf="optionActive > 3">
      <div
        class="cardx"
        (click)="goToStartEarningNow()"
        [ngStyle]="{
          'box-shadow': isCurrent3 ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">4</div>
        <div class="cardInfo px-3">
          “I want to start earning as soon as possible”
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-around" style="padding-right: 24px">
    <div class="row d-flex pr-3">
      <router-outlet></router-outlet>
    </div>
    <div class="col-md-6 col-lg-3 px-0 mb-3" *ngIf="optionActive < 2">
      <div
        class="cardx"
        (click)="goToFavoriteSubject()"
        [ngStyle]="{
          'box-shadow': isCurrent2 ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">2</div>
        <div class="cardInfo px-3">
          “I have a favourite subject and I want to pick a career suited to
          that”
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 px-0 mb-3" *ngIf="optionActive < 3">
      <div
        class="cardx"
        (click)="goToStepsToFollow()"
        [ngStyle]="{
          'box-shadow': isCurrent4 ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">3</div>
        <div class="cardInfo px-3">
          “I know what career professional I want to become. What steps should I
          follow?”
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 px-0 mb-3" *ngIf="optionActive < 4">
      <div
        class="cardx"
        (click)="goToStartEarningNow()"
        [ngStyle]="{
          'box-shadow': isCurrent3 ? '0 0 9px 0 rgba(47, 63, 67, 0.11)' : 'none'
        }"
      >
        <div class="cardNo">4</div>
        <div class="cardInfo px-3">
          “I want to start earning as soon as possible”
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <mat-accordion *ngIf = "deviceXs">
  <mat-expansion-panel >
    <mat-expansion-panel-header >
      <mat-panel-title > -->
<!-- This is the expansion title -->
<!-- <div class="cardNoMobile">3</div> -->
<!-- <div class="cardInfo px-3">
          “I have a particular career in mind. What steps should I follow?”
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header> -->
<!-- <p>This is the primary content of the panel.</p> -->
<!-- </mat-expansion-panel>
</mat-accordion> -->

<!-- <div class="container" *ngIf = "deviceXs">
    <div id="accordion" class="accordion">
        <div class="card mb-0">
            <div class="card-header collapsed" data-toggle="collapse" href="#collapseOne">
                <a class="card-title">
                    Item 1
                </a>
            </div>
            <div id="collapseOne" class="card-body collapse" data-parent="#accordion" >
                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                    aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                    craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                </p>
            </div>
            <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                <a class="card-title">
                  Item 2
                </a>
            </div>
            <div id="collapseTwo" class="card-body collapse" data-parent="#accordion" >
                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                    aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                    craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                </p>
            </div>
            <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                <a class="card-title">
                  Item 3
                </a>
            </div>
            <div id="collapseThree" class="collapse" data-parent="#accordion" >
                <div class="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                    aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. samus labore sustainable VHS.
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="container" *ngIf = "deviceXs">
  <div class="accordion" id="exampleAccordion">
    <div class="card">
      <div class="card-header" id="exItem1Header">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#exItem1" aria-expanded="false" aria-controls="exItem1">Expandable Item 1</button>
        </h5>
      </div>
      <div id="exItem1" class="collapse" aria-labelledby="exItem1Header" data-parent="#exampleAccordion">
        <div class="card-body">This is the card body. Your content is hidden initially. It is shown by clicking on the card header.</div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" id="exItem2Header">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#exItem2" aria-expanded="false" aria-controls="exItem2">Expandable Item 2</button>
        </h5>
      </div>
      <div id="exItem2" class="collapse show" aria-labelledby="exItem2Header" data-parent="#exampleAccordion">
        <div class="card-body">This is the card body. Your content is hidden initially. It is shown by clicking on the card header.</div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" id="exItem3Header">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#exItem3" aria-expanded="false" aria-controls="exItem3">Expandable Item 3</button>
        </h5>
      </div>
      <div id="exItem3" class="collapse" aria-labelledby="exItem3Header" data-parent="#exampleAccordion">
        <div class="card-body">This is the card body. Your content is hidden initially. It is shown by clicking on the card header.</div>
      </div>
    </div>
  </div>
</div> -->
