import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filterListObj, filterParamsObj } from '../models.model';
import { ApiCallsService } from '../api-calls.service';

@Component({
  selector: 'app-admin-districts',
  templateUrl: './admin-districts.component.html',
  styleUrls: ['./admin-districts.component.css'],
})
export class AdminDistrictsComponent implements OnInit {
  statesList: any[] = [];
  districtList: any[] = [];
  displayedColumns: string[] = ['id', 'district_id', 'name', 'state_name'];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  hideOptions: boolean = false;

  searchFormControl: FormControl = new FormControl();
  stateFormControl: FormControl = new FormControl();

  stateParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '-1',
  };

  searchParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  // filtersList: filterListObj[] = []

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.setDefaultControlValues();
  }

  getAllStates() {
    this.apiCaller.getAdminStates().subscribe(
      (res: any) => {
        if (res.length > 0) {
          console.log(res);
          const allStates = {
            id: -1,
            name: 'All',
            state_id: -1,
            published: 0,
          };
          this.statesList.push(allStates);
          res.forEach((stateObj, stateObjIndex) => {
            this.statesList.push(stateObj);
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  clearSearch() {
    this.searchFormControl.reset();
    this.getDistrictsData(this.currentPage, false);
  }

  setDefaultControlValues() {
    this.getAllStates();
    this.stateFormControl.patchValue(-1);
    this.getDistrictsData(1, false);
  }

  callDistrictsApi(page: number) {
    this.apiCaller
      .getDistrictsData(page, [this.stateParam, this.searchParam])
      .subscribe(
        (res: any) => {
          console.log(res);
          this.districtList = res.results;
          this.collectionSize = res.count;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getDistrictsData(page: number, isSearch: boolean) {
    this.makeParams();
    if (isSearch) {
      let searchValue: string = this.searchParam.filterParamValue as string;
      if (searchValue.length >= 3) {
        this.callDistrictsApi(page);
      }
    } else {
      this.callDistrictsApi(page);
    }
  }

  makeParams() {
    let searchValue: string = this.searchFormControl.value;
    if (searchValue) {
      if (searchValue.length >= 3) {
        this.searchParam.filterParamActive = true;
        this.searchParam.filterParamValue = searchValue;
        this.searchParam.filterParamName = 'search';
        this.currentPage = 1;
      } else {
        this.searchParam.filterParamActive = false;
      }
    } else {
      this.searchParam.filterParamActive = false;
    }

    let stateLevel: number = this.stateFormControl.value;
    if (stateLevel > -1) {
      this.stateParam.filterParamActive = true;
      this.stateParam.filterParamValue = stateLevel;
      this.stateParam.filterParamName = 'state_id';
      this.currentPage = 1;
    } else {
      this.stateParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }
}
