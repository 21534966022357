import { Component, OnInit, Input } from '@angular/core';
import { ApiCallsService } from '../api-calls.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
  providers: [NgbPaginationConfig],
})
export class NotificationsComponent implements OnInit {
  @Input() deviceXs: boolean;
  //Vars
  searchText;
  optionActive = 1;
  myControl = new FormControl();
  filterOptions: any = [];
  options: any = [];
  NotificationDetailResponse: any;
  notificationpathurl = null;
  attachmentLink = true;

  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  currentPageURL: string = null;
  currentFilterURL: string = null;
  filter: serverFilter[] = [];

  states: any = [];

  stateFilter: any = localStorage.getItem('stateValue');
  academicFilter1: any = localStorage.getItem('academicValue');
  notificationTag: any = '';

  groupTagValues = {
    0: 'Entrance Examination',
    1: 'Scholarship',
    2: 'Admission',
    3: 'Training/Skill Development',
    4: 'Government Jobs',
    5: 'Others',
  };

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver
  ) {}

  mediaSub: Subscription;

  statustext(item) {
    if (item != null && item != undefined) {
      return this.groupTagValues[item];
    }
  }

  colorTags() {
    if (this.groupTagValues[0]) {
      return;
    }
  }

  getActiveNotifications() {
    console.log('hello');
    this.apiCallService.getNotifications().subscribe(
      (res) => {
        console.log(res);
        this.options = res['results'];
        this.collectionSize = res['count'];
        // this.NotificationDetailResponse = res["results"];
        this.filterOptions = [...this.options];
        if (this.options['notification_path']) {
          this.notificationpathurl = this.options['notification_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getInActiveNotifications() {
    console.log('hello');
    this.apiCallService.getInactiveNotifications().subscribe(
      (res) => {
        console.log(res);
        this.options = res['results'];
        this.collectionSize = res['count'];
        // this.NotificationDetailResponse = res["results"];
        this.filterOptions = [...this.options];
        if (this.options['notification_path']) {
          this.notificationpathurl = this.options['notification_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  coToNotificationeDetails(jobId) {
    this.router.navigate(['/dashboard/notifications-detail/'], {
      queryParams: { id: jobId, Tag: '1', optionActive: this.optionActive },
    });
  }

  coToNotificationeTags(TagId) {
    this.router.navigate(['/dashboard/notifications-tags/'], {
      queryParams: { id: TagId },
    });
  }

  ngOnInit(): void {
    // this.verifyLogin();
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );

    this.getAllStates();
    this.filterData();

    // if(localStorage.getItem('stateValue')) {
    //   this.filterState();
    // }
    // if(localStorage.getItem('academicValue')) {
    //   this.filterAcademicLevel();
    // }

    // if(localStorage.getItem('academicValue') && localStorage.getItem('stateValue') !== '4') {
    //   // let Defaulturl = `?tag_academic_state=,${localStorage.getItem('academicValue')},${localStorage.getItem('stateValue')}`

    //   // this.getConditionalFilter(Defaulturl)
    //   this.academicFilter1 = localStorage.getItem('academicValue');
    //   this.stateFilter = parseInt(localStorage.getItem('stateValue'));
    //   this.filterAcademicLevel();
    //   this.filterState();
    // }
  }

  verifyLogin() {
    this.apiCallService.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }

  getAllStates() {
    this.apiCallService.getStates().subscribe(
      (res) => {
        console.log(res);
        this.states = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetFilter() {
    if (this.optionActive == 1) {
      if (
        localStorage.getItem('academicValue') &&
        localStorage.getItem('stateValue') !== '99'
      ) {
        let Defaulturl = `?tag_academic_state=,${localStorage.getItem(
          'academicValue'
        )},${localStorage.getItem('stateValue')}`;
        this.notificationTag = 'all';
        this.academicFilter1 = localStorage.getItem('academicValue');
        this.stateFilter = parseInt(localStorage.getItem('stateValue'));
        this.getConditionalFilter(Defaulturl);
      }
    }
  }

  filterSearch() {
    this.addOrUpdateFilter({ name: 'search', value: this.searchText });
    this.filterData();
  }

  filterState() {
    this.addOrUpdateFilter({ name: 'state', value: this.stateFilter });
    console.log(this.stateFilter);
    this.filterData();
  }

  filterAcademicLevel() {
    this.addOrUpdateFilter({
      name: 'academic_filter',
      value: this.academicFilter1,
    });
    this.filterData();
  }

  filterNotificationTag() {
    this.addOrUpdateFilter({ name: 'Tag', value: this.notificationTag });
    this.filterData();
  }

  activeClick() {
    this.optionActive = 1;
    if (
      this.stateFilter ||
      this.stateFilter === 0 ||
      this.academicFilter1 ||
      this.notificationTag ||
      this.notificationTag === 0 ||
      this.searchText
    ) {
      this.filterData();
    } else {
      this.getActiveNotifications();
    }
  }

  inactiveClick() {
    this.optionActive = 2;
    if (
      this.stateFilter ||
      this.stateFilter === 0 ||
      this.academicFilter1 ||
      this.notificationTag ||
      this.notificationTag === 0 ||
      this.searchText
    ) {
      this.filterData();
    } else {
      this.getInActiveNotifications();
    }
  }

  addOrUpdateFilter(data: serverFilter) {
    let found: boolean = false;
    this.filter.some((i, x) => {
      if (i.name === data.name) {
        this.filter[x].value = data.value;
        found = true;
        return true;
      }
    });

    if (!found) {
      this.filter.push(data);
    }
  }

  filterData() {
    let filters: string = '';
    let filterCS = '';
    let tempstream = [];
    let temptag = [];
    let tempstate = [];

    let flagAll = true;
    let defaultFlag = true;

    temptag = this.filter.filter((book) => book.name === 'Tag');

    tempstream = this.filter.filter((book) => book.name === 'academic_filter');

    tempstate = this.filter.filter((book) => book.name === 'state');

    if (temptag.length > 0 || tempstream.length > 0 || tempstate.length > 0) {
      filterCS = 'tag_academic_state=';
      if (temptag.length > 0) {
        if (temptag[0]['value'] != 'all') {
          flagAll = false;
          filterCS = filterCS + temptag[0]['value'] + ',';
        } else {
          filterCS = filterCS + ',';
        }
      } else {
        filterCS = filterCS + ',';
      }
      if (tempstream.length > 0) {
        if (tempstream[0]['value'] != 'all') {
          flagAll = false;
          defaultFlag = false;
          filterCS = filterCS + tempstream[0]['value'] + ',';
        } else {
          defaultFlag = false;
          filterCS = filterCS + ',';
        }
      }
      // to keep stram valu as it is in url when user chaning state value
      else if (localStorage.getItem('academicValue')) {
        defaultFlag = false;
        flagAll = false;
        filterCS = filterCS + localStorage.getItem('academicValue') + ',';
      } else {
        filterCS = filterCS + ',';
      }

      if (tempstate.length > 0) {
        if (tempstate[0]['value'] != 'all') {
          flagAll = false;
          defaultFlag = false;
          filterCS = filterCS + tempstate[0]['value'];
        } else {
          defaultFlag = false;
          filterCS = filterCS;
        }
      }
      // to keep state valu as it is in url when user chaning stream value
      else if (localStorage.getItem('stateValue')) {
        defaultFlag = false;
        flagAll = false;
        filterCS = filterCS + localStorage.getItem('stateValue');
      } else {
        filterCS = filterCS;
      }
    } else {
      filterCS = '';
    }
    if (flagAll) {
      filterCS = '';
    }
    if (defaultFlag) {
      filterCS = `tag_academic_state=,${localStorage.getItem(
        'academicValue'
      )},${localStorage.getItem('stateValue')}`;
    }

    this.filter.forEach((i) => {
      if (i.value !== '' && i.value !== 'all') {
        if (
          i.name !== 'Tag' &&
          i.name !== 'academic_filter' &&
          i.name !== 'state'
        ) {
          filters = `${filters}&${i.name}=${i.value}`;
        }
      }
    });

    let url;
    if (filters.trim() != '') {
      this.currentFilterURL = filterCS + '&' + filters;
      url = `?${filterCS}&${filters.substring(1, filters.length)}`;
    } else {
      this.currentFilterURL = filterCS + filters;
      url = `?${filterCS}${filters.substring(1, filters.length)}`;
    }
    console.log(url);
    this.getConditionalFilter(url);
  }

  onPageChange(currentPage) {
    let url = null;
    if (currentPage != 0) {
      url = `page=${currentPage}`;
      this.currentPageURL = url;
      if (this.currentFilterURL !== null) {
        url = `?${url}&${this.currentFilterURL}`;
      } else {
        url = `?${url}`;
      }
    }
    this.previousPage = currentPage;
    this.getConditionalFilter(url);
  }

  getConditionalFilter(url) {
    if (this.optionActive == 1) {
      this.apiCallService.getNotifications(url).subscribe(
        (res) => {
          console.log(res);
          this.options = res['results'];
          this.collectionSize = res['count'];
          this.filterOptions = [...this.options];
        },
        (err) => {
          console.log(err);
        }
      );
    } else if (this.optionActive == 2) {
      this.apiCallService.getInactiveNotifications(url).subscribe(
        (res) => {
          console.log(res);
          this.options = res['results'];
          this.collectionSize = res['count'];
          this.filterOptions = [...this.options];
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}

class serverFilter {
  name: string;
  value: any;
}
