import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filterParamsObj } from '../models.model';
import { ApiCallsService } from '../api-calls.service';

@Component({
  selector: 'app-admin-colleges-course-mapping',
  templateUrl: './admin-colleges-course-mapping.component.html',
  styleUrls: ['./admin-colleges-course-mapping.component.css'],
})
export class AdminCollegesCourseMappingComponent implements OnInit {
  collegesCourseMappingsArr: any[] = [];
  // statesList: any[] = [];
  displayedColumns: string[] = [
    'id',
    'name',
    'college_id',
    // 'college_name',
    'fee',
    'entrance_exam',
    'enrolment_size',
    'duration',
  ];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  hideOptions: boolean = false;

  searchFormControl: FormControl = new FormControl();

  searchParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.getCollegesCourseMappingData(1, false);
  }

  hideOptionsFunc() {
    this.hideOptions = !this.hideOptions;
  }

  callCollegesCourseMappingApi(page: number) {
    this.apiCaller
      .getCollegesCourseMappings(page, [this.searchParam])
      .subscribe(
        (res: any) => {
          console.log(res);
          this.collegesCourseMappingsArr = res.results;
          this.collectionSize = res.count;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getCollegesCourseMappingData(page: number, isSearch: boolean) {
    this.makeParams();
    if (isSearch) {
      let searchValue: string = this.searchParam.filterParamValue as string;
      if (searchValue.length >= 3) {
        this.callCollegesCourseMappingApi(page);
      }
    } else {
      this.callCollegesCourseMappingApi(page);
    }
  }

  clearSearch() {
    this.searchFormControl.reset();
    this.getCollegesCourseMappingData(this.currentPage, false);
  }

  makeParams() {
    let searchValue: string = this.searchFormControl.value;
    if (searchValue) {
      if (searchValue.length > 3) {
        this.searchParam.filterParamActive = true;
        this.searchParam.filterParamValue = searchValue;
        this.searchParam.filterParamName = 'search';
        this.currentPage = 1;
      } else {
        this.searchParam.filterParamActive = false;
      }
    } else {
      this.searchParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }
}
