import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../api-calls.service';
import {
  District,
  PaginatorRes,
  State,
  TrainingInstituteCourse,
  TrainingInstituteData,
  TrainingListChoices,
  TrainingListChoicesApiRes,
  TrainingListChoicesList,
  TrainingListFilters,
} from '../models.model';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-training-institute-list',
  templateUrl: './training-institute-list.component.html',
  styleUrls: ['./training-institute-list.component.css'],
})
export class TrainingInstituteListComponent implements OnInit {
  choicesList: TrainingListChoicesList[] = [
    {
      choiceFormControl: new FormControl(-1),
      choiceName: 'Category',
      filterName: 'category',
      choices: [],
    },
    // {
    //   choiceFormControl: new FormControl(''),
    //   choiceName: 'Category 2',
    //   filterName: 'category_2',
    //   choices: [],
    // },
    // {
    //   choiceFormControl: new FormControl(''),
    //   choiceName: 'Category 3',
    //   filterName: 'category_3',
    //   choices: [],
    // },
    {
      choiceFormControl: new FormControl(-1),
      choiceName: 'Minimum Qualification',
      filterName: 'minimum_qualification__gte',
      choices: [],
    },
    {
      choiceFormControl: new FormControl(-1),
      choiceName: 'Institution Type',
      filterName: 'institute_type',
      choices: [],
    },
    {
      choiceFormControl: new FormControl(-1),
      choiceName: 'Gender',
      filterName: 'gender',
      choices: [],
    },
    {
      choiceFormControl: new FormControl(-1),
      choiceName: 'Only Physically Handicapped',
      filterName: 'only_physically_handicapped',
      choices: [],
    },
    {
      choiceFormControl: new FormControl(-1),
      choiceName: 'State',
      filterName: 'state',
      choices: [],
    },
    {
      choiceFormControl: new FormControl(-1),
      choiceName: 'District',
      filterName: 'district',
      choices: [],
    },
  ];

  tableColumns: string[] = [
    'Institute Name',
    'Course',
    'Minimum Qualification',
    // 'Gender',
    // 'Applicable Only for Physically Handicapped',
    // 'Age Restriction',
    // 'Accomodation',
    'Approximate Notification Time',
    // 'Stipend',
    'State',
    // 'District',
    // 'Address And Contact Number',
    // 'Website Address',
  ];

  // ** Form controls
  // category1FormControl = new FormControl('');
  // category2FormControl = new FormControl('');
  // category3FormControl = new FormControl('');
  // qualificationFormControl = new FormControl('');
  // institutionTypeFormControl = new FormControl('');
  // genderFormControl = new FormControl('');
  // onlyPhysicallyHandicappedFormControl = new FormControl('');
  // stateFormControl = new FormControl('');
  // districtFormControl = new FormControl('');

  searchFormControl = new FormControl('');

  statesList: State[] = [];

  trainingInstitutesData: TrainingInstituteData[] = [];

  collectionSize: number = 0;
  // pageSize: number = 50;
  pageSize: number = 50;
  currentPage: number = 1;

  gettingTrainingInstitutesData = true;

  trainingListFiltersSubscription: Subscription = new Subscription();

  // isStatesFilterDisabled: boolean = false;

  isFromDetailPage: boolean = false;

  constructor(
    private apiCaller: ApiCallsService,
    private router: Router,
    private globalService: GlobalService
  ) {
    const previousUrl = this.router
      .getCurrentNavigation()
      .previousNavigation?.finalUrl?.toString();
    console.log(previousUrl);
    let previousUrlSplit = previousUrl?.split('/');
    console.log(previousUrlSplit);
    if (
      previousUrlSplit?.length > 3 &&
      previousUrlSplit[2] === 'trainingInstituteList' &&
      (previousUrlSplit[3] !== null ||
        previousUrlSplit[3] !== undefined ||
        previousUrlSplit[3] !== '')
    ) {
      console.log('getting filters');
      this.getAllTrainingFilters();
      this.isFromDetailPage = true;
    }
  }

  ngOnInit(): void {
    this.getAllStates();
  }

  printVal(val) {
    console.log(val);
  }

  getAllTrainingFilters() {
    this.trainingListFiltersSubscription = this.globalService
      .getTrainingInstituteFilters()
      .subscribe(
        (res: TrainingListFilters) => {
          console.log(res);
          if (res) {
            this.choicesList.forEach((displayChoice) => {
              Object.keys(res).forEach((FilterKey) => {
                if (displayChoice.filterName === FilterKey) {
                  displayChoice.choiceFormControl.setValue(res[FilterKey]);
                }
              });
            });
            this.searchFormControl.setValue(res.search);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getAllStates() {
    this.apiCaller.getStates().subscribe((res: State[]) => {
      console.log(res);
      this.statesList = [
        { id: -1, name: 'All', published: 0, state_id: -1 },
        ...res,
      ];
      // if (this.isFromDetailPage) {
      //   this.getAllTrainingFilters();
      // }
      let stateChoice = this.choicesList.find(
        (choice) => choice.choiceName === 'State'
      );
      this.getDistricts(stateChoice, false);
      this.getAllTrainingRelatedChoices();
    });
  }

  getAllTrainingRelatedChoices() {
    this.apiCaller.getAllTrainingRelatedChoices().subscribe(
      (res: TrainingListChoicesApiRes) => {
        console.log(res);
        res.choicesList.forEach((resChoiceList) => {
          let allObjChoices: TrainingListChoices[] | State[] | District[] = [
            { choiceKey: -1, choiceValue: 'All' },
          ];
          resChoiceList.choices = [...allObjChoices, ...resChoiceList.choices];
          console.log(resChoiceList.choices);
          this.choicesList.forEach((displayChoiceList) => {
            if (
              resChoiceList.choiceName === 'categories' &&
              displayChoiceList.choiceName === 'Category'
            ) {
              displayChoiceList.choices = resChoiceList.choices;
            } else if (
              resChoiceList.choiceName === 'qualifications' &&
              displayChoiceList.choiceName === 'Minimum Qualification'
            ) {
              displayChoiceList.choices = resChoiceList.choices;
            } else if (
              resChoiceList.choiceName === 'institutionType' &&
              displayChoiceList.choiceName === 'Institution Type'
            ) {
              displayChoiceList.choices = resChoiceList.choices;
            } else if (
              resChoiceList.choiceName === 'gender' &&
              displayChoiceList.choiceName === 'Gender'
            ) {
              displayChoiceList.choices = resChoiceList.choices;
            } else if (
              resChoiceList.choiceName === 'yesorno' &&
              displayChoiceList.choiceName === 'Only Physically Handicapped'
            ) {
              displayChoiceList.choices = resChoiceList.choices;
            } else if (displayChoiceList.choiceName === 'State') {
              displayChoiceList.choices = this.statesList;
            }
          });
        });
        this.getTrainingInstitutesData();
      },
      (err) => [console.log(err)]
    );
  }

  getDistricts(choice: TrainingListChoicesList, isDropdownChange: boolean) {
    console.log(this.choicesList);
    if (
      choice.choiceName === 'State' &&
      choice.choiceFormControl.value !== ''
    ) {
      let districtObj: TrainingListChoicesList = this.choicesList.find(
        (choiceObj) => choiceObj.choiceName === 'District'
      );
      districtObj.choices = [
        {
          id: -1,
          name: 'All',
          state_id: -1,
          district_id: -1,
        },
      ];
      if (isDropdownChange) {
        districtObj.choiceFormControl.setValue(-1);
      }
      this.apiCaller
        .getDistricts(
          choice.choiceFormControl.value > -1
            ? choice.choiceFormControl.value
            : ''
        )
        .subscribe(
          (res: District[]) => {
            // this.isStatesFilterDisabled = true;
            console.log(res);
            districtObj.choices = districtObj.choices.concat(res);
            if (isDropdownChange) {
              this.getTrainingInstitutesData();
            }
            // this.isStatesFilterDisabled = false;
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  areDistrictsDisabled(choiceName: string) {
    if (choiceName === 'District') {
      let stateFormControl = this.choicesList.find(
        (choice) => choice.choiceName === 'State'
      ).choiceFormControl;
      if (stateFormControl.value === '' || stateFormControl.value === null) {
        return true;
      }
    }
  }

  filterMaker(): string {
    let urlFilter: string = '?' + 'page=' + this.currentPage;
    // let count = 0;
    if (this.searchFormControl.value !== '') {
      urlFilter = urlFilter + '&' + 'search=' + this.searchFormControl.value;
      // count = 1;
    }
    this.choicesList.forEach((choice) => {
      console.log('formValue = ', choice.choiceFormControl.value);
      if (
        choice.choiceFormControl.value !== '' &&
        choice.choiceFormControl.value !== 'All' &&
        choice.choiceFormControl.value !== -1
      ) {
        console.log(choice.choiceFormControl.value);
        // count++;
        // if (count > 1) {
        urlFilter =
          urlFilter +
          '&' +
          choice.filterName +
          '=' +
          choice.choiceFormControl.value;
        // } else {
        //   urlFilter =
        //     urlFilter +
        //     '?' +
        //     choice.filterName +
        //     '=' +
        //     choice.choiceFormControl.value;
        // }
      }
    });
    return urlFilter;
  }

  filterComparer(trainingInstitute: TrainingInstituteData) {
    const trainingInstituteKeys: string[] = Object.keys(trainingInstitute);
    let elementFound = false;
    for (const key of trainingInstituteKeys) {
      if (key !== 'id') {
        if (key === 'state' || key === 'district') {
          if (
            trainingInstitute[key].name
              .toLowerCase()
              .includes(this.searchFormControl.value.toLowerCase())
          ) {
            elementFound = true;
            break;
          }
        } else if (key === 'trainingInstituteRelatedCourses') {
          for (const trainingCourse of trainingInstitute.trainingInstituteRelatedCourses) {
            if (
              trainingCourse.course.course_name
                .toLowerCase()
                .includes(this.searchFormControl.value.toLowerCase())
            ) {
              elementFound = true;
              break;
            }
          }
        } else {
          console.log('key --', key, trainingInstitute[key]);
          if (
            trainingInstitute[key]
              .toLowerCase()
              .includes(this.searchFormControl.value.toLowerCase())
          ) {
            console.log('found');
            elementFound = true;
            break;
          }
        }
      }
    }
    if (elementFound) {
      return trainingInstitute;
    }
  }

  searchTrigger(isClearSearch: boolean) {
    // if (!isClearSearch) {
    // if (this.searchFormControl.value.length >= 3) {
    //   //   // this.trainingInstitutesData = this.trainingInstitutesData.filter(
    //   //   //   (trainingInstitute) => {
    //   //   //     console.log(trainingInstitute);
    //   //   //     return this.filterComparer(trainingInstitute);
    //   //   //   }
    //   //   // );
    //   //   this.collectionSize = this.trainingInstitutesData.length;
    // } else if (this.searchFormControl.value.length === 0) {
    //   this.getTrainingInstitutesData();
    // }
    if (isClearSearch) {
      if (this.searchFormControl.value.length >= 1) {
        this.searchFormControl.setValue('');
        this.getTrainingInstitutesData();
      }
    } else {
      if (
        this.searchFormControl.value.length >= 3 ||
        this.searchFormControl.value.length === 0
      ) {
        this.getTrainingInstitutesData();
      }
    }
  }

  getTrainingInstitutesData() {
    this.trainingInstitutesData = [];
    this.gettingTrainingInstitutesData = true;
    let urlFilter: string = this.filterMaker();
    this.apiCaller.getAllTrainingInstitutesData(urlFilter).subscribe(
      (res: PaginatorRes) => {
        console.log(res);
        res.results.forEach((result) => {
          for (const resultProp in result) {
            if (
              result[resultProp] === null ||
              result[resultProp] === undefined ||
              result[resultProp] === ''
            ) {
              result[resultProp] = 'N/A';
            }
          }
        });
        this.trainingInstitutesData = res.results;
        this.collectionSize = res.count;
        if (this.searchFormControl.value.length >= 3) {
          this.trainingInstitutesData = this.trainingInstitutesData.filter(
            (trainingInstitute) => {
              console.log(trainingInstitute);
              return this.filterComparer(trainingInstitute);
            }
          );
          // this.collectionSize = this.trainingInstitutesData.length;
        }
        this.gettingTrainingInstitutesData = false;
      },
      (err) => {
        console.log(err);
        this.gettingTrainingInstitutesData = false;
      }
    );
  }

  joinAllRelatedTrainingCourses(
    trainingCourses: TrainingInstituteCourse[]
  ): string {
    // console.log(trainingCourses)
    let courses: string = '';
    trainingCourses.forEach((trainingCourse, courseIndex) => {
      // console.log(course)
      let separator = courseIndex === trainingCourses.length - 1 ? '' : ', ';
      courses = courses + trainingCourse.course.course_name + separator;
    });
    return courses !== '' ? courses : 'N/A';
  }

  pageChanger(currentPage: number) {
    console.log('pageNumber : ', this.currentPage);
    this.getTrainingInstitutesData();
  }

  setFiltersAndNavigateToDetail(trainingInstituteId: number) {
    let trainingListFilterObj: TrainingListFilters = {
      search: '',
      category: '',
      minimum_qualification__gte: '',
      institute_type: '',
      gender: '',
      only_physically_handicapped: '',
      state: '',
      district: '',
    };
    this.choicesList.forEach((choice) => {
      Object.keys(trainingListFilterObj).forEach((key) => {
        if (key === choice.filterName) {
          console.log(key, choice.filterName, choice.choiceFormControl.value);
          trainingListFilterObj[key] = choice.choiceFormControl.value;
        }
      });
    });
    trainingListFilterObj.search = this.searchFormControl.value;
    this.globalService.setTrainingInstituteFilters(trainingListFilterObj);
    this.router.navigate([
      `/dashboard/trainingInstituteList/${trainingInstituteId}`,
    ]);
  }
}
