import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '../api-calls.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-bankloan-list',
  templateUrl: './bankloan-list.component.html',
  styleUrls: ['./bankloan-list.component.css'],
})
export class BankloanListComponent implements OnInit, OnDestroy {
  @Input() deviceXs: boolean;

  //Vars
  options: any = [];
  filterOptions: any = [];
  // streams:any = [
  //   'Agriculture & Related Fields',
  //   'Armed Forces & Central Police',
  //   'Arts, Design, Creative',
  //   'Banking & Finance',
  //   'Building & Construction',
  //   'Clothing & Accessories',
  //   'Education & Training',
  //   'Hardware, Electronics & Telecommunications',
  //   'Engineering & Manufacturing',
  //   'Fitness & Leisure',
  //   'Healthcare & Life Sciences',
  //   'IT & ITES',
  //   'Social Sciences & Human Services',
  //   'Law & Order',
  //   'Management & Marketing',
  //   'Media & Entertainment',
  //   'Public Administration & Government',
  //   'Retail, Tourism and Hospitality',
  //   'Self Employment',
  //   'Transportation, Logistics, Warehousing'
  // ]
  searchText;

  // streamFilter = ''
  prefferdGroupFilter = '';
  study_location_filter: any = '';
  // salaryFilter = ''

  // groupValues = {
  //   0: "MPC",
  //   1: "BiPC",
  //   2: "CEC",
  //   3: "HEC",
  //   4: "Vocational",
  //   5: "MEC",
  //   6: "MBiPC",
  //   7: "MPC or BiPC",
  //   8: "MEC or CEC",
  //   9: "Any Group",
  //   10: "Not Applicable",
  // }

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService,
    public route: ActivatedRoute
  ) {}

  //Form Control
  myControl = new FormControl();
  mediaSub: Subscription;
  courseFilterSub: Subscription;
  studyFilterSub: Subscription;
  queryParam: Subscription;

  getAllJobs() {
    console.log('hello');
    this.apiCallService.getLoan().subscribe(
      (res) => {
        console.log(res);
        this.options = res;
        this.filterLoan();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  coToBankLoanDetails(jobId) {
    // let jobId = this.options[j]['id']
    // alert(jobId)
    window.scroll(0, 0);
    this.router.navigate(['/dashboard/bankloanDetail/'], {
      queryParams: { id: jobId },
    });
  }

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    // this.apiCallService.verifyLogin();
    this.queryParam = this.route.queryParams.subscribe((params: Params) => {
      this.prefferdGroupFilter = params.course ? params.course : '';
      const study = params.study ? parseInt(params.study) : parseInt('');
      this.study_location_filter = isNaN(study)
        ? params.study === 'all'
          ? 'all'
          : ''
        : study;
    });
    this.getAllJobs();
  }
  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  filterLoan() {
    // console.log(this.streamFilter)
    let filterLoan: any = [...this.options];
    // if(this.prefferdGroupFilter !== ''  && this.prefferdGroupFilter !== 'all')
    // {
    //   filteredCareers = filteredCareers.filter(option => option.prefered_group === this.prefferdGroupFilter)
    // }

    this.globalSservice.setCourseBankFilter(this.prefferdGroupFilter);
    this.globalSservice.setStudyBankFilter(this.study_location_filter);
    if (this.prefferdGroupFilter !== '' || this.study_location_filter !== '') {
      this.router.navigate(['/dashboard/bankloanlist'], {
        queryParams: {
          course: this.prefferdGroupFilter,
          study: this.study_location_filter,
        },
      });
    }

    if (this.prefferdGroupFilter !== '' && this.prefferdGroupFilter !== 'all') {
      // if(this.prefferdGroupFilter === '0'){
      //   filterLoan = filterLoan.filter(option => option.school_course_filter === 0)
      // }
      if (this.prefferdGroupFilter === '1') {
        filterLoan = filterLoan.filter(
          (option) => option.vocational_course_filter === 0
        );
      }
      if (this.prefferdGroupFilter === '2') {
        filterLoan = filterLoan.filter(
          (option) => option.grad_course_filter === 0
        );
      }
      if (this.prefferdGroupFilter === '3') {
        filterLoan = filterLoan.filter(
          (option) => option.pg_course_filter === 0
        );
      }
    }
    if (
      this.study_location_filter !== '' &&
      this.study_location_filter !== 'all'
    ) {
      filterLoan = filterLoan.filter(
        (option) => option.study_location === this.study_location_filter
      );
    }
    // if(this.salaryFilter !== ''  && this.salaryFilter !== 'all')
    // {
    //   filteredCareers = filteredCareers.filter(option => option.salary_range === this.salaryFilter)
    // }
    this.filterOptions = filterLoan;
    console.log(filterLoan);
  }
}
