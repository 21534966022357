import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthModuleComponent } from './auth-module/auth-module.component';
import { ChooseCareerComponent } from './choose-career/choose-career.component';
import { DashboardLandingComponent } from './dashboard-landing/dashboard-landing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FavoriteSubjectComponent } from './favorite-subject/favorite-subject.component';
import { ForgotPasswordRootComponent } from './forgot-password-root/forgot-password-root.component';
import { GetOTPComponent } from './get-otp/get-otp.component';
import { GetStartedLandingComponent } from './get-started-landing/get-started-landing.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { LoginRootComponent } from './login-root/login-root.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SignupRootComponent } from './signup-root/signup-root.component';
import { SignupComponent } from './signup/signup.component';
import { StartNowComponent } from './start-now/start-now.component';
import { StepsToFollowComponent } from './steps-to-follow/steps-to-follow.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { AdminAuthGuard, AuthGuard } from './auth.guard';
import { CareesrListComponent } from './careesr-list/careesr-list.component';
import { CareerDetailComponent } from './career-detail/career-detail.component';
import { CopylistComponent } from './course-list/copylist.component';
import { ScholarshiplistComponent } from './scholarshiplist/scholarshiplist.component';
import { ScholarshipDetailComponent } from './scholarship-detail/scholarship-detail.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { EntranceexamListComponent } from './entranceexam-list/entranceexam-list.component';
import { CollegeListComponent } from './college-list/college-list.component';
import { HostelsListComponent } from './hostels-list/hostels-list.component';
import { BankloanListComponent } from './bankloan-list/bankloan-list.component';

import { EntranceDetailComponent } from './entrance-detail/entrance-detail.component';
import { HostelDetailComponent } from './hostel-detail/hostel-detail.component';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';

import { BankloanDetailComponent } from './bankloan-detail/bankloan-detail.component';
import { CollegeDetailComponent } from './college-detail/college-detail.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsAdmissionComponent } from './notifications-Tags/notifications-admission.component';
import { NotificationsGovernmentJobsComponent } from './notificationsDetail/notifications-government-jobs.component';
import { ProfileComponent } from './profile/profile.component';
import { DistanceEducationListComponent } from './distance-education-list/distance-education-list.component';
import { TrainingInstituteListComponent } from './training-institute-list/training-institute-list.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminNotificationsComponent } from './admin-notifications/admin-notifications.component';
import { AdminMasterDashboardComponent } from './admin-master-dashboard/admin-master-dashboard.component';
import { ResourcesComponent } from './resources/resources.component';
import { AdminDeleteNotificationsComponent } from './admin-delete-notifications/admin-delete-notifications.component';

import { CareerMithraComponent } from './career-mithra/career-mithra.component';
import { MentoshipComponent } from './mentoship/mentoship.component';
import { HolisticMentoringComponent } from './holistic-mentoring/holistic-mentoring.component';
import { MentorFaqsComponent } from './mentor-faqs/mentor-faqs.component';
import { StudentFaqsComponent } from './student-faqs/student-faqs.component';
import { MentorDashboardComponent } from './mentor-dashboard/mentor-dashboard.component';
import { ProjectBasedMentoringComponent } from './project-based-mentoring/project-based-mentoring.component';
import { SkillBasedMentoringComponent } from './skill-based-mentoring/skill-based-mentoring.component';
import { MentoshipResourcesComponent } from './mentoship-resources/mentoship-resources.component';
import { ResourceMentorvideosComponent } from './resource-mentorvideos/resource-mentorvideos.component';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { DashboardGuard, MasterDashboardGuard } from './dashboard.guard';
import { AdminScholarshipsComponent } from './admin-scholarships/admin-scholarships.component';
import { AdminEntrancesComponent } from './admin-entrances/admin-entrances.component';
import { AdminCollegesComponent } from './admin-colleges/admin-colleges.component';
import { AdminStatesComponent } from './admin-states/admin-states.component';
import { AdminDistrictsComponent } from './admin-districts/admin-districts.component';
import { TrainingInstituteDetailComponent } from './training-institute-detail/training-institute-detail.component';

const routes: Routes = [
  { path: 'a', component: LoginMobileComponent },

  {
    path: 'auth',
    component: AuthModuleComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: LoginRootComponent },
      { path: 'forgotpassword', component: ForgotPasswordRootComponent },
      { path: 'getotp', component: GetOTPComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'otpverify', component: GetOTPComponent },
      { path: 'resetpassword', component: ResetpasswordComponent },
      { path: 'changepassword', component: ChangepasswordComponent },
    ],
  },
  { path: 'admin', component: AdminLoginComponent },
  {
    path: 'master-dashboard',
    canActivate: [MasterDashboardGuard],
    component: MasterDashboardComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivateChild: [DashboardGuard],
    children: [
      { path: '', component: DashboardLandingComponent },
      { path: 'notifications', component: NotificationsComponent },
      {
        path: 'notifications-tags',
        component: NotificationsAdmissionComponent,
      },
      {
        path: 'notifications-detail',
        component: NotificationsGovernmentJobsComponent,
      },
      { path: 'profile', component: ProfileComponent },
      { path: 'resources', component: ResourcesComponent },
      { path: 'careermithra', component: CareerMithraComponent },

      {
        path: 'getstarted',
        component: GetStartedComponent,
        children: [
          { path: '', component: GetStartedLandingComponent },
          { path: 'chooseCareer', component: ChooseCareerComponent },
          { path: 'favoriteSubject', component: FavoriteSubjectComponent },
          { path: 'startEarningNow', component: StartNowComponent },
          { path: 'particularCareer', component: StepsToFollowComponent },
        ],
      },
      { path: 'careerList', component: CareesrListComponent },
      { path: 'careerDetail', component: CareerDetailComponent },
      { path: 'courseList', component: CopylistComponent },
      { path: 'scholarshiplist', component: ScholarshiplistComponent },
      { path: 'scholarshiplistDetail', component: ScholarshipDetailComponent },
      { path: 'courselistDetail', component: CourseDetailComponent },
      { path: 'entrancelist', component: EntranceexamListComponent },
      { path: 'collegelist', component: CollegeListComponent },
      { path: 'hostellist', component: HostelsListComponent },
      { path: 'bankloanlist', component: BankloanListComponent },

      { path: 'entrancelistdetail', component: EntranceDetailComponent },
      { path: 'hostellistdetail', component: HostelDetailComponent },

      { path: 'bankloanDetail', component: BankloanDetailComponent },
      { path: 'collegeDetail', component: CollegeDetailComponent },
      {
        path: 'distanceEducationList',
        component: DistanceEducationListComponent,
      },
      {
        path: 'trainingInstituteList',
        component: TrainingInstituteListComponent,
      },
      {
        path: 'trainingInstituteList/:id',
        component: TrainingInstituteDetailComponent,
      },
      {
        path: 'mentorship',
        component: MentoshipComponent,
        children: [
          { path: 'holisticmentoring', component: HolisticMentoringComponent },
        ],
      },
      { path: 'holisticmentoring', component: HolisticMentoringComponent },
      { path: 'projectmentoring', component: ProjectBasedMentoringComponent },
      { path: 'skillmentoring', component: SkillBasedMentoringComponent },
      { path: 'mentorfaqs', component: MentorFaqsComponent },
      { path: 'studentfaqs', component: StudentFaqsComponent },
      { path: 'mentordashboard', component: MentorDashboardComponent },
      { path: 'mentorresources', component: MentoshipResourcesComponent },
      { path: 'resourcevideos', component: ResourceMentorvideosComponent },
    ],
  },

  {
    path: 'adminDashboard',
    component: AdminDashboardComponent,
    children: [
      { path: '', component: AdminMasterDashboardComponent },
      { path: 'notifications', component: AdminNotificationsComponent },
      {
        path: 'deleteNotifications',
        component: AdminDeleteNotificationsComponent,
      },
      { path: 'scholarships', component: AdminScholarshipsComponent },
      { path: 'entrances', component: AdminEntrancesComponent },
      { path: 'colleges', component: AdminCollegesComponent },
      { path: 'states', component: AdminStatesComponent },
      { path: 'districts', component: AdminDistrictsComponent },
      // { path: 'notifications', component: NotificationsComponent },
      // { path: 'notifications-tags', component: NotificationsAdmissionComponent },
      // { path: 'notifications-detail', component: NotificationsGovernmentJobsComponent },
      // { path: 'profile', component: ProfileComponent },
    ],
  },

  {
    path: '**',
    redirectTo: 'master-dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
