<div class="p-5">
  <div class="d-flex viewOptionsHolder">
    <div class="font-600">Select a view</div>
    <mat-radio-group
      [(ngModel)]="selectedDisplayView"
      aria-label="Select an option"
      class="d-flex collegeViewSelector"
    >
      <mat-radio-button
        *ngFor="let displayObj of collegesDisplayList"
        [value]="displayObj.displayValue"
        >{{ displayObj.displayName }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
  <div *ngIf="selectedDisplayView === 0" class="pt-3">
    <div class="d-flex totalHolder">
      <div class="d-flex fixedHolder">
        <div class="d-flex titleAndBackHolder">
          <div class="d-flex titleAndBackSection">
            <mat-icon
              class="backArrow cursor-pointer"
              routerLink="/adminDashboard"
              >arrow_back</mat-icon
            >
            <div class="">Colleges</div>
          </div>
          <div class="d-flex displayOptions">
            <div class="cursor-pointer">Hide Options</div>
            <mat-slide-toggle color="primary" [(ngModel)]="hideOptions">
            </mat-slide-toggle>
          </div>
        </div>
        <div *ngIf="!hideOptions" class="d-flex optionsHolder">
          <div class="d-flex align-center">
            <input
              class="searchInput"
              (keyup)="getCollegesData(currentPage, true)"
              placeholder="Search Colleges"
              [formControl]="searchFormControl"
            />
            <mat-icon
              *ngIf="searchFormControl.value?.length > 0"
              class="exportClearBtn"
              (click)="clearSearch()"
              >close</mat-icon
            >
          </div>
          <div class="filterHolder d-flex">
            <div *ngFor="let filters of filtersList">
              <mat-form-field class="filterFormField">
                <mat-label class="filterLabel">{{
                  filters.filterName
                }}</mat-label>
                <mat-select
                  [placeholder]="filters.filterName"
                  [formControl]="filters.filterFormControl"
                  (selectionChange)="getCollegesData(currentPage, false)"
                >
                  <mat-option
                    *ngFor="let filterOption of filters.filterOptions"
                    [value]="filterOption"
                    >{{ filterOption.filterOptionValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="filterFormField">
                <mat-label class="filterLabel">States</mat-label>
                <mat-select
                  placeholder="States"
                  [formControl]="stateFormControl"
                  (selectionChange)="getCollegesData(currentPage, false)"
                >
                  <mat-option
                    *ngFor="let stateObj of statesList"
                    [value]="stateObj.state_id"
                  >
                    {{ stateObj.name }}</mat-option
                  ></mat-select
                >
              </mat-form-field>
            </div>
          </div>
        </div>
        <div>
          <div [ngClass]="hideOptions ? 'tableHolder-full' : 'tableHolder'">
            <table
              mat-table
              [dataSource]="collegesArr"
              class="mat-elevation-z8 w-full"
            >
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
              </ng-container>

              <ng-container matColumnDef="college_id">
                <th mat-header-cell *matHeaderCellDef>College ID</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.college_id }}
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="institution_type">
                <th mat-header-cell *matHeaderCellDef>Institution Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.institution_type }}
                </td>
              </ng-container>

              <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef>Address</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.address }}
                </td>
              </ng-container>

              <ng-container matColumnDef="courses_offered">
                <th mat-header-cell *matHeaderCellDef>Courses Offered</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.courses_offered }}
                </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>State</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.state?.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="district_id">
                <th mat-header-cell *matHeaderCellDef>District</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.district_id?.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef>Gender</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.gender }}
                </td>
              </ng-container>

              <ng-container matColumnDef="hostel_facility">
                <th mat-header-cell *matHeaderCellDef>Hostel Facility</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.hostel_facility }}
                </td>
              </ng-container>

              <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef>Category</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.category }}
                </td>
              </ng-container>

              <ng-container matColumnDef="institute_prominence">
                <th mat-header-cell *matHeaderCellDef>Institute Prominence</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.institute_prominence }}
                </td>
              </ng-container>

              <ng-container matColumnDef="filter_management_1">
                <th mat-header-cell *matHeaderCellDef>Filter Management 1</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.filter_management_1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="filter_management_2">
                <th mat-header-cell *matHeaderCellDef>Filter Management 2</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.filter_management_2 }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <ngb-pagination
      class="d-flex justify-content-center pagination_theme"
      [maxSize]="5"
      [collectionSize]="collectionSize"
      [pageSize]="pageSize"
      [(page)]="currentPage"
      (pageChange)="getCollegesData(currentPage, false)"
    >
      <ng-template ngbPaginationPrevious>
        <img
          src="/assets/Dashboard/pageArrowPrev.svg"
          [ngClass]="[collectionSize] < '5' ? '' : ''"
        />
      </ng-template>
      <ng-template ngbPaginationNext>
        <img
          src="/assets/Dashboard/pageArrowNext.svg"
          [ngClass]="[collectionSize] < '5' ? '' : ''"
        />
      </ng-template>
    </ngb-pagination>
  </div>
  <div *ngIf="selectedDisplayView === 1">
    <app-admin-colleges-course-mapping></app-admin-colleges-course-mapping>
  </div>
  <div *ngIf="selectedDisplayView === 2">
    <app-admin-colleges-course-filter></app-admin-colleges-course-filter>
  </div>
  <div *ngIf="selectedDisplayView === 3">
    <app-admin-colleges-stream-filter></app-admin-colleges-stream-filter>
  </div>
</div>
