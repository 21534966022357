import { Component, Input, OnInit } from '@angular/core';
import { MatNotifierService } from '../mat-notifier.service';
import { ApiCallsService } from './../api-calls.service';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-favorite-subject',
  templateUrl: './favorite-subject.component.html',
  styleUrls: ['./favorite-subject.component.css'],
})
export class FavoriteSubjectComponent implements OnInit {
  @Input() deviceXs: boolean;
  //Vars
  mediaSub: Subscription;
  subjects = [
    'Maths',
    'Physics',
    'Chemistry',
    'Economics',
    'Statistics',
    'Commerce',
    'Business',
    'Accounts',
    'History',
    'Geography',
    'Language',
    'Literature',
    'Paramedical',
    'Sports',
    'Agriculture',
    'Home Science',
    'Multimedia',
    'Arts & Crafts',
    'Computers',
    'Biology',
    'Civics',
  ];

  selectedtIndexed = [];
  count = 0;

  constructor(
    private notifierService: MatNotifierService,
    private apiCaller: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver
  ) {}

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.verifyLogin();
    // this.getCareerSubjects();
  }

  verifyLogin() {
    this.apiCaller.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }

  processSubject(j) {
    const index = this.selectedtIndexed.indexOf(j);
    if (index > -1) {
      this.selectedtIndexed.splice(index, 1);
      console.log(this.selectedtIndexed);
    } else {
      if (this.selectedtIndexed.length < 3) {
        this.selectedtIndexed.push(j);
        console.log(this.selectedtIndexed);
      }
    }
  }

  navigatingToMethod() {
    let a = '';
    for (let i = 0; i < this.selectedtIndexed.length; i++) {
      if (i == 0) {
        a = a + (this.selectedtIndexed[i] + 1);
      } else {
        a = a + ',' + (this.selectedtIndexed[i] + 1);
      }

      console.log(a);
    }
    this.router.navigate(['/dashboard/careerList'], {
      queryParams: { selectedtIndexed: a },
    });
  }
}
