<!-- <p>distance-education-list works!</p>-->

<div class="col-md-12 spacing pt-4 mb-3 d-flex align-items-center">
  <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
  <span class="backTitle"> DistanceEducation List</span>
</div>

<div class="col px-5 pb-4">
  <div class="row py-3 px-2" style="background-color: white">
    <div
      class="col"
      style="
        justify-content: center;
        text-align: center;
        color: #00b1e3;
        font-size: 16px;
        font-weight: 500;
      "
    >
      Coming Soon..
    </div>
  </div>
</div>
