import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filterParamsObj } from '../models.model';
import { ApiCallsService } from '../api-calls.service';

@Component({
  selector: 'app-admin-scholarship-mappings',
  templateUrl: './admin-scholarship-mappings.component.html',
  styleUrls: ['./admin-scholarship-mappings.component.css'],
})
export class AdminScholarshipMappingsComponent implements OnInit {
  scholarshipsMappingsArr: any[] = [];
  statesList: any[] = [];
  displayedColumns: string[] = [
    'id',
    'scholarship',
    'state_name',
    'state_id',
    'scholarship_state_id',
  ];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  hideOptions: boolean = false;

  stateFormControl: FormControl = new FormControl();

  stateParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '-1',
  };

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.setDefaultControlValues();
  }

  getAllStates() {
    this.apiCaller.getAdminStates().subscribe(
      (res: any) => {
        if (res.length > 0) {
          console.log(res);
          const allStates = {
            id: -1,
            name: 'All',
            state_id: -1,
            published: 0,
          };
          this.statesList.push(allStates);
          res.forEach((stateObj, stateObjIndex) => {
            this.statesList.push(stateObj);
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  setDefaultControlValues() {
    this.getAllStates();
    this.stateFormControl.patchValue(-1);
    this.getScholarshipsMappingsData(1);
  }

  callScholarshipsMappingApi(page: number) {
    this.apiCaller.getScholarshipsMappings(page, [this.stateParam]).subscribe(
      (res: any) => {
        console.log(res);
        this.scholarshipsMappingsArr = res.results;
        this.collectionSize = res.count;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getScholarshipsMappingsData(page: number) {
    this.makeParams();
    this.callScholarshipsMappingApi(page);
    // TODO: For future use(If search is required!!
    // ** use isSearch as argument to control API calling using search params
    // ** (Used to avoid the issue caused by asynchronous requests)
    // isSearch: boolean
    // if (isSearch) {
    //   let searchValue: string = this.searchParam.filterParamValue as string;
    //   if (searchValue.length >= 3) {
    //     this.callDistrictsApi(page)
    //   }
    // } else {
    //   this.callDistrictsApi(page)
    // }
  }

  makeParams() {
    let stateLevel: number = this.stateFormControl.value;
    if (stateLevel > -1) {
      this.stateParam.filterParamActive = true;
      this.stateParam.filterParamValue = stateLevel;
      this.stateParam.filterParamName = 'state';
      this.currentPage = 1;
    } else {
      this.stateParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }
}
