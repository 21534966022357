<div class="row modalx" *ngIf="!deviceXs">
  <div class="col-12 d-flex justify-content-end beforeYourGO">
    <mat-icon matSuffix class="primaryText closeModal" (click)="goToLanding()">
      close</mat-icon
    >
  </div>
  <div class="col-12 d-flex justify-content-center beforeYourGO">
    Before you go any further...
  </div>
  <div class="col-12 d-flex justify-content-left mt-5">
    <div class="row">
      <span>
        <!-- <input type="checkbox" [formControl]="checkbox1" /> -->
        <mat-checkbox [formControl]="checkbox1"></mat-checkbox>
      </span>
      <span class="modalTxt col-md-11">
        Stopping my education early means that the jobs I can get will not
        usually be well-paid and the future improvement in salary will also be
        less.
      </span>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-left mt-4">
    <div class="row">
      <span>
        <!-- <input type="checkbox" [formControl]="checkbox2" /> -->
        <mat-checkbox [formControl]="checkbox2"></mat-checkbox>
      </span>
      <span class="modalTxt col-md-11">
        According to government rules, I should be atleast 18 years of age
        before taking a job.
      </span>
    </div>
  </div>
  <div class="col-10 d-flex justify-content-left mt-4">
    <div class="row">
      <span>
        <!-- <input type="checkbox" [formControl]="checkbox3" /> -->
        <mat-checkbox [formControl]="checkbox3"></mat-checkbox>
      </span>
      <span class="modalTxt col-md-11">
        If I need to stop my education early, I can continue to study through
        evening classes, part-time classes, correspondence courses etc. Learning
        can be lifelong!
      </span>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center mt-5">
    <button
      class="loginBtn"
      mat-dialog-close
      mat-raised-button
      (click)="onConfirmClick()"
      [disabled]="!checkbox1.value || !checkbox2.value || !checkbox3.value"
    >
      I Understand
    </button>
  </div>
</div>

<!-- For Mobile App -->

<div class="row modalx1 pl-2" *ngIf="deviceXs">
  <div class="col-12 d-flex justify-content-end beforeYourGO">
    <mat-icon
      matSuffix
      class="primaryText closeModal mb-4 mb-2 pl-4"
      (click)="goToLanding()"
    >
      close</mat-icon
    >
  </div>
  <div class="col-12 layout-align-xs ml-1 beforeYourGO1">
    Before you go any further...
  </div>

  <div class="row mt-5">
    <span class="box-pad" style="width: fit-content">
      <!-- <input type="checkbox" [formControl]="checkbox1" /> -->
      <mat-checkbox [formControl]="checkbox1"></mat-checkbox>
    </span>
    <span class="modalTxt1 col">
      Stopping my education early means that the jobs I can get will not usually
      be well-paid and the future improvement in salary will also be less.
    </span>
  </div>

  <div class="row mt-4">
    <span class="box-pad" style="width: fit-content">
      <!-- <input type="checkbox" [formControl]="checkbox1" /> -->
      <mat-checkbox [formControl]="checkbox2"></mat-checkbox>
    </span>
    <span class="modalTxt1 col">
      According to government rules, I should be atleast 18 years of age before
      taking a job.
    </span>
  </div>

  <div class="row mt-4">
    <span class="box-pad" style="width: fit-content">
      <!-- <input type="checkbox" [formControl]="checkbox1" /> -->
      <mat-checkbox [formControl]="checkbox3"></mat-checkbox>
    </span>
    <span class="modalTxt1 col">
      If I need to stop my education early, I can continue to study through
      evening classes, part-time classes, correspondence courses etc. Learning
      can be lifelong!
    </span>
  </div>

  <div class="col-12 d-flex justify-content-center mt-5">
    <button
      class="loginBtn"
      mat-dialog-close
      mat-raised-button
      (click)="onConfirmClick()"
      [disabled]="!checkbox1.value || !checkbox2.value || !checkbox3.value"
    >
      I Understand
    </button>
  </div>
</div>
