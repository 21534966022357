<!-- <p>notifications-government-jobs works!</p> -->

<div class="col-md-6 spacing pt-4 mb-3 d-flex align-items-center">
  <mat-icon
    *ngIf="isTokenVerified"
    class="backArrow"
    routerLink="/dashboard/notifications"
    >arrow_back</mat-icon
  >
  <span class="backTitle"> Notification</span>
</div>

<div class="col px-5 pb-4">
  <div class="row py-3 px-2" style="background-color: white">
    <div class="col-md-12 col-xs-12 col-sm-12">
      <div class="row">
        <div class="col">
          <span class="Notification-Details mr-1"> Notification Details </span>
          <span style="vertical-align: top">
            <img src="/assets/Dashboard/notification.svg" />
          </span>
        </div>
      </div>
      <div
        class="textline py-3"
        style="word-wrap: break-word"
        [innerHTML]="NotificationDetailResponse?.name"
      >
        <!-- {{ NotificationDetailResponse?.name }} -->
      </div>
      <div class="tags pt-3 pb-3">Tags:</div>
      <div class="bottomTagsStyling">
        <div
          class="mobileTag"
          *ngFor="let item of NotificationDetailResponse?.tags"
        >
          <div
            [innerHTML]="statustext(item)"
            [ngClass]="{
              admissionTag: item === 2,
              entranceTag: item === 0,
              scholarshipTag: item === 1,
              trainingTag: item === 3,
              govtJobsTag: item === 4
            }"
          ></div>
        </div>
      </div>
      <div class="tags pt-3">Attachments:</div>
      <div class="date py-3">
        <a
          href="{{ NotificationDetailResponse?.attachment_link }}"
          target="_blank"
          *ngIf="NotificationDetailResponse?.attachment_link?.length > 0"
        >
          <img src="/assets/Dashboard/Icon metro-attachment.svg" />
        </a>

        <a
          *ngIf="
            NotificationDetailResponse?.attachment_name != '' &&
            NotificationDetailResponse?.attachment_link?.length > 0
          "
          href="{{ NotificationDetailResponse?.attachment_link }}"
          target="_blank"
          class="attachment"
        >
          {{ NotificationDetailResponse?.attachment_name }}
        </a>
        <a
          *ngIf="
            NotificationDetailResponse?.attachment_name == '' &&
            NotificationDetailResponse?.attachment_link == null
          "
          class="unDecorated"
        >
          NA
        </a>
        <a
          *ngIf="
            NotificationDetailResponse?.attachment_name !== '' &&
            NotificationDetailResponse?.attachment_link?.length < 1
          "
          class="unDecorated"
        >
          {{ NotificationDetailResponse?.attachment_name }}
        </a>
      </div>
    </div>

    <!-- <div class="tags pt-1">Date information was obtained:</div>
      <div class="date py-3">
        {{
          NotificationDetailResponse?.last_date != (null || "")
            ? [NotificationDetailResponse?.last_date]
            : "NA"
        }}
      </div> -->

    <!-- <div class="row " style="padding-inline-start: 5px;">
        <div class = " img p-0" style=" margin-bottom: auto; ">
            <img src="/assets/careerDetail/ellipse.png" />
        </div>
        <div class=" text col-md-11 col-xs-8  col-sm-8" style="display: inline-block">

         {{ notificatiionitem.name }}
         <div>
            {{ notificatiionitem.last_date }}
         </div>

        </div>
        </div> -->
  </div>
</div>
