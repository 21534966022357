import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CareerlistServiceService {
  //vars
  // choiceFilterString = "all"
  // choiceScreenFilter = "all"
  private choiceFilterSource = new BehaviorSubject<any>('');
  choiceScreenFilter = this.choiceFilterSource.asObservable();
  constructor() {}

  updatechoiceScreenFilter(data: any) {
    this.choiceFilterSource.next(data);
  }
}
