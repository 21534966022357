<div class="row">
  <div class="col-md-12 d-flex justify-content-center cent">
    <img src="/assets/Auth/welcome.png" style="padding-right: 24px" />
  </div>
  <div class="col-md-12 loginText">Login</div>
  <div class="col-md-12 d-flex justify-content-center mt-5">
    <form [formGroup]="form" (ngSubmit)="login()">
      <div class="col-md-12 d-flex justify-content-center mt-4">
        <mat-form-field
          appearance="outline"
          class="authInput"
          (keyup)="authTypeValidator($event)"
          (focusout)="authTypeValidator($event)"
        >
          <mat-label>Username / Email / Phone</mat-label>
          <!-- <input formControlName="username" matInput placeholder="Username" /> -->
          <input
            formControlName="authDetail"
            [email]="validatedEmail"
            matInput
            placeholder="Username / Email / Phone"
          />
          <mat-icon matSuffix> <span class="eyeFake"></span></mat-icon>
          <!-- <mat-hint
            *ngIf="
              (form.controls.username.touched || formGroupValidator) &&
              form.controls.username.invalid
            "
            class="red"
            >Please enter username</mat-hint
          > -->
          <mat-hint
            *ngIf="
              (form.controls.authDetail.touched || formGroupValidator) &&
              form.controls.authDetail.invalid
            "
            class="red"
            >{{ authDetailErrMessage }}</mat-hint
          >
        </mat-form-field>
      </div>
      <div
        *ngIf="phoneLinkedUsernames.length > 0"
        class="col-md-12 d-flex justify-content-center mt-4"
      >
        <mat-form-field appearance="outline" class="authInput">
          <mat-label class="selectFontText">Select Username</mat-label>
          <mat-select
            placeholder="Username"
            formControlName="linkedUserId"
            autoWidth="true"
            class="selectFontText"
          >
            <mat-option
              *ngFor="let user of phoneLinkedUsernames"
              [value]="user.id"
              class="selectFontText"
              >{{ user.username }}</mat-option
            >
          </mat-select>
          <mat-hint
            *ngIf="
              (form.controls.authDetail.touched || formGroupValidator) &&
              form.controls.authDetail.invalid
            "
            class="red"
            >Select Username*</mat-hint
          >
        </mat-form-field>
      </div>
      <div
        class="col-md-12 justify-content-center mt-4"
        [ngClass]="linkedMessage === '' ? 'd-none' : 'linkedMessageText'"
      >
        {{ linkedMessage }}
      </div>
      <div class="col-md-12 d-flex justify-content-center mt-4">
        <mat-form-field appearance="outline" class="authInput">
          <mat-label>Password</mat-label>
          <input
            formControlName="password"
            matInput
            placeholder="Password"
            [type]="hide ? 'password' : 'text'"
          />
          <i class="material-icons" matSuffix (click)="hide = !hide">
            {{ hide ? "visibility" : "visibility_off" }}
          </i>
          <mat-hint
            *ngIf="
              (form.controls.password.touched || formGroupValidator) &&
              form.controls.password.invalid
            "
            class="red"
            >Please enter password</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="newMemberTxt col-md-12 d-flex justify-content-center mt-2">
        <div>
          <a
            class="authInput1 d-flex justify-content-end"
            routerLink="/auth/forgotpassword"
            >Forgot password</a
          >
        </div>
      </div>

      <div class="col-md-12 loginText mt-3">
        <button class="loginBtn" mat-raised-button type="submit">Login</button>
      </div>
    </form>
  </div>
  <div class="col-md-12 loginText2">
    <span class="newMemberTxt">
      <a class="forgotNextBtn" routerLink="/auth/signup"
        >New member? Click to Sign Up
      </a>
    </span>
  </div>
  <!-- <div class="col-md-12 loginText" *ngIf="!deviceXs">
    <button
      class="adminBtn"
      mat-raised-button
      type=""
      routerLink="/auth/forgotpassword"
    >
      Admin Login
    </button>
  </div> -->
</div>

<!-- For Mobile App -->

<!-- <div class="row" *ngIf = "deviceXs">
  <div class="row">
  <div class="col-md-12 d-flex justify-content-center mt-3" >
      <img src="/assets/Auth/logo-mobile.png" />
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-3">
      <span class="capTxt-auth "> Career Advancement Platform(CAP) </span>
  </div>
     <div class="col-md-12 d-flex justify-content-center vrglad-auth  mt-3 mb-4">
      <span class="lineStyling  px-4" >
        We’d be glad to guide you in this journey ahead
        <span class="primaryText" style="color: #00b1e3;">
          Sign up
        </span>
        and get started now!
      </span>
    </div>
  <div class="col-md-12 d-flex justify-content-center " style="padding-top: 0rem;">
    <img src="/assets/Auth/welcomee.png" />
  </div>
  <div class="col-md-12 loginText" style="padding-top: 1rem; font-size: 16px;">Login</div>
  <div class="col-md-12 d-flex justify-content-center ">
    <form [formGroup]="form" (ngSubmit)="login()">
      <div class="col-md-12 d-flex justify-content-center mt-4">
        <mat-form-field appearance="outline" class="authInput2">
          <mat-label>Username</mat-label>
          <input formControlName="username" matInput placeholder="Username" />
          <mat-icon matSuffix> <span class="eyeFake"></span></mat-icon>
          <mat-hint
            *ngIf="
              (form.controls.username.touched || formGroupValidator) &&
              form.controls.username.invalid
            "
            class="red"
            >Please enter username</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="col-md-12 d-flex justify-content-center mt-3">
        <mat-form-field appearance="outline" class="authInput2  ">
          <mat-label>Password</mat-label>
          <input
            formControlName="password"
            matInput
            placeholder="Password"
            [type]="hide ? 'password':'text'"
          />
          <i class="material-icons" matSuffix (click) = "hide = !hide">
           {{hide ? 'visibility': 'visibility_off' }}
          </i>
          <mat-hint
            *ngIf="
              (form.controls.password.touched || formGroupValidator) &&
              form.controls.password.invalid
            "
            class="red"
            >Please enter password</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="newMemberTxt col-md-12 d-flex justify-content-center mt-2">
        <div >
          <a  class="authInput1 d-flex justify-content-end" style ="font-size: 9px;" routerLink="/auth/forgotpassword">Forgot password</a>
        </div>
      </div>
      <div class="col-md-12 loginText ">
        <button class="loginBtn" style ="font-size: 11px;" mat-raised-button type="submit">Login</button>
      </div>
    </form>
  </div>
  <div class="col-md-12 loginText2">
    <span class="newMemberTxt">
      <a class="forgotNextBtn" style ="font-size: 9px;" routerLink="/auth/signup"
        >New member? Click to Sign Up
      </a>
    </span>
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-3" style="font-size: 8px; font-weight: 500; color: rgba(47, 63, 67, 0.8)">
    <span>Powered by</span>
  </div>
  <div class="col-md-12 d-flex justify-content-center" >
    <span class="px-2" style="padding-bottom: 1rem;">
      <img src="/assets/Auth/vidya helpline logo-mobile.png" />
    </span>
    <span class="px-2">
      <img src="/assets/Auth/vmware-mobile.png" />
    </span>
  </div>
</div>
</div> -->
