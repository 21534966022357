<div class="row p-0 m-0">
  <div class="col-md-12">
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
      <span class="backTitle"> Mentorship</span>
    </div>
  </div>
</div>

<div class="col-12">
  <div
    class="row p-0 m-0"
    style="
      background-color: white;
      padding: 36px 30px 20px 29px !important;
      border-radius: 6px;
    "
  >
    <!-- <youtube-player
      class="player"
      videoId="RGoPU-OLQHE"
      suggestedQuality="highres"
      allowfullscreen
      [height]="563"
      [width]="1280"
    >
    </youtube-player> -->

    <iframe
      class="player"
      src="https://www.youtube.com/embed/aeq4VBYZb8w"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <div class="aboutMentoring">About The Mentoring</div>

    <div class="aboutDiscription">
      Mentorship is a student supportive program specially designed to connect
      the passionate professionals called mentors with the underprivileged
      students who are in need of the career guidance, counselling, project
      subject expertise, skilling etc.
    </div>
  </div>
</div>

<div class="row p-0 m-0">
  <div class="col-md-12">
    <!-- <div class="col-md-12 mt-2 px-0 d-flex align-items-center"> -->
    <div class="typesOfMentoring">Types of Mentoring</div>
    <!-- </div> -->
  </div>
</div>

<div class="parent">
  <div class="child" routerLink="/dashboard/holisticmentoring">
    <img src="/assets/mentorship/holisticMentoring.svg" />
    <div class="holisticMentoring">Holistic Mentoring</div>
    <div class="holisticMentoringDiscrption">
      A yearlong mentorship program for a selected set of students.
    </div>
  </div>
  <div class="child" routerLink="/dashboard/projectmentoring">
    <img
      style="cursor: pointer"
      src="/assets/mentorship/projectMentoring.svg"
    />
    <div class="holisticMentoring">Project Based mentoring</div>
    <div class="holisticMentoringDiscrption">
      Domain experts from various professions (Engineering, programming,
      management etc.)
    </div>
  </div>
  <div class="child" routerLink="/dashboard/skillmentoring">
    <img style="cursor: pointer" src="/assets/mentorship/skillMentoring.svg" />
    <div class="holisticMentoring">Skill Based Mentoring</div>
    <div class="holisticMentoringDiscrption">
      A short up skilling program to the students in their own interest starting
      from English
    </div>
  </div>
</div>

<div class="col-12 mt-4">
  <div class="row p-0 m-0" style="background-color: white; border-radius: 6px">
    <div class="faq">
      FAQ's
      <div class="faqButtons">
        <img
          routerLink="/dashboard/studentfaqs"
          style="cursor: pointer"
          src="/assets/mentorship/studentButton.png"
        />
        <img
          class="secondButton"
          (click)="routingToMetorfaqs()"
          style="cursor: pointer"
          src="/assets/mentorship/mentorButton.png"
        />
      </div>
    </div>
  </div>
</div>

<div class="col-12 mt-4">
  <div class="row p-0 m-0" style="background-color: white; border-radius: 6px">
    <div class="faq">
      Contact info
      <div class="contactDetails">career.mentor@nirmaan.org</div>
    </div>
  </div>
</div>

<div class="col-12 mt-4" style="padding-bottom: 100px">
  <div class="row p-0 m-0" style="background-color: white; border-radius: 6px">
    <div class="rd-button-container">
      <div class="lastcontainer">
        <img
          routerLink="/dashboard/mentorresources"
          style="cursor: pointer"
          src="/assets/mentorship/resouce.png"
        />
        <img
          class="secondButton"
          routerLink="/dashboard/mentordashboard"
          style="cursor: pointer"
          src="/assets/mentorship/dashboard.png"
        />
      </div>
    </div>
  </div>
</div>

<div style="padding-top: 2rem" *ngIf="!deviceXs">
  <div class="footer">
    <div class="col-12 d-flex" style="padding: 1.7rem">
      <div class="col-5" style="text-align: end; cursor: pointer">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeJdeYRjyFopW-PuvVkKNRaZ9-uc3cpZwcdjxYvCyS-YruX5w/viewform"
          target="_blank"
        >
          <img src="/assets/mentorship/mentosRegistration.png" />
        </a>
      </div>
      <div class="col-6">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSf8-7bpqhX3bsyVSFLB5uMGrV2yaE0ZxkTbp8K-1Dx9yrzJqw/viewform"
          target="_blank"
        >
          <img
            style="cursor: pointer"
            src="/assets/mentorship/studentRegistration.png"
          />
        </a>
      </div>
    </div>
  </div>
</div>

<div style="padding-top: 2rem" *ngIf="deviceXs">
  <div class="mobilefooter">
    <div class="col-12 d-flex" style="padding: 1.3rem">
      <div class="col-4" style="text-align: end; cursor: pointer">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeJdeYRjyFopW-PuvVkKNRaZ9-uc3cpZwcdjxYvCyS-YruX5w/viewform"
          target="_blank"
        >
          <img
            src="/assets/mentorship/mentosRegistration.png"
            class="responsive"
          />
        </a>
      </div>
      <div class="col-6">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSf8-7bpqhX3bsyVSFLB5uMGrV2yaE0ZxkTbp8K-1Dx9yrzJqw/viewform"
          target="_blank"
        >
          <img
            style="cursor: pointer; width: 100%; margin-left: 3.5rem"
            src="/assets/mentorship/studentRegistration.png"
          />
        </a>
      </div>
    </div>
  </div>
</div>
