<div class="col-md-12 careerDetails" *ngIf="!deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/careerList"
        [queryParams]="{
          stream: this.streamFilter,
          prefereedGroup: this.prefferdGroupFilter,
          category: this.categoryFilter,
          salary: this.salaryFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ careerDetailResponse?.name }} </span>
    </div>
    <div class="col-md-12 mt-2 careerReq mt-3 pb-1">
      <div class="requirementsText mt-2">
        <span>Requirements</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/open-book.png" />
        </span>
      </div>
      <div class="col-md-12 d-flex justify-content-between px-0 mt-4">
        <div>
          <span class="reqt1">Preffered group in 10+2: </span>
          <span class="reqta">{{
            careerDetailResponse?.prefered_group != null
              ? groupValues[careerDetailResponse?.prefered_group]
              : "-"
          }}</span>
        </div>
        <div>
          <span class="reqt1">Required Course: </span>
          <span class="reqta">{{ careerDetailResponse?.required_course }}</span>
        </div>
        <div>
          <span class="reqt1">Specialisation: </span>
          <span class="reqta">{{ careerDetailResponse?.specialization }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-7 wrapperLeft">
          <div class="row">
            <div class="col-md-12 secondContainer">
              <div class="row">
                <div class="col-md-12 sc2Parent">
                  <span class="secondContainer2">Career Details</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/student-hat.png" />
                  </span>
                </div>
                <div class="col mt-4">
                  <div>
                    <span class="streamA">Stream: </span>
                    <span class="streamB">{{
                      careerDetailResponse?.stream != null
                        ? streamValues[careerDetailResponse?.stream]
                        : ""
                    }}</span>
                  </div>
                </div>
                <div class="col mt-4">
                  <div>
                    <span class="streamA">Category: </span>
                    <span class="streamB">
                      {{
                        careerDetailResponse?.category != null
                          ? categoryValues[careerDetailResponse?.category]
                          : "-"
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sc2Parent2 mt-4">
              <div class="row">
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.overview != null"
                >
                  <span class="secondContainer2">Overview</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/surface1.png" />
                  </span>

                  <p class="mt-3 overviewText pb-2">
                    {{ careerDetailResponse?.overview }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.day_in_the_life != null"
                >
                  <span class="secondContainer3">Day in the life</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/desktop.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.day_in_the_life }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.workplaces != null"
                >
                  <span class="secondContainer3">Workplace</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/desk.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.workplaces }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.benifits != null"
                >
                  <span class="secondContainer3">Benifits</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/star.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.benefits }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.required_aptitude != null"
                >
                  <span class="secondContainer3">Required skills</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/skills.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.required_aptitude }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.required_personaliy != null"
                >
                  <span class="secondContainer3">Personality Attributes</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/profile.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.required_personaliy }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 wrapperRight">
          <div class="row">
            <div class="col-md-12 roadMap">
              <div class="mt-3">
                <span class="roadMapText">Roadmap</span>
                <span class="pl-2">
                  <img src="/assets/careerDetail/chart.png" />
                </span>
              </div>
              <div class="seeSteps mt-3">
                See the steps you need to go through to become an
                <span> {{ careerDetailResponse?.name }} </span>
              </div>
              <div style="font-weight: 500; padding-top: 10px">
                Coming soon.
              </div>
              <div class="d-flex justify-content-end mt-3">
                <button
                  class="loginBtn mx-4 mb-3"
                  mat-raised-button
                  [disabled]="roadmapdisabled"
                  (click)="openRoadMap()"
                >
                  View
                </button>
              </div>
            </div>
            <div class="col-md-12 salaryDetails mt-3">
              <div class="mt-3">
                <span class="roadMapText">Salary Details: </span>
                <span class="pl-2">
                  <img src="/assets/careerDetail/coin.png" />
                </span>
              </div>
              <div class="mt-3">
                <span class="expectedSalary"
                  >Approximate Salary (Starting):
                </span>
                <span class="expectedSalary2">{{
                  careerDetailResponse?.salary_range
                    ? careerDetailResponse?.salary_range
                    : "Unknown"
                }}</span>
              </div>
            </div>
            <div class="col-md-12 salaryDetailsx mt-3 pb-3">
              <div class="mt-3">
                <span class="roadMapText">Alternate Careers: </span>
                <span class="pl-2">
                  <img src="/assets/careerDetail/career.png" />
                </span>
              </div>
              <div class="mt-3" *ngFor="let career of alternateCareers">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> {{ career }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- For Mobile App -->

<div class="col-md-12 careerDetails" *ngIf="deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/careerList"
        [queryParams]="{
          stream: this.streamFilter,
          prefereedGroup: this.prefferdGroupFilter,
          category: this.categoryFilter,
          salary: this.salaryFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ careerDetailResponse?.name }} </span>
    </div>
    <div class="col-12 mt-3 careerReq mt-3 pb-3">
      <div class="requirementsText mt-2">
        <span>Requirements</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/open-book.png" />
        </span>
      </div>
      <div class="col-md-12 px-0 mt-3">
        <div class="mb-2">
          <span class="reqt1">Preffered group in 10+2: </span>
          <span class="reqta">{{
            careerDetailResponse?.prefered_group != null
              ? groupValues[careerDetailResponse?.prefered_group]
              : "-"
          }}</span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Required Course: </span>
          <span class="reqta">{{ careerDetailResponse?.required_course }}</span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Specialisation: </span>
          <span class="reqta">{{ careerDetailResponse?.specialization }}</span>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3 careerReq mt-4 pb-3">
      <div class="requirementsText mt-2">
        <span>Career Details</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/student-hat.png" />
        </span>
      </div>
      <div class="col-md-12 px-0 mt-3">
        <div class="mb-2">
          <span class="reqt1">Stream: </span>
          <span class="reqta">{{
            careerDetailResponse?.stream != null
              ? streamValues[careerDetailResponse?.stream]
              : ""
          }}</span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Category: </span>
          <span class="reqta">
            {{
              careerDetailResponse?.category != null
                ? categoryValues[careerDetailResponse?.category]
                : "-"
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-7 wrapperLeft">
          <!--  -->
          <div class="row xx">
            <div class="col-md-12 sc2Parent2">
              <div class="row">
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.overview != null"
                >
                  <span class="secondContainer2">Overview</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/surface1.png" />
                  </span>

                  <p class="mt-3 overviewText pb-2">
                    {{ careerDetailResponse?.overview }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.day_in_the_life != null"
                >
                  <span class="secondContainer3">Day in the life</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/desktop.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.day_in_the_life }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.workplaces != null"
                >
                  <span class="secondContainer3">Workplace</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/desk.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.workplaces }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.benifits != null"
                >
                  <span class="secondContainer3">Benifits</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/star.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.benefits }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.required_aptitude != null"
                >
                  <span class="secondContainer3">Required skills</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/skills.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.required_aptitude }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="careerDetailResponse?.required_personaliy != null"
                >
                  <span class="secondContainer3">Personality Attributes</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/profile.png" />
                  </span>

                  <p class="mt-3 overviewText">
                    {{ careerDetailResponse?.required_personaliy }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 wrapperRight1">
          <div class="row">
            <div class="col-md-12 roadMap">
              <div class="mt-3">
                <span class="roadMapText">Roadmap</span>
                <span class="pl-2">
                  <img src="/assets/careerDetail/chart.png" />
                </span>
              </div>
              <div class="seeSteps mt-3">
                See the steps you need to go through to become an
                <span> {{ careerDetailResponse?.name }} </span>
              </div>
              <div style="font-weight: 500; padding-top: 10px">
                Coming soon.
              </div>
              <div class="d-flex justify-content-end mt-3">
                <button
                  class="loginBtn mx-4 mb-3"
                  mat-raised-button
                  [disabled]="roadmapdisabled"
                  (click)="openRoadMap()"
                >
                  View
                </button>
              </div>
            </div>
            <div class="col-md-12 salaryDetails mt-3">
              <div class="mt-3">
                <span class="roadMapText">Salary Details: </span>
                <span class="pl-2">
                  <img src="/assets/careerDetail/coin.png" />
                </span>
              </div>
              <div class="mt-3">
                <span class="expectedSalary"
                  >Approximate Salary (Starting):
                </span>
                <span class="expectedSalary2">{{
                  careerDetailResponse?.salary_range
                    ? careerDetailResponse?.salary_range
                    : "Unknown"
                }}</span>
              </div>
            </div>
            <div class="col-md-12 salaryDetailsx mt-3 pb-3">
              <div class="mt-3">
                <span class="roadMapText">Alternate Careers: </span>
                <span class="pl-2">
                  <img src="/assets/careerDetail/career.png" />
                </span>
              </div>
              <div class="mt-3" *ngFor="let career of alternateCareers">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> {{ career }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
