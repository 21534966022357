<div class="row p-0 m-0">
  <div class="col-md-12">
    <div class="col-md-12 mt-4 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/mentorresources/"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> Resources</span>
    </div>
  </div>
</div>

<div class="row px-2 p-0 m-0">
  <!-- below ex shows us id passing to TS file using ($event.target.id) and img tag -->
  <!-- <div class="videoCard">
    <img
      src="/assets/Dashboard/video.png"
      id="2"
      (click)="videoDialogClick($event.target.id)"
    />
    <div>abcd</div>
  </div> -->
  <div class="col-12 cardsStyled">
    <div class="videoCard" (click)="videoDialogClick(1)">
      <img
        src="/assets/Dashboard/video.png"
        style="
          inline-size: -webkit-fill-available;
          border-radius: inherit;
          position: relative;
        "
      />
      <img src="/assets/mentorship/playbutton.png" class="childimg" />

      <div class="cardDiscription">Student Management System</div>
      <div class="cardDiscription1">
        A short presentation by a group of CSE students about the project "
        STUDENT MANAGEMENT SYSTEM " that they have done under project based
        mentoring.
      </div>
    </div>
    <div class="videoCard" (click)="videoDialogClick(2)">
      <img
        src="/assets/Dashboard/video.png"
        style="inline-size: -webkit-fill-available; border-radius: inherit"
      />
      <img src="/assets/mentorship/playbutton.png" class="childimg" />
      <div class="cardDiscription">An application for CSP catalog</div>
      <div class="cardDiscription1">
        "A video description about the Holistic mentoring concept ( CSP) by a
        group of CSE students. It explains the roles & responsibilities of
        different users in an application for smooth functioning CSE batch 2 "
      </div>
    </div>
    <div class="videoCard" (click)="videoDialogClick(3)">
      <img
        src="/assets/Dashboard/video.png"
        style="inline-size: -webkit-fill-available; border-radius: inherit"
      />
      <img src="/assets/mentorship/playbutton.png" class="childimg" />
      <div class="cardDiscription">Multi Channel Fault Monitoring system</div>
      <div class="cardDiscription1">
        An experimental video which showcases the assembling, connections,  live
        results of a  fault-finding project that is being done by a group of ECE
        students.
      </div>
    </div>
    <!-- </div>
  <div class="col-12"> -->
    <div class="videoCard" (click)="videoDialogClick(4)">
      <img
        src="/assets/Dashboard/video.png"
        style="inline-size: -webkit-fill-available; border-radius: inherit"
      />
      <img src="/assets/mentorship/playbutton.png" class="childimg" />
      <div class="cardDiscription">User defined interface by LabVIEW</div>
      <div class="cardDiscription1">
        A short presentation about different applications of Labview software by
        a group of EEE students
      </div>
    </div>
    <div class="videoCard" (click)="videoDialogClick(5)">
      <img
        src="/assets/Dashboard/video.png"
        style="inline-size: -webkit-fill-available; border-radius: inherit"
      />
      <img src="/assets/mentorship/playbutton.png" class="childimg" />
      <div class="cardDiscription">Organ Donation</div>
      <div class="cardDiscription1">
        A video presentation describing the needs & importance of organ donation
        by a group of Bipc students
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="videoTerms"
  data-keyboard="false"
  style="overflow: hidden"
>
  <div class="modal-body videoBoxStyling" (click)="videoDialogCloseClick()">
    <iframe
      id="video"
      class="player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>
