import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiCallsService } from '../api-calls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-bankloan-detail',
  templateUrl: './bankloan-detail.component.html',
  styleUrls: ['./bankloan-detail.component.css'],
})
export class BankloanDetailComponent implements OnInit, OnDestroy {
  @Input() deviceXs: boolean;

  queryParamId = null;
  BankLoanDetailResponse: any;
  alternateCourses: any;
  bankloanpathurl = null;
  mediaSub: Subscription;
  courseFilterSub: Subscription;
  studyFilterSub: Subscription;
  queryParam: Subscription;

  prefferdGroupFilter = '';
  study_location_filter: any = '';

  groupValues = {
    0: 'Both',
    1: 'India',
    2: 'Abroad',
  };

  constructor(
    private activateRoute: ActivatedRoute,
    private api: ApiCallsService,
    public dialog: MatDialog,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService
  ) {}

  ngOnInit(): void {
    // this.api.verifyLogin();
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.activateRoute.queryParams.subscribe((params) => {
      console.log(params['id']);
      this.queryParamId = params['id'];
    });
    this.courseFilterSub = this.globalSservice
      .getCourseBankFilter()
      .subscribe((value) => (this.prefferdGroupFilter = value));
    this.studyFilterSub = this.globalSservice
      .getStudyBankFilter()
      .subscribe((value) => (this.study_location_filter = value));
    this.getBankLoanDetailApi();
  }

  getBankLoanDetailApi() {
    this.api.getBankLoanDetail(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        this.BankLoanDetailResponse = res;
        let altC = res['eligible_courses'];
        this.alternateCourses = altC.split(',');
        console.log(this.alternateCourses);
        if (this.BankLoanDetailResponse['loan_path']) {
          // this.roadmapdisabled = false
          this.bankloanpathurl = this.BankLoanDetailResponse['loan_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy() {
    this.courseFilterSub.unsubscribe();
    this.studyFilterSub.unsubscribe();
  }
}
