<div class="col-md-12" id="wrapper">
  <div class="d-flex" style="padding-top: 1rem">
    <div class="col-md-8 mb-3 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/adminDashboard/notifications"
        >arrow_back</mat-icon
      >
      <span class="backTitle">Delete Notifications</span>
    </div>
    <div class="col-md-4 d-flex justify-content-end">
      <mat-form-field appearance="outline">
        <mat-select
          (selectionChange)="filterNotificationType()"
          [(ngModel)]="notificationTypeFilter"
        >
          <mat-option value="1">Active Notifications</mat-option>
          <mat-option value="5">Inactive Notifications</mat-option>
          <mat-option value="2">Expired Notifications</mat-option>
          <mat-option value="3">Unpublished Notifications</mat-option>
          <mat-option value="4">published Notifications</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex" style="padding-top: 0.1rem">
    <div class="col-md-8 d-flex justify-content-start">
      <mat-form-field
        appearance="outline"
        class="searchField"
        style="width: 100%"
      >
        <input
          type="text"
          matInput
          placeholder="Search Notifications"
          [formControl]="myControl"
          (input)="filterNotificationType()"
          [(ngModel)]="searchText"
        />
        <mat-icon
          matSuffix
          src="/assets/Dashboard/pageArrowNext.svg"
          class="magBag"
        >
          search
        </mat-icon>
      </mat-form-field>
    </div>
    <div class="col-md-4 pt-2 mb-5 d-flex justify-content-end">
      <span>
        <button
          (click)="idsPostCall()"
          class="loginBtn"
          mat-raised-button
          type="submit"
        >
          DELETE
        </button>
      </span>
    </div>
  </div>

  <div class="col-md-12">
    <div class="BG table-responsive">
      <table class="table">
        <tr class="colorWaraper">
          <th class="coloumnSeperator">
            <div class="col tableHItem">select</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Notification Description</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Notification Type</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Active Until</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Academic Level</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">State Name</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Attachment Name/Upload</div>
          </th>
        </tr>
        <ng-container
          *ngFor="
            let option of notificationResponse | filter : searchText;
            index as j;
            let Last = last
          "
        >
          <tr class="tableHItem4">
            <td>
              <div
                class="col tableHItem2 py-2"
                style="margin: 0"
                [title]="option['name']"
              >
                <!-- <input
                      type="checkbox"
                      unchecked
                      (change)="postcallId(option['id'])"
                    /> -->
                <mat-checkbox
                  #inputEl
                  type="checkbox"
                  (change)="
                    makingNotificationIdsArray(
                      inputEl.checked,
                      option['notification_id']
                    )
                  "
                  class="checkboxStyling"
                ></mat-checkbox>
              </div>
            </td>
            <td>
              <div
                class="col tableHItem2 py-2"
                style="margin: 0"
                [title]="option['name']"
              >
                {{ option["name"] }}
              </div>
            </td>
            <td>
              <div
                class="col tableHItem2 py-2"
                style="margin: 0"
                *ngFor="let item of option?.tags"
              >
                <div
                  [innerHTML]="statustext(item)"
                  [ngClass]="{
                    admissionTag: item === 2,
                    entranceTag: item === 0,
                    scholarshipTag: item === 1,
                    trainingTag: item === 3,
                    govtJobsTag: item === 4
                  }"
                ></div>
              </div>
            </td>
            <td>
              <div class="col tableHItem2 py-2" style="margin: 0">
                {{ option["active_until"] }}
              </div>
            </td>
            <td>
              <div
                class="col tableHItem2 tableHItem4 py-2"
                style="margin: 0"
                *ngFor="let academic of option?.academic_levels"
              >
                {{ academic !== null ? groupAcademicValues[academic] : "" }}
              </div>
            </td>

            <td>
              <div
                class="col tableHItem2 pt-3"
                style="margin: 0"
                *ngFor="let State of option?.states"
              >
                {{ State.name }}
              </div>
            </td>
            <td>
              <div class="col tableHItem2 py-2" style="margin: 0">
                {{ option["attachment_name"] }}
              </div>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>

  <ngb-pagination
    class="d-flex justify-content-center pagination_theme"
    [maxSize]="5"
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    (pageChange)="onPageChange(currentPage)"
  >
    <ng-template ngbPaginationPrevious>
      <img
        src="/assets/Dashboard/pageArrowPrev.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
    <ng-template ngbPaginationNext>
      <img
        src="/assets/Dashboard/pageArrowNext.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
  </ngb-pagination>
</div>
