import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrainingListFilters } from './models.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // for user active status
  isTokenVerified = new BehaviorSubject<boolean>(false);

  //entranceExamList Variables for filters
  entryCourse = new BehaviorSubject<any>('');
  stateFilter = new BehaviorSubject<any>('');

  //careerList Variables for filters
  streamCareerFilter = new BehaviorSubject<any>('');
  preferredCareerGroupFilter = new BehaviorSubject<any>('');
  categoryCareerFilter = new BehaviorSubject<any>('');
  salaryCareerFilter = new BehaviorSubject<any>('');

  //courseList Variables for filters
  streamCourseFilter = new BehaviorSubject<any>('');
  elgibilityCourseFilter = new BehaviorSubject<any>('');
  categoryCourseFilter = new BehaviorSubject<any>('');

  //ScholarshipList Variables for filters
  academicScholarshipFilter = new BehaviorSubject<any>('');
  genderScholarshipFilter = new BehaviorSubject<any>('');
  handicappedScholarshipFilter = new BehaviorSubject<any>('');
  applicationScholarshipFilter = new BehaviorSubject<any>('');
  stateScholarshipFilter = new BehaviorSubject<any>('');

  //bankList Variables for filters
  courseBankFilter = new BehaviorSubject<any>('');
  studyBankFilter = new BehaviorSubject<any>('');

  //hostelList Variables for filters
  academicHostelFilter = new BehaviorSubject<any>('');
  genderHostelFilter = new BehaviorSubject<any>('');
  casteHostelfilter = new BehaviorSubject<any>('');
  stateHostelFilter = new BehaviorSubject<any>('');
  districtHostelFilter = new BehaviorSubject<any>('');

  //collegeList Variables for filters
  streamCollegeFilter = new BehaviorSubject<any>('');
  stateCollegeFilter = new BehaviorSubject<any>('');
  instituteTypeCollegefilter = new BehaviorSubject<string>('');
  courseCollegeFilter = new BehaviorSubject<any>('');
  genderCollegeFilter = new BehaviorSubject<any>('');
  districtCollegeFilter = new BehaviorSubject<any>('');
  instituteProminenceCollegefilter = new BehaviorSubject<any>('');
  categoryCollegeFilter = new BehaviorSubject<any>('');
  hostelFacilityCollegeFilter = new BehaviorSubject<any>('');

  // TrainingListFilters
  trainingInstituteFilters = new BehaviorSubject<TrainingListFilters>({
    search: '',
    category: '',
    minimum_qualification__gte: '',
    institute_type: '',
    gender: '',
    only_physically_handicapped: '',
    state: '',
    district: '',
  });

  constructor() {}

  // get User status
  getTokenStatus(): Observable<boolean> {
    return this.isTokenVerified.asObservable();
  }

  //entranceExamList get,set methods for filters
  getEntryToCourse(): Observable<string> {
    return this.entryCourse.asObservable();
  }

  setEntryCourse(value) {
    this.entryCourse.next(value);
  }

  getStateFilter(): Observable<any> {
    return this.stateFilter.asObservable();
  }

  setStateFilter(value) {
    this.stateFilter.next(value);
  }

  // ----------------------------------------------------------------------------------------------------------------------

  //careerList get,set methods for filters
  getStreamFilter(): Observable<any> {
    return this.streamCareerFilter.asObservable();
  }

  setStreamFilter(value) {
    this.streamCareerFilter.next(value);
  }

  getPreferredGroupFilter(): Observable<any> {
    return this.preferredCareerGroupFilter.asObservable();
  }

  setPreferredGroupFilter(value) {
    this.preferredCareerGroupFilter.next(value);
  }

  getCategoryFilter(): Observable<any> {
    return this.categoryCareerFilter.asObservable();
  }

  setCategoryFilter(value) {
    this.categoryCareerFilter.next(value);
  }

  getSalaryFilter(): Observable<any> {
    return this.salaryCareerFilter.asObservable();
  }

  setSalaryFilter(value) {
    this.salaryCareerFilter.next(value);
  }

  // ------------------------------------------------------------------------------------------------------------------------

  //courseList get,set methods for filters
  getStreamCourseFilter(): Observable<any> {
    return this.streamCourseFilter.asObservable();
  }

  setStreamCourseFilter(value) {
    this.streamCourseFilter.next(value);
  }

  getElgibiltyCourseFilter(): Observable<any> {
    return this.elgibilityCourseFilter.asObservable();
  }

  setElgibiltyCourseFilter(value) {
    this.elgibilityCourseFilter.next(value);
  }

  getCategoryCourseFilter(): Observable<any> {
    return this.categoryCourseFilter.asObservable();
  }

  setCategoryCourseFilter(value) {
    this.categoryCourseFilter.next(value);
  }

  // ------------------------------------------------------------------------------------------------------------------------

  //scholarshipList get,set methods for filters
  getAcademicScholarshipFilter(): Observable<string> {
    return this.academicScholarshipFilter.asObservable();
  }

  setAcademicScholarshipFilter(value) {
    this.academicScholarshipFilter.next(value);
  }

  getGenderScholarshipFilter(): Observable<any> {
    return this.genderScholarshipFilter.asObservable();
  }

  setGenderScholarshipFilter(value) {
    this.genderScholarshipFilter.next(value);
  }

  getHandicappedScholarshipFilter(): Observable<any> {
    return this.handicappedScholarshipFilter.asObservable();
  }

  setHandicappedScholarshipFilter(value) {
    this.handicappedScholarshipFilter.next(value);
  }

  getApplicationScholarshipFilter(): Observable<any> {
    return this.applicationScholarshipFilter.asObservable();
  }

  setApplicationScholarshipFilter(value) {
    this.applicationScholarshipFilter.next(value);
  }

  getstateScholarshipFilter(): Observable<any> {
    return this.stateScholarshipFilter.asObservable();
  }

  setstateScholarshipFilter(value) {
    this.stateScholarshipFilter.next(value);
  }

  // ----------------------------------------------------------------------------------------------------------------------

  //bankList get,set methods for filters
  getCourseBankFilter(): Observable<string> {
    return this.entryCourse.asObservable();
  }

  setCourseBankFilter(value) {
    this.entryCourse.next(value);
  }

  getStudyBankFilter(): Observable<any> {
    return this.stateFilter.asObservable();
  }

  setStudyBankFilter(value) {
    this.stateFilter.next(value);
  }

  // ------------------------------------------------------------------------------------------------------------------------

  //hostelList get,set methods for filters
  getAcademicHostelFilter(): Observable<string> {
    return this.academicHostelFilter.asObservable();
  }

  setAcademicHostelFilter(value) {
    this.academicHostelFilter.next(value);
  }

  getGenderHostelilter(): Observable<any> {
    return this.genderHostelFilter.asObservable();
  }

  setGenderHostelilter(value) {
    this.genderHostelFilter.next(value);
  }

  getCasteHostelilter(): Observable<any> {
    return this.casteHostelfilter.asObservable();
  }

  setCasteHostelilter(value) {
    this.casteHostelfilter.next(value);
  }

  getStateHostelilter(): Observable<any> {
    return this.stateHostelFilter.asObservable();
  }

  setStateHostelilter(value) {
    this.stateHostelFilter.next(value);
  }

  getDistrictHostelilter(): Observable<string> {
    return this.districtHostelFilter.asObservable();
  }

  setDistrictHostelilter(value) {
    this.districtHostelFilter.next(value);
  }

  // ----------------------------------------------------------------------------------------------------------------------

  //collegeList get,set methods for filters
  getStreamCollegeFilter(): Observable<any> {
    return this.streamCollegeFilter.asObservable();
  }

  setStreamCollegeFilter(value) {
    this.streamCollegeFilter.next(value);
  }

  getStateCollegeFilter(): Observable<any> {
    return this.stateCollegeFilter.asObservable();
  }

  setStateCollegeFilter(value) {
    this.stateCollegeFilter.next(value);
  }

  getInstituteTypeCollegeFilter(): Observable<string> {
    return this.instituteTypeCollegefilter.asObservable();
  }

  setInstituteTypeCollegeFilter(value) {
    this.instituteTypeCollegefilter.next(value);
  }

  getCourseCollegeFilter(): Observable<any> {
    return this.courseCollegeFilter.asObservable();
  }

  setCourseCollegeFilter(value) {
    this.courseCollegeFilter.next(value);
  }

  getGenderCollegeFilter(): Observable<any> {
    return this.genderCollegeFilter.asObservable();
  }

  setGenderCollegeFilter(value) {
    this.genderCollegeFilter.next(value);
  }

  getDistrictCollegeFilter(): Observable<string> {
    return this.districtCollegeFilter.asObservable();
  }

  setDistrictCollegeFilter(value) {
    this.districtCollegeFilter.next(value);
  }

  getInstituteProminenceCollegeFilter(): Observable<any> {
    return this.instituteProminenceCollegefilter.asObservable();
  }

  setInstituteProminenceCollegeFilter(value) {
    this.instituteProminenceCollegefilter.next(value);
  }

  getCategoryCollegeFilter(): Observable<any> {
    return this.categoryCollegeFilter.asObservable();
  }

  setCategoryCollegeFilter(value) {
    this.categoryCollegeFilter.next(value);
  }

  getHostelFacilityCollegeFilter(): Observable<any> {
    return this.hostelFacilityCollegeFilter.asObservable();
  }

  setHostelFacilityCollegeFilter(value) {
    this.hostelFacilityCollegeFilter.next(value);
  }

  // ** TrainingInstitutesFilters Getters and Setters
  getTrainingInstituteFilters() {
    return this.trainingInstituteFilters.asObservable();
  }

  setTrainingInstituteFilters(value: TrainingListFilters) {
    this.trainingInstituteFilters.next(value);
  }
}
