import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApiCallsService } from './../api-calls.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import Swal from 'sweetalert2';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
})
export class ChangepasswordComponent implements OnInit {
  @Input() deviceXs: boolean;

  hide = true;
  hide2 = true;
  mediaSub: Subscription;
  tool = false;

  constructor(
    private apicaller: ApiCallsService,
    private formBuilder: FormBuilder,
    private router: Router,
    public mediaObserver: MediaObserver
  ) {}

  hints() {
    // Swal.fire({
    //   title: 'Password reset successful',
    // showClass: {
    //   popup: 'animate__animated animate__fadeInDown'
    // },
    // hideClass: {
    //   popup: 'animate__animated animate__fadeOutUp'
    // }
    // })
    Swal.fire('Password reset successful', '', 'success');
  }

  iffail() {
    // Swal.fire({
    // title: 'Invalid OTP. Please enter valid OTP',
    // showClass: {
    //   popup: 'animate__animated animate__fadeInDown'
    // },
    // hideClass: {
    //   popup: 'animate__animated animate__fadeOutUp'
    // }
    // })
    Swal.fire('Invalid OTP. Please enter valid OTP', '', 'error');
  }

  passwordnotmatch() {
    // Swal.fire({
    // title: "Password doesn't match",
    // showClass: {
    //   popup: 'animate__animated animate__fadeInDown'
    // },
    // hideClass: {
    //   popup: 'animate__animated animate__fadeOutUp'
    // }
    // })
    Swal.fire('Password doesnt match', '', 'error');
  }

  form = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    password2: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });
  // Variables
  data = {
    password: this.form.controls.password.value,
  };

  formGroupValidator = false;

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  tooltip() {
    this.tool ? (this.tool = false) : (this.tool = true);
  }

  working() {
    // let data = this.form.value

    // console.log("The vals are", data)
    console.log('password values', this.form.value.password);
    console.log('p2', this.form.value.password2);

    if (this.form.valid) {
      if (
        this.form.controls.password.value == this.form.controls.password2.value
      ) {
        this.data.password = this.form.controls.password.value;
        this.apicaller.newUser(this.data).subscribe(
          (res) => {
            console.log(res);
            this.hints();

            this.router.navigate(['/dashboard']);
          },
          (err) => {
            console.log(err);

            console.log('Invalid otp');
            // alert('Invalid OTP')
            this.iffail();

            let message = err['error']['message'];
            if (message == 'Incorrect OTP') {
              console.log('Invalid otp');
              this.iffail();
              this.router.navigate(['/auth/otpverify']);
            }
          }
        );
      } else {
        console.log('Error please check');
      }
    }
    if (this.form.invalid) {
      this.formGroupValidator = true;
    }
    if (
      this.form.controls.password.value != this.form.controls.password2.value
    ) {
      // alert("Password doesn't match")
      this.passwordnotmatch();
    }
  }
}
