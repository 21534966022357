<div class="col mt-3">
  <div class="col-md-12 dashboardStyling">Dashboard</div>
</div>
<div class="col-12 mt-2 dashboardTxt">
  <div class="row">
    <div class="col-9">
      <div class="tr-cont pb-3">
        <div class="tr-c-h2 pt-3 pl-4">Career Wiki Data</div>
        <div class="d-flex cardWrap">
          <div class="pt-3">
            <div
              class="card"
              style="width: 171px; height: 98px; border-radius: 10px"
            >
              <div class="cardBodyHeading">Career Data</div>
              <div class="d-flex justify-content-end pr-1 pb-1">
                <img src="/assets/AdminDashboard/career.svg" />
              </div>
              <div
                class="cardBodyFooter d-flex justify-content-center op-03 cursor-not-allowed"
              >
                Upload data
              </div>
            </div>
          </div>

          <div class="pt-3">
            <div
              class="card"
              style="width: 171px; height: 98px; border-radius: 10px"
            >
              <div class="cardBodyHeading">Courses Data</div>
              <div class="d-flex justify-content-end pr-1 pb-1">
                <img src="/assets/AdminDashboard/courses.svg" />
              </div>
              <div
                class="cardBodyFooter d-flex justify-content-center op-03 cursor-not-allowed"
                style="background-color: #aa00ff"
              >
                Upload data
              </div>
            </div>
          </div>

          <div class="pt-3">
            <div
              class="card cursor-pointer"
              style="width: 171px; height: 98px; border-radius: 10px"
            >
              <div
                class="cardBodyHeading"
                routerLink="/adminDashboard/scholarships"
              >
                Scholarship Data
              </div>
              <div
                class="d-flex justify-content-end pr-1 pb-1"
                style="height: 38.62px"
                routerLink="/adminDashboard/scholarships"
              >
                <img src="/assets/AdminDashboard/scholarship.svg" />
              </div>
              <div
                class="cardBodyFooter d-flex justify-content-center"
                style="background-color: #c51162"
                (click)="uploadModalToggle('Scholarships')"
              >
                Upload data
              </div>
            </div>
          </div>

          <div class="pt-3">
            <div
              class="card cursor-pointer"
              style="width: 171px; height: 98px; border-radius: 10px"
            >
              <div
                class="cardBodyHeading"
                routerLink="/adminDashboard/entrances"
              >
                Entrance Examination Data
              </div>
              <span
                class="d-flex justify-content-end pr-1 pb-1"
                style="height: 18.68px"
                routerLink="/adminDashboard/entrances"
              >
                <img src="/assets/AdminDashboard/entrance.svg" />
              </span>
              <div
                class="cardBodyFooter d-flex justify-content-center"
                style="background-color: #dd2c00"
                (click)="uploadModalToggle('Entrances')"
              >
                Upload data
              </div>
            </div>
          </div>

          <div class="pt-3">
            <div
              class="card cursor-pointer"
              style="width: 171px; height: 98px; border-radius: 10px"
            >
              <div
                class="cardBodyHeading"
                routerLink="/adminDashboard/colleges"
              >
                Colleges/Universities Data
              </div>
              <span
                class="d-flex justify-content-end pr-1 pb-1"
                style="height: 38.68px"
                routerLink="/adminDashboard/colleges"
              >
                <img src="/assets/AdminDashboard/college.svg" />
              </span>
              <div
                class="cardBodyFooter d-flex justify-content-center"
                style="background-color: #ffab00"
                (click)="uploadModalToggle('Colleges')"
              >
                Upload data
              </div>
            </div>
          </div>

          <div class="pt-3">
            <div
              class="card"
              style="width: 171px; height: 98px; border-radius: 10px"
            >
              <div class="cardBodyHeading">Hostels Data</div>
              <div class="d-flex justify-content-end pr-1 pb-1">
                <img src="/assets/AdminDashboard/hostel.svg" />
              </div>
              <div
                class="cardBodyFooter d-flex justify-content-center op-03 cursor-not-allowed"
                style="background-color: #aeea00"
              >
                Upload data
              </div>
            </div>
          </div>

          <div class="pt-3">
            <div
              class="card"
              style="width: 171px; height: 98px; border-radius: 10px"
            >
              <div class="cardBodyHeading">Bank loan Data</div>
              <div
                class="d-flex justify-content-end pr-1 pb-1"
                style="height: 38.52px"
              >
                <img src="/assets/AdminDashboard/loan.svg" />
              </div>
              <div
                class="cardBodyFooter d-flex justify-content-center op-03 cursor-not-allowed"
                style="background-color: #00cc92"
              >
                Upload data
              </div>
            </div>
          </div>

          <div class="pt-3">
            <div
              class="card"
              style="
                width: 171px;
                height: 98px;
                border-radius: 10px;
                cursor: pointer;
              "
            >
              <div
                class="cardBodyHeading"
                routerLink="/adminDashboard/notifications"
              >
                Notifications Data
              </div>
              <div
                class="d-flex justify-content-end pr-1 pb-1"
                routerLink="/adminDashboard/notifications"
              >
                <img src="/assets/AdminDashboard/notification.svg" />
              </div>
              <div
                class="cardBodyFooter d-flex justify-content-center"
                style="background-color: #00b8d4"
                (click)="uploadModalToggle('Notifications')"
              >
                Upload data
              </div>
            </div>
          </div>

          <div class="pt-3">
            <div
              class="card cursor-pointer"
              style="width: 171px; height: 98px; border-radius: 10px"
            >
              <div
                class="cardBodyHeading"
                routerLink="/adminDashboard/districts"
              >
                Districts Data
              </div>
              <div
                class="d-flex justify-content-end pr-1 pb-1"
                style="height: 38.52px"
                routerLink="/adminDashboard/districts"
              >
                <img src="/assets/AdminDashboard/loan.svg" />
              </div>
              <div
                class="cardBodyFooter d-flex justify-content-center"
                style="background-color: #cc6300"
                (click)="uploadModalToggle('Districts')"
              >
                Upload data
              </div>
            </div>
          </div>

          <div class="pt-3">
            <div
              class="card cursor-pointer"
              style="width: 171px; height: 98px; border-radius: 10px"
            >
              <div class="cardBodyHeading" routerLink="/adminDashboard/states">
                States Data
              </div>
              <div
                class="d-flex justify-content-end pr-1 pb-1"
                style="height: 38.52px"
                routerLink="/adminDashboard/states"
              >
                <img src="/assets/AdminDashboard/loan.svg" />
              </div>
              <div
                class="cardBodyFooter d-flex justify-content-center"
                style="background-color: #86be02"
                (click)="uploadModalToggle('States')"
              >
                Upload data
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tr-cont2 mt-3">
        <div class="getStartedTxt mt-1 pl-4" style="padding-bottom: 5px">
          State Wise Data
        </div>
        <div class="col stateText pl-4">
          The data of all the users will be downloaded in csv format.
        </div>
        <div class="d-flex cardWrap">
          <div class="pt-3">
            <div
              class="card"
              style="width: 171px; height: 94px; border-radius: 10px"
            >
              <div class="stateBodyHeading">Telangana Data</div>
              <div class="stateBodyFooter d-flex justify-content-start">
                Upload data
                <span class="toggleStyling">
                  <img src="/assets/AdminDashboard/toggleOn.svg" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 notParent">
      <div class="notificationC mr-2">
        <div class="bellh">
          <span class="notificationTxt">Quick Actions</span>
        </div>

        <div
          class="col-md-12"
          (click)="generateDialogClick()"
          style="cursor: pointer"
        >
          Download user data
        </div>

        <!-- <div class="col-md-12" (click)="download()" style="cursor: pointer">
          Download user data
        </div> -->

        <div class="col downloadStyling">
          The data of all the users will be downloaded in csv format.
        </div>

        <div
          class="col-md-12 pt-3"
          (click)="deleteDialogClick()"
          style="cursor: pointer"
        >
          Delete expired notifications
        </div>
        <div class="col downloadStyling">
          The notifications past the expiration date will be wiped off from the
          database.
        </div>

        <div
          class="col-md-12 pt-3"
          (click)="uploadNotificationFilesToggle()"
          style="cursor: pointer"
        >
          Upload Notifications
        </div>

        <!-- <div class="col-md-12" (click)="download()" style="cursor: pointer">
          Download user data
        </div> -->

        <div class="col downloadStyling">
          Upload the notification files required to be stored on the server.
        </div>

        <div class="slider">
          <div class="notList" style="cursor: pointer"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- upload notifications modal -->

<div
  class="modal"
  id="uploadNotifications"
  data-keyboard="false"
  data-backdrop="static"
  style="overflow: hidden"
>
  <div class="modal-dialog dialogStyling">
    <div class="modal-content">
      <div
        class="modal-header"
        style="
          background-color: #f2fdff;
          border-bottom: solid 1px var(--primary);
          padding-bottom: 7px;
        "
      >
        <span class="modal-title deleteNotificationStyling"
          >Upload Notifications</span
        >
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body dialogBodyStyling">
        Please select the files to upload.
      </div>
      <div class="p-3">
        <input
          id="NotificationfilesInput"
          type="file"
          class="file-input"
          multiple
          (change)="changeNotificationFiles($event)"
          #notificationFilesUpload
        />

        <div
          class="d-flex file-upload"
          [ngClass]="{
            'file-upload-invalid':
              !notificationFilesAllowed && notificationFiles.length == 0
          }"
        >
          <span *ngIf="notificationFilesAllowed">Select Files</span>
          <span
            *ngIf="!notificationFilesAllowed && notificationFiles.length == 0"
            >Please select files</span
          >
          <button
            mat-mini-fab
            color="primary"
            class="upload-btn"
            [ngClass]="{
              'upload-btn-invalid':
                !notificationFilesAllowed && notificationFiles.length == 0
            }"
            (click)="notificationFilesUpload.click()"
          >
            <mat-icon>attach_file</mat-icon>
          </button>
        </div>
        <div class="d-flex notificationFilesHolder pt-3">
          <div *ngFor="let notificationFile of notificationFiles">
            <div class="d-flex notificationFile">
              <span>{{ notificationFile.name }}</span>
              <mat-icon>close</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="border: none">
        <span style="padding-right: 15px">
          <button
            class="cancelBtn"
            mat-raised-button
            data-dismiss="modal"
            (click)="clearNotificationFiles()"
          >
            Cancel
          </button>
        </span>
        <button
          class="saveBtn"
          mat-raised-button
          (click)="uploadNotifcationAttachments()"
        >
          Upload
        </button>
      </div>
    </div>
  </div>
</div>

<!-- upload modal -->

<div
  class="modal"
  id="uploadData"
  data-keyboard="false"
  data-backdrop="static"
  style="overflow: hidden"
>
  <div class="modal-dialog dialogStyling">
    <div class="modal-content">
      <div
        class="modal-header"
        style="
          background-color: #f2fdff;
          border-bottom: solid 1px var(--primary);
          padding-bottom: 7px;
        "
      >
        <span class="modal-title deleteNotificationStyling">Upload data</span>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body dialogBodyStyling">
        Please select the datasheet containing
        <span style="color: #00b8d4"> {{ careerWikiSection }}</span>
        <span> data</span>
      </div>
      <div class="p-3">
        <div *ngIf="dbOptionsRequired" class="d-flex operation">
          <mat-label>Select the data table : </mat-label>
          <mat-form-field appearance="fill" class="filterFormField">
            <mat-select [(value)]="selectedDbTable">
              <mat-option
                *ngFor="let subDbOptions of dbSubTables"
                value="{{ subDbOptions.value }}"
                >{{ subDbOptions.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="d-flex operation">
          <mat-label>Select the operation : </mat-label>
          <mat-form-field appearance="fill" class="filterFormField">
            <mat-select [(value)]="dbOperation">
              <mat-option value="Insert">Insert</mat-option>
              <mat-option value="Update">Update</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <input
          id="fileInput"
          type="file"
          class="file-input"
          (change)="changeData($event)"
          #fileUpload
        />

        <div
          class="d-flex file-upload"
          [ngClass]="{ 'file-upload-invalid': noFile }"
        >
          {{ fileName }}

          <button
            mat-mini-fab
            color="primary"
            class="upload-btn"
            [ngClass]="{ 'upload-btn-invalid': noFile }"
            (click)="fileUpload.click()"
          >
            <mat-icon>attach_file</mat-icon>
          </button>
        </div>
      </div>
      <div class="modal-footer" style="border: none">
        <span style="padding-right: 15px">
          <button class="cancelBtn" mat-raised-button data-dismiss="modal">
            Cancel
          </button>
        </span>
        <button class="saveBtn" mat-raised-button (click)="uploadData()">
          Upload
        </button>
      </div>
    </div>
  </div>
</div>

<!-- uploadResult Modal -->

<div
  class="modal"
  [ngClass]="{ 'px-5': !uploading && failedRows.length > 0 }"
  id="uploadResult"
  data-keyboard="false"
  data-backdrop="static"
  style="overflow: hidden"
>
  <div class="modal-dialog-upload dialogStyling" [ngClass]="{'w-half': failedRows.length == 0}">
    <div class="modal-content">
      <div
        class="modal-header"
        style="
          background-color: #f2fdff;
          border-bottom: solid 1px var(--primary);
          padding-bottom: 7px;
        "
      >
        <span *ngIf="uploading" class="modal-title deleteNotificationStyling"
          >Uploading...</span
        >
        <span *ngIf="!uploading" class="modal-title deleteNotificationStyling"
          >Upload Results</span
        >
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div *ngIf="!uploading" class="modal-body dialogBodyStyling">
        Please review the
        <span
          class="cursor-pointer"
          style="color: #00b8d4"
          (click)="navigateUsingResults()"
        >
          Results</span
        >
      </div>
      <div class="table-body p-3">
        <mat-progress-spinner
          *ngIf="uploading"
          class="result-spinner"
          [mode]="spinnerMode"
        >
        </mat-progress-spinner>
        <div *ngIf="!uploading && failedRows.length == 0">
          {{ resultMessage }}
        </div>
        <div *ngIf="!uploading && failedRows.length > 0">
          <table mat-table [dataSource]="failedRows" class="mat-elevation-z8">
            <ng-container matColumnDef="rowId">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element">{{ element.rowId }}</td>
            </ng-container>

            <ng-container matColumnDef="column_name">
              <th mat-header-cell *matHeaderCellDef>Column Name</th>
              <td mat-cell *matCellDef="let element">{{ element.colName }}</td>
            </ng-container>

            <ng-container matColumnDef="errMessage">
              <th mat-header-cell *matHeaderCellDef>Error Message</th>
              <td mat-cell *matCellDef="let element">
                {{ element.errMessage }}
              </td>
            </ng-container>

            <ng-container matColumnDef="process">
              <th mat-header-cell *matHeaderCellDef>Operation</th>
              <td mat-cell *matCellDef="let element">{{ element.process }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
      <div class="modal-footer" style="border: none">
        <span class="d-flex modal-footer-btnHolder">
          <button
            class="cancelBtn"
            mat-raised-button
            (click)="showUploadModal()"
          >
            Re-upload
          </button>
          <button class="cancelBtn" mat-raised-button data-dismiss="modal">
            Close
          </button>
        </span>
      </div>
    </div>
  </div>
</div>

<!-- Notifications upload Result Modal -->

<div
  class="modal"
  [ngClass]="{
    'px-5': !notificationsUploading && notificationsFailedRows.length > 0
  }"
  id="notificationsUploadResult"
  data-keyboard="false"
  data-backdrop="static"
  style="overflow: hidden"
>
  <div class="modal-dialog-upload dialogStyling">
    <div class="modal-content">
      <div
        class="modal-header"
        style="
          background-color: #f2fdff;
          border-bottom: solid 1px var(--primary);
          padding-bottom: 7px;
        "
      >
        <span
          *ngIf="notificationsUploading"
          class="modal-title deleteNotificationStyling"
          >Uploading...</span
        >
        <span
          *ngIf="!notificationsUploading"
          class="modal-title deleteNotificationStyling"
          >Upload Results</span
        >
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div
        *ngIf="!notificationsUploading"
        class="modal-body dialogBodyStyling"
        [ngClass]="{ 'w-full': notificationsFailedRows.length > 0 }"
      >
        Please review the Results
        <div class="table-body p-3">
          <mat-progress-spinner
            *ngIf="notificationsUploading"
            class="result-spinner"
            [mode]="spinnerMode"
          >
          </mat-progress-spinner>
          <div
            *ngIf="
              !notificationsUploading && notificationsFailedRows.length == 0
            "
          >
            {{ notificationsResultMessage }}
          </div>
          <div
            *ngIf="
              !notificationsUploading && notificationsFailedRows.length > 0
            "
          >
            <table
              mat-table
              [dataSource]="notificationsFailedRows"
              class="mat-elevation-z8"
            >
              <ng-container matColumnDef="serialNo">
                <th mat-header-cell *matHeaderCellDef>FileName</th>
                <td
                  mat-cell
                  *matCellDef="let element; let elementIndex = index"
                >
                  {{ elementIndex + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="fileName">
                <th mat-header-cell *matHeaderCellDef>FileName</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.fileName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="fileUrl">
                <th mat-header-cell *matHeaderCellDef>Attachment URL</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.fileUrl }}
                </td>
              </ng-container>

              <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef>Message</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.message }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="notificationsDisplayedColumns"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: notificationsDisplayedColumns"
              ></tr>
            </table>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <span class="d-flex modal-footer-btnHolder">
            <button
              class="cancelBtn"
              mat-raised-button
              (click)="showNotificationsUploadModal()"
            >
              Re-upload
            </button>
            <button class="cancelBtn" mat-raised-button data-dismiss="modal">
              Close
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="terms"
  data-keyboard="false"
  data-backdrop="static"
  style="overflow: hidden"
>
  <div class="modal-dialog dialogStyling">
    <div class="modal-content">
      <div
        class="modal-header"
        style="
          background-color: #f2fdff;
          border-bottom: solid 1px var(--primary);
          padding-bottom: 7px;
        "
      >
        <span class="modal-title deleteNotificationStyling"
          >Delete Notifications</span
        >
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body dialogBodyStyling" *ngIf="count == 0">
        There are no expired notification to delete
      </div>

      <div class="modal-body dialogBodyStyling" *ngIf="count !== 0">
        Are you sure you want to delete
        <span style="color: #00b8d4"> {{ count }} expired notifications? </span>
      </div>
      <div class="modal-footer" style="border: none" *ngIf="count !== 0">
        <span style="padding-right: 15px">
          <button class="cancelBtn" mat-raised-button data-dismiss="modal">
            Cancel
          </button>
        </span>
        <button
          class="saveBtn"
          mat-raised-button
          (click)="deleteExpiredNotifications()"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</div>

<!-- For Genertae user data dialog box -->

<div
  class="modal"
  id="deleleDialog"
  data-keyboard="false"
  data-backdrop="static"
>
  <div class="modal-dialog dialogStyling">
    <div class="modal-content">
      <div
        class="modal-header"
        style="
          background-color: #f2fdff;
          border-bottom: solid 1px var(--primary);
          padding-bottom: 7px;
        "
      >
        <span class="modal-title deleteNotificationStyling"
          >Generate user data</span
        >
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form [formGroup]="form1" (ngSubmit)="getUsersApiCall()">
        <div class="modal-body dialogBodyStyling" style="padding-bottom: 0px">
          <span> Please select date </span>
          <div class="row" style="padding-top: 1rem">
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label class="selectFontText"> From*</mat-label>
                <input
                  placeholder="Select Date*"
                  matInput
                  [max]="minDate"
                  class="selectFontText"
                  formControlName="fromDate"
                  [matDatepicker]="dp"
                  [readonly]="true"
                />

                <mat-datepicker-toggle
                  matSuffix
                  [for]="dp"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
                <mat-hint
                  *ngIf="
                    (form1.controls.fromDate.touched || formGroupValidator) &&
                    form1.controls.fromDate.invalid
                  "
                  class="red"
                  >Select Date</mat-hint
                >
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label class="selectFontText"> TO*</mat-label>
                <input
                  placeholder="Select Date*"
                  matInput
                  [max]="minDate"
                  class="selectFontText"
                  formControlName="toDate"
                  [matDatepicker]="dp1"
                  [readonly]="true"
                />

                <mat-datepicker-toggle
                  matSuffix
                  [for]="dp1"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp1></mat-datepicker>
                <mat-hint
                  *ngIf="
                    (form1.controls.toDate.touched || formGroupValidator) &&
                    form1.controls.toDate.invalid
                  "
                  class="red"
                  >Select Date</mat-hint
                >
              </mat-form-field>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 modal-footer"
          style="
            border: none;
            justify-content: start;
            padding-top: 0px;
            padding-bottom: 20px;
          "
        >
          <button class="saveBtn" mat-raised-button type="submit">
            Download user data
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
