import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from './../api-calls.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
})
export class AdminDashboardComponent implements OnInit {
  constructor(private router: Router, private apiCaller: ApiCallsService) {}

  logout() {
    this.apiCaller.logout().subscribe(
      (res) => {
        console.log(res);
        localStorage.clear();
        this.router.navigate(['/admin']);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/admin']);
      }
    );
  }

  verifyLogin() {
    this.apiCaller.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/admin']);
      }
    );
  }

  ngOnInit(): void {
    this.verifyLogin();

    const firstTime = localStorage.getItem('key');
    if (!firstTime) {
      localStorage.setItem('key', 'loaded');
      location.reload();
    } else {
      localStorage.removeItem('key');
    }
  }
}
