import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
// import { timeStamp } from 'console';
import { ApiCallsService } from './../api-calls.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import Swal from 'sweetalert2';
import { Districts } from './districts.data';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  @Input() deviceXs: boolean;
  maxDate: Date;
  tool = false;
  tool1 = false;
  mediaSub: Subscription;
  trigger = false;
  hide = true;
  hide2 = true;

  constructor(
    private apicaller: ApiCallsService,
    private formBuilder: FormBuilder,
    private router: Router,
    public mediaObserver: MediaObserver
  ) {
    this.maxDate = new Date();
  }

  states: any = [];
  districts: any = [];

  regsuc() {
    // Swal.fire({
    //   title: 'Signup successful. You can now login to the CAP portal with the credentials sent to your registered mobile number',
    //   // showClass: {
    //   //   popup: 'animate__animated animate__fadeInDown'
    //   // },
    //   // hideClass: {
    //   //   popup: 'animate__animated animate__fadeOutUp'
    //   // }
    // })
    Swal.fire('Signup successful.', '', 'success');
  }

  regfail() {
    Swal.fire('Signup failed!', '', 'error');
  }

  dataa() {
    this.trigger = true;
  }

  data = {
    email: null,
    username: '',
    password: '',
    name: '',
    mobile: '',
    gender: null,
    disability: null,
    date_of_birth: null,
    current_status: null,
    qualification: null,
    institute_category: null,
    institute_name: null,
    course: null,
    residential_category: null,
    city_name: '',
    mandal_name: '',
    state: '',
    district: null,
    district_id: 999,
    profession: null,
    organization_name: null,
  };

  usernameErrorMessage = 'Please enter valid username';
  usernameExists = false;
  usernameNotExists = false;

  emailErrorMessage = '';
  emailExists = false;
  emailNotExists = false;

  loadedDistricts: any = [];

  //Vars
  stepTracker = null;
  formGroupValidator = false;
  formGroupValidator2 = false;
  formGroupValidator3 = false;
  formGroupValidator4 = false;
  currentStatus = null;
  studyEnabler = true;

  currentPath = '/assets/Auth/current.svg';
  completePath = '/assets/Auth/complete.svg';
  futurePath = '/assets/Auth/future.svg';

  step1 = this.currentPath;
  step2 = this.futurePath;
  step3 = this.futurePath;
  step4 = this.futurePath;

  goldText1 = false;
  goldText2 = false;
  goldText3 = false;

  innerWidth: any;

  isCapacitor: boolean;

  // Functions

  usernameVerification() {
    // console.log("on blur executed");
    // this.usernameErrorMessage = "Please enter valid username"
    let usernameToverify = this.form4.controls.username.value;
    let usernameToverify1 = this.form4.controls.username.invalid;
    this.usernameErrorMessage = 'Please enter valid username';
    this.usernameExists = false;
    this.usernameNotExists = false;
    usernameToverify = usernameToverify.trim();
    if (usernameToverify.includes('.')) {
      this.form4.controls.username.setErrors({ incorrect: true });
    }
    if (
      usernameToverify != null &&
      usernameToverify != undefined &&
      usernameToverify != '' &&
      usernameToverify1 != true
    ) {
      this.apicaller.usernameApi(usernameToverify).subscribe((res) => {
        console.log(res);
        // localStorage.setItem('Token', res['token'])

        let message = res['message'];
        console.log['message'];
        if (res['message'] == 'Username Available') {
          this.usernameErrorMessage = 'Username available ';
          this.usernameNotExists = true;
        }
        if (res['message'] == 'Username Already Exists') {
          this.form4.controls.username.setErrors({ incorrect: true });
          this.usernameErrorMessage = 'Username not available ';
          this.usernameExists = true;
        }
      });
    }
  }

  emailVerification() {
    // console.log("on blur executed");
    // this.usernameErrorMessage = "Please enter valid username"
    let emailToverify = this.form1.controls.email.value;
    let emailToverify1 = this.form1.controls.email.invalid;
    this.emailErrorMessage = 'Please enter valid email';
    this.emailExists = false;
    this.emailNotExists = false;
    emailToverify = emailToverify.trim();
    if (
      emailToverify != null &&
      emailToverify != undefined &&
      emailToverify != '' &&
      emailToverify1 != true
    ) {
      this.apicaller.emailCheckApi(emailToverify).subscribe((res) => {
        console.log(res);
        // localStorage.setItem('Token', res['token'])

        let message = res['message'];
        console.log['message'];
        if (res['message'] == 'Email Available') {
          this.emailErrorMessage = 'Email Available ';
          this.emailNotExists = true;
        }
        if (res['message'] == 'Email Already Exists') {
          this.emailExists = true;
          this.form1.controls.email.setErrors({ incorrect: true });
          this.emailErrorMessage = 'Email already exists';
        }
      });
    }
  }

  // input() {
  //   console.log("logged")
  //   this.form1.controls['email'].setValue(null);
  //   console.log(this.form1.value.email);
  // }

  GotoStep3() {
    this.stepTracker = 2;
  }

  onSubmit() {
    console.log('checking onSubmit.. ');
    // this.usernameVerification();
    if (this.form1.valid) {
      console.log('in first if case');
      // alert("Form is valid")
      if (this.form1.value.email == '') {
        this.form1.controls['email'].setValue(null);
      }

      this.stepTracker = 3;
      this.step1 = this.completePath;
      this.step3 = this.currentPath;
      this.goldText3 = true;
    } else {
      console.log('in else case');
      // alert("Invalid")
      this.formGroupValidator = true;
    }
  }

  onSubmitStep2() {
    // console.log("hello")
    if (this.form2.valid) {
      console.log('entered if');
      // alert("Form is valid")
      this.stepTracker = 2;
      this.step2 = this.completePath;
      this.step3 = this.currentPath;
      this.goldText3 = true;
      // localStorage.setItem('academicValue', this.form2.value.qualification )
    } else {
      // alert("Invalid")
      this.formGroupValidator2 = true;
    }
    // if(this.studyEnabler==false){
    //   this.form2.value.instituteCat.Validators.require
    // }
  }

  onSubmitStep3() {
    // console.log("hello")
    if (this.form3.valid) {
      console.log('entered iffffff');
      // alert("Form is valid")
      this.stepTracker = 3;
      this.step3 = this.completePath;
      this.step4 = this.currentPath;
      this.goldText1 = true;
      // localStorage.setItem('stateValue', this.form3.value.state);
    } else {
      // alert("Invalid")
      this.formGroupValidator3 = true;
    }
    // if(this.studyEnabler==false){
    //   this.form2.value.instituteCat.Validators.require
    // }
  }

  submitFinal() {
    if (this.stepTracker === null) {
      this.onSubmit();
    } else {
      console.log(this.form1.value.email);
      if (
        this.form1.value.email == '' ||
        this.form1.value.email == null ||
        this.form1.value.email == undefined
      ) {
        console.log('email is not entered; email is empty');
        this.emailNotExists = true;
      }
      console.log(`form1 status: ${this.form1.valid}`);
      console.log(`uname status: ${this.usernameNotExists}`);
      console.log(`email status: ${this.emailNotExists}`);
      if (
        // this.form3.valid &&
        // this.form2.valid &&
        // this.form4.valid &&
        this.form1.valid &&
        this.usernameNotExists == true &&
        this.emailNotExists == true
      ) {
        {
          console.log('Form is valid');
          // if(this.form1.value.email == ""){
          //   this.form1.controls['email'].setValue(null);
          //   console.log("Form is passed")
          // this.stepTracker = 2
          this.signup();
          // this.step3 = this.currentPath
          this.goldText3 = true;
          // }
        }
      } else {
        // alert('Invalid');
        this.formGroupValidator4 = true;
      }
    }
  }

  scrollForMobile(control: string) {
    console.log('triggered');
    let firstValidator: boolean = false;
    let secondValidator: boolean = false;
    const beforeQualificationArr: string[] = [
      'name',
      'email',
      'phone',
      'gender',
    ];
    const afterQualificationArr: string[] = beforeQualificationArr.concat([
      'qualification',
      'status',
      'villageTownCityName',
      'state',
      'district',
    ]);
    if (control === 'qualification') {
      beforeQualificationArr.forEach((beforeQualificationEle, _) => {
        if (this.form1.controls[beforeQualificationEle].valid) {
          console.log(
            'isValid: ',
            this.form1.controls[beforeQualificationEle].valid
          );
          firstValidator = true;
        } else {
          firstValidator = false;
        }
      });
      if (this.form1.controls.qualification.valid && firstValidator) {
        console.log('qualification');
        let element = document.getElementById('signupForm');
        console.log(element);
        element.scrollTop = element.scrollHeight;
      }
    } else if (control === 'isEmployed') {
      afterQualificationArr.forEach((afterQualificationEle, _) => {
        if (this.form1.controls[afterQualificationEle].valid) {
          secondValidator = true;
        } else {
          secondValidator = false;
        }
      });
      if (this.form1.controls.status.valid && secondValidator) {
        setTimeout(() => {
          console.log('isEmployed');
          let element = document.getElementById('signupForm');
          console.log(element);
          element.scrollTop = element.scrollHeight;
        }, 100);
      }
    }
  }

  checkStatus() {
    console.log('hello', this.form1.value);
    this.scrollForMobile('isEmployed');
    if (this.form1.value.status === '0') {
      this.studyEnabler = false;
      console.log('studying123..');
      // this.form2.controls['instituteCat'].setValidators([Validators.required]);
      // this.form2.get('instituteCat').updateValueAndValidity();
      this.form1.controls['schoolOrCollege'].setValidators([
        Validators.required,
        Validators.maxLength(100),
      ]);
      this.form1.get('schoolOrCollege').updateValueAndValidity();
      // this.form2.controls['instituteCourse'].setValidators([
      //   Validators.required,
      //   Validators.maxLength(100),
      // ]);
      // this.form2.get('instituteCourse').updateValueAndValidity();

      this.form1.controls['professionOrOrganization'].setValue(null);
      // this.form1.controls['professionOrOrganization'].setValue(null);

      this.form1.controls['professionOrOrganization'].clearValidators();
      this.form1.controls['professionOrOrganization'].updateValueAndValidity();

      // this.form2.controls['organization'].clearValidators();
      // this.form2.controls['organization'].updateValueAndValidity();

      console.log('studying..');
    } else if (this.form1.value.status === '1') {
      this.studyEnabler = true;
      console.log('Senorita');
      this.form1.controls['schoolOrCollege'].setValue(null);
      // this.form1.controls['instituteName'].setValue(null);
      // this.form1.controls['instituteCourse'].setValue(null);
      this.form1.controls['schoolOrCollege'].clearValidators();
      this.form1.get('schoolOrCollege').updateValueAndValidity();
      // this.form1.controls['instituteName'].clearValidators();
      // this.form1.get('instituteName').updateValueAndValidity();
      // this.form1.controls['instituteCourse'].clearValidators();
      // this.form1.get('instituteCourse').updateValueAndValidity();

      this.form1.controls['professionOrOrganization'].setValidators([
        Validators.required,
      ]);
      this.form1.get('professionOrOrganization').updateValueAndValidity();

      // this.form2.controls['organization'].setValidators([Validators.required]);
      // this.form2.get('organization').updateValueAndValidity();
    } else if (this.form1.value.status === '2') {
      this.studyEnabler = true;
      console.log('none');
      this.form1.controls['schoolOrCollege'].setValue(null);
      // this.form1.controls['instituteName'].setValue(null);
      // this.form1.controls['instituteCourse'].setValue(null);
      this.form1.controls['schoolOrCollege'].clearValidators();
      this.form1.get('schoolOrCollege').updateValueAndValidity();
      // this.form1.controls['instituteName'].clearValidators();
      // this.form1.get('instituteName').updateValueAndValidity();
      // this.form1.controls['instituteCourse'].clearValidators();
      // this.form1.get('instituteCourse').updateValueAndValidity();

      this.form1.controls['professionOrOrganization'].setValue(null);
      // this.form1.controls['organization'].setValue(null);

      this.form1.controls['professionOrOrganization'].clearValidators();
      this.form1.controls['professionOrOrganization'].updateValueAndValidity();

      // this.form1.controls['organization'].clearValidators();
      // this.form1.controls['organization'].updateValueAndValidity();
    }
  }

  checkStatus1() {
    this.scrollForMobile('isEmployed');
    if (this.form1.value.state === 99) {
      this.form1.controls['district'].setValidators([Validators.required]);
      this.form1.get('district').updateValueAndValidity();
      this.form1.controls['district_id'].setValue(999);
      this.form1.controls['district_id'].clearValidators();
      this.form1.controls['district_id'].updateValueAndValidity();
    } else {
      this.form1.controls['district_id'].setValidators([Validators.required]);
      this.form1.get('district_id').updateValueAndValidity();
      this.form1.controls['district'].setValue(null);
      this.form1.controls['district'].clearValidators();
      this.form1.controls['district'].updateValueAndValidity();
    }
  }

  // Form builder
  form1 = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z ]*$'),
    ]),

    phone: new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^(0|[1-9][0-9]*)$'),
    ]),
    // dob: new FormControl('', [Validators.required]),
    dob: new FormControl(''),
    email: new FormControl('', [
      // Validators.required,

      Validators.pattern('^[a-zA-Z0-9+_.]+@[a-zA-Z]+[.]+[a-zA-Z]+$'),
    ]),
    gender: new FormControl('', [Validators.required]),
    // disability: new FormControl('', [Validators.required]),
    disability: new FormControl(''),
    // ** form 2 controls
    qualification: new FormControl('', [Validators.required]),
    // status: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    // instituteCat: new FormControl(''),
    // instituteName: new FormControl('', [Validators.required]),
    // instituteName: new FormControl(''),
    // instituteCourse: new FormControl('', [Validators.required]),
    instituteCourse: new FormControl(''),
    professionOrOrganization: new FormControl(''),
    schoolOrCollege: new FormControl(''),
    // ** Form 3 controls
    residenceCat: new FormControl(''),
    villageTownCityName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    // talukMandalName: new FormControl('', [
    //   Validators.required,
    //   Validators.maxLength(100),
    // ]),
    talukMandalName: new FormControl(''),
    state: new FormControl('', [Validators.required]),
    district: new FormControl('', [Validators.required]),
    district_id: new FormControl('', [Validators.required]),
  });

  form2 = new FormGroup({
    qualification: new FormControl('', [Validators.required]),
    // status: new FormControl('', [Validators.required]),
    status: new FormControl(''),
    instituteCat: new FormControl(''),
    // instituteName: new FormControl('', [Validators.required]),
    instituteName: new FormControl(''),
    // instituteCourse: new FormControl('', [Validators.required]),
    instituteCourse: new FormControl(''),
    professional: new FormControl(''),
    organization: new FormControl(''),
  });

  form3 = new FormGroup({
    // residenceCat: new FormControl('', [Validators.required]),
    residenceCat: new FormControl(''),
    villageTownCityName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    // talukMandalName: new FormControl('', [
    //   Validators.required,
    //   Validators.maxLength(100),
    // ]),
    talukMandalName: new FormControl(''),
    state: new FormControl('', [Validators.required]),
    district: new FormControl('', [Validators.required]),
    district_id: new FormControl('', [Validators.required]),
  });

  form4 = new FormGroup({
    // username: new FormControl('', [
    //   Validators.required,
    //   Validators.pattern('^[a-zA-Z0-9_-]*$'),
    // ]),
    username: new FormControl('', [
      Validators.required,
      Validators.pattern('^[^.]*$'),
    ]),

    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    password2: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        console.log(result);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.innerWidth = window.innerWidth;
    this.checkPlatform();
    // this.filteredOptions = this.district1.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filter(value))
    //   );

    this.getAllStates();
    // this.getAllDistricts();
  }

  // ** Being Used for responsive purposes
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log('innerWidth is: ', this.innerWidth);
  }

  checkPlatform() {
    if (Capacitor.isNativePlatform()) {
      this.isCapacitor = true;
      this.innerWidth = 'capacitor';
      console.log('Capacitor value: ', this.isCapacitor);
    } else {
      this.isCapacitor = false;
      console.log('Capacitor value: ', this.isCapacitor);
    }
  }

  addCapacitorClasses(): string {
    this.checkPlatform();
    let commonClass = 'row mt-4';
    if (this.innerWidth > 1199 && !this.isCapacitor) {
      return `${commonClass} + " " + dropdown-large`;
    } else if (this.innerWidth == 'capacitor' && this.isCapacitor) {
      return `${commonClass} + " " + dropdown-upto-large-capacitor`;
    } else {
      return `${commonClass} + " " + dropdown-upto-large`;
    }
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  // }

  signup() {
    // console.log('hello')
    // let date = new Date()
    // console.log('helloooooo')
    // date=this.form1.controls.dob.value
    // console.log(date)
    // let date2= new Date('01/01/2020')

    // ** commented for new feature!
    // let month = this.form1.controls.dob.value;
    // console.log(month.getMonth())
    // let day = this.form1.controls.dob.value;
    // console.log(day.getDay())
    // let year = this.form1.controls.dob.value;
    // console.log(month.getMonth());
    // console.log(
    //   `${month.getMonth() + 1}-${day.getDay()}-${year.getFullYear()}`
    // );
    // ** commented for new feature!

    let district = this.form1.value.district;
    let district_id = this.form1.value.district_id;

    if (district == 'empty') {
      district = null;
    }

    if (this.form1.valid && this.form4.valid) {
      this.data = {
        name: this.form1.value.name,
        email: this.form1.value.email,
        username: this.form4.value.username,
        password: this.form4.value.password,
        mobile: this.form1.value.phone,
        // date_of_birth: `${
        //   month.getMonth() + 1
        // }/${day.getDate()}/${year.getFullYear()}`,
        date_of_birth: '',
        gender: parseInt(this.form1.value.gender),
        disability: parseInt(this.form1.value.disability),
        current_status: parseInt(this.form1.value.status),
        qualification: parseInt(this.form1.value.qualification),
        institute_category: null,
        institute_name: this.form1.value.schoolOrCollege,
        course: this.form1.value.schoolOrCollege,
        residential_category: parseInt(this.form1.value.residenceCat),
        city_name: this.form1.value.villageTownCityName,
        mandal_name: this.form1.value.talukMandalName,
        state: this.form1.value.state,
        district: this.form1.value.district,
        district_id: this.form1.value.district_id,
        profession: this.form1.value.professionOrOrganization,
        organization_name: this.form1.value.professionOrOrganization,
        // institute_category: null,
        // institute_name: null,
        // course: null,
      };

      // ** optional fields modifier
      for (const key in this.data) {
        console.log(`key: ${key}, value: ${this.data[key]}`);
        if (this.data[key] != null) {
          if (this.data[key].length <= 0) {
            this.data[key] = null;
          }
        }
      }

      if ((this.studyEnabler = false)) {
        console.log('qwerty');
        (this.data.institute_category = this.form1.value.instituteCat),
          (this.data.institute_name = this.form1.value.instituteName),
          (this.data.course = this.form1.value.instituteCourse);
      }
      if (this.form1.value.status === 1) {
        console.log('abcd');
      }

      console.log('The vals are', this.data);
      this.apicaller.signupApi(this.data).subscribe(
        (res) => {
          console.log(res);
          // alert("Signup succesful")
          this.regsuc();
          this.router.navigate(['/auth']);
        },
        (err) => {
          console.log(err);
          this.regfail();
          // alert('Signup Failed')
        }
      );
    } else {
      console.error('Forms are not valid please check');
    }
  }

  getAllStates() {
    this.apicaller.getAdminStates().subscribe(
      (res) => {
        console.log(res);
        this.states = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllDistricts(id) {
    this.apicaller.getDistricts(id).subscribe(
      (res) => {
        console.log(res);
        this.districts = res;
        // if(this.form3.value.state === 99) {
        //   this.form3.value.district_id = 999
        // }
        // else{
        //   this.form3.value.district = "empty"
        // }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  tooltip() {
    this.tool ? (this.tool = false) : (this.tool = true);
  }

  tooltipp() {
    this.tool1 ? (this.tool1 = false) : (this.tool1 = true);
  }
  /* Function triggers on state chnages */

  /* Route to prefered Step */
  gotoStep(step) {
    this.stepTracker = step;
  }
}
