import { Component, OnDestroy, OnInit } from '@angular/core';
// import { Plugins } from '@capacitor/core';
// import {ApiCallsService} from './api-calls.service'
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'nirman';
  mediaSub: Subscription;
  deviceXs: boolean;
  constructor(
    public mediaObserver: MediaObserver,
    public router: Router,
    private ga: GoogleAnalytics
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-BZVSR993G4', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
    this.initializeGA();
  }
  ngOnInit() {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
  }

  initializeGA() {
    this.ga
      .startTrackerWithId('G-BZVSR993G4')
      .then(() => {
        console.log('Google analytics is ready now');
        this.ga.trackView('master-dashboard');
        // Tracker is ready
        // You can now track pages or set additional information such as AppVersion or UserId
      })
      .catch((e) => console.log('Error starting GoogleAnalytics', e));
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }
}
