import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ApiCallsService } from './../api-calls.service';
import { __values } from 'tslib';

declare var $: any;

@Component({
  selector: 'app-admin-add-notifications',
  templateUrl: './admin-add-notifications.component.html',
  styleUrls: ['./admin-add-notifications.component.css'],
})
export class AdminAddNotificationsComponent implements OnInit {
  // for notification checkbox
  @Input() isNotificationChecked0 = false;
  @Input() isNotificationChecked1 = false;
  @Input() isNotificationChecked2 = false;
  @Input() isNotificationChecked3 = false;
  @Input() isNotificationChecked4 = false;
  @Input() isNotificationChecked5 = false;

  // for academicLevel checkbox
  @Input() isAcademicChecked0 = false;
  @Input() isAcademicChecked1 = false;
  @Input() isAcademicChecked2 = false;
  @Input() isAcademicChecked3 = false;
  @Input() isAcademicChecked4 = false;
  @Input() isAcademicChecked5 = false;
  @Input() isAcademicChecked6 = false;
  @Input() isAcademicChecked7 = false;
  @Input() isAcademicChecked8 = false;
  @Input() isAcademicChecked9 = false;
  @Input() isAcademicChecked10 = false;
  @Input() isAcademicChecked11 = false;
  @Input() isAcademicChecked12 = false;
  @Input() isAcademicChecked13 = false;
  @Input() isAcademicChecked14 = false;
  @Input() isAcademicChecked15 = false;

  @Input() attachmentname = 'Upload Attachmentfile';

  minDate: Date;

  constructor(
    private router: Router,
    public mediaObserver: MediaObserver,
    public dialog: MatDialog,
    private apicaller: ApiCallsService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AdminAddNotificationsComponent>
  ) {
    this.minDate = new Date();
  }

  //for state checkbox

  stateArray: any = [];
  stateList: any = [];

  //for Upload Attachment file

  attachmentType = null;

  //  for notification checkbox

  notificationValue: number;
  notificationArray: any = [];

  //  for acdemic checkboxs

  academicValue: number;
  academicArray: any = [];

  notificationId = {
    id: null,
  };

  //Vars
  formGroupValidator = false;
  formGroupStateValidator = false;
  formGroupNotificationValidator = false;
  formGroupAttachmentNameValidator = false;
  formGroupActiveuntil = false;
  formGroupAttachmentFile = false;
  formGroupAcademicValidator = false;

  // Form builder
  form1 = new FormGroup({
    name: new FormControl('', [Validators.required]),
    active_untill: new FormControl('', [Validators.required]),
    attachment_name: new FormControl(''),
    attachment_file: new FormControl(''),
  });

  form2 = new FormGroup({
    selectedNotifications: new FormControl('', [Validators.required]),
  });

  //for Upload Attachment file

  setAttachmentFiles(files) {
    this.attachmentType = files[0];
    if (this.attachmentType === undefined) {
      this.formGroupValidator = true;
      console.log(this.attachmentType);
      return (this.attachmentname = 'Upload Attachmentfile');
    }
    if (this.attachmentType === null) {
      this.formGroupValidator = true;
      console.log(this.attachmentType);
      // return this.attachmentname = "Upload Attachmentfile"
    } else if (this.attachmentType !== '') {
      console.log(this.attachmentType);
      this.attachmentname = this.attachmentType.name;
      this.formGroupValidator = false;
      return this.attachmentname;
    }
  }

  goToLanding() {
    console.log('closing');
    console.log('closing');
    this.dialogRef.close(true);
  }

  // notification tag functions

  onStateChange() {
    this.stateArray = [];
    this.stateList.forEach((value, index) => {
      if (value.isChecked) {
        this.stateArray.push(value.state_id);
        console.log(this.stateArray);
      }
      if (this.stateArray.length === 0) {
        this.formGroupStateValidator = true;
      }
      if (this.stateArray.length !== 0) {
        this.formGroupStateValidator = false;
      }
    });
  }

  onNotificationChange(
    isNotificationChecked0,
    isNotificationChecked1,
    isNotificationChecked2,
    isNotificationChecked3,
    isNotificationChecked4,
    isNotificationChecked5
  ) {
    let notificationArray = [];
    let selectedNotifications = '';
    if (
      !isNotificationChecked0 &&
      !isNotificationChecked1 &&
      !isNotificationChecked2 &&
      !isNotificationChecked3 &&
      !isNotificationChecked4 &&
      !isNotificationChecked5
    ) {
      this.form2.controls.selectedNotifications.setValue(null);
      this.formGroupNotificationValidator = true;
    } else {
      if (isNotificationChecked0) {
        notificationArray.push(0);
        this.formGroupNotificationValidator = false;
      }
      if (isNotificationChecked1) {
        notificationArray.push(1);
        this.formGroupNotificationValidator = false;
      }
      if (isNotificationChecked2) {
        notificationArray.push(2);
        this.formGroupNotificationValidator = false;
      }
      if (isNotificationChecked3) {
        notificationArray.push(3);
        this.formGroupNotificationValidator = false;
      }
      if (isNotificationChecked4) {
        notificationArray.push(4);
        this.formGroupNotificationValidator = false;
      }
      if (isNotificationChecked5) {
        notificationArray.push(5);
        this.formGroupNotificationValidator = false;
      }
    }
    for (let i = 0; i < notificationArray.length; i++) {
      if (i == 0) {
        selectedNotifications = selectedNotifications + notificationArray[i];
      } else {
        selectedNotifications =
          selectedNotifications + ',' + notificationArray[i];
      }
    }
    this.form2.controls.selectedNotifications.setValue(selectedNotifications);
    console.log(this.form2.controls.selectedNotifications);
  }

  // Academic level functions

  onChangeAcademiccheck0(isAcademicChecked0) {
    if (isAcademicChecked0 === true) {
      this.academicValue = 0;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(0);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck1(isAcademicChecked1) {
    if (isAcademicChecked1 === true) {
      this.academicValue = 1;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(1);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck2(isAcademicChecked2) {
    if (isAcademicChecked2 === true) {
      this.academicValue = 2;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(2);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck3(isAcademicChecked3) {
    if (isAcademicChecked3 === true) {
      this.academicValue = 3;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(3);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck4(isAcademicChecked4) {
    if (isAcademicChecked4 === true) {
      this.academicValue = 4;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(4);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck5(isAcademicChecked5) {
    if (isAcademicChecked5 === true) {
      this.academicValue = 5;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(5);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck6(isAcademicChecked6) {
    if (isAcademicChecked6 === true) {
      this.academicValue = 6;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(6);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck7(isAcademicChecked7) {
    if (isAcademicChecked7 === true) {
      this.academicValue = 7;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(7);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck8(isAcademicChecked8) {
    if (isAcademicChecked8 === true) {
      this.academicValue = 8;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(8);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck9(isAcademicChecked9) {
    if (isAcademicChecked9 === true) {
      this.academicValue = 9;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(9);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck10(isAcademicChecked10) {
    if (isAcademicChecked10 === true) {
      this.academicValue = 10;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(10);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck11(isAcademicChecked11) {
    if (isAcademicChecked11 === true) {
      this.academicValue = 11;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(11);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck12(isAcademicChecked12) {
    if (isAcademicChecked12 === true) {
      this.academicValue = 12;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(12);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck13(isAcademicChecked13) {
    if (isAcademicChecked13 === true) {
      this.academicValue = 13;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(13);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck14(isAcademicChecked14) {
    if (isAcademicChecked14 === true) {
      this.academicValue = 14;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(14);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onChangeAcademiccheck15(isAcademicChecked15) {
    if (isAcademicChecked15 === true) {
      this.academicValue = 15;
      this.academicArray.push(this.academicValue);
      this.formGroupAcademicValidator = false;
    } else {
      var academicIndex = this.academicArray.indexOf(15);
      if (academicIndex > -1) {
        this.academicArray.splice(academicIndex, 1);
      }
    }
  }

  onSaveAndCreateNew() {
    if (this.form1.value.active_untill === '') {
      this.formGroupActiveuntil = true;
    }
    if (this.form2.value.selectedNotifications === '') {
      this.formGroupNotificationValidator = true;
    }
    if (this.academicArray.length === 0) {
      this.formGroupAcademicValidator = true;
    }
    if (this.academicArray.length !== 0) {
      this.formGroupAcademicValidator = false;
    }
    if (this.stateArray.length === 0) {
      this.formGroupStateValidator = true;
    }
    if (this.stateArray.length !== 0) {
      this.formGroupStateValidator = false;
    }

    if (
      this.form1.value.name != '' &&
      this.stateArray.length !== 0 &&
      (this.form1.value.attachment_name != '' ||
        this.form1.value.attachment_name == '') &&
      this.form1.value.active_untill != '' &&
      this.form2.value.selectedNotifications != '' &&
      this.academicArray.length !== 0 &&
      (this.attachmentType != '' || this.attachmentType == '')
    ) {
      this.postCall();
      this.dialog.open(AdminAddNotificationsComponent, {
        disableClose: true,
        closeOnNavigation: true,
      });
    }
  }

  onPublishSubmit() {
    if (this.form1.value.active_untill === '') {
      this.formGroupActiveuntil = true;
    }
    if (this.form2.value.selectedNotifications === '') {
      this.formGroupNotificationValidator = true;
    }
    if (this.academicArray.length === 0) {
      this.formGroupAcademicValidator = true;
    }
    if (this.academicArray.length !== 0) {
      this.formGroupAcademicValidator = false;
    }
    if (this.stateArray.length === 0) {
      this.formGroupStateValidator = true;
    }
    if (this.stateArray.length !== 0) {
      this.formGroupStateValidator = false;
    }

    if (
      this.form1.value.name != '' &&
      this.stateArray.length !== 0 &&
      (this.form1.value.attachment_name != '' ||
        this.form1.value.attachment_name == '') &&
      this.form1.value.active_untill != '' &&
      this.form2.value.selectedNotifications != '' &&
      this.academicArray.length !== 0 &&
      (this.attachmentType != '' || this.attachmentType == '')
    ) {
      this.publishCall();
    }
  }

  publishCall() {
    let academicFinalValue = '';
    for (let i = 0; i < this.academicArray.length; i++) {
      if (i == 0) {
        academicFinalValue = academicFinalValue + this.academicArray[i];
      } else {
        academicFinalValue = academicFinalValue + ',' + this.academicArray[i];
      }

      console.log(academicFinalValue);
    }

    let selectedStates = '';
    for (let i = 0; i < this.stateArray.length; i++) {
      if (i == 0) {
        selectedStates = selectedStates + this.stateArray[i];
      } else {
        selectedStates = selectedStates + ',' + this.stateArray[i];
      }
      console.log(selectedStates);
    }

    let month = this.form1.controls.active_untill.value;
    console.log(month.getMonth());
    let day = this.form1.controls.active_untill.value;
    console.log(day.getDay());
    let year = this.form1.controls.active_untill.value;
    console.log(year.getFullYear());
    console.log(month.getMonth());
    console.log(day.getDate());

    console.log(
      `${year.getFullYear()}-${month.getMonth() + 1}-${day.getDate()}`
    );

    const formmData = new FormData();
    formmData.append('name', this.form1.value.name);
    formmData.append('states', selectedStates);
    formmData.append('tags', this.form2.value.selectedNotifications);
    formmData.append('academic_levels', academicFinalValue);
    formmData.append(
      'active_untill',
      `${year.getFullYear()}-${month.getMonth() + 1}-${day.getDate()}`
    );
    formmData.append('attachment_name', this.form1.value.attachment_name);
    formmData.append('attachment_file', this.attachmentType);
    formmData.append('entrence', this.entrence_id);
    formmData.append('scholarship', this.scholarship_id);

    console.log(formmData);

    this.apicaller.postAdminNotifications(formmData).subscribe(
      (res) => {
        console.log(res);

        // payload or body
        this.notificationId = {
          id: res['id'],
        };

        console.log(this.notificationId);

        // publishing notification

        this.apicaller.publishNotifications(this.notificationId).subscribe(
          (publishCallResponse) => {
            console.log(publishCallResponse);
          },
          (err) => {
            console.log(err);
          }
        );

        this.dialogRef.close(true);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onSaveSubmit() {
    if (this.form1.value.active_untill === '') {
      this.formGroupActiveuntil = true;
    }
    if (this.form2.value.selectedNotifications === '') {
      this.formGroupNotificationValidator = true;
    }
    if (this.academicArray.length === 0) {
      this.formGroupAcademicValidator = true;
    }
    if (this.academicArray.length !== 0) {
      this.formGroupAcademicValidator = false;
    }

    if (this.stateArray.length === 0) {
      this.formGroupStateValidator = true;
    }
    if (this.stateArray.length !== 0) {
      this.formGroupStateValidator = false;
    }

    if (
      this.form1.value.name != '' &&
      this.stateArray.length !== 0 &&
      (this.form1.value.attachment_name != '' ||
        this.form1.value.attachment_name == '') &&
      this.form1.value.active_untill != '' &&
      this.form2.value.selectedNotifications != '' &&
      this.academicArray.length !== 0 &&
      (this.attachmentType != '' || this.attachmentType == '')
    ) {
      this.postCall();
    }
    console.log(this.academicArray);
    console.log('state array', this.stateArray);
    console.log('Attachment Link', this.attachmentType);
  }

  postCall() {
    let academicFinalValue = '';
    for (let i = 0; i < this.academicArray.length; i++) {
      if (i == 0) {
        academicFinalValue = academicFinalValue + this.academicArray[i];
      } else {
        academicFinalValue = academicFinalValue + ',' + this.academicArray[i];
      }

      console.log(academicFinalValue);
    }

    let selectedStates = '';
    for (let i = 0; i < this.stateArray.length; i++) {
      if (i == 0) {
        selectedStates = selectedStates + this.stateArray[i];
      } else {
        selectedStates = selectedStates + ',' + this.stateArray[i];
      }
      console.log(selectedStates);
    }

    let month = this.form1.controls.active_untill.value;
    console.log(month.getMonth());
    let day = this.form1.controls.active_untill.value;
    console.log(day.getDay());
    let year = this.form1.controls.active_untill.value;
    console.log(year.getFullYear());
    console.log(month.getMonth());
    console.log(day.getDate());

    console.log(
      `${year.getFullYear()}-${month.getMonth() + 1}-${day.getDate()}`
    );

    // this.data = {
    //   name: this.form1.value.name,
    //   states: this.form2.value.selectedStates,
    //   tags : this.form2.value.selectedNotifications,
    //   academic_levels : academicFinalValue,
    //   active_untill : `${year.getFullYear()}-${month.getMonth()+1}-${day.getDate()}`,
    //   attachment_name : this.form1.value.attachment_name,
    //   attachment_file : this.form1.value.attachment_file,
    // }
    // console.log(this.data)

    const formmData = new FormData();
    formmData.append('name', this.form1.value.name);
    formmData.append('states', selectedStates);
    formmData.append('tags', this.form2.value.selectedNotifications);
    formmData.append('academic_levels', academicFinalValue);
    formmData.append(
      'active_untill',
      `${year.getFullYear()}-${month.getMonth() + 1}-${day.getDate()}`
    );
    formmData.append('attachment_name', this.form1.value.attachment_name);
    formmData.append('attachment_file', this.attachmentType);
    formmData.append('entrence', this.entrence_id);
    formmData.append('scholarship', this.scholarship_id);

    console.log(formmData);

    this.apicaller.postAdminNotifications(formmData).subscribe(
      (res) => {
        console.log(res);
        // alert("Signup succesful")

        this.dialogRef.close(true);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllStates() {
    this.apicaller.getAdminStates().subscribe(
      (res) => {
        this.stateList = res;
        console.log(this.stateList);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  searchText = '';
  scholarshipSearchText = '';
  entrence_id = '';
  scholarship_id = '';
  streams = [
    {
      name: 'kow',
      id: 1,
    },
  ];

  options: any = [];
  published: number = null;
  scholarshipOptions: any = [];

  getAllEntranceExamList() {
    console.log('hello');
    this.apicaller.getEntranceExams().subscribe(
      (res) => {
        console.log(res);
        this.options = res;
        // this.published = res["notification"].published
        // console.log(this.published)
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllJobs() {
    this.apicaller.getScholarships('all', '').subscribe(
      (res) => {
        console.log(res);
        this.scholarshipOptions = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  val(obj) {
    console.log(obj);
    this.entrence_id = obj;
  }

  scholarshipval(obj) {
    this.scholarship_id = obj;
  }

  ngOnInit(): void {
    this.getAllStates();
    this.getAllEntranceExamList();
    this.getAllJobs();
  }
}
