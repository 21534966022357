import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiCallsService } from '../api-calls.service';
import { GlobalService } from '../global.service';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import { Capacitor } from '@capacitor/core';
import {
  ScreenOrientation,
  OrientationType,
} from '@capawesome/capacitor-screen-orientation';

@Component({
  selector: 'app-master-dashboard',
  templateUrl: './master-dashboard.component.html',
  styleUrls: ['./master-dashboard.component.css'],
})
export class MasterDashboardComponent implements OnInit, OnDestroy {
  constructor(
    private apiCaller: ApiCallsService,
    private router: Router,
    private globalService: GlobalService
  ) {}

  responseVar;

  tokenSub: Subscription;

  isTokenVerified: boolean = false;

  isCapacitor: boolean = false;

  async checkPlatform() {
    if (Capacitor.isNativePlatform()) {
      console.log('in if');
      this.isCapacitor = true;
      await ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
      const result = await AppUpdate.getAppUpdateInfo();
      console.log(`Update Availability status : ${result.updateAvailability}`);
      if (
        result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE
      ) {
        return;
      }
      if (result.immediateUpdateAllowed) {
        await AppUpdate.performImmediateUpdate();
      }
    } else {
      this.isCapacitor = false;
    }
  }

  ngOnInit(): void {
    this.checkPlatform();
    // this.verifyLogin();
    this.getUserInfo();
    this.getTokenInfo();
  }

  verifyLogin() {
    this.apiCaller.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }

  getUserInfo() {
    this.apiCaller.getUserInfo().subscribe(
      (res) => {
        console.log(res);
        this.responseVar = res['data']['name'];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getTokenInfo() {
    this.apiCaller.verifyToken();
    this.tokenSub = this.globalService.isTokenVerified.subscribe(
      (result: boolean) => {
        this.isTokenVerified = result;
        console.log(result);
      }
    );
  }

  logout() {
    this.apiCaller.logout().subscribe(
      (res) => {
        console.log(res);
        localStorage.clear();
        this.router.navigate(['/auth']);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }

  takeTest() {
    window.open('http://bestcareer.vidyahelpline.org/#/login');
  }

  trimToThreeAlphabet(responseVar) {
    if (responseVar != null && responseVar != undefined) {
      if (responseVar.length > 8) {
        return responseVar.substring(0, 14) + '...';
      } else {
        return responseVar;
      }
    } else {
      return '';
    }
  }

  ngOnDestroy() {
    this.tokenSub.unsubscribe();
  }
}
