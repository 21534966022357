import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '../api-calls.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-copylist',
  templateUrl: './copylist.component.html',
  styleUrls: ['./copylist.component.css'],
})
export class CopylistComponent implements OnInit, OnDestroy {
  @Input() deviceXs: boolean;

  //Vars
  options: any = [];
  filterOptions: any = [];
  streams: any = [
    'Agriculture & Related Fields',
    'Armed Forces & Central Police',
    'Arts, Design, Creative',
    'Banking & Finance',
    'Building & Construction',
    'Clothing & Accessories',
    'Education & Training',
    'Hardware, Electronics & Telecommunications',
    'Engineering & Manufacturing',
    'Fitness & Leisure',
    'Healthcare & Life Sciences',
    'IT & ITES',
    'Social Sciences & Human Services',
    'Law & Order',
    'Management & Marketing',
    'Media & Entertainment',
    'Public Administration & Government',
    'Retail, Tourism and Hospitality',
    'Self Employment',
    'Transportation, Logistics, Warehousing',
  ];
  searchText;

  streamFilter: any = '';
  eligibilityFilter: any = '';
  categoryFilter: any = '';

  groupValues = {
    0: 'Agriculture & Related Fields',
    1: 'Armed Forces & Central Police',
    2: 'Arts, Design, Creative',
    3: 'Banking & Finance',
    4: 'Building & Construction',
    5: 'Clothing & Accessories',
    6: 'Education & Training',
    7: 'Hardware, Electronics & Telecommunications',
    8: 'Engineering & Manufacturing',
    9: 'Fitness & Leisure',
    10: 'Healthcare & Life Sciences',
    11: 'IT & ITES',
    12: 'Social Sciences & Human Services',
    13: 'Law & Order',
    14: 'Management & Marketing',
    15: 'Media & Entertainment',
    16: 'Public Administration & Government',
    17: 'Retail, Tourism and Hospitality',
    18: 'Self Employment',
    19: 'Transportation, Logistics, Warehousing',
  };

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService,
    public route: ActivatedRoute
  ) {}

  //Form Control
  myControl = new FormControl();
  mediaSub: Subscription;
  streamFilterSub: Subscription;
  elgibilityFilterSub: Subscription;
  categoryFilterSub: Subscription;
  queryParam: Subscription;

  getAllJobs() {
    console.log('hello');
    this.apiCallService.getCourses().subscribe(
      (res) => {
        console.log(res);
        this.options = res;
        this.filterCourse();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  coToCareerDetails(jobId) {
    // let jobId = this.options[j]['id']
    // alert(jobId)
    window.scroll(0, 0);
    this.router.navigate(['/dashboard/courselistDetail/'], {
      queryParams: { id: jobId },
    });
  }

  ngOnInit(): void {
    // this.apiCallService.verifyLogin();
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.getAllJobs();

    this.queryParam = this.route.queryParams.subscribe((params: Params) => {
      const stream = params.stream ? parseInt(params.stream) : parseInt('');
      this.streamFilter = isNaN(stream)
        ? params.stream === 'all'
          ? 'all'
          : ''
        : stream;

      const elgibility = params.elgibility
        ? parseInt(params.elgibility)
        : parseInt('');
      this.eligibilityFilter = isNaN(elgibility)
        ? params.elgibility === 'all'
          ? 'all'
          : ''
        : elgibility;

      const category = params.category
        ? parseInt(params.category)
        : parseInt('');
      this.categoryFilter = isNaN(category)
        ? params.category === 'all'
          ? 'all'
          : ''
        : category;
    });
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  filterCourse() {
    let filterdCourses: any = [...this.options];

    this.globalSservice.setStreamCourseFilter(this.streamFilter);
    this.globalSservice.setElgibiltyCourseFilter(this.eligibilityFilter);
    this.globalSservice.setCategoryCourseFilter(this.categoryFilter);
    if (
      this.streamFilter !== '' ||
      this.eligibilityFilter !== '' ||
      this.categoryFilter !== ''
    ) {
      this.router.navigate(['/dashboard/courseList'], {
        queryParams: {
          stream: this.streamFilter,
          elgibility: this.eligibilityFilter,
          category: this.categoryFilter,
        },
      });
    }

    if (this.streamFilter !== '' && this.streamFilter !== 'all') {
      filterdCourses = filterdCourses.filter(
        (option) => option.stream === this.streamFilter
      );
    }
    if (this.eligibilityFilter !== '' && this.eligibilityFilter !== 'all') {
      filterdCourses = filterdCourses.filter(
        (option) => option.eligibility_filter === this.eligibilityFilter
      );
    }
    if (this.categoryFilter !== '' && this.categoryFilter !== 'all') {
      filterdCourses = filterdCourses.filter(
        (option) => option.category === this.categoryFilter
      );
    }
    this.filterOptions = filterdCourses;
  }
}
