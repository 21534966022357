import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css'],
})
export class CourseDetailComponent implements OnInit {
  @Input() deviceXs: boolean;

  //Vars
  streamFilter = '';
  elgibilityFilter = '';
  categoryFilter = '';

  //Sub Vars
  queryParamId = null;
  CourseDetailResponse: any;
  alternateCourses: any;
  coursepathurl = null;
  mediaSub: Subscription;
  streamFilterSub: Subscription;
  elgibilityFilterSub: Subscription;
  categoryFilterSub: Subscription;
  queryParam: Subscription;

  constructor(
    private activateRoute: ActivatedRoute,
    private api: ApiCallsService,
    public dialog: MatDialog,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService
  ) {}
  streamValues = {
    0: 'Agriculture & Related Fields',
    1: 'Armed Forces & Central Police',
    2: 'Arts, Design, Creative',
    3: 'Banking & Finance',
    4: 'Building & Construction',
    5: 'Clothing & Accessories',
    6: 'Education & Training',
    7: 'Hardware, Electronics & Telecommunications',
    8: 'Engineering & Manufacturing',
    9: 'Fitness & Leisure',
    10: 'Healthcare & Life Sciences',
    11: 'IT & ITES',
    12: 'Social Sciences & Human Services',
    13: 'Law & Order',
    14: 'Management & Marketing',
    15: 'Media & Entertainment',
    16: 'Public Administration & Government',
    17: 'Retail, Tourism and Hospitality',
    18: 'Self Employment',
    19: 'Transportation, Logistics, Warehousing',
  };

  categoryValues = {
    0: 'Vocational',
    1: 'Professional',
  };

  groupValues = {
    0: 'MPC',
    1: 'BiPC',
    2: 'CEC',
    3: 'HEC',
    4: 'Vocational',
    5: 'MEC',
    6: 'MBiPC',
    7: 'MPC or BiPC',
    8: 'MEC or CEC',
    9: 'Any Group',
    10: 'Not Applicable',
  };

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.activateRoute.queryParams.subscribe((params) => {
      console.log(params['id']);
      this.queryParamId = params['id'];
    });
    // this.api.verifyLogin();
    this.streamFilterSub = this.globalSservice
      .getStreamCourseFilter()
      .subscribe((value) => (this.streamFilter = value));
    this.elgibilityFilterSub = this.globalSservice
      .getElgibiltyCourseFilter()
      .subscribe((value) => (this.elgibilityFilter = value));
    this.categoryFilterSub = this.globalSservice
      .getCategoryCourseFilter()
      .subscribe((value) => (this.categoryFilter = value));
    this.getCourseDetailsApi();
  }

  getCourseDetailsApi() {
    this.api.getCourseDetail(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        this.CourseDetailResponse = res;
        let altC = res['careers'];
        if (altC != null) {
          this.alternateCourses = altC.split(',');
        } else {
          this.alternateCourses = [];
        }
        // this.alternateCourses.filter(Boolean)
        console.log(this.CourseDetailResponse);
        console.log(this.alternateCourses);

        if (this.CourseDetailResponse['course_path']) {
          // this.roadmapdisabled = false
          this.coursepathurl = this.CourseDetailResponse['course_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy() {
    this.streamFilterSub.unsubscribe();
    this.elgibilityFilterSub.unsubscribe();
    this.categoryFilterSub.unsubscribe();
  }
}
