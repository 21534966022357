import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  filterListObj,
  filterOptionObj,
  filterParamsObj,
} from '../models.model';
import { ApiCallsService } from '../api-calls.service';

@Component({
  selector: 'app-admin-notification-mappings',
  templateUrl: './admin-notification-mappings.component.html',
  styleUrls: ['./admin-notification-mappings.component.css'],
})
export class AdminNotificationMappingsComponent implements OnInit {
  notificationsMappingArr: any[] = [];
  statesList: any[] = [];
  displayedColumns: string[] = [
    'id',
    'notification_id',
    'db_notification_id',
    'notification_id_2',
    'db_notification_id_2',
    'tag',
    'academic_level',
    'state',
  ];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  hideOptions: boolean = false;

  searchFormControl: FormControl = new FormControl();
  tagFormControl: FormControl = new FormControl();
  academicFormControl: FormControl = new FormControl();
  stateFormControl: FormControl = new FormControl();

  tagOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Entrance Examination' },
    { filterOptionKey: 1, filterOptionValue: 'Scholarship' },
    { filterOptionKey: 2, filterOptionValue: 'Admission' },
    { filterOptionKey: 3, filterOptionValue: 'Training/Skill Development' },
    { filterOptionKey: 4, filterOptionValue: 'Government Jobs' },
    { filterOptionKey: 5, filterOptionValue: 'Others' },
  ];

  academicOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Below 10th' },
    { filterOptionKey: 1, filterOptionValue: 'Studying 10th' },
    { filterOptionKey: 2, filterOptionValue: 'Completed 10th' },
    { filterOptionKey: 3, filterOptionValue: 'Studying Inter/12th/PUC' },
    { filterOptionKey: 4, filterOptionValue: 'Completed Inter/12th/PUC' },
    { filterOptionKey: 5, filterOptionValue: 'Studying ITI' },
    { filterOptionKey: 6, filterOptionValue: 'Completed ITI' },
    { filterOptionKey: 7, filterOptionValue: 'Studying Diploma' },
    { filterOptionKey: 8, filterOptionValue: 'Completed Diploma' },
    { filterOptionKey: 9, filterOptionValue: 'Studying Graduation' },
    { filterOptionKey: 10, filterOptionValue: 'Completed Graduation' },
    { filterOptionKey: 11, filterOptionValue: 'Studying Technical Graduation' },
    {
      filterOptionKey: 12,
      filterOptionValue: 'Completed Technical Graduation',
    },
    { filterOptionKey: 13, filterOptionValue: 'Studying PG' },
    { filterOptionKey: 14, filterOptionValue: 'Completed PG' },
    { filterOptionKey: 15, filterOptionValue: 'Others' },
  ];

  filtersList: filterListObj[] = [
    {
      filterName: 'Tag Filter',
      filterOptions: this.tagOptions,
      filterFormControl: this.tagFormControl,
    },
    {
      filterName: 'Academic Filter',
      filterOptions: this.academicOptions,
      filterFormControl: this.academicFormControl,
    },
  ];

  tagParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  academicParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  stateParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  searchParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.setDefaultControlValues();
  }

  getAllStates() {
    this.apiCaller.getAdminStates().subscribe(
      (res: any) => {
        if (res.length > 0) {
          console.log(res);
          const allStates = {
            id: -1,
            name: 'All',
            state_id: -1,
            published: 0,
          };
          this.statesList.push(allStates);
          res.forEach((stateObj, stateObjIndex) => {
            this.statesList.push(stateObj);
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  setDefaultControlValues() {
    this.getAllStates();
    this.stateFormControl.patchValue(-1);
    this.tagFormControl.patchValue(this.tagOptions[0]);
    this.academicFormControl.patchValue(this.academicOptions[0]);
    this.getNotificationMappingsData(1, false);
  }

  hideOptionsFunc() {
    this.hideOptions = !this.hideOptions;
  }

  callNotificationsMappingApi(page: number) {
    this.apiCaller
      .getNotificationMappingData(page, [
        this.tagParam,
        this.academicParam,
        this.stateParam,
        this.searchParam,
      ])
      .subscribe(
        (res: any) => {
          console.log(res);
          this.notificationsMappingArr = res.results;
          this.collectionSize = res.count;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getNotificationMappingsData(page: number, isSearch: boolean) {
    this.makeParams();
    if (isSearch) {
      let searchValue: string = this.searchParam.filterParamValue as string;
      if (searchValue.length >= 3) {
        this.callNotificationsMappingApi(page);
      }
    } else {
      this.callNotificationsMappingApi(page);
    }
  }

  makeParams() {
    let searchValue: string = this.searchFormControl.value;
    if (searchValue) {
      if (searchValue.length >= 3) {
        this.searchParam.filterParamActive = true;
        this.searchParam.filterParamValue = searchValue;
        this.searchParam.filterParamName = 'search';
        this.currentPage = 1;
      } else {
        this.searchParam.filterParamActive = false;
      }
    } else {
      this.searchParam.filterParamActive = false;
    }

    let tagLevel: filterOptionObj = this.tagFormControl.value;
    if (tagLevel.filterOptionKey !== -1) {
      this.tagParam.filterParamActive = true;
      this.tagParam.filterParamValue = tagLevel.filterOptionKey;
      this.tagParam.filterParamName = 'tag';
      this.currentPage = 1;
    } else {
      this.tagParam.filterParamActive = false;
    }

    let academicLevel: filterOptionObj = this.academicFormControl.value;
    if (academicLevel.filterOptionKey !== -1) {
      this.academicParam.filterParamActive = true;
      this.academicParam.filterParamValue = academicLevel.filterOptionKey;
      this.academicParam.filterParamName = 'academic_level';
      this.currentPage = 1;
    } else {
      this.academicParam.filterParamActive = false;
    }

    let stateLevel: number = this.stateFormControl.value;
    if (stateLevel > -1) {
      this.stateParam.filterParamActive = true;
      this.stateParam.filterParamValue = stateLevel;
      this.stateParam.filterParamName = 'state';
      this.currentPage = 1;
    } else {
      this.stateParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }
}
