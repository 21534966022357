<!-- <p>profile works!</p> -->

<div class="col px-4 py-4">
  <div class="col-md-3 mb-3 px-0 d-flex align-items-center">
    <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
    <span class="backTitle"> Profile</span>
  </div>

  <div
    class="col-md-12 col-xs-12"
    style="background-color: white; text-align: center"
  >
    <div class="row py-3" style="text-align: center; justify-content: center">
      <div class="imageAlign" (click)="personalInfoClick()">
        <img
          src="/assets/Auth/skills.svg"
          style="word-wrap: break-word; cursor: pointer; margin-inline: auto"
        />
        <span [ngClass]="optionActive == 1 ? 'bold' : 'normal'">
          Personal Information
        </span>
      </div>
      <span
        *ngIf="!deviceXs"
        style="color: rgba(0, 0, 0, 0.12); padding-top: 1rem; font-weight: bold"
      >
        <!-- --- --- --- --- --- --- --- --- -->
        --- --- --- --- --- --- ---
      </span>
      <span
        *ngIf="deviceXs"
        style="color: rgba(0, 0, 0, 0.12); padding-top: 1rem; font-weight: bold"
      >
        <!-- -- -- -- -- -- -->
        --- --- ---
      </span>
      <div class="imageAlign" (click)="academicInfoClick()">
        <img
          src="/assets/Auth/student-hat.svg"
          style="word-wrap: break-word; cursor: pointer; margin-inline: auto"
        />
        <span [ngClass]="optionActive == 2 ? 'bold' : 'normal'">
          Academic Information
        </span>
      </div>
      <span
        *ngIf="!deviceXs"
        style="color: rgba(0, 0, 0, 0.12); padding-top: 1rem; font-weight: bold"
      >
        <!-- --- --- --- --- --- --- --- --- -->
        --- --- --- --- --- --- ---
      </span>
      <span
        *ngIf="deviceXs"
        style="color: rgba(0, 0, 0, 0.12); padding-top: 1rem; font-weight: bold"
      >
        <!-- -- -- -- -- -- -->
        --- --- ---
      </span>
      <div class="imageAlign" (click)="residentInfoClick()">
        <img
          src="/assets/Auth/location.svg"
          style="word-wrap: break-word; cursor: pointer; margin-inline: auto"
        />
        <span [ngClass]="optionActive == 3 ? 'bold' : 'normal'">
          Residence Information
        </span>
      </div>
    </div>
  </div>

  <div class="row-md-12 row-xs-12 pt-3" *ngIf="optionActive == 1">
    <div class="col-md-12 col-xs-12 pt-4 pl-5" style="background-color: white">
      <!-- <div style="margin-bottom: 2%">  -->
      <!-- <div class="mat-form-field-wrapper ng-tns-c138-0">

      </div> -->
      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Name</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{ userInfoResponse?.data?.name }}"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Phone*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{ userInfoResponse?.data?.mobile }}"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Email Id*</mat-label>
        <input matInput [readonly]="true" [value]="emailValidity()" />
      </mat-form-field>
      <!-- </div> -->

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Username*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{ userInfoResponse?.data?.username }}"
        />
      </mat-form-field>

      <mat-form-field
        *ngIf="!simplifySignUp"
        appearance="outline"
        class="profileInput"
      >
        <mat-label class="selectFontText">Date of Birth*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{ userInfoResponse?.data?.date_of_birth }}"
        />
      </mat-form-field>

      <mat-form-field
        *ngIf="!simplifySignUp"
        appearance="outline"
        class="profileInput"
      >
        <mat-label class="selectFontText">Disability*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{
            groupDisabilityValues[userInfoResponse?.data?.disability]
          }}"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Gender*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{ groupGernderValues[userInfoResponse?.data?.gender] }}"
        />
      </mat-form-field>
      <div class="d-flex justify-content-end pb-4">
        <button mat-raised-button class="editButton" [disabled]="studyEnabler">
          Edit
        </button>
      </div>
    </div>
  </div>

  <div class="row-md-12 row-xs-12 pt-3" *ngIf="optionActive == 2">
    <div
      class="col-md-12 col-xs-12 pt-4 pl-5"
      style="background-color: white; justify-content: space-between"
    >
      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText"
          >Highest/Current Qualification*</mat-label
        >
        <input
          matInput
          [readonly]="true"
          value="{{
            groupQualificationValues[userInfoResponse?.data?.qualification]
          }}"
        />
      </mat-form-field>

      <mat-form-field
        *ngIf="!simplifySignUp"
        appearance="outline"
        class="profileInput"
      >
        <mat-label class="selectFontText">Current Status**</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{
            groupStatusValues[userInfoResponse?.data?.current_status]
          }}"
        />
        <!-- <mat-hint
                      *ngIf="
                        (form1.controls.name.touched || formGroupValidator) &&
                        form1.controls.name.invalid
                      "
                      class="red"
                      >Please enter name</mat-hint
                    > -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Institute Category</mat-label>
        <input matInput [readonly]="true" [value]="instituteMethod()" />
        <!-- <mat-hint
                      *ngIf="
                        (form1.controls.email.touched || formGroupValidator) &&
                        form1.controls.email.invalid
                      "
                      class="red"
                      >Enter valid Email</mat-hint
                    > -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Institute Name</mat-label>
        <input matInput [readonly]="true" [value]="instituteName()" />
        <!-- <mat-hint
                      *ngIf="
                        (form1.controls.name.touched || formGroupValidator) &&
                        form1.controls.name.invalid
                      "
                      class="red"
                      >Please enter name</mat-hint
                    > -->
      </mat-form-field>

      <mat-form-field
        *ngIf="!simplifySignUp"
        appearance="outline"
        class="profileInput"
      >
        <mat-label class="selectFontText">Course Pursued</mat-label>
        <input matInput [readonly]="true" [value]="courseMethod()" />
        <!-- <mat-hint
                      *ngIf="
                        (form1.controls.name.touched || formGroupValidator) &&
                        form1.controls.name.invalid
                      "
                      class="red"
                      >Please enter name</mat-hint
                    > -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Profession</mat-label>
        <input matInput [readonly]="true" [value]="professionMethod()" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Organization Name</mat-label>
        <input matInput [readonly]="true" [value]="orgMethod()" />
      </mat-form-field>

      <div class="d-flex justify-content-end pb-4">
        <button mat-raised-button class="editButton" [disabled]="studyEnabler">
          Edit
        </button>
      </div>
    </div>
  </div>

  <div class="row-md-12 pt-3" *ngIf="optionActive == 3">
    <div
      class="col-md-12 col-xs-12 pt-4 pl-5"
      style="background-color: white; justify-content: space-between"
    >
      <mat-form-field
        *ngIf="!simplifySignUp"
        appearance="outline"
        class="profileInput"
      >
        <mat-label class="selectFontText">Residence Category*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{
            groupResidentialValues[userInfoResponse?.data?.residential_category]
          }}"
        />
        <!-- <mat-hint
                    *ngIf="
                      (form1.controls.name.touched || formGroupValidator) &&
                      form1.controls.name.invalid
                    "
                    class="red"
                    >Please enter name</mat-hint
                  > -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">Village/Town/City Name*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{ userInfoResponse?.data?.city_name }}"
        />
      </mat-form-field>

      <mat-form-field
        *ngIf="!simplifySignUp"
        appearance="outline"
        class="profileInput"
      >
        <mat-label class="selectFontText">Taluk/Mandal Name*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{ userInfoResponse?.data?.mandal_name }}"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">State*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{ [userInfoResponse?.data?.state?.name] }}"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="profileInput">
        <mat-label class="selectFontText">District*</mat-label>
        <input
          matInput
          [readonly]="true"
          value="{{
            userInfoResponse?.data?.district !== null
              ? userInfoResponse?.data?.district
              : userInfoResponse?.data?.district_id?.name
          }}"
        />
      </mat-form-field>

      <div class="d-flex justify-content-end pb-4">
        <button mat-raised-button class="editButton" [disabled]="studyEnabler">
          Edit
        </button>
      </div>
    </div>
  </div>
</div>
