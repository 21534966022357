import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-based-mentoring',
  templateUrl: './project-based-mentoring.component.html',
  styleUrls: ['./project-based-mentoring.component.css'],
})
export class ProjectBasedMentoringComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
