import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiCallsService } from './../api-calls.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.css'],
})
export class GetStartedComponent implements OnInit, OnDestroy {
  @Input() deviceXs: boolean;
  panelOpenState = false;
  constructor(
    private router: Router,
    private apiCaller: ApiCallsService,
    public mediaObserver: MediaObserver
  ) {}

  //Vars
  isCurrent = false;
  isCurrent2 = false;
  isCurrent3 = false;
  isCurrent4 = false;
  isGetStarted = true;
  routerSubscription: Subscription;
  mediaSub: Subscription;
  optionActive = 1;

  ngOnInit(): void {
    this.verifyLogin();
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.isGetStarted = this.router?.url === '/dashboard/getstarted';
    this.routerSubscription = this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.isGetStarted = route?.url === '/dashboard/getstarted';
      }
    });
  }

  verifyLogin() {
    this.apiCaller.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }

  goToChooseCareer() {
    this.router.navigate(['/dashboard/getstarted/chooseCareer']);
    this.isCurrent = true;
    this.isCurrent2 = false;
    this.isCurrent3 = false;
    this.isCurrent4 = false;
    this.optionActive = 1;
  }

  goToFavoriteSubject() {
    this.router.navigate(['dashboard/getstarted/favoriteSubject']);
    this.isCurrent = false;
    this.isCurrent2 = true;
    this.isCurrent3 = false;
    this.isCurrent4 = false;
    this.optionActive = 2;
  }

  goToStartEarningNow() {
    this.router.navigate(['dashboard/getstarted/startEarningNow']);
    this.isCurrent3 = true;
    this.isCurrent2 = false;
    this.isCurrent = false;
    this.isCurrent4 = false;
    this.optionActive = 4;
  }

  goToStepsToFollow() {
    this.router.navigate(['dashboard/careerList']);
    this.isCurrent4 = true;
    this.isCurrent3 = false;
    this.isCurrent2 = false;
    this.isCurrent = false;
    this.optionActive = 3;
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
