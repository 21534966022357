import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../api-calls.service';
import { FormControl } from '@angular/forms';
import {
  filterListObj,
  filterOptionObj,
  filterParamsObj,
} from '../models.model';

@Component({
  selector: 'app-admin-entrances',
  templateUrl: './admin-entrances.component.html',
  styleUrls: ['./admin-entrances.component.css'],
})
export class AdminEntrancesComponent implements OnInit {
  entrancesArr: any[] = [];
  statesList: any[] = [];
  displayedColumns: string[] = [
    'id',
    'name',
    'entry_to_courses',
    'approx_notification',
    'notification',
    'state',
  ];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  hideOptions: boolean = false;

  searchFormControl: FormControl = new FormControl();
  stateFormControl: FormControl = new FormControl();
  coursesFormControl: FormControl = new FormControl();
  agriFormControl: FormControl = new FormControl();
  pharmaFormControl: FormControl = new FormControl();
  applicationStatusFormControl: FormControl = new FormControl();

  courseOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: '10th Grade and Lower Grades' },
    {
      filterOptionKey: 1,
      filterOptionValue: '10+2/Intermediate/PUC',
    },
    { filterOptionKey: 2, filterOptionValue: 'Polytechnic Diploma' },
    {
      filterOptionKey: 3,
      filterOptionValue:
        'BE/BTech, BTech (Integrated), B.Tech Lateral Entry, B.Arch',
    },
    { filterOptionKey: 4, filterOptionValue: 'B.Ed, Diploma in Education' },
    { filterOptionKey: 5, filterOptionValue: 'BA, BSc, BCom' },
    { filterOptionKey: 6, filterOptionValue: 'BPEd, Diploma in Phys Ed' },
    { filterOptionKey: 7, filterOptionValue: 'Law' },
    { filterOptionKey: 8, filterOptionValue: 'MA, MSc, MCom' },
    { filterOptionKey: 9, filterOptionValue: 'MTech, M.Pharm, M. Arch' },
    { filterOptionKey: 10, filterOptionValue: 'MBA, MCA' },
    { filterOptionKey: 11, filterOptionValue: 'Other Graduate' },
    { filterOptionKey: 12, filterOptionValue: 'Other Post Graduate' },
    { filterOptionKey: 13, filterOptionValue: 'Medical and Related' },
    { filterOptionKey: 14, filterOptionValue: 'Ph.D' },
  ];

  yesOrNoFilterOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Yes' },
    { filterOptionKey: 1, filterOptionValue: 'No' },
  ];

  filtersList: filterListObj[] = [
    {
      filterName: 'Course Filter',
      filterOptions: this.courseOptions,
      filterFormControl: this.coursesFormControl,
    },
    {
      filterName: 'Agriculture',
      filterOptions: this.yesOrNoFilterOptions,
      filterFormControl: this.agriFormControl,
    },
    {
      filterName: 'Pharma Filter',
      filterOptions: this.yesOrNoFilterOptions,
      filterFormControl: this.pharmaFormControl,
    },
    {
      filterName: 'Application Status',
      filterOptions: this.yesOrNoFilterOptions,
      filterFormControl: this.applicationStatusFormControl,
    },
  ];

  coursesParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  agriParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  pharmaParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  applicationStatusParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  stateParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  searchParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.setDefaultControlValues();
  }

  getAllStates() {
    this.apiCaller.getAdminStates().subscribe(
      (res: any) => {
        if (res.length > 0) {
          console.log(res);
          const allStates = {
            id: -1,
            name: 'All',
            state_id: -1,
            published: 0,
          };
          this.statesList.push(allStates);
          res.forEach((stateObj, stateObjIndex) => {
            this.statesList.push(stateObj);
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  setDefaultControlValues() {
    this.getAllStates();
    this.stateFormControl.patchValue(-1);
    this.coursesFormControl.patchValue(this.courseOptions[0]);
    this.agriFormControl.patchValue(this.yesOrNoFilterOptions[0]);
    this.pharmaFormControl.patchValue(this.yesOrNoFilterOptions[0]);
    this.applicationStatusFormControl.patchValue(this.yesOrNoFilterOptions[0]);
    this.getEntrancesData(1, false);
  }

  hideOptionsFunc() {
    this.hideOptions = !this.hideOptions;
  }

  callEntrancesApi(page: number) {
    this.apiCaller
      .getEntrancesData(page, [
        this.coursesParam,
        this.agriParam,
        this.pharmaParam,
        this.applicationStatusParam,
        this.stateParam,
        this.searchParam,
      ])
      .subscribe(
        (res: any) => {
          console.log(res);
          this.entrancesArr = res.results;
          this.collectionSize = res.count;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getEntrancesData(page: number, isSearch: boolean) {
    this.makeParams();
    if (isSearch) {
      let searchValue: string = this.searchParam.filterParamValue as string;
      if (searchValue.length >= 3) {
        this.callEntrancesApi(page);
      }
    } else {
      this.callEntrancesApi(page);
    }
  }

  clearSearch() {
    this.searchFormControl.reset();
    this.getEntrancesData(this.currentPage, false);
  }

  makeParams() {
    let searchValue: string = this.searchFormControl.value;
    if (searchValue) {
      if (searchValue.length > 3) {
        this.searchParam.filterParamActive = true;
        this.searchParam.filterParamValue = searchValue;
        this.searchParam.filterParamName = 'search';
        this.currentPage = 1;
      } else {
        this.searchParam.filterParamActive = false;
      }
    } else {
      this.searchParam.filterParamActive = false;
    }

    let coursesLevel: filterOptionObj = this.coursesFormControl.value;
    if (coursesLevel.filterOptionKey !== -1) {
      this.coursesParam.filterParamActive = true;
      this.coursesParam.filterParamValue = coursesLevel.filterOptionKey;
      this.coursesParam.filterParamName = 'courses';
      this.currentPage = 1;
    } else {
      this.coursesParam.filterParamActive = false;
    }

    let stateLevel: number = this.stateFormControl.value;
    if (stateLevel > -1) {
      this.stateParam.filterParamActive = true;
      this.stateParam.filterParamValue = stateLevel;
      this.stateParam.filterParamName = 'state';
      this.currentPage = 1;
    } else {
      this.stateParam.filterParamActive = false;
    }

    let agriLevel: filterOptionObj = this.agriFormControl.value;
    if (agriLevel.filterOptionKey !== -1) {
      this.agriParam.filterParamActive = true;
      this.agriParam.filterParamValue = agriLevel.filterOptionKey;
      this.agriParam.filterParamName = 'agri';
      this.currentPage = 1;
    } else {
      this.agriParam.filterParamActive = false;
    }

    let pharmaLevel: filterOptionObj = this.pharmaFormControl.value;
    if (pharmaLevel.filterOptionKey !== -1) {
      this.pharmaParam.filterParamActive = true;
      this.pharmaParam.filterParamValue = pharmaLevel.filterOptionKey;
      this.pharmaParam.filterParamName = 'pharma';
      this.currentPage = 1;
    } else {
      this.pharmaParam.filterParamActive = false;
    }

    let applicationStatusLevel: filterOptionObj =
      this.applicationStatusFormControl.value;
    if (applicationStatusLevel.filterOptionKey !== -1) {
      this.applicationStatusParam.filterParamActive = true;
      this.applicationStatusParam.filterParamValue =
        applicationStatusLevel.filterOptionKey;
      this.applicationStatusParam.filterParamName = 'applicationStatus';
      this.currentPage = 1;
    } else {
      this.applicationStatusParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }
}
