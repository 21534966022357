import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// https://www.youtube.com/watch?v=gO8rL5iXk-Y
@Injectable({
  providedIn: 'root',
})
export class MatNotifierService {
  constructor(private snackBar: MatSnackBar) {}

  showNotification(Message, Message2) {
    this.snackBar.open(Message, Message2, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
