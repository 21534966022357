<div class="col-md-3 spacing pt-4 mb-3 d-flex align-items-center">
  <mat-icon class="backArrow" routerLink="/dashboard/notifications"
    >arrow_back</mat-icon
  >
  <span class="backTitle"> Notification </span> <span class="ml-2"> > </span>
  <span
    [ngClass]="{
      admissionColor: queryParamId === '2',
      entranceColor: queryParamId === '0',
      scholarshipColor: queryParamId === '1',
      trainingColor: queryParamId === '3',
      govtJobsColor: queryParamId === '4'
    }"
    class="coloring-tag ml-2"
    [innerHTML]="tagName()"
  >
  </span>
</div>

<div class="col px-5 pb-4">
  <div
    class="row py-3 px-2"
    style="background-color: white"
    *ngFor="let notificatiionitem of NotificationTagResponse"
  >
    <div class="col-md-10 col-xs-12 col-sm-12">
      <div class="row" style="padding-inline-start: 5px">
        <div class="img p-0" style="margin-bottom: auto">
          <img src="/assets/careerDetail/ellipse.png" />
        </div>
        <div
          class="text col-md-11 col-xs-8 col-sm-8"
          style="display: inline-block"
        >
          <div
            style="word-wrap: break-word; cursor: pointer"
            (click)="coToNotificationeDetails(notificatiionitem['id'])"
          >
            {{ notificatiionitem.name }}
          </div>
          <div>
            {{ notificatiionitem.last_date }}
            <span *ngIf="notificatiionitem.attachment_link?.length > 0">
              <a href="{{ notificatiionitem.attachment_link }}" target="_blank">
                <img
                  src="/assets/Dashboard/Icon metro-attachment.svg"
                  style="padding-inline-start: 3px"
                />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <span> {{ last_date }} </span> -->

    <div class="col-md-2 col-xs-12 col-sm-12">
      <div
        class="mobileTag"
        *ngFor="let item of notificatiionitem.notification_tags"
      >
        <div
          [innerHTML]="statustext(item)"
          [ngClass]="{
            admissionTag: item === 2,
            entranceTag: item === 0,
            scholarshipTag: item === 1,
            trainingTag: item === 3,
            govtJobsTag: item === 4
          }"
        ></div>
      </div>
    </div>

    <!-- <table>
                <tr>
                    <td>
                        <div class = " p-0" style=" margin-bottom: auto; ">
                            <img src="/assets/careerDetail/ellipse.png" />
                        </div>
                    </td>
                        
                    <td>
                        {{ notificatiionitem.name }} <div>
                            {{ notificatiionitem.last_date }} 
                         </div>
                    </td>
    
                    <td>
                        <div style="width: fit-content; display: inline-block;">
                            efgh
                        </div>
                        <div style="width: fit-content; display: inline-block;">
                            abcd
                        </div>
                        <div style="width: fit-content;display: inline-block">
                            ghyuio
                        </div>
                    </td>
                </tr>
            </table> -->
  </div>
</div>
