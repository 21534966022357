<div class="col-md-12 careerDetails">
  <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
    <mat-icon
      class="backArrow"
      routerLink="/dashboard/hostellist"
      [queryParams]="{
        academic: this.academicFilter1,
        gender: this.genderFilter,
        caste: this.casteFilter,
        state: this.stateFilter,
        district: this.districtFilter
      }"
      >arrow_back</mat-icon
    >
    <span class="backTitle"> {{ HostelDetailResponse?.name }}</span>
  </div>
  <div class="row">
    <div class="col-md-7 wrapperLeft">
      <div class="col-md-12 sc2Parent2">
        <div class="row">
          <div class="col-md-12">
            <span class="secondContainer2">Hostel details</span>
            <span class="pl-2">
              <img src="/assets/careerDetail/bed.png" />
            </span>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Academic Level</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  HostelDetailResponse?.academic_level != null
                    ? [HostelDetailResponse?.academic_level]
                    : "-"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Hostel Capacity</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  HostelDetailResponse?.number_of_students != null
                    ? [HostelDetailResponse?.number_of_students]
                    : "-"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Category(Government/Private/NGO)</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  HostelDetailResponse?.govt_private_ngo != null
                    ? govtValues[HostelDetailResponse?.govt_private_ngo]
                    : "-"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Gender Restriction</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  HostelDetailResponse?.gender_restrictions != null
                    ? genderValues[HostelDetailResponse?.gender_restrictions]
                    : "-"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Caste Restriction</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  HostelDetailResponse?.caste_restrictions != null
                    ? casteValues[HostelDetailResponse?.caste_restrictions]
                    : "-"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Source of Data</span>
              <p
                class="mt-3 overviewText pl-3 pb-2"
                style="word-wrap: break-word"
              >
                <a
                  (click)="webLink(HostelDetailResponse?.source_data)"
                  style="color: #007bff; cursor: pointer"
                  *ngIf="HostelDetailResponse?.source_data?.length > 0"
                >
                  {{
                    HostelDetailResponse?.source_data != null
                      ? [HostelDetailResponse?.source_data]
                      : "-"
                  }}
                </a>
                <span *ngIf="HostelDetailResponse?.source_data?.length == 0">
                  {{ "NA" }}
                </span>
                <span *ngIf="HostelDetailResponse?.source_data == null">
                  {{ "NA" }}
                </span>
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Date of data Gathered</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  HostelDetailResponse?.date_of_data != null
                    ? [HostelDetailResponse?.date_of_data]
                    : "-"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5 wrapperRight">
      <div class="row">
        <div class="col-md-12 salaryDetails pb-3">
          <div class="mt-3">
            <span class="roadMapText">Contact Information</span>
            <span class="pl-2">
              <img src="/assets/careerDetail/location (1).png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Address: </span>
            <span class="expectedSalary2 ml-1" style="word-wrap: break-word">
              {{
                HostelDetailResponse?.address != null
                  ? [HostelDetailResponse?.address]
                  : "-"
              }}
            </span>
          </div>

          <div class="mt-3">
            <span class="expectedSalary">District: </span>
            <span class="expectedSalary2 ml-1">
              {{
                HostelDetailResponse?.district_id?.name != null
                  ? [HostelDetailResponse?.district_id?.name]
                  : "NA"
              }}
            </span>
          </div>

          <div class="mt-3">
            <span class="expectedSalary">State: </span>
            <span class="expectedSalary2 ml-1">
              {{
                HostelDetailResponse?.state != null
                  ? [HostelDetailResponse?.state?.name]
                  : "-"
              }}
            </span>
          </div>

          <div class="mt-3">
            <span class="expectedSalary">Phone no: </span>
            <span class="expectedSalary2 ml-1">
              {{
                HostelDetailResponse?.contact != null
                  ? [HostelDetailResponse?.contact]
                  : "-"
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
