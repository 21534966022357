import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivate,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ApiCallsService } from './api-calls.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivateChild {
  constructor(private router: Router, private apiService: ApiCallsService) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log(childRoute);
    console.log(childRoute.routeConfig.path);
    console.log(childRoute.routeConfig.component.name);
    let currentRoute: string = childRoute.routeConfig.path;
    let currentComponentName: string = childRoute.routeConfig.component.name;
    let skipUserCheckRoutes: string[] = ['notifications-detail'];
    let skipUserCheckComponents: string[] = ['DashboardLandingComponent'];
    console.log(`is production environment : ${environment.production}`);
    skipUserCheckRoutes.forEach(
      (skipUserRoute: string, skipUserRouteIndex: number) => {
        skipUserCheckComponents.forEach(
          (skipUserComponent: string, skipUserComponentIndex: number) => {
            if (currentRoute !== skipUserRoute) {
              if (environment.production) {
                if (currentRoute !== '') {
                  console.log(currentRoute);
                  console.log(currentComponentName);
                  this.apiService.verifyLogin();
                  console.log('Is on private route');
                }
              } else {
                if (currentComponentName !== skipUserComponent) {
                  console.log(currentRoute);
                  console.log(currentComponentName);
                  this.apiService.verifyLogin();
                  console.log('Is on private route');
                }
              }
            } else {
              console.log('Is on public route/component');
            }
          }
        );
      }
    );
    return true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MasterDashboardGuard implements CanActivate {
  constructor(private router: Router, private apiService: ApiCallsService) {}
  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log(childRoute);
    console.log(childRoute.routeConfig.path);
    console.log(childRoute.routeConfig.component.name);
    let currentRoute: string = childRoute.routeConfig.path;
    let currentComponentName: string = childRoute.routeConfig.component.name;
    let skipUserCheckRoutes: string[] = ['master-dashboard'];
    let skipUserCheckComponents: string[] = ['DashboardLandingComponent'];
    skipUserCheckRoutes.forEach(
      (skipUserRoute: string, skipUserRouteIndex: number) => {
        skipUserCheckComponents.forEach(
          (skipUserComponent: string, skipUserComponentIndex: number) => {
            if (currentRoute !== skipUserRoute) {
              if (environment.production) {
                if (currentRoute !== '') {
                  console.log(currentRoute);
                  console.log(currentComponentName);
                  this.apiService.verifyLogin();
                  console.log('Is on private route');
                }
              } else {
                if (currentComponentName !== skipUserComponent) {
                  console.log(currentRoute);
                  console.log(currentComponentName);
                  this.apiService.verifyLogin();
                  console.log('Is on private route');
                }
              }
            } else {
              console.log('Is on public route/component');
            }
          }
        );
      }
    );
    return true;
  }
}
