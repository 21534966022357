import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { filterParamsObj } from './models.model';

@Injectable({
  providedIn: 'root',
})
export class ApiCallsService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private globalService: GlobalService
  ) {}

  urlpath = 'http://cap.vidyahelpline.org/api/';

  isTokenVerified: boolean = false;

  verifyToken(): void {
    this.verifyUser().subscribe(
      (res) => {
        this.globalService.isTokenVerified.next(true);
        console.log(res);
        console.log(this.globalService.isTokenVerified);
      },
      (err) => {
        this.globalService.isTokenVerified.next(false);
        localStorage.clear();
        console.log(err);
        console.log(this.globalService.isTokenVerified);
      }
    );
  }

  verifyLogin() {
    this.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }

  headerMaker(authRequired: boolean) {
    let token = localStorage.getItem('Token');
    const headers = {};
    if (authRequired) {
      (headers['Accept'] = 'application/json'),
        (headers['Authorization'] = `Token ${token}`);
    } else {
      headers['Accept'] = 'Application/json';
    }
    return headers;
  }

  getPhoneLinkedUsernames(data) {
    const url = this.urlpath + 'auth/mobile-usernames';
    const headers = {
      Accept: 'application/json',
    };
    return this.http.post(url, data, { headers });
  }

  //Set Password for First time login
  newUser(data) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'auth/setPassword';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    console.log(headers.Authorization);
    return this.http.post(url, data, { headers });
  }

  //Verify User
  verifyUser() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'auth/authenticate';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    console.log(headers.Authorization);
    return this.http.get(url, { headers });
  }

  // userlist retiving based on date

  userList(fromDate, toDate) {
    console.log(fromDate, toDate);
    let token = localStorage.getItem('Token');
    const url =
      this.urlpath + 'admin/userslist/?date=' + fromDate + '/' + toDate;
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    console.log(headers.Authorization);
    return this.http.get(url, { headers });
  }

  //Login
  loginApi(data) {
    const url = this.urlpath + 'auth/login';
    const headers = {
      Accept: 'application/json',
    };
    return this.http.post(url, data, { headers });
  }

  // Multi Login
  multiLoginApi(data) {
    const url = this.urlpath + 'auth/login-multi';
    const headers = {
      Accept: 'application/json',
    };
    return this.http.post(url, data, { headers });
  }

  //Login
  adminLoginApi(data) {
    const url = this.urlpath + 'admin/login';
    const headers = {
      Accept: 'application/json',
    };
    return this.http.post(url, data, { headers });
  }

  //usernameAvailablity
  usernameApi(data) {
    const url = this.urlpath + 'auth/usernameAvailability';
    const headers = {
      Accept: 'application/json',
    };
    let dataToPost = { username: data };
    return this.http.post(url, dataToPost, { headers });
  }

  // emailAvailability
  emailCheckApi(data) {
    const url = this.urlpath + 'auth/emailAvailability';
    const headers = {
      Accept: 'application/json',
    };
    let dataToPost = { email: data };
    return this.http.post(url, dataToPost, { headers });
  }

  //Signup
  signupApi(data) {
    const url = this.urlpath + 'auth/signup';
    const headers = {
      Accept: 'application/json',
    };
    return this.http.post(url, data, { headers });
  }

  // Bulk upload API's start

  // Bank Loans upload

  uploadLoansData(loansFile, operationType: string) {
    const url: string =
      this.urlpath + `admin/loansUpload/?operation_type=${operationType}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, loansFile, { headers });
  }

  // Hostel Bulk upload

  uploadHostelData(hostelFile, operationType: string) {
    const url =
      this.urlpath + `admin/hostelsUpload/?operation_type=${operationType}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, hostelFile, { headers });
  }

  // Career Bulk upload

  uploadCareersData(
    careersFile,
    selectedDbTable: string,
    operationType: string
  ) {
    const url: string =
      this.urlpath +
      `admin/${selectedDbTable}/?operation_type=${operationType}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, careersFile, { headers });
  }

  // Courses bulk upload

  uploadCoursesData(coursesFile, operationType: string) {
    const url =
      this.urlpath + `admin/coursesUpload/?operation_type=${operationType}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, coursesFile, { headers });
  }

  // Colleges bulk upload

  uploadCollegesData(
    collegesFile,
    selectedDbTable: string,
    operationType: string
  ) {
    const url: string =
      this.urlpath +
      `admin/${selectedDbTable}/?operation_type=${operationType}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, collegesFile, { headers });
  }

  // Scholarships bulk upload

  uploadScholarshipsData(
    scholarshipsFile,
    selectedDbTable: string,
    operationType: string
  ) {
    const url: string =
      this.urlpath +
      `admin/${selectedDbTable}/?operation_type=${operationType}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, scholarshipsFile, { headers });
  }

  // Entrance Exams bulk upload

  uploadEntrancesData(coursesFile, operationType: string) {
    const url =
      this.urlpath + `admin/entrancesUpload/?operation_type=${operationType}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, coursesFile, { headers });
  }

  // Notifications Exams bulk upload

  uploadNotificationsData(
    notificationsFIle,
    selectedDbTable: string,
    operationType: string
  ) {
    const url: string =
      this.urlpath +
      `admin/${selectedDbTable}/?operation_type=${operationType}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, notificationsFIle, { headers });
  }

  uploadDistrictsData(districtsFile, operation_type: string) {
    const url: string =
      this.urlpath +
      `admin/districtListUpload/?operation_type=${operation_type}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, districtsFile, { headers });
  }

  uploadStatesData(statesFile, operation_type: string) {
    const url: string =
      this.urlpath + `admin/stateListUpload/?operation_type=${operation_type}`;
    const headers = this.headerMaker(true);
    return this.http.post(url, statesFile, { headers });
  }

  // Bulk upload API's end

  // admin table view API's

  getCollegesData(pageNumber: number, params: filterParamsObj[]) {
    let url: string = this.urlpath + `admin/getColleges/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getCollegesCourseMappings(pageNumber: number, params: filterParamsObj[]) {
    let url: string =
      this.urlpath + `admin/getCollegesCourseMappings/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getCollegesCourseFilters(pageNumber: number, params: filterParamsObj[]) {
    let url: string =
      this.urlpath + `admin/getCollegesCourseFilters/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getCollegesStreamFilters(pageNumber: number, params: filterParamsObj[]) {
    let url: string =
      this.urlpath + `admin/getCollegesStreamFilters/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getScholarshipsData(pageNumber: number, params: filterParamsObj[]) {
    let url: string =
      this.urlpath + `admin/getScholarships/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getScholarshipsMappings(pageNumber: number, params: filterParamsObj[]) {
    let url: string =
      this.urlpath + `admin/getScholarshipsMappings/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getEntrancesData(pageNumber: number, params: filterParamsObj[]) {
    let url: string = this.urlpath + `admin/getEntrances/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getNotificationMappingData(pageNumber: number, params: filterParamsObj[]) {
    let url: string =
      this.urlpath + `admin/getNotificationMappings/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getStatesData(pageNumber: number, params: filterParamsObj[]) {
    let url: string = this.urlpath + `admin/getStates/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getDistrictsData(pageNumber: number, params: filterParamsObj[]) {
    let url: string = this.urlpath + `admin/getDistricts/?page=${pageNumber}`;
    params.forEach((param, paramIndex) => {
      if (param.filterParamActive) {
        url = url + `&${param.filterParamName}=${param.filterParamValue}`;
      }
    });
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  // admin table view API's END

  // admin notification attachments upload API

  uploadNotificationAttachments(attachmentData) {
    const url = this.urlpath + 'admin/uploadNotificationAttachments';
    let token = localStorage.getItem('Token');
    const headers = this.headerMaker(true);
    return this.http.post(url, attachmentData, { headers });
  }

  // admin notification post call

  postAdminNotifications(notificationdData) {
    const url = this.urlpath + 'admin/createNotification';
    let token = localStorage.getItem('Token');
    const headers = {
      // Accept: "multipart/form-data",
      Authorization: `Token ${token}`,
      // "Content-Type":"multipart/form-data"
    };
    return this.http.post(url, notificationdData, { headers });
  }

  // admin notification post call

  publishNotifications(notificationdDId) {
    const url = this.urlpath + 'admin/toggleNotification';
    let token = localStorage.getItem('Token');
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.post(url, notificationdDId, { headers });
  }

  // delete expired notificsation in admin panel

  deleteExpiredNotifications() {
    const url = this.urlpath + 'admin/deleteExpiredNotifications';
    let token = localStorage.getItem('Token');
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // delete notificsation in admin panel

  singleEntryDeleteNotifications(notificationIdsString) {
    const url = this.urlpath + 'admin/deleteNotifications';
    let token = localStorage.getItem('Token');
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.post(url, notificationIdsString, { headers });
  }

  // admin notifications

  adminActiveInactiveNotifications(notificationTypeValue) {
    // const url = this.urlpath+'admin/notification/' + notificationTypeValue
    if (notificationTypeValue !== null) {
      const url = `${this.urlpath}admin/notification/${notificationTypeValue}`;
      let token = localStorage.getItem('Token');
      const headers = {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      };
      return this.http.get(url, { headers });
    }
  }

  //ForgotPassword
  forgotpasswordApi(data) {
    const url = this.urlpath + 'auth/forgotPasswordRequest';
    const headers = {
      Accept: 'application/json',
    };
    return this.http.post(url, data, { headers });
  }
  //resetpassword
  resetpasswordApi(data) {
    const url = this.urlpath + 'auth/forgotPassword';
    const headers = {
      Accept: 'application/json',
    };
    return this.http.post(url, data, { headers });
  }

  //LogoutAPI
  logout() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'auth/logout';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get User Info
  getUserInfo() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'user';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get careers
  getCareers() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'career/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  //Get CareerDetails
  getCareerDetail(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'career/' + id + '/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  getCareerSubjects(subjects) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'career/?subjects=' + subjects;
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  //Get CousreDetails
  getCourseDetail(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'course/' + id + '/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  //Get courses
  getCourses() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'course/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  //Get Scholarships
  getScholarships(stateid, applicationStatus) {
    let token = localStorage.getItem('Token');
    console.log(stateid, applicationStatus);
    if (
      (stateid === null || stateid === 'all') &&
      (applicationStatus === '' || applicationStatus === 'all')
    ) {
      const url = this.urlpath + 'scholarship/';
      const headers = {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      };
      return this.http.get(url, { headers });
    } else {
      if (stateid === 'all') {
        const url = this.urlpath + `scholarship/?status=${applicationStatus}`;
        const headers = {
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        };
        return this.http.get(url, { headers });
      } else if (stateid !== 'all') {
        const url =
          this.urlpath +
          `scholarship/?state=${stateid}&status=${applicationStatus}`;
        const headers = {
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        };
        return this.http.get(url, { headers });
      } else {
        const url =
          this.urlpath +
          `scholarship/?state=${stateid}&status=${applicationStatus}`;
        const headers = {
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        };
        return this.http.get(url, { headers });
      }
    }
  }

  //Get CousreDetails
  getScholarshipDetail(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'scholarship/' + id + '/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get careers
  getEntranceExams() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'entrance/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  getLoan() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'loan/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }
  getHostels(URL?) {
    let token = localStorage.getItem('Token');
    const url = URL ? `${this.urlpath}hostel/${URL}` : `${this.urlpath}hostel/`;
    console.log(url);
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  //Get EntranceDetails
  getEntranceDetail(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'entrance/' + id + '/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  //Get HostelDetails
  getHostelDetail(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'hostel/' + id + '/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  //Get BankloanDetails
  getBankLoanDetail(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'loan/' + id + '/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get Colleges
  getColleges(URL?) {
    let token = localStorage.getItem('Token');
    const url = URL
      ? `${this.urlpath}college/${URL}`
      : `${this.urlpath}college/`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  getcollegeDetail(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'college/' + id + '/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get Dashboard Notifications
  getDashboardNotifications() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'dashboardNotification';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get Notifications
  getNotifications(URL?) {
    let token = localStorage.getItem('Token');
    const url = URL
      ? `${this.urlpath}notification/${URL}`
      : `${this.urlpath}notification/`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get Inactive Notifications
  getInactiveNotifications(URL?) {
    let token = localStorage.getItem('Token');
    const url = URL
      ? `${this.urlpath}inactiveNotification/${URL}`
      : `${this.urlpath}inactiveNotification/`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get Notifications
  getNotificationDetail(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'notification/' + id + '/';
    const headers = {
      Accept: 'application/json',
      // ** Authorization has been removed to
      // Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get inactiveNotifications
  getInactiveNotificationDetail(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'inactiveNotification/' + id + '/';
    const headers = {
      Accept: 'application/json',
      // Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }
  // Get NotificationsTags
  getNotificationTags(id) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'notification/tag/' + id + '/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // profile data
  getUserinfo() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'user';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // Get States
  getStates() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'states/';
    const headers = {
      Accept: 'application/json',
      // Authorization: `Token ${token}`
    };
    return this.http.get(url, { headers });
  }

  // get all states
  getAdminStates() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'admin/states/';
    const headers = {
      Accept: 'application/json',
      // Authorization: `Token ${token}`
    };
    return this.http.get(url, { headers });
  }

  //Get Districts
  getDistricts(id) {
    const url = this.urlpath + 'districts/?stateid=' + id;
    const headers = {
      Accept: 'application/json',
    };
    return this.http.get(url, { headers });
  }

  // Get resources

  getResources() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'resources/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }

  // get Mentor Resources

  getMentorshipResources() {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'mentorshipresources/';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.get(url, { headers });
  }
  postSendEmailData(data) {
    let token = localStorage.getItem('Token');
    const url = this.urlpath + 'sendemail';
    const headers = {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    };
    return this.http.post(url, data, { headers });
  }

  // ** Training Institutions API's

  getAllTrainingRelatedChoices() {
    const url = this.urlpath + 'trainingInstitutesChoices/';
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getAllTrainingInstitutesData(urlFilter: string) {
    let url = this.urlpath + `trainingInstitutes/` + urlFilter;
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }

  getTrainingInstituteDetail(id) {
    const url = this.urlpath + 'trainingInstitutes/' + id + '/';
    const headers = this.headerMaker(true);
    return this.http.get(url, { headers });
  }
}
