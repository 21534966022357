<!-- <p>scholarship-detail works!</p> -->

<div class="col-md-12 careerDetails" *ngIf="!deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/scholarshiplist"
        [queryParams]="{
          academic: this.academicFilter,
          gender: this.genderFilter,
          handicapped: this.handicappedFilter,
          application: this.applicationFilter,
          state: this.stateFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ ScholarshipDetailResponse?.name }} </span>
    </div>
    <div class="col-md-12 mt-2 careerReq mt-3">
      <div class="requirementsText mt-2">
        <span>Scholarship Details</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/student-hat.png" />
        </span>
      </div>
      <div class="col-md-12 d-flex justify-content-between px-0 mt-4">
        <div>
          <span class="reqt1">No. of Scholarships Available: </span>
          <span class="reqta">
            {{ ScholarshipDetailResponse?.available_scholarships }}
          </span>
        </div>
        <div>
          <span class="reqt1">Approximate Application Date: </span>
          <span class="reqta">{{
            ScholarshipDetailResponse?.approximate_application_date
          }}</span>
        </div>
        <div>
          <span class="reqt1">Status: </span>
          <span class="reqta">{{ applicationStatus }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex px-3">
    <div class="col-md-12 mt-2 careerReq mt-3">
      <div class="requirementsText mt-2">
        <span>Conditions</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/career.png" />
        </span>
      </div>
      <div class="col-md-12 d-flex justify-content-between px-0 mt-4">
        <div>
          <span class="reqt1">Age: </span>
          <span class="reqta">
            {{ ScholarshipDetailResponse?.age_eigibility }}
          </span>
        </div>
        <div>
          <span class="reqt1">For Handicap Student?: </span>
          <span class="reqta">{{
            ScholarshipDetailResponse?.physically_handicaped != null
              ? handicappedValues[
                  ScholarshipDetailResponse?.physically_handicaped
                ]
              : "-"
          }}</span>
        </div>
        <div>
          <span class="reqt1">Gender: </span>
          <span class="reqta">{{
            ScholarshipDetailResponse?.gender_eligibility != null
              ? genderValues[ScholarshipDetailResponse?.gender_eligibility]
              : "-"
          }}</span>
        </div>
        <div>
          <span class="reqt1">Caste & Religion: </span>
          <span class="reqta">
            {{ ScholarshipDetailResponse?.caste_eligibility }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 wrapperLeft py-3">
      <div class="row">
        <div class="col-md-12 sc2Parent2">
          <div class="row">
            <div class="col-md-12">
              <span class="secondContainer2">Other details</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/open-book.png" />
              </span>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Academic Restriction</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.academic_level != null
                      ? [ScholarshipDetailResponse?.academic_level]
                      : "NA"
                  }}
                </p>
              </div>
              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Income restriction</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.income_restrictions != null
                      ? [ScholarshipDetailResponse?.income_restrictions]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Location restriction</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.location_eligibility != null
                      ? [ScholarshipDetailResponse?.location_eligibility]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Other Restriction</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.other_restrictions != null
                      ? [ScholarshipDetailResponse?.other_restrictions]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Scholarship amount</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.scholarship_details != null
                      ? [ScholarshipDetailResponse?.scholarship_details]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Source of Data</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.source_of_data != null
                      ? [ScholarshipDetailResponse?.source_of_data]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Date of data Gathered</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.date_of_gathered != null
                      ? [ScholarshipDetailResponse?.date_of_gathered]
                      : "NA"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 wrapperRight py-3">
      <div class="row">
        <div
          class="col-md-12 salaryDetails pb-3 mt-1"
          *ngIf="published == 0 && applicationStatus === 'Active'"
        >
          <div class="mt-3">
            <span class="roadMapText">Notification Details</span>
            <span class="pl-2">
              <img src="/assets/Dashboard/notification.svg" />
            </span>
          </div>
          <div class="mt-3">
            <div
              class="notificationDescription"
              [class.show]="show"
              [innerHTML]="ScholarshipDetailResponse?.notification?.name"
            >
              <!-- {{ ScholarshipDetailResponse?.notification?.name }} -->
            </div>
            <span class="viewText" (click)="show = !show">{{
              show ? "View less" : "View More"
            }}</span>
          </div>
          <div class="bottomTagsStyling mt-2">
            <span class="expectedSalary mr-2">tags: </span>
            <div
              *ngFor="let item of ScholarshipDetailResponse?.notification?.tags"
            >
              <div
                [innerHTML]="statustext(item)"
                [ngClass]="{
                  admissionTag: item === 2,
                  entranceTag: item === 0,
                  scholarshipTag: item === 1,
                  trainingTag: item === 3,
                  govtJobsTag: item === 4,
                  otherTag: item === 5
                }"
              ></div>
            </div>
          </div>
          <!-- <div class="mt-3">
            <span class="expectedSalary">Date information was obtained: </span>
            <span class="expectedSalary2 ml-1" style="word-wrap: break-word">
              {{
                ScholarshipDetailResponse?.date_of_data != null
                  ? [ScholarshipDetailResponse?.date_of_data]
                  : "NA"
              }}
            </span>
          </div> -->
          <div class="mt-3">
            <span class="expectedSalary">Attachments: </span>
            <span class="expectedSalary2 ml-1" style="word-wrap: break-word">
              <a
                href="{{
                  ScholarshipDetailResponse?.notification?.attachment_link
                }}"
                target="_blank"
                *ngIf="
                  ScholarshipDetailResponse?.notification?.attachment_link
                    ?.length > 0
                "
              >
                <img src="/assets/Dashboard/Icon metro-attachment.svg" />
              </a>

              <a
                *ngIf="
                  ScholarshipDetailResponse?.notification?.attachment_name !=
                    '' &&
                  ScholarshipDetailResponse?.notification?.attachment_link
                    ?.length > 0
                "
                href="{{
                  ScholarshipDetailResponse?.notification?.attachment_link
                }}"
                target="_blank"
                class="attachment"
              >
                {{ ScholarshipDetailResponse?.notification?.attachment_name }}
              </a>
              <a
                *ngIf="
                  ScholarshipDetailResponse?.notification?.attachment_name ==
                    '' &&
                  ScholarshipDetailResponse?.notification?.attachment_link ==
                    null
                "
                class="unDecorated"
              >
                NA
              </a>
              <a
                *ngIf="
                  ScholarshipDetailResponse?.notification?.attachment_name !==
                    '' &&
                  ScholarshipDetailResponse?.notification?.attachment_link
                    ?.length < 1
                "
                class="unDecorated"
              >
                {{ ScholarshipDetailResponse?.notification?.attachment_name }}
              </a>
            </span>
          </div>
        </div>
        <div class="col-md-12 salaryDetails mt-3">
          <div class="mt-3">
            <span class="roadMapText">Application/Selection Info </span>
            <span class="pl-2">
              <img src="/assets/careerDetail/skills.png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Application Process: </span>
            <span class="expectedSalary2 ml-1">{{
              ScholarshipDetailResponse?.application_process
                ? ScholarshipDetailResponse?.application_process
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Last day: </span>
            <span class="expectedSalary2 ml-1">{{
              ScholarshipDetailResponse?.notification !== null
                ? ScholarshipDetailResponse?.notification.active_until
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Selection Process: </span>
            <span class="expectedSalary2 ml-1">{{
              ScholarshipDetailResponse?.selection_process
                ? ScholarshipDetailResponse?.selection_process
                : "NA"
            }}</span>
          </div>
        </div>

        <div class="col-md-12 salaryDetails mt-3">
          <div class="mt-3">
            <span class="roadMapText">Contact Information </span>
            <span class="pl-2">
              <img src="/assets/careerDetail/desktop.png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Web Link: </span>
            <a
              href="{{ ScholarshipDetailResponse?.website }}"
              target="_blank"
              *ngIf="ScholarshipDetailResponse?.website?.length > 0"
            >
              {{
                ScholarshipDetailResponse?.website != ""
                  ? [ScholarshipDetailResponse?.website]
                  : "NA"
              }}
            </a>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Phone No: </span>
            <span class="expectedSalary2 ml-1">{{
              ScholarshipDetailResponse?.phone_number
                ? ScholarshipDetailResponse?.phone_number
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Email: </span>
            <span class="expectedSalary2 ml-1">{{
              ScholarshipDetailResponse?.email
                ? ScholarshipDetailResponse?.email
                : "NA"
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- For Mobile App -->

<div class="col-md-12 careerDetails" *ngIf="deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/scholarshiplist"
        [queryParams]="{
          academic: this.academicFilter,
          gender: this.genderFilter,
          handicapped: this.handicappedFilter,
          application: this.applicationFilter,
          state: this.stateFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ ScholarshipDetailResponse?.name }} </span>
    </div>
    <div class="col-md-12 mt-2 careerReq mt-3">
      <div class="requirementsText mt-2">
        <span>Scholarship Details</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/student-hat.png" />
        </span>
      </div>
      <div class="col-md-12 px-0 mt-4">
        <div class="mb-2">
          <span class="reqt1">No. of Scholarships Available: </span>
          <span class="reqta">
            {{ ScholarshipDetailResponse?.available_scholarships }}
          </span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Approximate Application Date: </span>
          <span class="reqta">{{
            ScholarshipDetailResponse?.approximate_application_date
          }}</span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Status: </span>
          <span class="reqta">{{
            ScholarshipDetailResponse?.status != null
              ? statusValues[ScholarshipDetailResponse?.status]
              : "-"
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex px-3">
    <div class="col-md-12 mt-2 careerReq mt-3">
      <div class="requirementsText mt-2">
        <span>Conditions</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/career.png" />
        </span>
      </div>
      <div class="col-md-12 px-0 mt-4">
        <div class="mb-2">
          <span class="reqt1">Age: </span>
          <span class="reqta">
            {{ ScholarshipDetailResponse?.age_eigibility }}
          </span>
        </div>
        <div class="mb-2">
          <span class="reqt1">For Handicap Student?: </span>
          <span class="reqta">{{
            ScholarshipDetailResponse?.physically_handicaped != null
              ? handicappedValues[
                  ScholarshipDetailResponse?.physically_handicaped
                ]
              : "-"
          }}</span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Gender: </span>
          <span class="reqta">{{
            ScholarshipDetailResponse?.gender_eligibility != null
              ? genderValues[ScholarshipDetailResponse?.gender_eligibility]
              : "-"
          }}</span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Caste & Religion: </span>
          <span class="reqta">
            {{ ScholarshipDetailResponse?.caste_eligibility }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 wrapperLeft1">
      <div class="row">
        <div class="col-md-12 sc2Parent2 mt-1">
          <div class="row">
            <div class="col-md-12 pt-0">
              <span class="secondContainer2">Other details</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/open-book.png" />
              </span>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Academic Restriction</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.academic_level != null
                      ? [ScholarshipDetailResponse?.academic_level]
                      : "NA"
                  }}
                </p>
              </div>
              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Income restriction</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.income_restrictions != null
                      ? [ScholarshipDetailResponse?.income_restrictions]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Location restriction</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.location_eligibility != null
                      ? [ScholarshipDetailResponse?.location_eligibility]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Other Restriction</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.other_restrictions != null
                      ? [ScholarshipDetailResponse?.other_restrictions]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Scholarship amount</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.scholarship_details != null
                      ? [ScholarshipDetailResponse?.scholarship_details]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Source of Data</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.source_of_data != null
                      ? [ScholarshipDetailResponse?.source_of_data]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Date of data Gathered</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    ScholarshipDetailResponse?.date_of_gathered != null
                      ? [ScholarshipDetailResponse?.date_of_gathered]
                      : "NA"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 wrapperRight1">
      <div class="row">
        <div class="col-md-12 salaryDetails mt-2">
          <div class="mt-3">
            <span class="roadMapText">Application/Selection Info </span>
            <span class="pl-2">
              <img src="/assets/careerDetail/skills.png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Application Process: </span>
            <span class="expectedSalary2 ml-1">{{
              ScholarshipDetailResponse?.application_process
                ? ScholarshipDetailResponse?.application_process
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Last day: </span>
            <span class="expectedSalary2 ml-1">{{
              ScholarshipDetailResponse?.last_date
                ? ScholarshipDetailResponse?.last_date
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Selection Process: </span>
            <span class="expectedSalary2 ml-1 pb-2">{{
              ScholarshipDetailResponse?.selection_process
                ? ScholarshipDetailResponse?.selection_process
                : "NA"
            }}</span>
          </div>
        </div>

        <div class="col-md-12 salaryDetails mt-4 mr-0 mb-3">
          <div class="mt-3">
            <span class="roadMapText">Contact Information </span>
            <span class="pl-2">
              <img src="/assets/careerDetail/desktop.png" />
            </span>
          </div>
          <div class="row mr-0" style="word-wrap: break-word">
            <span class="row expectedSalary ml-3">Web Link: </span>
            <span class="ml-3 pl-2" style="word-break: break-all">
              <a
                href="{{ ScholarshipDetailResponse?.website }}"
                target="_blank"
                *ngIf="ScholarshipDetailResponse?.website?.length > 0"
              >
                {{
                  ScholarshipDetailResponse?.website != ""
                    ? [ScholarshipDetailResponse?.website]
                    : "NA"
                }}
              </a>
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Phone No: </span>
            <span class="expectedSalary2 ml-1">{{
              ScholarshipDetailResponse?.phone_number
                ? ScholarshipDetailResponse?.phone_number
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Email: </span>
            <span class="expectedSalary2 ml-1">{{
              ScholarshipDetailResponse?.email
                ? ScholarshipDetailResponse?.email
                : "NA"
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal boxModal bd-example-modal-lg"
  id="terms"
  data-keyboard="false"
  data-backdrop="static"
>
  <div class="modal-dialog modal-lg dialogStyling">
    <div class="modal-content" style="border-radius: 8px">
      <div
        class="modal-header"
        style="
          background-color: #fff4f2;
          border-bottom: none;
          padding-bottom: 7px;
        "
      >
        <span class="modal-title deleteNotificationStyling"
          >Notification alert</span
        >
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body p-0">
        <div class="col-md-12 col-xs-12 col-sm-12">
          <div
            class="textline py-3"
            style="word-break: break-word"
            [innerHTML]="ScholarshipDetailResponse?.notification?.name"
          >
            <!-- {{ ScholarshipDetailResponse?.notification?.name }} -->
          </div>
          <div class="tags pt-3 pb-3">Tags:</div>
          <div class="bottomTagsStyling">
            <div
              class="mobileTag"
              *ngFor="let item of ScholarshipDetailResponse?.notification?.tags"
            >
              <div
                [innerHTML]="statustext(item)"
                [ngClass]="{
                  admissionTag: item === 2,
                  entranceTag: item === 0,
                  scholarshipTag: item === 1,
                  trainingTag: item === 3,
                  govtJobsTag: item === 4,
                  otherTag: item === 5
                }"
              ></div>
            </div>
          </div>
          <!-- <div class="tags pt-3">Date information was obtained:</div>
          <div class="date py-3">{{
            ScholarshipDetailResponse?.date_of_data != null
            ? [ScholarshipDetailResponse?.date_of_data]
            : "NA"
        }}
          </div> -->
          <div class="tags pt-3">Attachments:</div>
          <div class="date py-3">
            <a
              href="{{
                ScholarshipDetailResponse?.notification?.attachment_link
              }}"
              target="_blank"
              *ngIf="
                ScholarshipDetailResponse?.notification?.attachment_link
                  ?.length > 0
              "
            >
              <img src="/assets/Dashboard/Icon metro-attachment.svg" />
            </a>

            <a
              *ngIf="
                ScholarshipDetailResponse?.notification?.attachment_name !=
                  '' &&
                ScholarshipDetailResponse?.notification?.attachment_link
                  ?.length > 0
              "
              href="{{
                ScholarshipDetailResponse?.notification?.attachment_link
              }}"
              target="_blank"
              class="attachment"
            >
              {{ ScholarshipDetailResponse?.notification?.attachment_name }}
            </a>
            <a
              *ngIf="
                ScholarshipDetailResponse?.notification?.attachment_name ==
                  '' &&
                ScholarshipDetailResponse?.notification?.attachment_link == null
              "
              class="unDecorated"
            >
              NA
            </a>
            <a
              *ngIf="
                ScholarshipDetailResponse?.notification?.attachment_name !==
                  '' &&
                ScholarshipDetailResponse?.notification?.attachment_link
                  ?.length < 1
              "
              class="unDecorated"
            >
              {{ ScholarshipDetailResponse?.notification?.attachment_name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
