<div class="row p-0 m-0">
  <div class="col-md-12">
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/mentorship/"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> Holistic Mentoring</span>
    </div>
  </div>
</div>
<!-- <div class="row"> -->

<div class="col-12 px-4">
  <div
    class="row p-0 m-0"
    style="
      background-color: white;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    "
  >
    <!-- <div class="row d-flex pb-5"> -->
    <div class="col-md-12 imageStyling">
      <img
        src="/assets/mentorship/holisticmentoringphoto.jpg"
        class="responsive"
      />
    </div>
    <div class="col-md-12 boxClass mt-4">
      <div class="col-12 careerClass">Holistic mentoring</div>
      <div class="col-12 careerMatter pt-2 pb-3">
        <div>
          Holistic Mentoring is a selective student support program for the
          meritorious students but economically weak in assisting, mentoring and
          tracking them till successful employment and looping the students back
          to the organization for its sustenance.

          <div class="row d-flex pt-4">
            <div class="col-md-3 col-5 key">Time Period</div>
            <span class="colon"> : </span>
            <div class="col-md-7 col-6 value">1 year to 6 years</div>

            <div class="col-md-3 col-5 key pt-2">Eligibility</div>
            <span class="colon pt-2"> : </span>
            <div class="col-md-7 col-6 value pt-2">
              After 10<sup>th</sup> grade
            </div>

            <div class="col-md-3 col-5 key pt-2">Areas of mentoring</div>
            <span class="colon pt-2"> : </span>
            <div class="col-md-7 col-6 value pt-2">
              Overall development of the students on Education, emotional,
              career wise, personality development etc.,
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
