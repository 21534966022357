<div class="route-content">
  <div class="nav-bar-dash">
    <div class="row d-flex">
      <div class="col-4">
        <img
          class="imageStyle"
          src="/assets/MasterDashboard/Vidya-Helpline-logo.png"
        />
      </div>
      <div class="col-4 d-flex justify-content-center captextStyle">
        Career Advancement Platform
      </div>
      <div class="col-4 d-flex textStyle" style="justify-content: flex-end">
        <div class="row d-flex textStyle" style="margin-inline-end: 0.4rem">
          <div *ngIf="isTokenVerified" class="usernameStyle">
            <span
              class="row userNametxt"
              style="padding-inline-start: 4rem"
              [innerHtml]="trimToThreeAlphabet(responseVar)"
            ></span>
          </div>
          <div *ngIf="isTokenVerified" class="imgStyling" (click)="logout()">
            <img
              style="vertical-align: unset; margin-right: 0.3rem"
              src="/assets/MasterDashboard/logout.svg"
              routerLink="/dashboard"
              alt="home_icon"
            />
            <!-- Logout -->
            <!--#G commented as per the design-->
            Log Out
          </div>
          <div *ngIf="!isTokenVerified" class="imgStyling" routerLink="/auth">
            <img
              style="vertical-align: unset; margin-right: 0.3rem"
              src="/assets/MasterDashboard/logout.svg"
              routerLink="/dashboard"
              alt="home_icon"
            />
            <!-- Logout -->
            <!--#G commented as per the design-->
            Login
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex m-0 spacing">
    <div class="cardStyle" routerLink="/dashboard">
      <div class="imgAlign">
        <img class="cardImg" src="/assets/MasterDashboard/career.svg" />
      </div>
      <div class="card-body" style="padding-top: 12px !important">
        <div class="bodyHead">Career Wiki</div>
        <div class="bodyText">Information on careers, courses and more</div>
      </div>
    </div>
    <div class="cardStyle" (click)="takeTest()">
      <div class="imgAlign">
        <img class="cardImg" src="/assets/MasterDashboard/psych.svg" />
      </div>
      <div class="card-body" style="padding-top: 12px !important">
        <div class="bodyHead">Psychometric Test</div>
        <div class="bodyText">
          Get recommendations for careers suitable for you
        </div>
      </div>
    </div>
    <div class="cardStyle" routerLink="/dashboard/resources">
      <div class="imgAlign">
        <img class="cardImg" src="/assets/MasterDashboard/res.svg" />
      </div>
      <div class="card-body" style="padding-top: 12px !important">
        <div class="bodyHead">Resources</div>
        <div class="bodyText">Useful documents</div>
      </div>
    </div>
    <div class="cardStyle" routerLink="/dashboard/mentorship">
      <div class="imgAlign">
        <img class="cardImg" src="/assets/MasterDashboard/ment.svg" />
      </div>
      <div class="card-body" style="padding-top: 12px !important">
        <div class="bodyHead">Mentorship</div>
        <div class="bodyText">Get help from professionals in your area</div>
      </div>
    </div>
    <div class="cardStyle" routerLink="/dashboard/careermithra">
      <div class="imgAlign">
        <img class="cardImg" src="/assets/MasterDashboard/cm.svg" />
      </div>
      <div class="card-body" style="padding-top: 12px !important">
        <div class="bodyHead">Career Mithra</div>
        <div class="bodyText">Empower youth with information!</div>
      </div>
    </div>
    <div class="cardStyle" routerLink="/dashboard/notifications">
      <div class="imgAlign">
        <img class="cardImg" src="/assets/MasterDashboard/notification.svg" />
      </div>
      <div class="card-body" style="padding-top: 12px !important">
        <div class="bodyHead">Notifications</div>
        <div class="bodyText">
          The latest news on entrance exams, scholarships etc
        </div>
      </div>
    </div>
  </div>
  <div
    class="col-12 d-flex justify-content-center pt-3"
    style="background-color: #f5f2f2"
  >
    <img src="/assets/MasterDashboard/nirman-logo.jpg" />
  </div>
</div>
