<div class="col-md-12 mt-3" style="height: 117.62px !important">
  <div>
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
      <span class="backTitle"> Hostel List</span>
    </div>
  </div>
  <mat-form-field appearance="outline" class="searchField">
    <input
      type="text"
      matInput
      placeholder="Search Hostels"
      (input)="filterSearch()"
      [(ngModel)]="searchText"
    />
    <!-- [formControl]="myControl" -->
    <!-- <mat-autocomplete>
        <mat-option *ngFor="let option of options; index as j;let last=last" [value]="option">{{
          option
        }}</mat-option>
      </mat-autocomplete> -->
    <mat-icon matSuffix class="magBag"> search </mat-icon>
  </mat-form-field>
</div>

<div class="col-md-12">
  <div class="row" *ngIf="!deviceXs">
    <div class="col-md-2" style="height: 77.64px !important">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Academic Level</mat-label>
        <mat-select
          placeholder="Academic Level"
          (selectionChange)="filterAcademicLevel()"
          [(ngModel)]="academicFilter1"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'0'">10+2/Intermediate/PUC</mat-option>
            <mat-option [value]="'1'">Degree</mat-option>
            <mat-option [value]="'2'">Post Graduate</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Gender Restriction</mat-label>
        <mat-select
          placeholder="Gender Restriction"
          (selectionChange)="filterGenderRestriction()"
          [(ngModel)]="genderFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Boys </mat-option>
            <mat-option [value]="1">Girls</mat-option>
            <mat-option [value]="2">Both</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments"
          >Caste or Minority Restriction</mat-label
        >
        <mat-select
          placeholder="Caste or Minority Restriction"
          (selectionChange)="filterCaste()"
          [(ngModel)]="casteFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">SC</mat-option>
            <mat-option [value]="1">ST</mat-option>
            <mat-option [value]="2">BC</mat-option>
            <mat-option [value]="3">Other Caste</mat-option>
            <mat-option [value]="4">Minority Religion</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">State</mat-label>
        <mat-select
          placeholder="State"
          (selectionChange)="filterState()"
          [(ngModel)]="stateFilter"
        >
          <mat-option class="optionStyling" value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let state of states">
            <mat-option
              [value]="state.state_id"
              (click)="getAllDistricts(state.state_id)"
              *ngIf="state.state_id != 99"
              >{{ state.name }}</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">District</mat-label>
        <mat-select
          placeholder="District"
          (selectionChange)="filterDistrict()"
          [(ngModel)]="districtFilter"
        >
          <mat-option class="optionStyling" value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let district of districts">
            <mat-option [value]="district.district_id">
              {{ district.name }}
            </mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- for mobile app filter -->

  <div class="row" *ngIf="deviceXs">
    <!-- <ngx-spinner
      bdColor="rgba(51, 51, 51, 0.8)"
      size="default"
      type="ball-spin-clockwise"
    >
      <p style="color: white">Please Wait.</p>
    </ngx-spinner> -->
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Academic Level</mat-label>
        <mat-select
          placeholder="Academic Level"
          (selectionChange)="filterAcademicLevel()"
          [(ngModel)]="academicFilter1"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'0'">10+2/Intermediate/PUC</mat-option>
            <mat-option [value]="'1'">Degree</mat-option>
            <mat-option [value]="'2'">Post Graduate</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">Gender Restriction</mat-label>
        <mat-select
          placeholder="Gender Restriction"
          (selectionChange)="filterGenderRestriction()"
          [(ngModel)]="genderFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Boys </mat-option>
            <mat-option [value]="1">Girls</mat-option>
            <mat-option [value]="2">Both</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Caste or Minority Restriction</mat-label>
        <mat-select
          placeholder="Caste or Minority Restriction"
          (selectionChange)="filterCaste()"
          [(ngModel)]="casteFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">SC</mat-option>
            <mat-option [value]="1">ST</mat-option>
            <mat-option [value]="2">BC</mat-option>
            <mat-option [value]="3">Other Caste</mat-option>
            <mat-option [value]="4">Minority Religion</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">State</mat-label>
        <mat-select
          placeholder="State"
          (selectionChange)="filterState()"
          [(ngModel)]="stateFilter"
        >
          <mat-option class="optionStyling" value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let state of states">
            <mat-option
              [value]="state.state_id"
              (click)="getAllDistricts(state.state_id)"
              *ngIf="state.state_id != 99"
              >{{ state.name }}</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="col-6">
        <mat-form-field appearance="outline" class="auth">
          <mat-select placeholder="District" (selectionChange) = "filterHostel()" [(ngModel)] = "districtFilter">
            <mat-option value='all'>All </mat-option>
            <mat-option [value]="0">Active</mat-option>
            <mat-option [value]="1">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">District</mat-label>
        <mat-select
          placeholder="District"
          (selectionChange)="filterDistrict()"
          [(ngModel)]="districtFilter"
        >
          <mat-option class="optionStyling" value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let district of districts">
            <mat-option [value]="district.district_id">
              {{ district.name }}
            </mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="BG mt-1 table-responsive">
    <table class="table">
      <tr class="colorWaraper">
        <th class="coloumnSeperator">
          <div class="col tableHItem">Hostel Name</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">State</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">District</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Gender Restriction</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Academic Level</div>
        </th>
      </tr>
      <ng-container
        *ngFor="
          let option of filterOptions | filter : searchText;
          index as j;
          let Last = last
        "
      >
        <tr class="tableHItem4" (click)="coHostelDetails(option['id'])">
          <td>
            <div
              class="col tableHItem2 py-2"
              style="margin: 0"
              [title]="option['name']"
            >
              {{ option["name"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["state"]?.name }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["district_id"]?.name }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{
                option["gender_restrictions"] != null
                  ? groupValuesGender[option["gender_restrictions"]]
                  : "-"
              }}
            </div>
          </td>

          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["academic_level"] }}
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
    <ngb-pagination
      *ngIf="!deviceXs"
      class="d-flex justify-content-center pagination_theme"
      [maxSize]="5"
      [collectionSize]="collectionSize"
      [pageSize]="pageSize"
      [(page)]="currentPage"
      (pageChange)="onPageChange(currentPage)"
    >
      <ng-template ngbPaginationPrevious>
        <img
          src="/assets/Dashboard/pageArrowPrev.svg"
          [ngClass]="[collectionSize] < '5' ? '' : ''"
        />
      </ng-template>
      <ng-template ngbPaginationNext>
        <img
          src="/assets/Dashboard/pageArrowNext.svg"
          [ngClass]="[collectionSize] < '5' ? '' : ''"
        />
      </ng-template>
    </ngb-pagination>
    <!-- <ngb-pagination
      *ngIf="deviceXs"
      class="d-flex justify-content-right pagination_theme"
      style="overflow: auto; text-overflow: ellipsis"
      [collectionSize]="collectionSize"
      [pageSize]="pageSize"
      [(page)]="currentPage"
      (pageChange)="onPageChange(currentPage)"
    >
      <ng-template ngbPaginationPrevious>
        <img src="/assets/Dashboard/pageArrowPrev.svg" />
      </ng-template>
      <ng-template ngbPaginationNext>
        <img src="/assets/Dashboard/pageArrowNext.svg" />
      </ng-template> -->
    <!-- <ngx-spinner
        bdColor="rgba(51, 51, 51, 0.8)"
        size="default"
        type="ball-spin-clockwise"
      >
        <p style="color: white">Please Wait.</p>
      </ngx-spinner> -->
    <!-- </ngb-pagination> -->
    <!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="">
      "<img src="/assets/Auth/loader.png" />"
      <p style="color: white">Please Wait.</p>
    </ngx-spinner> -->
  </div>

  <ngb-pagination
    *ngIf="deviceXs"
    class="d-flex justify-content-center pagination_theme"
    [maxSize]="2"
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    (pageChange)="onPageChange(currentPage)"
  >
    <ng-template ngbPaginationPrevious>
      <img src="/assets/Dashboard/pageArrowPrev.svg" />
    </ng-template>
    <ng-template ngbPaginationNext>
      <img src="/assets/Dashboard/pageArrowNext.svg" />
    </ng-template>

    <!-- <ngx-spinner
        bdColor="rgba(51, 51, 51, 0.8)"
        size="default"
        type="ball-spin-clockwise"
      >
        <p style="color: white">Please Wait.</p>
      </ngx-spinner> -->
  </ngb-pagination>
</div>
