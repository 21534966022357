<div class="row p-0 m-0">
  <div class="col-md-12">
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/mentorship/"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> Skill based mentoring</span>
    </div>
  </div>
</div>
<!-- <div class="row"> -->

<div class="col-12 px-4">
  <div
    class="row p-0 m-0"
    style="
      background-color: white;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    "
  >
    <!-- <div class="row d-flex pb-5"> -->
    <div class="col-md-3 imageStyling">
      <img src="/assets/mentorship/skill-new.jpg" style="max-width: 100%" />
      <!-- Changed the img and added max-width: 100% #G-->
    </div>
    <div class="col-md-8 ml-xl-3 boxClass">
      <!-- added ml-xl-3 #G -->
      <div class="col-12 careerClass">Skill based mentoring</div>
      <div class="col-12 careerMatter pt-2 pb-3">
        <div>
          A unique mentorship support where mentors- experts in their domains,
          devise and implement a learning engagement with students to upskill
          their knowledge in the domain chosen by students - through
          various-remote-engagement models.
          <div class="row d-flex pt-4">
            <div class="col-md-3 col-5 key">Time Period</div>
            <span class="colon"> : </span>
            <div class="col-md-7 col-6 value">3 Months.</div>

            <div class="col-md-3 col-5 key pt-2">Eligibility</div>
            <span class="colon pt-2"> : </span>
            <div class="col-md-7 col-6 value pt-2">
              12<sup>th</sup> class & above
            </div>
            <!-- changed text from "8th grade to graduation" to "12th class & above" #G-->

            <div class="col-md-3 col-5 key pt-2">Areas of mentoring</div>
            <span class="colon pt-2"> : </span>
            <div class="col-md-7 col-6 value pt-2">
              Enhancing the students’ knowledge on their interested skills. It
              can be Languages, arts, etc.
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
