import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-root',
  templateUrl: './signup-root.component.html',
  styleUrls: ['./signup-root.component.css'],
})
export class SignupRootComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
