import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../api-calls.service';
import { FormControl } from '@angular/forms';
import {
  filterListObj,
  filterOptionObj,
  filterParamsObj,
} from '../models.model';

@Component({
  selector: 'app-admin-colleges',
  templateUrl: './admin-colleges.component.html',
  styleUrls: ['./admin-colleges.component.css'],
})
export class AdminCollegesComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'college_id',
    'name',
    'institution_type',
    'address',
    'courses_offered',
    'district_id',
    'gender',
    'hostel_facility',
    'state',
    'category',
    'institute_prominence',
    'filter_management_1',
    'filter_management_2',
  ];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  collegesArr: any[] = [];
  statesList: any[] = [];
  districtList: any[] = [];
  hideOptions: boolean = false;

  collegesDisplayList: any[] = [
    { displayName: 'Colleges', displayValue: 0 },
    { displayName: 'Colleges Course Mapping', displayValue: 1 },
    { displayName: 'Colleges Course Filter', displayValue: 2 },
    { displayName: 'Colleges Stream Filter', displayValue: 3 },
  ];

  selectedDisplayView: any = 0;

  searchFormControl: FormControl = new FormControl();
  stateFormControl: FormControl = new FormControl();
  districtFormControl: FormControl = new FormControl();
  genderFormControl: FormControl = new FormControl();
  prominenceFormControl: FormControl = new FormControl();
  categoryFormControl: FormControl = new FormControl();
  facilityFormControl: FormControl = new FormControl();
  management1FormControl: FormControl = new FormControl();
  management2FormControl: FormControl = new FormControl();

  management1Options: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Government' },
    { filterOptionKey: 1, filterOptionValue: 'Private' },
    { filterOptionKey: 2, filterOptionValue: 'NGO' },
    { filterOptionKey: 3, filterOptionValue: 'Aided' },
  ];

  categoryOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Vocational' },
    { filterOptionKey: 1, filterOptionValue: 'Professional' },
  ];

  prominenceOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'State Level' },
    { filterOptionKey: 1, filterOptionValue: 'National Level' },
  ];

  management2Options: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Minority' },
    { filterOptionKey: 1, filterOptionValue: 'PWD' },
  ];

  yesOrNoFilterOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Yes' },
    { filterOptionKey: 1, filterOptionValue: 'No' },
  ];

  genderFilterOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'No Filter' },
    { filterOptionKey: 3, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Male' },
    { filterOptionKey: 1, filterOptionValue: 'Female' },
    { filterOptionKey: 2, filterOptionValue: 'Other' },
  ];

  prominenceParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '-1',
  };

  categoryParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '-1',
  };

  management1Param: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '-1',
  };

  management2Param: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '-1',
  };

  stateParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '-1',
  };

  districtParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '-1',
  };

  genderParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  facilityParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: -1,
  };

  searchParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  filtersList: filterListObj[] = [
    {
      filterName: 'Category',
      filterOptions: this.categoryOptions,
      filterFormControl: this.categoryFormControl,
    },
    {
      filterName: 'Institute Prominence',
      filterOptions: this.prominenceOptions,
      filterFormControl: this.prominenceFormControl,
    },
    {
      filterName: 'Gender',
      filterOptions: this.genderFilterOptions,
      filterFormControl: this.genderFormControl,
    },
    {
      filterName: 'Hostel Facility',
      filterOptions: this.genderFilterOptions,
      filterFormControl: this.facilityFormControl,
    },
    {
      filterName: 'Management 1',
      filterOptions: this.management1Options,
      filterFormControl: this.management1FormControl,
    },
    {
      filterName: 'Management 2',
      filterOptions: this.management2Options,
      filterFormControl: this.management2FormControl,
    },
  ];

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.setDefaultControlValues();
  }

  getAllStates() {
    this.apiCaller.getAdminStates().subscribe(
      (res: any) => {
        if (res.length > 0) {
          console.log(res);
          const allStates = {
            id: -1,
            name: 'All',
            state_id: -1,
            published: 0,
          };
          this.statesList.push(allStates);
          res.forEach((stateObj, stateObjIndex) => {
            this.statesList.push(stateObj);
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  setDefaultControlValues() {
    this.getAllStates();
    this.prominenceFormControl.patchValue(this.prominenceOptions[0]);
    this.categoryFormControl.patchValue(this.categoryOptions[0]);
    this.facilityFormControl.patchValue(this.genderFilterOptions[0]);
    this.management1FormControl.patchValue(this.management1Options[0]);
    this.management2FormControl.patchValue(this.management2Options[0]);
    this.genderFormControl.patchValue(this.genderFilterOptions[0]);
    this.stateFormControl.patchValue(-1);
    this.districtFormControl.patchValue(-1);
    this.getCollegesData(1, false);
  }

  hideOptionsFunc() {
    this.hideOptions = !this.hideOptions;
  }

  callCollegesApi(page: number) {
    this.apiCaller
      .getCollegesData(page, [
        this.genderParam,
        this.prominenceParam,
        this.categoryParam,
        this.facilityParam,
        this.management1Param,
        this.management2Param,
        this.stateParam,
        this.searchParam,
      ])
      .subscribe(
        (res: any) => {
          console.log(res);
          this.collegesArr = res.results;
          this.collectionSize = res.count;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getCollegesData(page: number, isSearch: boolean) {
    this.makeParams();
    if (isSearch) {
      let searchValue: string = this.searchParam.filterParamValue as string;
      if (searchValue.length >= 3) {
        this.callCollegesApi(page);
      }
    } else {
      this.callCollegesApi(page);
    }
  }

  clearSearch() {
    this.searchFormControl.reset();
    this.getCollegesData(this.currentPage, false);
  }

  makeParams() {
    let searchValue: string = this.searchFormControl.value;
    if (searchValue) {
      if (searchValue.length >= 3) {
        this.searchParam.filterParamActive = true;
        this.searchParam.filterParamValue = searchValue;
        this.searchParam.filterParamName = 'search';
        this.currentPage = 1;
      } else {
        this.searchParam.filterParamActive = false;
      }
    } else {
      this.searchParam.filterParamActive = false;
    }

    let genderLevel: filterOptionObj = this.genderFormControl.value;
    if (genderLevel.filterOptionKey !== -1) {
      this.genderParam.filterParamActive = true;
      this.genderParam.filterParamValue = genderLevel.filterOptionKey;
      this.genderParam.filterParamName = 'gender';
      this.currentPage = 1;
    } else {
      this.genderParam.filterParamActive = false;
    }

    let facilityLevel: filterOptionObj = this.facilityFormControl.value;
    if (facilityLevel.filterOptionKey !== -1) {
      this.facilityParam.filterParamActive = true;
      this.facilityParam.filterParamValue = facilityLevel.filterOptionKey;
      this.facilityParam.filterParamName = 'hostel_facility';
      this.currentPage = 1;
    } else {
      this.facilityParam.filterParamActive = false;
    }

    let prominenceLevel: filterOptionObj = this.prominenceFormControl.value;
    if (prominenceLevel.filterOptionKey !== -1) {
      this.prominenceParam.filterParamActive = true;
      this.prominenceParam.filterParamValue = prominenceLevel.filterOptionKey;
      this.prominenceParam.filterParamName = 'institute_prominence';
      this.currentPage = 1;
    } else {
      this.prominenceParam.filterParamActive = false;
    }

    let categoryLevel: filterOptionObj = this.categoryFormControl.value;
    if (categoryLevel.filterOptionKey !== -1) {
      this.categoryParam.filterParamActive = true;
      this.categoryParam.filterParamValue = categoryLevel.filterOptionKey;
      this.categoryParam.filterParamName = 'category';
      this.currentPage = 1;
    } else {
      this.categoryParam.filterParamActive = false;
    }

    let management1Level: filterOptionObj = this.management1FormControl.value;
    if (management1Level.filterOptionKey !== -1) {
      this.management1Param.filterParamActive = true;
      this.management1Param.filterParamValue = management1Level.filterOptionKey;
      this.management1Param.filterParamName = 'filter_management_1';
      this.currentPage = 1;
    } else {
      this.management1Param.filterParamActive = false;
    }

    let management2Level: filterOptionObj = this.management2FormControl.value;
    if (management2Level.filterOptionKey !== -1) {
      this.management2Param.filterParamActive = true;
      this.management2Param.filterParamValue = management2Level.filterOptionKey;
      this.management2Param.filterParamName = 'filter_management_2';
      this.currentPage = 1;
    } else {
      this.management2Param.filterParamActive = false;
    }

    let stateLevel: number = this.stateFormControl.value;
    if (stateLevel > -1) {
      this.stateParam.filterParamActive = true;
      this.stateParam.filterParamValue = stateLevel;
      this.stateParam.filterParamName = 'state';
      this.currentPage = 1;
    } else {
      this.stateParam.filterParamActive = false;
    }

    let districtLevel: number = this.districtFormControl.value;
    if (districtLevel > -1) {
      this.districtParam.filterParamActive = true;
      this.districtParam.filterParamValue = districtLevel;
      this.districtParam.filterParamName = 'district';
      this.currentPage = 1;
    } else {
      this.districtParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }
}
