import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModuleComponent } from './auth-module/auth-module.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { Routes, Router } from '@angular/router';
import { ForgotPasswordRootComponent } from './forgot-password-root/forgot-password-root.component';
import { LoginRootComponent } from './login-root/login-root.component';
import { GetOTPComponent } from './get-otp/get-otp.component';
import { SignupComponent } from './signup/signup.component';
import { SignupRootComponent } from './signup-root/signup-root.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ApiCallsService } from './api-calls.service';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardLandingComponent } from './dashboard-landing/dashboard-landing.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { GetStartedLandingComponent } from './get-started-landing/get-started-landing.component';
import { ChooseCareerComponent } from './choose-career/choose-career.component';
import { FavoriteSubjectComponent } from './favorite-subject/favorite-subject.component';
import { StartNowComponent } from './start-now/start-now.component';
import { StepsToFollowComponent } from './steps-to-follow/steps-to-follow.component';
import { GetStartedModalComponent } from './get-started-modal/get-started-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { CareesrListComponent } from './careesr-list/careesr-list.component';
import { CareerDetailComponent } from './career-detail/career-detail.component';
import { FilterPipe } from './filter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OpenRoadMapComponent } from './career-detail/open-road-map/open-road-map.component';
import { CopylistComponent } from './course-list/copylist.component';
import { ScholarshiplistComponent } from './scholarshiplist/scholarshiplist.component';
import { ScholarshipDetailComponent } from './scholarship-detail/scholarship-detail.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { EntranceexamListComponent } from './entranceexam-list/entranceexam-list.component';
import { CollegeListComponent } from './college-list/college-list.component';
import { HostelsListComponent } from './hostels-list/hostels-list.component';
import { BankloanListComponent } from './bankloan-list/bankloan-list.component';
import { EntranceexamlistComponent } from './entranceexamlist/entranceexamlist.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { EntranceDetailComponent } from './entrance-detail/entrance-detail.component';
import { HostelDetailComponent } from './hostel-detail/hostel-detail.component';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';

import { BankloanDetailComponent } from './bankloan-detail/bankloan-detail.component';

import { CollegeDetailComponent } from './college-detail/college-detail.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsAdmissionComponent } from './notifications-Tags/notifications-admission.component';
import { NotificationsGovernmentJobsComponent } from './notificationsDetail/notifications-government-jobs.component';
import { ProfileComponent } from './profile/profile.component';
import { DistanceEducationListComponent } from './distance-education-list/distance-education-list.component';
import { TrainingInstituteListComponent } from './training-institute-list/training-institute-list.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminNotificationsComponent } from './admin-notifications/admin-notifications.component';
import { AdminMasterDashboardComponent } from './admin-master-dashboard/admin-master-dashboard.component';
import { AdminAddNotificationsComponent } from './admin-add-notifications/admin-add-notifications.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ResourcesComponent } from './resources/resources.component';
import { AdminDeleteNotificationsComponent } from './admin-delete-notifications/admin-delete-notifications.component';
import { CareerMithraComponent } from './career-mithra/career-mithra.component';

import { MentoshipComponent } from './mentoship/mentoship.component';
import { HolisticMentoringComponent } from './holistic-mentoring/holistic-mentoring.component';
import { MentorFaqsComponent } from './mentor-faqs/mentor-faqs.component';
import { StudentFaqsComponent } from './student-faqs/student-faqs.component';
import { MentorDashboardComponent } from './mentor-dashboard/mentor-dashboard.component';
import { ProjectBasedMentoringComponent } from './project-based-mentoring/project-based-mentoring.component';
import { SkillBasedMentoringComponent } from './skill-based-mentoring/skill-based-mentoring.component';
import { MentoshipResourcesComponent } from './mentoship-resources/mentoship-resources.component';
import { ResourceMentorvideosComponent } from './resource-mentorvideos/resource-mentorvideos.component';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { AdminCollegesComponent } from './admin-colleges/admin-colleges.component';
import { AdminScholarshipsComponent } from './admin-scholarships/admin-scholarships.component';
import { AdminEntrancesComponent } from './admin-entrances/admin-entrances.component';
import { AdminStatesComponent } from './admin-states/admin-states.component';
import { AdminCollegesCourseMappingComponent } from './admin-colleges-course-mapping/admin-colleges-course-mapping.component';
import { AdminCollegesCourseFilterComponent } from './admin-colleges-course-filter/admin-colleges-course-filter.component';
import { AdminCollegesStreamFilterComponent } from './admin-colleges-stream-filter/admin-colleges-stream-filter.component';
import { AdminDistrictsComponent } from './admin-districts/admin-districts.component';
import { AdminNotificationMappingsComponent } from './admin-notification-mappings/admin-notification-mappings.component';
import { AdminScholarshipMappingsComponent } from './admin-scholarship-mappings/admin-scholarship-mappings.component';

import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { TrainingInstituteDetailComponent } from './training-institute-detail/training-institute-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthModuleComponent,
    ForgotPasswordRootComponent,
    LoginRootComponent,
    GetOTPComponent,
    SignupComponent,
    SignupRootComponent,
    ResetpasswordComponent,
    DashboardComponent,
    DashboardLandingComponent,
    GetStartedComponent,
    GetStartedLandingComponent,
    ChooseCareerComponent,
    FavoriteSubjectComponent,
    StartNowComponent,
    StepsToFollowComponent,
    GetStartedModalComponent,
    ChangepasswordComponent,
    CareesrListComponent,
    CareerDetailComponent,
    FilterPipe,
    OpenRoadMapComponent,
    CopylistComponent,
    ScholarshiplistComponent,
    ScholarshipDetailComponent,
    CourseDetailComponent,
    EntranceexamListComponent,
    CollegeListComponent,
    HostelsListComponent,
    BankloanListComponent,
    EntranceexamlistComponent,
    EntranceDetailComponent,
    HostelDetailComponent,
    LoginMobileComponent,
    LoginMobileComponent,
    BankloanDetailComponent,
    CollegeDetailComponent,
    NotificationsComponent,
    NotificationsAdmissionComponent,
    NotificationsGovernmentJobsComponent,
    ProfileComponent,
    DistanceEducationListComponent,
    TrainingInstituteListComponent,
    AdminLoginComponent,
    AdminDashboardComponent,
    AdminNotificationsComponent,
    AdminAddNotificationsComponent,
    AdminMasterDashboardComponent,
    ResourcesComponent,
    AdminDeleteNotificationsComponent,
    AdminMasterDashboardComponent,
    ResourcesComponent,
    CareerMithraComponent,
    MentoshipComponent,
    HolisticMentoringComponent,
    MentorFaqsComponent,
    StudentFaqsComponent,
    MentorDashboardComponent,
    ProjectBasedMentoringComponent,
    SkillBasedMentoringComponent,
    MentoshipResourcesComponent,
    ResourceMentorvideosComponent,
    MasterDashboardComponent,
    AdminCollegesComponent,
    AdminScholarshipsComponent,
    AdminEntrancesComponent,
    AdminStatesComponent,
    AdminCollegesCourseMappingComponent,
    AdminCollegesCourseFilterComponent,
    AdminCollegesStreamFilterComponent,
    AdminDistrictsComponent,
    AdminNotificationMappingsComponent,
    AdminScholarshipMappingsComponent,
    TrainingInstituteDetailComponent,
  ],
  entryComponents: [GetStartedModalComponent, AdminAddNotificationsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatIconModule,
    Ng2SearchPipeModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatExpansionModule,
    ScrollingModule,
    NgbModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
  ],
  providers: [GoogleAnalytics, ApiCallsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
