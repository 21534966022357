<div class="row mt-6">
  <div class="col-md-12 d-flex justify-content-center cent-otp">
    <img src="/assets/Auth/welcome.png" style="padding-right: 35px" />
  </div>
  <div class="col-md-12 loginText-otp">Enter OTP</div>
  <div class="col-md-12 d-flex justify-content-center mt-4 enterid-fgt">
    Enter the OTP sent to your mobile number or email id
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-5">
    <form [formGroup]="form" (ngSubmit)="otpvalidation()">
      <div class="col-md-12 d-flex justify-content-center mt-3 xyz">
        <mat-form-field appearance="outline" class="authInput-otp">
          <mat-label>Enter OTP</mat-label>
          <input formControlName="otp" matInput placeholder="Enter OTP" />
          <mat-icon matSuffix> <span class="eyeFake"></span></mat-icon>
          <mat-hint
            *ngIf="
              (form.controls.otp.touched || formGroupValidator) &&
              form.controls.otp.invalid
            "
            class="red"
            >Please enter OTP</mat-hint
          >
        </mat-form-field>
      </div>

      <div class="col-md-12 loginText-otp mt-4 pt-5">
        <button class="loginBtn-otp" mat-raised-button>Next</button>
      </div>
    </form>
  </div>
  <div class="col-md-12 loginText-otp2 xyz">
    <span class="newMemberTxt-fgt">
      <a class="newMemberTxt-fgt" routerLink="/auth">Login </a>
    </span>
  </div>
</div>
