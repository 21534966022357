import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApiCallsService } from './../api-calls.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import Swal from 'sweetalert2';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css'],
})
export class ResetpasswordComponent implements OnInit {
  @Input() deviceXs: boolean;

  hide = true;
  hide2 = true;

  tool = false;

  constructor(
    private apicaller: ApiCallsService,
    private formBuilder: FormBuilder,
    private router: Router,
    public mediaObserver: MediaObserver
  ) {}

  // Variables
  mediaSub: Subscription;
  data = {
    id: localStorage.getItem('id'),
    otp: localStorage.getItem('otp'),
    password: '',
  };

  hints() {
    // Swal.fire({
    //   title: 'Password reset successful',
    // showClass: {
    //   popup: 'animate__animated animate__fadeInDown'
    // },
    // hideClass: {
    //   popup: 'animate__animated animate__fadeOutUp'
    // }
    // })
    Swal.fire('Password reset successful', '', 'success');
  }

  iffail() {
    // Swal.fire({
    // title: 'Invalid OTP. Please enter valid OTP',
    // showClass: {
    //   popup: 'animate__animated animate__fadeInDown'
    // },
    // hideClass: {
    //   popup: 'animate__animated animate__fadeOutUp'
    // }
    // })
    Swal.fire('Invalid OTP', 'Please enter valid OTP', 'error');
  }

  passwordnotmatch() {
    // Swal.fire({
    // title: "Password doesn't match",
    // showClass: {
    //   popup: 'animate__animated animate__fadeInDown'
    // },
    // hideClass: {
    //   popup: 'animate__animated animate__fadeOutUp'
    // }
    // })
    Swal.fire("Password doesn't match", '', 'error');
  }

  form = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    password2: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  formGroupValidator = false;

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  tooltip() {
    this.tool ? (this.tool = false) : (this.tool = true);
  }

  onBlurNewPassword() {
    if (
      this.form.controls.password.value != this.form.controls.password2.value
    ) {
      // alert("Password doesn't match")
      // this.passwordnotmatch()
      this.form.controls.password.setErrors({ incorrect: true });
    }
  }

  onBlurRenterPassword() {
    if (
      this.form.controls.password.value != this.form.controls.password2.value
    ) {
      // alert("Password doesn't match")
      // this.passwordnotmatch()
      // console.log("incorrect p2");
      this.form.controls.password2.setErrors({ incorrect: true });
    }
  }

  working() {
    // let data = this.form.value

    // console.log("The vals are", data)
    console.log('password values', this.form.value.password);
    console.log('p2', this.form.value.password2);

    if (this.form.valid) {
      if (
        this.form.controls.password.value == this.form.controls.password2.value
      ) {
        this.data.password = this.form.controls.password.value;
        this.apicaller.resetpasswordApi(this.data).subscribe(
          (res) => {
            console.log(res);
            this.hints();

            this.router.navigate(['/auth']);
          },
          (err) => {
            console.log(err);
            this.router.navigate(['/auth/otpverify']);
            console.log('Invalid otp');
            // alert('Invalid OTP')
            this.iffail();
          }
        );
      } else {
        console.log('Error please check');
      }
    }
    if (this.form.invalid) {
      this.formGroupValidator = true;
    }
    if (
      this.form.controls.password.value != this.form.controls.password2.value
    ) {
      // alert("Password doesn't match")
      this.passwordnotmatch();
    }
  }
}
