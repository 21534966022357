<!-- <p>admin-add-notifications works!</p> -->

<div class="row d-flex boxStyling">
  <!-- <div class="backgcolor"> -->
  <div class="col-md-8 mb-3 d-flex align-items-start">
    <span class="NotHeader"> Add Notification</span>
  </div>
  <div class="col-md-4 mb-3 d-flex justify-content-end">
    <span>
      <mat-icon
        matSuffix
        class="primaryText closeModal"
        (click)="goToLanding()"
        style="cursor: pointer"
      >
        close</mat-icon
      >
    </span>
    <!-- </div> -->
  </div>

  <div class="col-md-12 d-flex justify-content-start">
    <form [formGroup]="form1">
      <mat-form-field class="notificationBoxStyling" appearance="outline">
        <mat-label class="selectFontText">Notification Description"</mat-label>
        <textarea
          class="adminScrlBar"
          matInput
          placeholder="Enter Notification Description"
          formControlName="name"
          rows="5"
        ></textarea>
        <mat-hint
          *ngIf="
            (form1.controls.name.touched || formGroupValidator) &&
            form1.controls.name.invalid
          "
          class="red"
          >Please enter notification</mat-hint
        >
      </mat-form-field>
    </form>
  </div>

  <form [formGroup]="form1">
    <div class="row-md-12 d-flex">
      <div class="col-4">
        <mat-form-field appearance="outline">
          <mat-label class="selectFontText"> Active Until</mat-label>
          <!-- #G Removed asterisk* (changed Active until* to Active Until)-->
          <input
            placeholder="Select Date"
            matInput
            [matDatepicker]="dp"
            formControlName="active_untill"
            class="selectFontText"
          />
          <!-- #G Removed asterisk* in line 47 (changed select Date* to select Date)-->

          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp></mat-datepicker>
          <mat-hint
            *ngIf="
              (form1.controls.active_untill.touched || formGroupActiveuntil) &&
              form1.controls.active_untill.invalid
            "
            class="red"
            >Select Date</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline">
          <mat-label class="selectFontText">Attachment Name</mat-label>
          <input
            formControlName="attachment_name"
            matInput
            placeholder="Enter Name to be Displayed "
          />
          <mat-icon matSuffix> <span class="eyeFake"></span></mat-icon>
          <!-- <mat-hint
            *ngIf="
              (form1.controls.attachment_name.touched ||
                formGroupAttachmentNameValidator) &&
              form1.controls.attachment_name.invalid
            "
            class="red"
            >Please enter attachment name</mat-hint
          > -->
        </mat-form-field>
      </div>
      <div class="col-4 uploadAttachmentStyling">
        <div class="stylin" style="padding-top: 1rem">
          <mat-label
            class="selectFontTextUpload"
            style="padding-inline-end: 2.5rem"
            >{{ attachmentname }}</mat-label
          >

          <input
            type="file"
            (change)="setAttachmentFiles($event.target.files)"
            #attachment_id
            style="word-wrap: break-word; display: none"
          />

          <img
            src="/assets/Dashboard/Icon metro-attachment.svg"
            (click)="attachment_id.click()"
            style="cursor: pointer"
          />
        </div>
        <!-- <div [innerHtml]="setAttachmentFiles(attachmentType?.file?.name)"></div> -->

        <!-- <mat-icon matSuffix> <span class="home"></span></mat-icon> -->
        <!-- <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle> -->
        <div style="padding-top: 1.1rem">
          <!-- <mat-hint
            *ngIf="form1.controls.attachment_file.touched || formGroupValidator"
            class="red"
            >Please upload attachment</mat-hint
          > -->
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="form2">
    <div class="col checkboxHeadings mb-1">Select State/s</div>
    <!-- <div class="col pt-2">
        <mat-checkbox
          #inputEl
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked

            )
          "
          class="checkboxStyling"
          >National</mat-checkbox
        >
        <mat-checkbox
          #inputE2
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Andhra Pradesh</mat-checkbox
        >
        <mat-checkbox
          #inputE3
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Telangana</mat-checkbox
        >
        <mat-checkbox
          #inputE4
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Karnataka</mat-checkbox
        >
        <mat-checkbox
          #inputE5
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Gujarat
          </mat-checkbox
        >
        <mat-checkbox
          #inputE6
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Rajasthan
          </mat-checkbox
        >
        <mat-checkbox
          #inputE7
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Bihar
          </mat-checkbox
        >
        <mat-checkbox
          #inputE8
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Haryana
          </mat-checkbox
        >
        <mat-checkbox
          #inputE9
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Punjab
          </mat-checkbox
        >
        <mat-checkbox
          #inputE10
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Orissa
          </mat-checkbox
        >
        <mat-checkbox
          #inputE11
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >Assam
          </mat-checkbox
        >
        <mat-checkbox
          #inputE12
          type="checkbox"
          (change)="
            onStateChange(
              inputEl.checked,
              inputE2.checked,
              inputE3.checked,
              inputE4.checked,
              inputE5.checked,
              inputE6.checked,
              inputE7.checked,
              inputE8.checked,
              inputE9.checked,
              inputE10.checked,
              inputE11.checked,
              inputE12.checked
            )
          "
          class="checkboxStyling"
          >others
          </mat-checkbox
        >

        <mat-hint *ngIf="formGroupStateValidator === true" class="red"
          >Please select atleast one state</mat-hint
        >
      </div> -->
    <span class="col pt-2" *ngFor="let state of stateList">
      <mat-checkbox
        type="checkbox"
        class="checkboxStyling"
        [(ngModel)]="state.isChecked"
        [ngModelOptions]="{ standalone: true }"
        (change)="onStateChange()"
      >
        {{ state.name }}</mat-checkbox
      >
    </span>
    <mat-hint *ngIf="formGroupStateValidator === true" class="red"
      >Please select atleast one state</mat-hint
    >

    <div>
      <div class="col pt-2 checkboxHeadings">Select Notification Type/s</div>
      <div class="col pt-2">
        <mat-checkbox
          #inputE13
          type="checkbox"
          (change)="
            onNotificationChange(
              inputE13.checked,
              inputE14.checked,
              inputE15.checked,
              inputE16.checked,
              inputE17.checked,
              inputE34.checked
            )
          "
          class="checkboxStyling"
          >Entrance Examination</mat-checkbox
        >
        <mat-checkbox
          #inputE14
          type="checkbox"
          (change)="
            onNotificationChange(
              inputE13.checked,
              inputE14.checked,
              inputE15.checked,
              inputE16.checked,
              inputE17.checked,
              inputE34.checked
            )
          "
          class="checkboxStyling"
          >Scholarship</mat-checkbox
        >
        <mat-checkbox
          #inputE15
          type="checkbox"
          (change)="
            onNotificationChange(
              inputE13.checked,
              inputE14.checked,
              inputE15.checked,
              inputE16.checked,
              inputE17.checked,
              inputE34.checked
            )
          "
          class="checkboxStyling"
          >Admission</mat-checkbox
        >
        <mat-checkbox
          #inputE16
          type="checkbox"
          (change)="
            onNotificationChange(
              inputE13.checked,
              inputE14.checked,
              inputE15.checked,
              inputE16.checked,
              inputE17.checked,
              inputE34.checked
            )
          "
          class="checkboxStyling"
        >
          Training Skill Development</mat-checkbox
        >
        <mat-checkbox
          #inputE17
          type="checkbox"
          (change)="
            onNotificationChange(
              inputE13.checked,
              inputE14.checked,
              inputE15.checked,
              inputE16.checked,
              inputE17.checked,
              inputE34.checked
            )
          "
          class="checkboxStyling"
          >Government Jobs</mat-checkbox
        >
        <mat-checkbox
          #inputE34
          type="checkbox"
          (change)="
            onNotificationChange(
              inputE13.checked,
              inputE14.checked,
              inputE15.checked,
              inputE16.checked,
              inputE17.checked,
              inputE34.checked
            )
          "
          class="checkboxStyling"
          >Others</mat-checkbox
        >
        <mat-hint *ngIf="formGroupNotificationValidator === true" class="red"
          >Please select atleast one tag</mat-hint
        >
      </div>
    </div>
  </form>
  <div>
    <div class="col pt-2 checkboxHeadings">Select Academic Level/s</div>
    <div class="col pt-2">
      <mat-checkbox
        #inputE18
        type="checkbox"
        [(ngModel)]="isAcademicChecked0"
        (change)="onChangeAcademiccheck0(inputE18.checked)"
        class="checkboxStyling"
        >Below 10th</mat-checkbox
      >
      <mat-checkbox
        #inputE19
        type="checkbox"
        [(ngModel)]="isAcademicChecked1"
        (change)="onChangeAcademiccheck1(inputE19.checked)"
        class="checkboxStyling"
        >Studying 10th</mat-checkbox
      >
      <mat-checkbox
        #inputE20
        type="checkbox"
        [(ngModel)]="isAcademicChecked2"
        (change)="onChangeAcademiccheck2(inputE20.checked)"
        class="checkboxStyling"
        >Completed 10th</mat-checkbox
      >
      <mat-checkbox
        #inputE21
        type="checkbox"
        [(ngModel)]="isAcademicChecked3"
        (change)="onChangeAcademiccheck3(inputE21.checked)"
        class="checkboxStyling"
        >Studying Inter/12th/PUC</mat-checkbox
      >
      <mat-checkbox
        #inputE22
        type="checkbox"
        [(ngModel)]="isAcademicChecked4"
        (change)="onChangeAcademiccheck4(inputE22.checked)"
        class="checkboxStyling"
        >Completed Inter/12th/PUC</mat-checkbox
      >
      <mat-checkbox
        #inputE23
        type="checkbox"
        [(ngModel)]="isAcademicChecked5"
        (change)="onChangeAcademiccheck5(inputE23.checked)"
        class="checkboxStyling"
        >Studying ITI</mat-checkbox
      >
      <mat-checkbox
        #inputE24
        type="checkbox"
        [(ngModel)]="isAcademicChecked6"
        (change)="onChangeAcademiccheck6(inputE24.checked)"
        class="checkboxStyling"
        >Completed ITI</mat-checkbox
      >
      <mat-checkbox
        #inputE25
        type="checkbox"
        [(ngModel)]="isAcademicChecked7"
        (change)="onChangeAcademiccheck7(inputE25.checked)"
        class="checkboxStyling"
        >Studying Diploma</mat-checkbox
      >
      <mat-checkbox
        #inputE26
        type="checkbox"
        [(ngModel)]="isAcademicChecked8"
        (change)="onChangeAcademiccheck8(inputE26.checked)"
        class="checkboxStyling"
        >Completed Diploma</mat-checkbox
      >
      <mat-checkbox
        #inputE27
        type="checkbox"
        [(ngModel)]="isAcademicChecked9"
        (change)="onChangeAcademiccheck9(inputE27.checked)"
        class="checkboxStyling"
        >Studying Graduation</mat-checkbox
      >
      <mat-checkbox
        #inputE28
        type="checkbox"
        [(ngModel)]="isAcademicChecked10"
        (change)="onChangeAcademiccheck10(inputE28.checked)"
        class="checkboxStyling"
        >Completed Graduation</mat-checkbox
      >
      <mat-checkbox
        #inputE29
        type="checkbox"
        [(ngModel)]="isAcademicChecked11"
        (change)="onChangeAcademiccheck11(inputE29.checked)"
        class="checkboxStyling"
        >Studying Technical Graduation</mat-checkbox
      >
      <mat-checkbox
        #inputE30
        type="checkbox"
        [(ngModel)]="isAcademicChecked12"
        (change)="onChangeAcademiccheck12(inputE30.checked)"
        class="checkboxStyling"
        >Completed Technical Graduation</mat-checkbox
      >
      <mat-checkbox
        #inputE31
        type="checkbox"
        [(ngModel)]="isAcademicChecked13"
        (change)="onChangeAcademiccheck13(inputE31.checked)"
        class="checkboxStyling"
        >Studying PG</mat-checkbox
      >
      <mat-checkbox
        #inputE32
        type="checkbox"
        [(ngModel)]="isAcademicChecked14"
        (change)="onChangeAcademiccheck14(inputE32.checked)"
        class="checkboxStyling"
        >Completed PG</mat-checkbox
      >
      <mat-checkbox
        #inputE33
        type="checkbox"
        [(ngModel)]="isAcademicChecked15"
        (change)="onChangeAcademiccheck15(inputE33.checked)"
        class="checkboxStyling"
        >Other</mat-checkbox
      >
      <mat-hint *ngIf="formGroupAcademicValidator === true" class="red"
        >Please select atleast one Academic level</mat-hint
      >
    </div>
  </div>

  <div class="col-12 pt-2 checkboxHeadings">Link with career wiki</div>
  <div class="row">
    <div class="col-12 d-flex pt-2">
      <div class="col-6">
        <mat-form-field
          style="width: 22rem"
          appearance="outline"
          class="forAlignments"
        >
          <mat-label class="forAlignments fontmont">Scholarships</mat-label>
          <!--Added a new class "fontmont" for setting font-family-->
          <input
            type="text"
            matInput
            [matAutocomplete]="autoScholarships"
            [(ngModel)]="scholarshipSearchText"
          />
          <mat-icon
            matSuffix
            src="/assets/AdminDashboard/search.svg"
            class="magBag"
          >
            search
          </mat-icon>
          <mat-autocomplete #autoScholarships="matAutocomplete">
            <span
              *ngFor="
                let scholarshipOption of scholarshipOptions
                  | filter : scholarshipSearchText
              "
            >
              <mat-option
                [value]="scholarshipOption.name"
                (click)="scholarshipval(scholarshipOption.id)"
                >{{ scholarshipOption.name }}</mat-option
              >
            </span>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field
          style="width: 22rem"
          appearance="outline"
          class="forAlignments"
        >
          <mat-label class="forAlignments fontmont"
            >Entrance Examinations</mat-label
          >
          <!--Added a new class "fontmont" for setting font-family-->
          <input
            type="text"
            matInput
            [matAutocomplete]="autoEntrance"
            [(ngModel)]="searchText"
          />
          <mat-icon
            matSuffix
            src="/assets/AdminDashboard/search.svg"
            class="magBag"
          >
            search
          </mat-icon>
          <mat-autocomplete #autoEntrance="matAutocomplete">
            <span *ngFor="let option of options | filter : searchText">
              <mat-option [value]="option.name" (click)="val(option.id)">{{
                option.name
              }}</mat-option>
            </span>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div class="row d-flex pt-3">
  <div class="col-md-8">
    <button class="cancelBtn" mat-raised-button (click)="onSaveAndCreateNew()">
      Save and Create New
    </button>
  </div>
  <div class="col-md-2">
    <button class="cancelBtn" mat-raised-button (click)="onPublishSubmit()">
      Publish
    </button>
  </div>
  <div class="col-md-2">
    <button class="saveBtn" mat-raised-button (click)="onSaveSubmit()">
      Save
    </button>
  </div>
</div>
