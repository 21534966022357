import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../api-calls.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notifications-admission',
  templateUrl: './notifications-admission.component.html',
  styleUrls: ['./notifications-admission.component.css'],
})
export class NotificationsAdmissionComponent implements OnInit {
  //Vars
  queryParamId = null;
  options: any = [];
  NotificationTagResponse: any;
  notificationpathurl = null;
  streams: any = [
    'Agriculture & Related Fields',
    'Armed Forces & Central Police',
    'Arts, Design, Creative',
    'Banking & Finance',
    'Building & Construction',
    'Clothing & Accessories',
    'Education & Training',
    'Hardware, Electronics & Telecommunications',
    'Engineering & Manufacturing',
    'Fitness & Leisure',
    'Healthcare & Life Sciences',
    'IT & ITES',
    'Social Sciences & Human Services',
    'Law & Order',
    'Management & Marketing',
    'Media & Entertainment',
    'Public Administration & Government',
    'Retail, Tourism and Hospitality',
    'Self Employment',
    'Transportation, Logistics, Warehousing',
  ];

  groupTagValues = {
    0: 'Entrance Examination',
    1: 'Scholarship',
    2: 'Admission',
    3: 'Training/Skill Development',
    4: 'Government Jobs',
  };

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {}

  getAllJobs() {
    console.log('senorita..');
    this.apiCallService.getNotificationTags(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        this.NotificationTagResponse = res;
        if (this.NotificationTagResponse['notification_path']) {
          // this.roadmapdisabled = false
          this.notificationpathurl =
            this.NotificationTagResponse['notification_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      console.log(params['id']);
      this.queryParamId = params['id'];
    });
    // this.apiCallService.verifyLogin();
    this.getAllJobs();
  }

  tagName() {
    if (this.queryParamId == 2) {
      return 'Admission';
    } else if (this.queryParamId == 1) {
      return 'Scholarship';
    } else if (this.queryParamId == 3) {
      return 'Training/Skill Development';
    } else if (this.queryParamId == 4) {
      return 'Government Jobs';
    } else if (this.queryParamId == 0) {
      return 'Entrance Examination';
    } else {
      return '';
    }
  }
  statustext(item) {
    if (this.queryParamId == 2) {
      return 'Admission';
    } else if (this.queryParamId == 1) {
      return 'Scholarship';
    } else if (this.queryParamId == 3) {
      return 'Training/Skill Development';
    } else if (this.queryParamId == 4) {
      return 'Government Jobs';
    } else if (this.queryParamId == 0) {
      return 'Entrance Examination';
    } else {
      return '';
    }
  }

  coToNotificationeDetails(jobId) {
    // let jobId = this.options[j]['id']
    //  alert(jobId)
    this.router.navigate(['/dashboard/notifications-detail/'], {
      queryParams: { id: jobId },
    });
  }
}
