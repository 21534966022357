import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  filterListObj,
  filterOptionObj,
  filterParamsObj,
} from '../models.model';
import { ApiCallsService } from '../api-calls.service';

@Component({
  selector: 'app-admin-states',
  templateUrl: './admin-states.component.html',
  styleUrls: ['./admin-states.component.css'],
})
export class AdminStatesComponent implements OnInit {
  statesArr: any[] = [];
  // statesList: any[] = [];
  displayedColumns: string[] = [
    'id',
    'name',
    'state_id',
    'published',
    'state_order',
  ];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  hideOptions: boolean = false;

  searchFormControl: FormControl = new FormControl();
  publishedFormControl: FormControl = new FormControl();

  publishedOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Yes' },
    { filterOptionKey: 1, filterOptionValue: 'No' },
  ];

  filtersList: filterListObj[] = [
    {
      filterName: 'Published',
      filterOptions: this.publishedOptions,
      filterFormControl: this.publishedFormControl,
    },
  ];

  searchParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  publishedParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.setDefaultControlValues();
  }

  setDefaultControlValues() {
    this.publishedFormControl.patchValue(this.publishedOptions[0]);
    this.getStatesData(1, false);
  }

  callStatesApi(page: number) {
    this.apiCaller
      .getStatesData(page, [this.searchParam, this.publishedParam])
      .subscribe(
        (res: any) => {
          console.log(res);
          this.statesArr = res.results;
          this.collectionSize = res.count;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getStatesData(page: number, isSearch: boolean) {
    this.makeParams();
    if (isSearch) {
      let searchValue: string = this.searchParam.filterParamValue as string;
      if (searchValue.length >= 3) {
        this.callStatesApi(page);
      }
    } else {
      this.callStatesApi(page);
    }
  }

  clearSearch() {
    this.searchFormControl.reset();
    this.getStatesData(this.currentPage, false);
  }

  makeParams() {
    let searchValue: string = this.searchFormControl.value;
    if (searchValue) {
      if (searchValue.length >= 3) {
        this.searchParam.filterParamActive = true;
        this.searchParam.filterParamValue = searchValue;
        this.searchParam.filterParamName = 'search';
        this.currentPage = 1;
      } else {
        this.searchParam.filterParamActive = false;
      }
    } else {
      this.searchParam.filterParamActive = false;
    }

    let publishedLevel: filterOptionObj = this.publishedFormControl.value;
    if (publishedLevel.filterOptionKey !== -1) {
      this.publishedParam.filterParamActive = true;
      this.publishedParam.filterParamValue = publishedLevel.filterOptionKey;
      this.publishedParam.filterParamName = 'published';
      this.currentPage = 1;
    } else {
      this.publishedParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }
}
