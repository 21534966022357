<div class="p-5">
  <div class="d-flex totalHolder">
    <div class="d-flex fixedHolder">
      <div class="d-flex titleAndBackHolder">
        <div class="d-flex titleAndBackSection">
          <mat-icon
            class="backArrow cursor-pointer"
            routerLink="/adminDashboard"
            >arrow_back</mat-icon
          >
          <div class="">Notification Mappings</div>
        </div>
        <div class="d-flex displayOptions">
          <div class="cursor-pointer">Hide Options</div>
          <mat-slide-toggle color="primary" [(ngModel)]="hideOptions">
          </mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="!hideOptions" class="d-flex optionsHolder">
        <!-- <div>
          <input
            class="searchInput"
            type="search"
            (keyup)="getNotificationMappingsData(currentPage)"
            placeholder="Search Notification Mappings"
            [formControl]="searchFormControl"
          />
        </div> -->
        <div class="filterHolder d-flex">
          <div *ngFor="let filters of filtersList">
            <mat-form-field class="filterFormField">
              <mat-label class="filterLabel">{{
                filters.filterName
              }}</mat-label>
              <mat-select
                [placeholder]="filters.filterName"
                [formControl]="filters.filterFormControl"
                (selectionChange)="
                  getNotificationMappingsData(currentPage, false)
                "
              >
                <mat-option
                  *ngFor="let filterOption of filters.filterOptions"
                  [value]="filterOption"
                  >{{ filterOption.filterOptionValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="filterFormField">
              <mat-label class="filterLabel">States</mat-label>
              <mat-select
                placeholder="States"
                [formControl]="stateFormControl"
                (selectionChange)="
                  getNotificationMappingsData(currentPage, false)
                "
              >
                <mat-option
                  *ngFor="let stateObj of statesList"
                  [value]="stateObj.state_id"
                >
                  {{ stateObj.name }}</mat-option
                ></mat-select
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div [ngClass]="hideOptions ? 'tableHolder-full' : 'tableHolder'">
        <table
          mat-table
          [dataSource]="notificationsMappingArr"
          class="mat-elevation-z8 w-full"
        >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="tag">
            <th mat-header-cell *matHeaderCellDef>Tags</th>
            <td mat-cell *matCellDef="let element">
              {{ element.tag! }}
            </td>
          </ng-container>

          <ng-container matColumnDef="notification_id">
            <th mat-header-cell *matHeaderCellDef>Notification ID</th>
            <td mat-cell *matCellDef="let element">
              {{ element.notification_id?.notification_id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="db_notification_id">
            <th mat-header-cell *matHeaderCellDef>Table Notification ID</th>
            <td mat-cell *matCellDef="let element">
              {{ element.notification_id?.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="notification_id_2">
            <th mat-header-cell *matHeaderCellDef>Notification ID 2</th>
            <td mat-cell *matCellDef="let element">
              {{ element.notification_id_2?.notification_id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="db_notification_id_2">
            <th mat-header-cell *matHeaderCellDef>Table Notification ID 2</th>
            <td mat-cell *matCellDef="let element">
              {{ element.notification_id_2?.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let element">
              {{ element.state?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="academic_level">
            <th mat-header-cell *matHeaderCellDef>Academic Level ID</th>
            <td mat-cell *matCellDef="let element">
              {{ element.academic_level }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
  <ngb-pagination
    class="d-flex justify-content-center pagination_theme"
    [maxSize]="5"
    [collectionSize]="collectionSize"
    [pageSize]="50"
    [(page)]="currentPage"
    (pageChange)="getNotificationMappingsData(currentPage, false)"
  >
    <ng-template ngbPaginationPrevious>
      <img
        src="/assets/Dashboard/pageArrowPrev.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
    <ng-template ngbPaginationNext>
      <img
        src="/assets/Dashboard/pageArrowNext.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
  </ngb-pagination>
</div>
