import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-holistic-mentoring',
  templateUrl: './holistic-mentoring.component.html',
  styleUrls: ['./holistic-mentoring.component.css'],
})
export class HolisticMentoringComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
