<div class="col-md-12 mt-3" style="height: 117.62px !important">
  <div>
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
      <span class="backTitle"> Colleges/Universities List</span>
    </div>
  </div>
  <mat-form-field appearance="outline" class="searchField">
    <input
      type="text"
      matInput
      placeholder="Search Colleges/Universities"
      [formControl]="myControl"
      (input)="filterSearch()"
      [(ngModel)]="searchText"
    />
    <!-- [formControl]="myControl" -->
    <!-- <mat-autocomplete>
        <mat-option *ngFor="let option of options; index as j;let last=last" [value]="option">{{
          option
        }}</mat-option>
      </mat-autocomplete> -->
    <mat-icon matSuffix class="magBag"> search </mat-icon>
  </mat-form-field>
</div>

<div class="col-md-12">
  <div class="row" *ngIf="!deviceXs">
    <div class="col-md-2" style="height: 77.64px !important">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Stream</mat-label>
        <mat-select
          placeholder="Stream"
          (selectionChange)="filterStream()"
          [(ngModel)]="streamFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <!-- <mat-option [value]="j" *ngFor="let stream of streams; index as j"
            >{{ stream }}
          </mat-option> -->
            <mat-option [value]="0">Agriculture & Related Fields</mat-option>
            <mat-option [value]="1">Armed Forces & Central Police</mat-option>
            <mat-option [value]="2">Arts, Design, Creative</mat-option>
            <mat-option [value]="3">Banking & Finance</mat-option>
            <mat-option [value]="4">Building & Construction</mat-option>
            <mat-option [value]="5">Clothing & Accessories</mat-option>
            <mat-option [value]="6">Education & Training</mat-option>
            <mat-option [value]="7"
              >Hardware, Electronics & Telecommunications</mat-option
            >
            <mat-option [value]="8">Engineering & Manufacturing</mat-option>
            <mat-option [value]="9">Fitness & Leisure</mat-option>
            <mat-option [value]="10">Healthcare & Life Sciences</mat-option>
            <mat-option [value]="11">IT & ITES</mat-option>
            <mat-option [value]="12"
              >Social Sciences & Human Services</mat-option
            >
            <mat-option [value]="13">Law & Order</mat-option>
            <mat-option [value]="14">Management & Marketing</mat-option>
            <mat-option [value]="15">Media & Entertainment</mat-option>
            <mat-option [value]="16"
              >Public Administration & Government</mat-option
            >
            <mat-option [value]="17"
              >Retail, Tourism and Hospitality</mat-option
            >
            <mat-option [value]="18">Self Employment</mat-option>
            <mat-option [value]="19"
              >Transportation, Logistics, Warehousing</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-2">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">State</mat-label>
        <mat-select
          placeholder="State"
          (selectionChange)="filterState()"
          [(ngModel)]="stateFilter"
        >
          <mat-option class="optionStyling" value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let state of states">
            <mat-option
              [value]="state.state_id"
              (click)="getAllDistricts(state.state_id)"
              *ngIf="state.state_id != 99"
              >{{ state.name }}</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-2">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Institute Type</mat-label>
        <mat-select
          placeholder="Institute Type"
          (selectionChange)="filterInstituteType()"
          [(ngModel)]="instituteFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'0'">Government</mat-option>
            <mat-option [value]="'1'">Aided</mat-option>
            <mat-option [value]="'2'">Private</mat-option>
            <!-- <mat-option [value]="'3'">NGO</mat-option> -->
            <mat-option [value]="'4'">Minority</mat-option>
            <mat-option [value]="'5'"
              >Only for People With Disabilities</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <div class="col-md-2 ml-2">
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Institute Type"
          (selectionChange)="filterInstituteType()"
          [(ngModel)]="instituteFilter"
        >
          <mat-option value="all">All </mat-option>
          <mat-option [value]="institute" *ngFor="let institute of instituteType;"
            >{{ institute }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->

    <div class="col-md-2 ml-2">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Course</mat-label>
        <mat-select
          placeholder="Course"
          (selectionChange)="filterCourse()"
          [(ngModel)]="courseFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="j" *ngFor="let course of courses; index as j"
              >{{ course }}
            </mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-2">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Gender</mat-label>
        <mat-select
          placeholder="Gender"
          (selectionChange)="filterGender()"
          [(ngModel)]="genderFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="1">Female Only</mat-option>
            <mat-option [value]="0">Male Only</mat-option>
            <mat-option [value]="3">Co-educational</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2" style="height: 77.64px !important">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">District</mat-label>
        <mat-select
          placeholder="District"
          (selectionChange)="filterDistrict()"
          [(ngModel)]="districtFilter"
        >
          <mat-option class="optionStyling" value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let district of districts">
            <mat-option [value]="district.district_id">{{
              district.name
            }}</mat-option>
          </span>
          <!-- </span> -->
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-2">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Institute Prominence</mat-label>
        <mat-select
          placeholder="Institute Prominence"
          (selectionChange)="filterInstituteProminance()"
          [(ngModel)]="intituteProvinanceFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">State Level</mat-option>
            <mat-option [value]="1">National Level</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-2">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Category</mat-label>
        <mat-select
          placeholder="Category"
          (selectionChange)="filterCategory()"
          [(ngModel)]="CategoryFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Vocational</mat-option>
            <mat-option [value]="1">Professional</mat-option>
            <!-- <mat-option [value]="2">Both</mat-option> -->
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-2">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Hostel Facility</mat-label>
        <mat-select
          placeholder="Hostel Facility"
          (selectionChange)="filterHostelFacility()"
          [(ngModel)]="HostelFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Boys</mat-option>
            <mat-option [value]="1">Girls</mat-option>
            <!-- <mat-option [value]="3">Both</mat-option> -->
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- for mobile app -->

  <div class="row" *ngIf="deviceXs">
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Stream</mat-label>
        <mat-select
          placeholder="Stream"
          (selectionChange)="filterStream()"
          [(ngModel)]="streamFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <!-- <mat-option [value]="j" *ngFor="let stream of streams; index as j"
          >{{ stream }}
        </mat-option> -->
            <mat-option [value]="0">Agriculture & Related Fields</mat-option>
            <mat-option [value]="1">Armed Forces & Central Police</mat-option>
            <mat-option [value]="2">Arts, Design, Creative</mat-option>
            <mat-option [value]="3">Banking & Finance</mat-option>
            <mat-option [value]="4">Building & Construction</mat-option>
            <mat-option [value]="5">Clothing & Accessories</mat-option>
            <mat-option [value]="6">Education & Training</mat-option>
            <mat-option [value]="7"
              >Hardware, Electronics & Telecommunications</mat-option
            >
            <mat-option [value]="8">Engineering & Manufacturing</mat-option>
            <mat-option [value]="9">Fitness & Leisure</mat-option>
            <mat-option [value]="10">Healthcare & Life Sciences</mat-option>
            <mat-option [value]="11">IT & ITES</mat-option>
            <mat-option [value]="12"
              >Social Sciences & Human Services</mat-option
            >
            <mat-option [value]="13">Law & Order</mat-option>
            <mat-option [value]="14">Management & Marketing</mat-option>
            <mat-option [value]="15">Media & Entertainment</mat-option>
            <mat-option [value]="16"
              >Public Administration & Government</mat-option
            >
            <mat-option [value]="17"
              >Retail, Tourism and Hospitality</mat-option
            >
            <mat-option [value]="18">Self Employment</mat-option>
            <mat-option [value]="19"
              >Transportation, Logistics, Warehousing</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">State</mat-label>
        <mat-select
          placeholder="State"
          (selectionChange)="filterState()"
          [(ngModel)]="stateFilter"
        >
          <mat-option class="optionStyling" value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let state of states">
            <mat-option
              [value]="state.state_id"
              (click)="getAllDistricts(state.state_id)"
              *ngIf="state.state_id != 99"
              >{{ state.name }}</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Institute Type</mat-label>
        <mat-select
          placeholder="Institute Type"
          (selectionChange)="filterInstituteType()"
          [(ngModel)]="instituteFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'0'">Government</mat-option>
            <mat-option [value]="'1'">Aided</mat-option>
            <mat-option [value]="'2'">Private</mat-option>
            <!-- <mat-option [value]="'3'">NGO</mat-option> -->
            <mat-option [value]="'4'">Minority</mat-option>
            <mat-option [value]="'5'"
              >Only for People With Disabilities</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">Course</mat-label>
        <mat-select
          placeholder="Course"
          (selectionChange)="filterCourse()"
          [(ngModel)]="courseFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="j" *ngFor="let course of courses; index as j"
              >{{ course }}
            </mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Gender</mat-label>
        <mat-select
          placeholder="Gender"
          (selectionChange)="filterGender()"
          [(ngModel)]="genderFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="1">Female Only</mat-option>
            <mat-option [value]="0">Male Only</mat-option>
            <mat-option [value]="3">Co-educational</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">District</mat-label>
        <mat-select
          placeholder="District"
          (selectionChange)="filterDistrict()"
          [(ngModel)]="districtFilter"
        >
          <mat-option value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let district of districts">
            <mat-option [value]="district.district_id">{{
              district.name
            }}</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Institute Prominence</mat-label>
        <mat-select
          placeholder="Institute Prominence"
          (selectionChange)="filterInstituteProminance()"
          [(ngModel)]="intituteProvinanceFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">State Level</mat-option>
            <mat-option [value]="1">National Level</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">Category</mat-label>
        <mat-select
          placeholder="Category"
          (selectionChange)="filterCategory()"
          [(ngModel)]="CategoryFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Vocational</mat-option>
            <mat-option [value]="1">Professional</mat-option>
            <!-- <mat-option [value]="2">Both</mat-option> -->
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Hostel Facility</mat-label>
        <mat-select
          placeholder="Hostel Facility"
          (selectionChange)="filterHostelFacility()"
          [(ngModel)]="HostelFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Boys</mat-option>
            <mat-option [value]="1">Girls</mat-option>
            <!-- <mat-option [value]="3">Both</mat-option> -->
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="BG mt-1 table-responsive">
    <table class="table">
      <tr class="colorWaraper">
        <th class="coloumnSeperator">
          <div class="col tableHItem">Institution Name</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Course</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Institution Type</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Gender</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Address</div>
        </th>
      </tr>
      <ng-container
        *ngFor="
          let option of filterOptions | filter : searchText;
          index as j;
          let Last = last
        "
      >
        <tr class="tableHItem4" (click)="coToCollegeDetails(option['id'])">
          <td>
            <div
              class="col tableHItem2 py-2"
              style="margin: 0"
              [title]="option['name']"
            >
              {{ option["name"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["courses_offered"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{
                option["institution_type"] != null
                  ? [option["institution_type"]]
                  : "-"
              }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 tableHItem4 py-2" style="margin: 0">
              {{
                option["gender"] != null
                  ? groupGenderValues[option["gender"]]
                  : "-"
              }}
            </div>
          </td>

          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["address"] }}
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
    <ngb-pagination
      *ngIf="!deviceXs"
      class="d-flex justify-content-center pagination_theme"
      [collectionSize]="collectionSize"
      [maxSize]="5"
      [pageSize]="pageSize"
      [(page)]="currentPage"
      (pageChange)="onPageChange(currentPage)"
    >
      <ng-template ngbPaginationPrevious>
        <img src="/assets/Dashboard/pageArrowPrev.svg" />
      </ng-template>
      <ng-template ngbPaginationNext>
        <img src="/assets/Dashboard/pageArrowNext.svg" />
      </ng-template>
    </ngb-pagination>
    <!-- <div class="col-md-12 tableList h-100">
      <div class="row colorWaraper">
        <div class="col  tableHItem">Career Name</div>
        <div class="col coloumnSeperator tableHItem">Required Course</div>
        <div class="col coloumnSeperator tableHItem">Specialization</div>
        <div class="col coloumnSeperator tableHItem">
          Preferred Group in 10+2 /PUC/Intermediate
        </div>
        <div class="col tableHItem coloumnSeperator">Expected Starting Salary(Monthly)</div>
      </div>
  
      <div
        class="row   tableHItem4"
        *ngFor="let option of options; index as j"
        (click)="coToCareerDetails(j)"
      >
        <div class="col tableHItem3  pt-3 m-0"  style="margin: 0;" [title]="option['name']">
          {{ option["name"] }}
        </div>
        <div class="col tableHItem2 coloumnSeperator pt-3" style="margin: 0;">{{ option["required_course"] }}</div>
        <div class="col tableHItem2 coloumnSeperator pt-3" style="margin: 0;">{{ option["specialization"] }}</div>
        <div class="col tableHItem2 coloumnSeperator pt-3" style="margin: 0;">
          {{
            option["prefered_group"] != null
              ? groupValues[option["prefered_group"]]
              : "-"
          }}
        </div>
        <div class="col tableHItem2 coloumnSeperator pt-3"style="margin: 0;" (click)="goTOCareerDetailPage()">
          {{ option["salary_range"] !== null ? option["salary_range"] : "-" }}
        </div>
      </div>
    </div> -->
  </div>

  <ngb-pagination
    *ngIf="deviceXs"
    class="d-flex justify-content-center pagination_theme"
    [collectionSize]="collectionSize"
    [maxSize]="2"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    (pageChange)="onPageChange(currentPage)"
  >
    <ng-template ngbPaginationPrevious>
      <img src="/assets/Dashboard/pageArrowPrev.svg" />
    </ng-template>
    <ng-template ngbPaginationNext>
      <img src="/assets/Dashboard/pageArrowNext.svg" />
    </ng-template>
  </ngb-pagination>
</div>
