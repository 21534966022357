import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../api-calls.service';
import {
  filterListObj,
  filterOptionObj,
  filterParamsObj,
} from '../models.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-admin-scholarships',
  templateUrl: './admin-scholarships.component.html',
  styleUrls: ['./admin-scholarships.component.css'],
})
export class AdminScholarshipsComponent implements OnInit {
  scholarshipsArr: any[] = [];
  statesList: any[] = [];
  displayedColumns: string[] = [
    'id',
    'scholarship_id',
    'scholarship_name',
    'academic_level',
    'email',
    'age_eigibility',
    'gender_eligibility',
    'physically_handicaped',
    'filter_acad_10_and_below',
    'filter_acad_11_12_iti_poly',
    'filter_degree_pg',
    'states',
    'application_process',
    'approximate_application_date',
    'caste_eligibility',
  ];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  hideOptions: boolean = false;

  scholarshipsDisplayList: any[] = [
    { displayName: 'Scholarships', displayValue: 0 },
    { displayName: 'Scholarships Mapping', displayValue: 1 },
  ];

  selectedDisplayView: any = 0;

  searchFormControl: FormControl = new FormControl();
  academicFormControl: FormControl = new FormControl();
  physicallyHandicappedFormControl: FormControl = new FormControl();
  genderFormControl: FormControl = new FormControl();
  stateFormControl: FormControl = new FormControl();

  academicFilterOptions: filterOptionObj[] = [
    { filterOptionKey: 1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: '10th and below' },
    {
      filterOptionKey: 0,
      filterOptionValue: '11th and above (Upto Polytechnic)',
    },
    { filterOptionKey: 0, filterOptionValue: 'Degree / PG' },
  ];
  yesOrNoFilterOptions: filterOptionObj[] = [
    { filterOptionKey: 2, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Yes' },
    { filterOptionKey: 1, filterOptionValue: 'No' },
  ];
  genderFilterOptions: filterOptionObj[] = [
    { filterOptionKey: 4, filterOptionValue: 'No Filter' },
    { filterOptionKey: 3, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Male' },
    { filterOptionKey: 1, filterOptionValue: 'Female' },
    { filterOptionKey: 2, filterOptionValue: 'Other' },
  ];

  filtersList: filterListObj[] = [
    {
      filterName: 'Academic Filter',
      filterOptions: this.academicFilterOptions,
      filterFormControl: this.academicFormControl,
    },
    {
      filterName: 'Physically Handicapped',
      filterOptions: this.yesOrNoFilterOptions,
      filterFormControl: this.physicallyHandicappedFormControl,
    },
    {
      filterName: 'Gender Eligibility',
      filterOptions: this.genderFilterOptions,
      filterFormControl: this.genderFormControl,
    },
  ];

  academicLevelParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: 1,
  };
  physicallyHandicappedParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: 2,
  };
  genderParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: 4,
  };
  searchParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };
  stateParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '-1',
  };

  // search

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.setDefaultControlValues();
  }

  getAllStates() {
    this.apiCaller.getAdminStates().subscribe(
      (res: any) => {
        if (res.length > 0) {
          console.log(res);
          const allStates = {
            id: -1,
            name: 'All',
            state_id: -1,
            published: 0,
          };
          this.statesList.push(allStates);
          res.forEach((stateObj, stateObjIndex) => {
            this.statesList.push(stateObj);
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  setDefaultControlValues() {
    this.getAllStates();
    this.academicFormControl.patchValue(this.academicFilterOptions[0]);
    this.physicallyHandicappedFormControl.patchValue(
      this.yesOrNoFilterOptions[0]
    );
    this.genderFormControl.patchValue(this.genderFilterOptions[0]);
    this.stateFormControl.patchValue(-1);
    this.getScholarshipsData(1, false);
  }

  hideOptionsFunc() {
    this.hideOptions = !this.hideOptions;
  }

  callScholarshipsApi(page: number) {
    this.apiCaller
      .getScholarshipsData(page, [
        this.academicLevelParam,
        this.physicallyHandicappedParam,
        this.genderParam,
        this.searchParam,
        this.stateParam,
      ])
      .subscribe(
        (res: any) => {
          console.log(res);
          this.scholarshipsArr = res.results;
          this.collectionSize = res.count;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getScholarshipsData(page: number, isSearch: boolean) {
    this.makeParams();
    if (isSearch) {
      let searchValue: string = this.searchParam.filterParamValue as string;
      if (searchValue.length >= 3) {
        this.callScholarshipsApi(page);
      }
    } else {
      this.callScholarshipsApi(page);
    }
  }

  clearSearch() {
    this.searchFormControl.reset();
    this.getScholarshipsData(this.currentPage, false);
  }

  makeParams() {
    let searchValue: string = this.searchFormControl.value;
    if (searchValue) {
      if (searchValue.length >= 3) {
        this.searchParam.filterParamActive = true;
        this.searchParam.filterParamValue = searchValue;
        this.searchParam.filterParamName = 'search';
        this.currentPage = 1;
      } else {
        this.searchParam.filterParamActive = false;
      }
    } else {
      this.searchParam.filterParamActive = false;
    }

    let academicLevel: filterOptionObj = this.academicFormControl.value;
    if (academicLevel.filterOptionKey !== 1) {
      this.academicLevelParam.filterParamActive = true;
      this.academicLevelParam.filterParamValue = academicLevel.filterOptionKey;
      let academicLevelStr: string = academicLevel.filterOptionValue;
      if (academicLevelStr === '10th and below') {
        this.academicLevelParam.filterParamName = 'filter_acad_10_and_below';
      } else if (academicLevelStr === '11th and above (Upto Polytechnic)') {
        this.academicLevelParam.filterParamName = 'filter_acad_11_12_iti_poly';
      } else if (academicLevelStr === 'Degree / PG') {
        this.academicLevelParam.filterParamName = 'filter_degree_pg';
      }
      this.currentPage = 1;
    } else {
      this.academicLevelParam.filterParamActive = false;
    }

    let physicallyHandicappedLevel: filterOptionObj =
      this.physicallyHandicappedFormControl.value;
    if (physicallyHandicappedLevel.filterOptionKey !== 2) {
      this.physicallyHandicappedParam.filterParamActive = true;
      this.physicallyHandicappedParam.filterParamValue =
        physicallyHandicappedLevel.filterOptionKey;
      this.physicallyHandicappedParam.filterParamName = 'physically_handicaped';
      this.currentPage = 1;
    } else {
      this.physicallyHandicappedParam.filterParamActive = false;
    }

    let genderLevel: filterOptionObj = this.genderFormControl.value;
    if (genderLevel.filterOptionKey !== 4) {
      this.genderParam.filterParamActive = true;
      this.genderParam.filterParamValue = genderLevel.filterOptionKey;
      this.genderParam.filterParamName = 'gender_eligibility';
      this.currentPage = 1;
    } else {
      this.genderParam.filterParamActive = false;
    }

    let stateLevel: number = this.stateFormControl.value;
    if (stateLevel > -1) {
      this.stateParam.filterParamActive = true;
      this.stateParam.filterParamValue = stateLevel;
      this.stateParam.filterParamName = 'states';
      this.currentPage = 1;
    } else {
      this.stateParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }

  giveStates(states: any[]): string {
    let returnString: string = '';
    let stateList = [];
    states?.forEach((state, stateIndex) => {
      stateList.push(state?.name);
    });
    returnString = stateList.join(' ,');
    return returnString;
  }

  changedControlValue(whichControl: FormControl) {
    console.log(whichControl.value);
  }
}
