import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-resource-mentorvideos',
  templateUrl: './resource-mentorvideos.component.html',
  styleUrls: ['./resource-mentorvideos.component.css'],
})
export class ResourceMentorvideosComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  videoDialogClick(card) {
    console.log(card);
    if (card == 1) {
      document
        .getElementById('video')
        .setAttribute(
          'src',
          'https://www.youtube.com/embed/VnUTa-pAULo?autoplay=1'
        );
      $('#videoTerms').modal('toggle');
    } else if (card == 2) {
      document
        .getElementById('video')
        .setAttribute(
          'src',
          'https://www.youtube.com/embed/nC4knAmugHo?autoplay=1'
        );
      $('#videoTerms').modal('toggle');
    } else if (card == 3) {
      document
        .getElementById('video')
        .setAttribute(
          'src',
          'https://www.youtube.com/embed/IEFkseMOYhY?autoplay=1'
        );
      $('#videoTerms').modal('toggle');
    } else if (card == 4) {
      document
        .getElementById('video')
        .setAttribute(
          'src',
          'https://www.youtube.com/embed/amXQghAov7Q?autoplay=1'
        );
      $('#videoTerms').modal('toggle');
    } else if (card == 5) {
      document
        .getElementById('video')
        .setAttribute(
          'src',
          'https://www.youtube.com/embed/zEfKm8W2j3U?autoplay=1'
        );
      $('#videoTerms').modal('toggle');
    }
  }
  videoDialogCloseClick() {
    document.getElementById('video').setAttribute('src', '');
    $('#videoTerms').modal('toggle');
  }
}
