import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-faqs',
  templateUrl: './student-faqs.component.html',
  styleUrls: ['./student-faqs.component.css'],
})
export class StudentFaqsComponent implements OnInit {
  constructor() {}

  details = [];

  validator = false;

  data = [
    // {
    //   name: 'Who can register as a mentee?',
    //   details:
    //     [`Holistic : Any student who completes his/her 10th grade with 80% of marks \n` ,
    //      `Project Based: Only graduation students with 60 % of marks`,
    //      `Skill Based : Starting from  8th grade, any student who wants to improve their skills in any aspects.`]
    // },
    {
      name: 'What support do i get from VHL?',
      details:
        'You will get all the necessary information and guidance regarding admissions, hostels, scholarships etc. As a part of CSP, you will also get a mentor who will provide necessary support, in the tenure of mentorship. You will also get a scholarship amount called CAREER ADVANCEMENT FUND, and in kind donations like books, laptops, phones, etc, upon availability',
    },
    {
      name: 'Who will be my mentor?',
      details:
        'We follow manual mentor mapping procedures. So, based on the needs & interests of the mentee, the mentor will be assigned.',
    },
    {
      name: 'What if my mentor leaves in the middle of the program duration?',
      details:
        "Our program is designed with certain Terms & conditions which ensures the mentor's commitment throughout the program. In at worst case, if the mentor leaves then a new mentor will be replaced.",
    },
    {
      name: 'If i am not comfortable with my mentor, can he be changed?',
      details:
        'Yes. An assessment test will be taken for both mentor & mentees, if the pair is not comfortable which each other then surely it can be changed.',
    },
    {
      name: 'Do i get som monetary support?',
      details: 'Yes. But only based on your score points ',
    },
  ];

  ngOnInit(): void {}
}
