<div class="row mt-6">
  <div class="col-md-12 d-flex justify-content-center cent">
    <img src="/assets/Auth/welcome.png" style="padding-right: 33px" />
  </div>

  <div class="col-md-12 loginText">Reset Password</div>
  <div class="col-md-12 d-flex justify-content-center mt-4 enterid-fgt">
    Set a new pasword for your account
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-5">
    <form [formGroup]="form" (ngSubmit)="working()">
      <!-- <div class="row"> -->
      <div
        class="col-md-12 d-flex justify-content-center mt-4 xyz"
        *ngIf="!deviceXs"
      >
        <mat-form-field appearance="outline" class="authInput">
          <mat-label>New Password</mat-label>
          <input
            formControlName="password"
            matInput
            placeholder="Password"
            [type]="hide ? 'password' : 'text'"
          />
          <!-- <mat-icon matSuffix class="eyeOfPa">
        <img src="/assets/Auth/eye.svg" />
      </mat-icon> -->
          <i class="material-icons" matSuffix (click)="hide = !hide">
            {{ hide ? "visibility" : "visibility_off" }}
          </i>

          <mat-hint
            *ngIf="
              (form.controls.password.touched || formGroupValidator) &&
              form.controls.password.invalid
            "
            class="red"
            >Please enter valid password</mat-hint
          >
        </mat-form-field>
        <img
          src="assets/Auth/information.svg"
          alt="info"
          style="width: 24px; margin-left: 15px"
          [matTooltip]="'Password must be minimum 6 characters long.'"
        />
      </div>
      <div
        class="col-md-12 d-flex justify-content-center mt-4 xyz"
        *ngIf="!deviceXs"
      >
        <mat-form-field appearance="outline" class="authInput">
          <mat-label>Re-enter New Password</mat-label>
          <input
            formControlName="password2"
            matInput
            placeholder="Password"
            (input)="onBlurRenterPassword()"
            [type]="hide2 ? 'password' : 'text'"
          />
          <!-- <mat-icon matSuffix class="eyeOfPa">
        <img src="/assets/Auth/eye.svg" />
      </mat-icon> -->
          <i class="material-icons" matSuffix (click)="hide2 = !hide2">
            {{ hide2 ? "visibility" : "visibility_off" }}
          </i>

          <mat-hint
            *ngIf="
              ((form.controls.password2.touched || formGroupValidator) &&
                form.controls.password2.invalid) ||
              this.form.controls.password.value !=
                this.form.controls.password2.value
            "
            class="red"
            >Please enter valid password</mat-hint
          >
        </mat-form-field>
        <img
          src="assets/Auth/information.svg"
          alt="info"
          style="width: 24px; margin-left: 15px; visibility: hidden"
        />
        <!-- <div class="row">
      <button type="button" class="btn">
        <mat-icon class="sizepx">
          info
        </mat-icon>
    </button>
    </div> -->
      </div>

      <!-- mobile app -->

      <div
        #tooltip
        class="fakeTooltip"
        style="margin-left: auto"
        *ngIf="tool && deviceXs"
      >
        Password must be minimum 6 characters long.
        <!-- <ol style="list-style: none;"> 
          <li style="color: white;">
            1 Uppercase
          </li>
          <li style="color: white;">
            1 Lowercase
          </li>
          <li style="color: white;">
            1 Number
          </li>
          <li style="color: white;">
            1 Special Character
          </li>
          <li style="color: white;">
            6-16 Characters
          </li>
        </ol> -->
      </div>

      <div
        class="col-md-12 d-flex justify-content-center mt-4 xyz"
        *ngIf="deviceXs"
      >
        <mat-form-field appearance="outline" class="authInput">
          <mat-label>New Password</mat-label>
          <input
            formControlName="password"
            matInput
            placeholder="Password"
            [type]="hide ? 'password' : 'text'"
          />
          <!-- <mat-icon matSuffix class="eyeOfPa">
        <img src="/assets/Auth/eye.svg" />
      </mat-icon> -->
          <i class="material-icons" matSuffix (click)="hide = !hide">
            {{ hide ? "visibility" : "visibility_off" }}
          </i>

          <mat-hint
            *ngIf="
              (form.controls.password.touched || formGroupValidator) &&
              form.controls.password.invalid
            "
            class="red"
            >Please enter valid password</mat-hint
          >
        </mat-form-field>

        <img
          (click)="tooltip()"
          src="assets/Auth/information.svg"
          alt="info"
          style="width: 24px; margin-left: 15px"
          [matTooltip]="
            'Please keep these points in mind while formulating your password. Please include:
          1 Uppercase
          1 Lowercase
          1 Number
          1 Special Character
          6-16 Characters'
          "
        />
      </div>

      <div
        class="col-md-12 d-flex justify-content-center mt-4 xyz"
        *ngIf="deviceXs"
      >
        <mat-form-field appearance="outline" class="authInput">
          <mat-label>Re-enter New Password</mat-label>
          <input
            formControlName="password2"
            matInput
            placeholder="Password"
            (input)="onBlurRenterPassword()"
            [type]="hide2 ? 'password' : 'text'"
          />
          <!-- <mat-icon matSuffix class="eyeOfPa">
        <img src="/assets/Auth/eye.svg" />
      </mat-icon> -->
          <i class="material-icons" matSuffix (click)="hide2 = !hide2">
            {{ hide2 ? "visibility" : "visibility_off" }}
          </i>

          <mat-hint
            *ngIf="
              ((form.controls.password2.touched || formGroupValidator) &&
                form.controls.password2.invalid) ||
              this.form.controls.password.value !=
                this.form.controls.password2.value
            "
            class="red"
            >Please enter valid password</mat-hint
          >
        </mat-form-field>
        <img
          src="assets/Auth/information.svg"
          alt="info"
          style="width: 24px; margin-left: 15px; visibility: hidden"
        />

        <!-- <div class="row">
      <button type="button" class="btn">
        <mat-icon class="sizepx">
          info
        </mat-icon>
    </button>
    </div> -->
      </div>

      <div class="col-md-12 loginText mt-4 abs">
        <button type="submit" class="loginBtn" mat-raised-button>
          Change Password
        </button>
      </div>
    </form>
  </div>
  <div class="col-md-12 loginText2">
    <span class="newMemberTxt" routerLink="/auth">Login</span>
  </div>
</div>
