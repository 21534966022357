import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '../api-calls.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-entranceexam-list',
  templateUrl: './entranceexam-list.component.html',
  styleUrls: ['./entranceexam-list.component.css'],
})
export class EntranceexamListComponent implements OnInit {
  @Input() deviceXs: boolean;
  //Vars
  options: any = [];
  filterOptions: any = [];
  streams: any = [
    '10th Grade and Lower Grades',
    '10+2/Intermediate/PUC',
    'Arts, Design, Creative',
    'Polytechnic Diploma',
    'BE/BTech, BTech (Integrated), B.Tech Lateral Entry, B.Arch',
    'Pharmacy',
    'Agriculture and Related',
    'B.Ed, Diploma in Education',
    'BA, BSc, BCom',
    'BPEd, Diploma in Phys Ed',
    'Law',
    'MA, MSc, MCom',
    'MTech, M.Pharm, M. Arch',
    'MBA, MCA',
    'Other Graduate',
    'Other Post Graduate',
    'Medical and Related',
  ];
  searchText;

  entryCourseFilter = '';
  prefferdGroupFilter = '';
  nationalFilter: any = '';
  salaryFilter = '';

  states: any = [];

  groupValues = {
    0: 'MPC',
    1: 'BiPC',
    2: 'CEC',
    3: 'HEC',
    4: 'Vocational',
    5: 'MEC',
    6: 'MBiPC',
    7: 'MPC or BiPC',
    8: 'MEC or CEC',
    9: 'Any Group',
    10: 'Not Applicable',
  };

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService,
    public route: ActivatedRoute
  ) {}

  //Form Control
  myControl = new FormControl();
  mediaSub: Subscription;
  entryToCourseSub: Subscription;
  stateFilterSub: Subscription;
  queryParam: Subscription;

  stateValues = {
    0: 'National',
    1: 'Andra Pradesh',
    2: 'Telangana',
    3: 'Karnataka',
  };

  applicationValues = {
    0: 'Yes',
    1: 'No',
  };

  getAllJobs() {
    console.log('hello');
    this.apiCallService.getEntranceExams().subscribe(
      (res) => {
        console.log(res);
        this.options = res;
        this.filterCareer();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  coToCareerDetails(jobId) {
    // let jobId = this.options[j]['id']
    // alert(jobId)
    window.scroll(0, 0);
    this.router.navigate(['/dashboard/entrancelistdetail/'], {
      queryParams: { id: jobId },
    });
  }

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    // this.entryToCourseSub = this.globalSservice.getEntryToCourse().subscribe(value => this.entryCourseFilter = value);
    // this.stateFilterSub = this.globalSservice.getStateFilter().subscribe(value => this.nationalFilter = value);
    this.queryParam = this.route.queryParams.subscribe((params: Params) => {
      this.entryCourseFilter = params.entryCourse ? params.entryCourse : '';
      const national = params.national
        ? parseInt(params.national)
        : parseInt('');
      this.nationalFilter = isNaN(national)
        ? params.national === 'all'
          ? 'all'
          : ''
        : national;
    });
    // this.apiCallService.verifyLogin();
    this.getAllJobs();
    this.getAllStates();
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
    // this.entryToCourseSub.unsubscribe();
    // this.stateFilterSub.unsubscribe();
  }

  getAllStates() {
    this.apiCallService.getStates().subscribe(
      (res) => {
        console.log(res);
        this.states = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  filterCareer() {
    // console.log(this.entryCourseFilter)
    // TODO: uncomment the line below if pagination is implemented
    // let filteredCareers: any = [...this.options.results];
    // TODO: uncomment the line below if pagination is not implemented
    let filteredCareers: any = [...this.options];
    // if(this.streamFilter !== '' && this.streamFilter !== 'all')
    // {
    //   console.log("stramd data")
    //   filteredCareers = filteredCareers.filter(option => option.courses_filter === this.streamFilter)
    // }
    // if(this.prefferdGroupFilter !== ''  && this.prefferdGroupFilter !== 'all')
    // {
    //   filteredCareers = filteredCareers.filter(option => option.prefered_group === this.prefferdGroupFilter)
    // }
    this.globalSservice.setEntryCourse(this.entryCourseFilter);
    this.globalSservice.setStateFilter(this.nationalFilter);
    if (this.nationalFilter !== '' || this.entryCourseFilter !== '') {
      this.router.navigate(['/dashboard/entrancelist'], {
        queryParams: {
          entryCourse: this.entryCourseFilter,
          national: this.nationalFilter,
        },
      });
    }
    if (this.entryCourseFilter !== '' && this.entryCourseFilter !== 'all') {
      if (this.entryCourseFilter === '0') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 0
        );
      }
      if (this.entryCourseFilter === '1') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 1
        );
      }
      if (this.entryCourseFilter === '2') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 2
        );
      }
      if (this.entryCourseFilter === '3') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 3
        );
      }
      if (this.entryCourseFilter === '4') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 4
        );
      }
      if (this.entryCourseFilter === 'Pharmacy') {
        filteredCareers = filteredCareers.filter(
          (option) => option.pharma_filter === 0
        );
      }
      if (this.entryCourseFilter === 'Agriculture') {
        filteredCareers = filteredCareers.filter(
          (option) => option.agri_filter === 0
        );
      }
      if (this.entryCourseFilter === '5') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 5
        );
      }
      if (this.entryCourseFilter === '6') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 6
        );
      }
      if (this.entryCourseFilter === '7') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 7
        );
      }
      if (this.entryCourseFilter === '8') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 8
        );
      }
      if (this.entryCourseFilter === '9') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 9
        );
      }
      if (this.entryCourseFilter === '10') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 10
        );
      }
      if (this.entryCourseFilter === '11') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 11
        );
      }
      if (this.entryCourseFilter === '12') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 12
        );
      }
      if (this.entryCourseFilter === '13') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 13
        );
      }

      if (this.entryCourseFilter === '14') {
        filteredCareers = filteredCareers.filter(
          (option) => option.courses_filter === 14
        );
      }
    }

    if (this.nationalFilter !== '' && this.nationalFilter !== 'all') {
      filteredCareers = filteredCareers.filter(
        (option) => option.state.state_id === this.nationalFilter
      );
    }
    // if(this.salaryFilter !== ''  && this.salaryFilter !== 'all')
    // {
    //   filteredCareers = filteredCareers.filter(option => option.salary_range === this.salaryFilter)
    // }
    this.filterOptions = filteredCareers;
    console.log(filteredCareers);
  }
}
