<div class="row p-0 m-0">
  <div class="col-md-12">
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
      <span class="backTitle"> Career Mithra</span>
    </div>
  </div>
</div>
<!-- <div class="row"> -->

<div class="col-12 px-4">
  <div
    class="row p-0 m-0"
    style="
      background-color: white;
      padding-top: 3rem !important;
      padding-bottom: 2rem !important;
    "
  >
    <!-- <div class="row d-flex pb-5"> -->
    <div class="col-md-3 imageStyling">
      <img src="/assets/careerMithra/cm.png" class="responsive" />
    </div>
    <div class="col-md-8 boxClass">
      <div class="col-12 careerClass">Be A Career Mitra!</div>
      <div class="col-12 careerMatter pt-2 pb-3">
        <div>
          Are you someone who interacts frequently with many students from
          underprivileged backgrounds? Do you want to guide some student you
          know who is from an underprivileged background? You may be a teacher
          in a school, an NGO worker or a government employee or a citizen who
          wants to make a difference in a students’ life. If so you may be
          getting lots of questions from students on career paths, colleges,
          entrance exams etc. You may be keen to help out students in these
          matters, but you may not know the answers to students’ questions.
        </div>
        <div class="pt-4">
          If so, CAP is the right place for you! As you can see, CAP stores a
          lot of useful information that you can gather to answer students’
          queries.
        </div>
        <div class="pt-4">
          Nirmaan would also like to encourage and empower you in making a
          difference in students’ lives. So we have started a programme called
          “Career Mitra”. Through this programme we would like to be in touch
          with people like you and facilitate your work.
        </div>
        <div class="pt-4">
          You can click on the button below to sign up as a Career Mitra. It
          does not cost anything to be a Career Mitra. It does not have any
          specific time commitments either.
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>

<div class="col-md-12 px-4 pt-4 pb-5">
  <div class="boxStyling">
    <div class="row p-0 m-0" style="background-color: white">
      <div class="col-12 careerClass pt-4">
        What are the benefits of being a Career Mitra?
      </div>
      <div class="col-12 careersubClass pt-3 pb-3">
        Request a Career Workshop:
        <span class="careerMatter pl-1">
          Nirmaan Organisation (which runs CAP) also conducts career workshops
          in schools and colleges. These are for students from 9th onwards till
          12th or Inter 2nd Year. You can write to us below to request us to
          conduct such a career workshop.While we cannot guarantee this can be
          done, we will do our best.
        </span>
      </div>

      <div class="col-12 careersubClass pt-2 pb-3">
        Using BestCareer:
        <span class="careerMatter pl-1">
          One of the good features of CAP is “Best Career”. Best Career is a
          career psychometric. It is a test that students take and based on the
          results they will get a nice report about their interests, abilities
          and personality and career recommendations that are suited to them. As
          a Career Mitra, you can get a ‘coupon code’ that you can enter after
          taking the Best Career test, so that it is free. You can use this
          coupon code for your students. Please send us a message using the link
          below to make a request for this.
        </span>
      </div>

      <div class="col-12 careersubClass pt-2 pb-3">
        Request financial or other support for a student:
        <span class="careerMatter pl-1">
          Career Mitras can write to us requesting small financial support that
          a deserving student may need. For eg. to buy an advanced textbook,
          money to travel to a student conference etc. Nirmaan would get in
          touch with you to authenticate the request and then consider it for
          fulfillment. We cannot guarantee that all deserving requests will be
          fulfilled, but we will do our best.
        </span>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<div style="padding-top: 2rem" *ngIf="!deviceXs">
  <div class="footer">
    <div class="col-12 d-flex" style="padding: 1.7rem">
      <div class="col-5" style="text-align: end; cursor: pointer">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeK5ulqg3INGEQD1G2SzJ813FqN3DKMJ6Vr8mevL7mCN3C9Qg/viewform"
          target="_blank"
        >
          <img src="/assets/careerMithra/signup.svg" />
        </a>
      </div>
      <div class="col-6">
        <img
          (click)="openDialogClick()"
          style="cursor: pointer"
          src="/assets/careerMithra/write-to-us.svg"
        />
      </div>
    </div>
  </div>
</div>

<div style="padding-top: 2rem" *ngIf="deviceXs">
  <div class="mobilefooter">
    <div class="col-12 d-flex" style="padding: 1.3rem">
      <div class="col-6" style="text-align: end; cursor: pointer">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeK5ulqg3INGEQD1G2SzJ813FqN3DKMJ6Vr8mevL7mCN3C9Qg/viewform"
          target="_blank"
        >
          <img src="/assets/careerMithra/signup.svg" style="width: 130%" />
        </a>
      </div>
      <div class="col-6">
        <img
          (click)="openDialogClick()"
          style="cursor: pointer; width: 73%; margin-left: 2rem"
          src="/assets/careerMithra/write-to-us.svg"
        />
      </div>
    </div>
  </div>
</div>

<div
  class="modal pr-0"
  id="terms"
  data-keyboard="false"
  data-backdrop="static"
  style="overflow: hidden"
>
  <div class="modal-dialog dialogStyling">
    <div
      class="modal-content dialogALignStyling mb-3"
      style="border-radius: 8px"
    >
      <div
        class="modal-header"
        style="
          background-color: #f2fdff;
          border-bottom: none;
          padding-bottom: 7px;
        "
      >
        <div class="col-md-8 d-flex" style="align-self: center">
          <span class="modal-title writeStyling">Write to us</span>
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="mx-2 pb-3">
        <div class="modal-body dialogBodyStyling mt-2">
          Please select the topic which you want write to us
        </div>
        <!-- <div class="row"> -->
        <div
          class="col-12 col-md-9 topicInput d-flex justify-content-start mt-2"
        >
          <mat-form-field appearance="outline" class="topicInput align-box">
            <mat-label class="forAlignments font15">Select topic</mat-label>
            <mat-select
              style="font-family: Montserrat"
              (selectionChange)="filterOption()"
              [(ngModel)]="optionGroupFilter"
              placeholder="Select topic"
            >
              <span class="optionStyling">
                <mat-option value="0">Request for a Career Workshop</mat-option>
                <mat-option value="1"
                  >Request for Best Career Coupon Code</mat-option
                >
                <mat-option value="2"
                  >Request for Financial Support for a needy student</mat-option
                >
                <mat-option value="3">Other</mat-option>
              </span>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          class="col-12 col-md-9 topicInput d-flex justify-content-start mt-2"
        >
          <mat-form-field appearance="outline" class="topicInput align-box">
            <mat-label class="font15">Email-ID</mat-label>
            <input
              matInput
              style="font-family: Montserrat"
              placeholder="Enter your Email"
              type="email"
              [(ngModel)]="emailField"
              email
            />
          </mat-form-field>
        </div>

        <div
          class="col-12 col-md-9 topicInput d-flex justify-content-start mt-2"
        >
          <mat-form-field appearance="outline" class="topicInput align-box">
            <mat-label class="font15">Mobile Number</mat-label>
            <input
              matInput
              type="tel"
              style="font-family: Montserrat"
              placeholder="Enter your Mobile Number"
              pattern="[0-9]{10}"
              [(ngModel)]="mobileNumberField"
            />
          </mat-form-field>
        </div>

        <div
          class="col-12 col-md-12 composeInput d-flex justify-content-start mt-2"
        >
          <mat-form-field appearance="outline" class="composeInput">
            <mat-label class="font15">Compose</mat-label>
            <textarea
              class="scrlBar"
              matInput
              style="font-family: Montserrat"
              placeholder="Compose your message"
              type="textarea"
              [(ngModel)]="bodyField"
              (ngModelChange)="filterOption()"
              text
              rows="5"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="col-12" *ngIf="validator == false">
          <button
            (click)="continueDialogClick()"
            class="cnBtn"
            mat-raised-button
            type="submit"
          >
            Continue
          </button>
        </div>
        <div class="col-12" *ngIf="validator == true">
          <button
            class="cnBtn"
            [disabled]="true"
            mat-raised-button
            type="submit"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
