import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';

@Component({
  selector: 'app-steps-to-follow',
  templateUrl: './steps-to-follow.component.html',
  styleUrls: ['./steps-to-follow.component.css'],
})
export class StepsToFollowComponent implements OnInit {
  constructor(private router: Router, private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.verifyLogin();
  }

  verifyLogin() {
    this.apiCaller.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }
}
