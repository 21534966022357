import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '../api-calls.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Districts } from './districts';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-college-list',
  templateUrl: './college-list.component.html',
  styleUrls: ['./college-list.component.css'],
})
export class CollegeListComponent implements OnInit {
  @Input() deviceXs: boolean;
  //Vars
  options: any = [];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  currentPageURL: string = null;
  currentFilterURL: string = null;
  filterOptions: any = [];
  // streams:any = [
  //   'Agriculture & Related Fields',
  //   'Armed Forces & Central Police',
  //   'Arts, Design, Creative',
  //   'Banking & Finance',
  //   'Building & Construction',
  //   'Clothing & Accessories',
  //   'Education & Training',
  //   'Hardware, Electronics & Telecommunications',
  //   'Engineering & Manufacturing',
  //   'Fitness & Leisure',
  //   'Healthcare & Life Sciences',
  //   'IT & ITES',
  //   'Social Sciences & Human Services',
  //   'Law & Order',
  //   'Management & Marketing',
  //   'Media & Entertainment',
  //   'Public Administration & Government',
  //   'Retail, Tourism and Hospitality',
  //   'Self Employment',
  //   'Transportation, Logistics, Warehousing'
  // ]

  courses: any = [
    'Polytechnic and other Diploma',
    'BE/BTech, BTech (Integrated), B.Tech Lateral Entry, B.Arch',
    'Pharmacy',
    'Agriculture and Related',
    'B.Ed, Diploma in Education',
    'BA, BSc, BCom',
    'BPEd, Diploma in Phys Ed',
    'Law',
    'MA, MSc, MCom',
    'MTech, M.Pharm, M. Arch',
    'MBA, MCA',
    'Other Graduate',
    'Other Post Graduate',
    'Medical and Related',
    'CA and Related',
  ];

  states: any = [];
  districts: any = [];

  id = '';
  // instituteType: any = [
  //   'Government',
  //   'Aided',
  //   'Private',
  //   'Minority',
  //   'Only for People With Disabilities',
  // ]

  searchText;

  streamFilter: any = '';
  stateFilter: any = '';
  instituteFilter = '';
  courseFilter: any = '';
  genderFilter: any = '';
  districtFilter: any = '';
  intituteProvinanceFilter: any = '';
  CategoryFilter: any = '';
  HostelFilter: any = '';
  loadedDistricts: any = Districts['Andhra_Pradesh']
    .concat(Districts['Telangana'])
    .concat(Districts['Karnataka'])
    .sort();

  // groupInstituteValues = {
  //   0: "Government",
  //   1: "Private",
  //   2: "NGO",
  //   3: "Aided",
  // }

  groupGenderValues = {
    1: 'Female Only',
    0: 'Male Only',
    3: 'Mixed',
  };

  filter: serverFilter[] = [];
  //Form Control
  myControl = new FormControl();
  mediaSub: Subscription;
  streamFilterSub: Subscription;
  stateFilterSub: Subscription;
  instituteTypeFilterSub: Subscription;
  courseFilterSub: Subscription;
  genderFilterSub: Subscription;
  districtFilterSub: Subscription;
  instituteProminenceFilterSub: Subscription;
  categoryFilterSub: Subscription;
  hostelFacilityFilterSub: Subscription;
  queryParam: Subscription;
  isBack: boolean = false;

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService,
    public route: ActivatedRoute
  ) {}

  getAllJobs() {
    console.log('hello');
    this.apiCallService.getColleges().subscribe(
      (res) => {
        console.log(res);
        this.options = res['results'];
        this.collectionSize = res['count'];
        this.filterOptions = [...this.options];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  coToCollegeDetails(jobId) {
    // let jobId = this.options[j]['id']
    // alert(jobId)
    window.scroll(0, 0);
    this.router.navigate(['/dashboard/collegeDetail/'], {
      queryParams: { id: jobId },
    });
  }

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );

    // this.apiCallService.verifyLogin();

    // this.getAllJobs()
    this.queryParam = this.route.queryParams.subscribe((params: Params) => {
      // this.streamFilter = params.stream ? params.stream : ""

      const stream = params.stream ? parseInt(params.stream) : parseInt('');
      this.streamFilter = isNaN(stream)
        ? params.stream === 'all'
          ? 'all'
          : ''
        : stream;

      const state = params.state ? parseInt(params.state) : parseInt('');
      this.stateFilter = isNaN(state)
        ? params.state === 'all'
          ? 'all'
          : ''
        : state;

      this.instituteFilter = params.instituteType ? params.instituteType : '';

      // this.courseFilter = params.course ? params.course : ""

      const course = params.course ? parseInt(params.course) : parseInt('');
      this.courseFilter = isNaN(course)
        ? params.course === 'all'
          ? 'all'
          : ''
        : course;

      const gender = params.gender ? parseInt(params.gender) : parseInt('');
      this.genderFilter = isNaN(gender)
        ? params.gender === 'all'
          ? 'all'
          : ''
        : gender;

      // this.districtFilter = params.districti ? params.districti : ""

      const institueProminence = params.institueProminence
        ? parseInt(params.institueProminence)
        : parseInt('');
      this.intituteProvinanceFilter = isNaN(institueProminence)
        ? params.institueProminence === 'all'
          ? 'all'
          : ''
        : institueProminence;

      const category = params.category
        ? parseInt(params.category)
        : parseInt('');
      this.CategoryFilter = isNaN(category)
        ? params.category === 'all'
          ? 'all'
          : ''
        : category;

      const hostelFacility = params.hostelFacility
        ? parseInt(params.hostelFacility)
        : parseInt('');
      this.HostelFilter = isNaN(hostelFacility)
        ? params.hostelFacility === 'all'
          ? 'all'
          : ''
        : hostelFacility;

      const district = params.district
        ? parseInt(params.district)
        : parseInt('');
      this.districtFilter = isNaN(district)
        ? params.district === 'all'
          ? 'all'
          : ''
        : district;

      // if(params.district){
      //   this.isBack = true
      // }

      // this.stateFilter = localStorage.getItem('stateValue')
    });

    var isParam = false;

    if (this.streamFilter !== '') {
      this.filterStream(true);
      isParam = true;
    }

    if (this.districtFilter !== '') {
      this.isBack = true;
    }

    if (this.stateFilter !== '') {
      // this.isBack = true
      this.filterState(true);
      isParam = true;
    }

    if (this.instituteFilter !== '') {
      this.filterInstituteType(true);
      isParam = true;
    }

    if (this.courseFilter !== '') {
      this.filterCourse(true);
      isParam = true;
    }

    if (this.genderFilter !== '') {
      this.filterGender(true);
      isParam = true;
    }

    if (this.districtFilter !== '') {
      this.filterDistrict(true);
      isParam = true;
    }

    if (this.intituteProvinanceFilter !== '') {
      this.filterInstituteProminance(true);
      isParam = true;
    }

    if (this.CategoryFilter !== '') {
      this.filterCategory(true);
      isParam = true;
    }

    if (this.HostelFilter !== '') {
      this.filterHostelFacility(true);
      isParam = true;
    }

    if (isParam) {
      this.filterData();
    } else {
      this.getAllJobs();
    }

    this.getAllStates();
    this.getAllDistricts(this.id);
  }

  getAllStates() {
    this.apiCallService.getStates().subscribe(
      (res) => {
        console.log(res);
        this.states = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllDistricts(id) {
    this.apiCallService.getDistricts(id).subscribe(
      (res) => {
        console.log(res);
        this.districts = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  filterSearch() {
    this.addOrUpdateFilter({ name: 'search', value: this.searchText });
    // console.log("searchfilter");
    this.filterData();
  }
  filterStream(dontFilter?: boolean) {
    this.globalSservice.setStreamCollegeFilter(this.streamFilter);
    this.globalSservice.setStateCollegeFilter(this.stateFilter);
    this.globalSservice.setInstituteTypeCollegeFilter(this.instituteFilter);
    this.globalSservice.setCourseCollegeFilter(this.courseFilter);
    this.globalSservice.setGenderCollegeFilter(this.genderFilter);
    this.globalSservice.setDistrictCollegeFilter(this.districtFilter);
    this.globalSservice.setInstituteProminenceCollegeFilter(
      this.intituteProvinanceFilter
    );
    this.globalSservice.setCategoryCollegeFilter(this.CategoryFilter);
    this.globalSservice.setHostelFacilityCollegeFilter(this.HostelFilter);
    if (
      this.streamFilter !== '' ||
      this.stateFilter !== '' ||
      this.instituteFilter !== '' ||
      this.courseFilter !== '' ||
      this.genderFilter !== '' ||
      this.districtFilter !== '' ||
      this.intituteProvinanceFilter !== '' ||
      this.CategoryFilter !== '' ||
      this.HostelFilter !== ''
    ) {
      this.router.navigate(['/dashboard/collegelist'], {
        queryParams: {
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter,
        },
      });
    }
    this.addOrUpdateFilter({
      name: 'filter_stream_1',
      value: this.streamFilter,
    });
    if (!dontFilter) {
      this.filterData();
    }
  }
  filterState(dontFilter?: boolean) {
    if (!this.isBack) {
      this.districtFilter = '';
      this.addOrUpdateFilter({
        name: 'district_id',
        value: this.districtFilter,
      });
    } else {
      this.isBack = false;
    }
    this.globalSservice.setStreamCollegeFilter(this.streamFilter);
    this.globalSservice.setStateCollegeFilter(this.stateFilter);
    this.globalSservice.setInstituteTypeCollegeFilter(this.instituteFilter);
    this.globalSservice.setCourseCollegeFilter(this.courseFilter);
    this.globalSservice.setGenderCollegeFilter(this.genderFilter);
    this.globalSservice.setDistrictCollegeFilter(this.districtFilter);
    this.globalSservice.setInstituteProminenceCollegeFilter(
      this.intituteProvinanceFilter
    );
    this.globalSservice.setCategoryCollegeFilter(this.CategoryFilter);
    this.globalSservice.setHostelFacilityCollegeFilter(this.HostelFilter);
    if (
      this.streamFilter !== '' ||
      this.stateFilter !== '' ||
      this.instituteFilter !== '' ||
      this.courseFilter !== '' ||
      this.genderFilter !== '' ||
      this.districtFilter !== '' ||
      this.intituteProvinanceFilter !== '' ||
      this.CategoryFilter !== '' ||
      this.HostelFilter !== ''
    ) {
      this.router.navigate(['/dashboard/collegelist'], {
        queryParams: {
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter,
        },
      });
    }
    this.addOrUpdateFilter({ name: 'state', value: this.stateFilter });
    // if(this.stateFilter == "1")
    // {
    //   this.loadedDistricts = Districts["Andhra_Pradesh"];
    // }
    // if(this.stateFilter == "2")
    // {
    //   this.loadedDistricts = Districts["Telangana"];
    // }
    // if(this.stateFilter == "3")
    // {
    //   this.loadedDistricts = Districts["Karnataka"];
    // }
    if (!dontFilter) {
      this.filterData();
    }
  }
  filterInstituteType(dontFilter?: boolean) {
    this.globalSservice.setStreamCollegeFilter(this.streamFilter);
    this.globalSservice.setStateCollegeFilter(this.stateFilter);
    this.globalSservice.setInstituteTypeCollegeFilter(this.instituteFilter);
    this.globalSservice.setCourseCollegeFilter(this.courseFilter);
    this.globalSservice.setGenderCollegeFilter(this.genderFilter);
    this.globalSservice.setDistrictCollegeFilter(this.districtFilter);
    this.globalSservice.setInstituteProminenceCollegeFilter(
      this.intituteProvinanceFilter
    );
    this.globalSservice.setCategoryCollegeFilter(this.CategoryFilter);
    this.globalSservice.setHostelFacilityCollegeFilter(this.HostelFilter);
    if (
      this.streamFilter !== '' ||
      this.stateFilter !== '' ||
      this.instituteFilter !== '' ||
      this.courseFilter !== '' ||
      this.genderFilter !== '' ||
      this.districtFilter !== '' ||
      this.intituteProvinanceFilter !== '' ||
      this.CategoryFilter !== '' ||
      this.HostelFilter !== ''
    ) {
      this.router.navigate(['/dashboard/collegelist'], {
        queryParams: {
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter,
        },
      });
    }

    // this.addOrUpdateFilter({name: "institution_type",value: this.instituteFilter});
    // if(this.instituteFilter !== ''  && this.instituteFilter !== 'all')

    if (this.instituteFilter == '0') {
      this.addOrUpdateFilter({ name: 'filter_management_1', value: 0 });
    } else if (this.instituteFilter == '1') {
      this.addOrUpdateFilter({ name: 'filter_management_1', value: 3 });
    } else if (this.instituteFilter == '2') {
      this.addOrUpdateFilter({ name: 'filter_management_1', value: 1 });
    } else if (this.instituteFilter == '4') {
      this.addOrUpdateFilter({ name: 'filter_management_2', value: 0 });
    } else if (this.instituteFilter == '5') {
      this.addOrUpdateFilter({ name: 'filter_management_2', value: 1 });
      console.log('disablityyyy');
    } else if (this.instituteFilter == 'all') {
      this.addOrUpdateFilter({ name: 'filter_management_1', value: 'all' });
    }

    if (!dontFilter) {
      this.filterData();
    }
  }
  filterCourse(dontFilter?: boolean) {
    this.globalSservice.setStreamCollegeFilter(this.streamFilter);
    this.globalSservice.setStateCollegeFilter(this.stateFilter);
    this.globalSservice.setInstituteTypeCollegeFilter(this.instituteFilter);
    this.globalSservice.setCourseCollegeFilter(this.courseFilter);
    this.globalSservice.setGenderCollegeFilter(this.genderFilter);
    this.globalSservice.setDistrictCollegeFilter(this.districtFilter);
    this.globalSservice.setInstituteProminenceCollegeFilter(
      this.intituteProvinanceFilter
    );
    this.globalSservice.setCategoryCollegeFilter(this.CategoryFilter);
    this.globalSservice.setHostelFacilityCollegeFilter(this.HostelFilter);
    if (
      this.streamFilter !== '' ||
      this.stateFilter !== '' ||
      this.instituteFilter !== '' ||
      this.courseFilter !== '' ||
      this.genderFilter !== '' ||
      this.districtFilter !== '' ||
      this.intituteProvinanceFilter !== '' ||
      this.CategoryFilter !== '' ||
      this.HostelFilter !== ''
    ) {
      this.router.navigate(['/dashboard/collegelist'], {
        queryParams: {
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter,
        },
      });
    }

    this.addOrUpdateFilter({
      name: 'filter_course_1',
      value: this.courseFilter,
    });
    if (!dontFilter) {
      this.filterData();
    }
  }
  filterGender(dontFilter?: boolean) {
    this.globalSservice.setStreamCollegeFilter(this.streamFilter);
    this.globalSservice.setStateCollegeFilter(this.stateFilter);
    this.globalSservice.setInstituteTypeCollegeFilter(this.instituteFilter);
    this.globalSservice.setCourseCollegeFilter(this.courseFilter);
    this.globalSservice.setGenderCollegeFilter(this.genderFilter);
    this.globalSservice.setDistrictCollegeFilter(this.districtFilter);
    this.globalSservice.setInstituteProminenceCollegeFilter(
      this.intituteProvinanceFilter
    );
    this.globalSservice.setCategoryCollegeFilter(this.CategoryFilter);
    this.globalSservice.setHostelFacilityCollegeFilter(this.HostelFilter);
    if (
      this.streamFilter !== '' ||
      this.stateFilter !== '' ||
      this.instituteFilter !== '' ||
      this.courseFilter !== '' ||
      this.genderFilter !== '' ||
      this.districtFilter !== '' ||
      this.intituteProvinanceFilter !== '' ||
      this.CategoryFilter !== '' ||
      this.HostelFilter !== ''
    ) {
      this.router.navigate(['/dashboard/collegelist'], {
        queryParams: {
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter,
        },
      });
    }

    this.addOrUpdateFilter({ name: 'gender', value: this.genderFilter });
    if (!dontFilter) {
      this.filterData();
    }
  }
  filterDistrict(dontFilter?: boolean) {
    this.globalSservice.setStreamCollegeFilter(this.streamFilter);
    this.globalSservice.setStateCollegeFilter(this.stateFilter);
    this.globalSservice.setInstituteTypeCollegeFilter(this.instituteFilter);
    this.globalSservice.setCourseCollegeFilter(this.courseFilter);
    this.globalSservice.setGenderCollegeFilter(this.genderFilter);
    this.globalSservice.setDistrictCollegeFilter(this.districtFilter);
    this.globalSservice.setInstituteProminenceCollegeFilter(
      this.intituteProvinanceFilter
    );
    this.globalSservice.setCategoryCollegeFilter(this.CategoryFilter);
    this.globalSservice.setHostelFacilityCollegeFilter(this.HostelFilter);
    if (
      this.streamFilter !== '' ||
      this.stateFilter !== '' ||
      this.instituteFilter !== '' ||
      this.courseFilter !== '' ||
      this.genderFilter !== '' ||
      this.districtFilter !== '' ||
      this.intituteProvinanceFilter !== '' ||
      this.CategoryFilter !== '' ||
      this.HostelFilter !== ''
    ) {
      this.router.navigate(['/dashboard/collegelist'], {
        queryParams: {
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter,
        },
      });
    }

    this.addOrUpdateFilter({ name: 'district_id', value: this.districtFilter });
    if (!dontFilter) {
      this.filterData();
    }
  }
  filterInstituteProminance(dontFilter?: boolean) {
    this.globalSservice.setStreamCollegeFilter(this.streamFilter);
    this.globalSservice.setStateCollegeFilter(this.stateFilter);
    this.globalSservice.setInstituteTypeCollegeFilter(this.instituteFilter);
    this.globalSservice.setCourseCollegeFilter(this.courseFilter);
    this.globalSservice.setGenderCollegeFilter(this.genderFilter);
    this.globalSservice.setDistrictCollegeFilter(this.districtFilter);
    this.globalSservice.setInstituteProminenceCollegeFilter(
      this.intituteProvinanceFilter
    );
    this.globalSservice.setCategoryCollegeFilter(this.CategoryFilter);
    this.globalSservice.setHostelFacilityCollegeFilter(this.HostelFilter);
    if (
      this.streamFilter !== '' ||
      this.stateFilter !== '' ||
      this.instituteFilter !== '' ||
      this.courseFilter !== '' ||
      this.genderFilter !== '' ||
      this.districtFilter !== '' ||
      this.intituteProvinanceFilter !== '' ||
      this.CategoryFilter !== '' ||
      this.HostelFilter !== ''
    ) {
      this.router.navigate(['/dashboard/collegelist'], {
        queryParams: {
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter,
        },
      });
    }

    this.addOrUpdateFilter({
      name: 'institute_prominence',
      value: this.intituteProvinanceFilter,
    });
    if (!dontFilter) {
      this.filterData();
    }
  }
  filterCategory(dontFilter?: boolean) {
    this.globalSservice.setStreamCollegeFilter(this.streamFilter);
    this.globalSservice.setStateCollegeFilter(this.stateFilter);
    this.globalSservice.setInstituteTypeCollegeFilter(this.instituteFilter);
    this.globalSservice.setCourseCollegeFilter(this.courseFilter);
    this.globalSservice.setGenderCollegeFilter(this.genderFilter);
    this.globalSservice.setDistrictCollegeFilter(this.districtFilter);
    this.globalSservice.setInstituteProminenceCollegeFilter(
      this.intituteProvinanceFilter
    );
    this.globalSservice.setCategoryCollegeFilter(this.CategoryFilter);
    this.globalSservice.setHostelFacilityCollegeFilter(this.HostelFilter);
    if (
      this.streamFilter !== '' ||
      this.stateFilter !== '' ||
      this.instituteFilter !== '' ||
      this.courseFilter !== '' ||
      this.genderFilter !== '' ||
      this.districtFilter !== '' ||
      this.intituteProvinanceFilter !== '' ||
      this.CategoryFilter !== '' ||
      this.HostelFilter !== ''
    ) {
      this.router.navigate(['/dashboard/collegelist'], {
        queryParams: {
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter,
        },
      });
    }

    this.addOrUpdateFilter({ name: 'category', value: this.CategoryFilter });
    if (!dontFilter) {
      this.filterData();
    }
  }
  filterHostelFacility(dontFilter?: boolean) {
    this.globalSservice.setStreamCollegeFilter(this.streamFilter);
    this.globalSservice.setStateCollegeFilter(this.stateFilter);
    this.globalSservice.setInstituteTypeCollegeFilter(this.instituteFilter);
    this.globalSservice.setCourseCollegeFilter(this.courseFilter);
    this.globalSservice.setGenderCollegeFilter(this.genderFilter);
    this.globalSservice.setDistrictCollegeFilter(this.districtFilter);
    this.globalSservice.setInstituteProminenceCollegeFilter(
      this.intituteProvinanceFilter
    );
    this.globalSservice.setCategoryCollegeFilter(this.CategoryFilter);
    this.globalSservice.setHostelFacilityCollegeFilter(this.HostelFilter);
    if (
      this.streamFilter !== '' ||
      this.stateFilter !== '' ||
      this.instituteFilter !== '' ||
      this.courseFilter !== '' ||
      this.genderFilter !== '' ||
      this.districtFilter !== '' ||
      this.intituteProvinanceFilter !== '' ||
      this.CategoryFilter !== '' ||
      this.HostelFilter !== ''
    ) {
      this.router.navigate(['/dashboard/collegelist'], {
        queryParams: {
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter,
        },
      });
    }

    this.addOrUpdateFilter({
      name: 'hostel_facility',
      value: this.HostelFilter,
    });
    if (!dontFilter) {
      this.filterData();
    }
  }

  addOrUpdateFilter(data: serverFilter) {
    let found: boolean = false;
    this.filter.some((i, x) => {
      if (i.name === data.name) {
        this.filter[x].value = data.value;

        found = true;
        return true;
      } else {
        if (
          i.name === 'filter_management_1' ||
          i.name === 'filter_management_2'
        ) {
          if (
            data.name === 'filter_management_1' ||
            data.name === 'filter_management_2'
          ) {
            this.filter[x].name = data.name;
            this.filter[x].value = data.value;
            found = true;
            return true;
          }
        }
      }
    });

    if (!found) {
      this.filter.push(data);
    }
  }

  filterData() {
    let filters: string = '';
    let filterCS = '';
    let tempcourse = [];
    tempcourse = this.filter.filter((book) => book.name === 'filter_course_1');
    let tempstream = [];
    let flagAll = true;

    tempstream = this.filter.filter((book) => book.name === 'filter_stream_1');

    let tempHostel = [];
    tempHostel = this.filter.filter((book) => book.name === 'hostel_facility');

    if (
      tempcourse.length > 0 ||
      tempstream.length > 0 ||
      tempHostel.length > 0
    ) {
      filterCS = 'course_stream_hostel=';
      if (tempcourse.length > 0) {
        if (tempcourse[0]['value'] != 'all') {
          flagAll = false;
          filterCS = filterCS + tempcourse[0]['value'] + ',';
        } else {
          filterCS = filterCS + ',';
        }
      } else {
        filterCS = filterCS + ',';
      }
      if (tempstream.length > 0) {
        if (tempstream[0]['value'] != 'all') {
          flagAll = false;
          filterCS = filterCS + tempstream[0]['value'] + ',';
        } else {
          filterCS = filterCS + ',';
        }
      } else {
        filterCS = filterCS + ',';
      }

      if (tempHostel.length > 0) {
        if (tempHostel[0]['value'] != 'all') {
          flagAll = false;
          filterCS = filterCS + tempHostel[0]['value'];
        } else {
          filterCS = filterCS;
        }
      } else {
        filterCS = filterCS;
      }
    } else {
      filterCS = '';
    }
    if (flagAll) {
      filterCS = '';
    }

    this.filter.forEach((i) => {
      if (i.value !== '' && i.value !== 'all') {
        if (
          i.name !== 'filter_course_1' &&
          i.name !== 'filter_stream_1' &&
          i.name !== 'hostel_facility'
        ) {
          filters = `${filters}&${i.name}=${i.value}`;
        }
      }
    });

    let url;

    // this.currentFilterURL = filters;
    // let url = `?${filters.substring(1,filters.length)}`;

    console.log(filters);

    if (filters.trim() != '') {
      this.currentFilterURL = filterCS + '&' + filters;
      url = `?${filterCS}&${filters.substring(1, filters.length)}`;
    } else {
      this.currentFilterURL = filterCS + filters;
      url = `?${filterCS}${filters.substring(1, filters.length)}`;
    }

    console.log(url);
    this.apiCallService.getColleges(url).subscribe(
      (res) => {
        this.options = res['results'];
        this.collectionSize = res['count'];
        this.filterOptions = [...this.options];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // filterCollege() {
  //   console.log(this.streamFilter)
  //   let filteredCareers :any = [...this.options]
  //   if(this.streamFilter !== '' && this.streamFilter !== 'all')
  //   {
  //     filteredCareers = filteredCareers.filter(option => (option.filter_stream_1 === this.streamFilter) || (option.filter_stream_2 === this.streamFilter) || (option.filter_stream_3 === this.streamFilter))
  //   }

  //   if(this.genderFilter !== '' && this.genderFilter !== 'all')
  //   {
  //     console.log("stramd data")
  //     filteredCareers = filteredCareers.filter(option => option.gender === this.genderFilter)
  //   }

  //   if(this.instituteFilter !== ''  && this.instituteFilter !== 'all')
  //   {
  //     // filteredCareers = filteredCareers.filter(option => option.filter_management_1 === this.instituteFilter)

  //     if(this.instituteFilter == "0")
  //     {
  //       filteredCareers = filteredCareers.filter(option => option.filter_management_1 === 0)
  //     }
  //     if(this.instituteFilter == "1")
  //     {
  //       filteredCareers = filteredCareers.filter(option => option.filter_management_1 === 3)
  //     }
  //     if(this.instituteFilter == "2")
  //     {
  //       filteredCareers = filteredCareers.filter(option => option.filter_management_1 === 1)
  //     }
  //     if(this.instituteFilter == "3")
  //     {
  //       filteredCareers = filteredCareers.filter(option => option.filter_management_2 === 2)
  //     }
  //     if(this.instituteFilter == "4")
  //     {
  //       filteredCareers = filteredCareers.filter(option => option.filter_management_2 === 0)
  //     }
  //     if(this.instituteFilter == "5")
  //     {
  //       filteredCareers = filteredCareers.filter(option => option.filter_management_2 === 1)
  //     }
  //   }

  //   if(this.courseFilter !== ''  && this.courseFilter !== 'all')
  //   {
  //     filteredCareers = filteredCareers.filter(option => (option.filter_course_1 === this.courseFilter) || (option.filter_course_2 === this.courseFilter) ||  (option.filter_course_3 === this.courseFilter))
  //   }

  //   if(this.stateFilter !== ''  && this.stateFilter !== 'all')
  //   {
  //     filteredCareers = filteredCareers.filter(option => option.state === this.stateFilter)

  //     //  console.log(this.stateFilter)
  //      if(this.stateFilter == "1" ){
  //       this.loadedDistricts = Districts["Andhra_Pradesh"];
  //       // console.log(this.loadedDistricts)
  //      }
  //      if(this.stateFilter == "2" ){
  //       this.loadedDistricts = Districts["Telangana"];
  //       // console.log(this.loadedDistricts)
  //      }
  //      if(this.stateFilter == "3" ){
  //       this.loadedDistricts = Districts["Karnataka"];
  //       // console.log(this.loadedDistricts)
  //      }
  //   }

  //   if(this.districtFilter !== ''  && this.districtFilter !== 'all')
  //   {
  //     console.log(this.districtFilter)
  //     filteredCareers = filteredCareers.filter(option => option.district === this.districtFilter)
  //   }

  //   if(this.intituteProvinanceFilter !== ''  && this.intituteProvinanceFilter !== 'all')
  //   {
  //     filteredCareers = filteredCareers.filter(option => option.institute_prominence === this.intituteProvinanceFilter)
  //   }

  //   if(this.CategoryFilter !== ''  && this.CategoryFilter !== 'all')
  //   {
  //     filteredCareers = filteredCareers.filter(option => option.institute_prominence === this.CategoryFilter)
  //   }

  //   if(this.HostelFilter !== ''  && this.HostelFilter !== 'all')
  //   {
  //     filteredCareers = filteredCareers.filter(option => option.hostel_facility === this.HostelFilter)
  //   }
  //   // if(this.salaryFilter !== ''  && this.salaryFilter !== 'all')
  //   // {
  //   //   filteredCareers = filteredCareers.filter(option => option.salary_range === this.salaryFilter)
  //   // }
  //   this.filterOptions = filteredCareers
  //   console.log(filteredCareers)
  //   }

  onPageChange(currentPage) {
    let url = null;
    if (currentPage != 0) {
      url = `page=${currentPage}`;
      this.currentPageURL = url;
      if (this.currentFilterURL !== null) {
        url = `?${url}&${this.currentFilterURL}`;
      } else {
        url = `?${url}`;
      }
    }

    this.previousPage = currentPage;
    this.apiCallService.getColleges(url).subscribe(
      (res) => {
        console.log(res);
        this.options = res['results'];
        this.collectionSize = res['count'];
        this.filterOptions = [...this.options];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}

class serverFilter {
  name: string;
  value: any;
}
