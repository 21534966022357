import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../api-calls.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-notifications-government-jobs',
  templateUrl: './notifications-government-jobs.component.html',
  styleUrls: ['./notifications-government-jobs.component.css'],
})
export class NotificationsGovernmentJobsComponent implements OnInit {
  //Vars
  queryParamId = null;
  optionActive = null;
  options: any = [];
  NotificationDetailResponse: any;
  notificationpathurl = null;
  tokenSub: Subscription;
  isTokenVerified = false;

  groupTagValues = {
    0: 'Entrance Examination',
    1: 'Scholarship',
    2: 'Admission',
    3: 'Training/Skill Development',
    4: 'Government Jobs',
  };
  constructor(
    private apiCallService: ApiCallsService,
    private globalService: GlobalService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {}

  getActiveNotificationDetailJobs() {
    console.log('hello');

    this.apiCallService.getNotificationDetail(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        this.NotificationDetailResponse = res;
        console.log(res);
        if (this.NotificationDetailResponse['notification_path']) {
          // this.roadmapdisabled = false
          this.notificationpathurl =
            this.NotificationDetailResponse['notification_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getInactiveNotificationDetail() {
    console.log('hello');

    this.apiCallService
      .getInactiveNotificationDetail(this.queryParamId)
      .subscribe(
        (res) => {
          console.log(res);
          this.NotificationDetailResponse = res;
          if (this.NotificationDetailResponse['notification_path']) {
            // this.roadmapdisabled = false
            this.notificationpathurl =
              this.NotificationDetailResponse['notification_path'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getTokenInfo() {
    this.apiCallService.verifyToken();
    this.tokenSub = this.globalService.isTokenVerified.subscribe(
      (result: boolean) => {
        console.log(result);
        this.isTokenVerified = result;
      }
    );
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      console.log(params['id']);
      console.log(params['Tag']);
      console.log(params['optionActive']);
      this.queryParamId = params['id'];
      this.optionActive = params['optionActive'];
    });
    if (this.optionActive == 1) {
      this.getActiveNotificationDetailJobs();
    }
    if (this.optionActive == 2) {
      this.getInactiveNotificationDetail();
    }
    this.getTokenInfo();
  }

  statustext(item) {
    if (item != null && item != undefined) {
      return this.groupTagValues[item];
    } else {
      return '';
    }
  }
}
