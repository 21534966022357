<div class="p-5">
  <div class="d-flex viewOptionsHolder">
    <div class="font-600">Select a view</div>
    <mat-radio-group
      [(ngModel)]="selectedDisplayView"
      aria-label="Select an option"
      class="d-flex scholarshipViewSelector"
    >
      <mat-radio-button
        *ngFor="let displayObj of scholarshipsDisplayList"
        [value]="displayObj.displayValue"
        >{{ displayObj.displayName }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
  <div *ngIf="selectedDisplayView === 0" class="pt-3">
    <div class="d-flex totalHolder">
      <div class="d-flex fixedHolder">
        <div class="d-flex titleAndBackHolder">
          <div class="d-flex titleAndBackSection">
            <mat-icon
              class="backArrow cursor-pointer"
              routerLink="/adminDashboard"
              >arrow_back</mat-icon
            >
            <div class="">Scholarships</div>
          </div>
          <div class="d-flex displayOptions">
            <div class="cursor-pointer">Hide Options</div>
            <mat-slide-toggle color="primary" [(ngModel)]="hideOptions">
            </mat-slide-toggle>
          </div>
        </div>
        <div *ngIf="!hideOptions" class="d-flex optionsHolder">
          <div class="d-flex align-center">
            <input
              class="searchInput"
              (keyup)="getScholarshipsData(currentPage, true)"
              placeholder="Search Scholarships"
              [formControl]="searchFormControl"
            />
            <mat-icon
              *ngIf="searchFormControl.value?.length > 0"
              class="exportClearBtn"
              (click)="clearSearch()"
              >close</mat-icon
            >
          </div>
          <div class="filterHolder d-flex">
            <div *ngFor="let filters of filtersList">
              <mat-form-field class="filterFormField">
                <mat-label class="filterLabel">{{
                  filters.filterName
                }}</mat-label>
                <mat-select
                  [placeholder]="filters.filterName"
                  [formControl]="filters.filterFormControl"
                  (selectionChange)="getScholarshipsData(currentPage, false)"
                >
                  <mat-option
                    *ngFor="let filterOption of filters.filterOptions"
                    [value]="filterOption"
                    >{{ filterOption.filterOptionValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="filterFormField">
                <mat-label class="filterLabel">States</mat-label>
                <mat-select
                  placeholder="States"
                  [formControl]="stateFormControl"
                  (selectionChange)="getScholarshipsData(currentPage, false)"
                >
                  <mat-option
                    *ngFor="let stateObj of statesList"
                    [value]="stateObj.id"
                  >
                    {{ stateObj.name }}</mat-option
                  ></mat-select
                >
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div [ngClass]="hideOptions ? 'tableHolder-full' : 'tableHolder'">
          <table
            mat-table
            [dataSource]="scholarshipsArr"
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="scholarship_id">
              <th mat-header-cell *matHeaderCellDef>Scholarship ID</th>
              <td mat-cell *matCellDef="let element">{{ element.scholarship_id }}</td>
            </ng-container>

            <ng-container matColumnDef="scholarship_name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="academic_level">
              <th mat-header-cell *matHeaderCellDef>Academic Level</th>
              <td mat-cell *matCellDef="let element">
                {{ element.academic_level }}
              </td>
            </ng-container>

            <ng-container matColumnDef="age_eigibility">
              <th mat-header-cell *matHeaderCellDef>Age Eligibility</th>
              <td mat-cell *matCellDef="let element">
                {{ element.age_eigibility }}
              </td>
            </ng-container>

            <ng-container matColumnDef="physically_handicaped">
              <th mat-header-cell *matHeaderCellDef>Physically Handicapped</th>
              <td mat-cell *matCellDef="let element">
                {{ element.physically_handicaped }}
              </td>
            </ng-container>

            <ng-container matColumnDef="states">
              <th mat-header-cell *matHeaderCellDef>States</th>
              <td mat-cell *matCellDef="let element">
                {{ giveStates(element.states) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="application_process">
              <th mat-header-cell *matHeaderCellDef>Appllication Process</th>
              <td mat-cell *matCellDef="let element">
                {{ element.application_process }}
              </td>
            </ng-container>

            <ng-container matColumnDef="approximate_application_date">
              <th mat-header-cell *matHeaderCellDef>
                Approximate Application Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.approximate_application_date }}
              </td>
            </ng-container>

            <ng-container matColumnDef="caste_eligibility">
              <th mat-header-cell *matHeaderCellDef>Caste Eligibility</th>
              <td mat-cell *matCellDef="let element">
                {{ element.caste_eligibility }}
              </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let element">
                {{ element.email }}
              </td>
            </ng-container>

            <ng-container matColumnDef="filter_acad_10_and_below">
              <th mat-header-cell *matHeaderCellDef>10th and Below</th>
              <td mat-cell *matCellDef="let element">
                {{ element.filter_acad_10_and_below }}
              </td>
            </ng-container>

            <ng-container matColumnDef="filter_acad_11_12_iti_poly">
              <th mat-header-cell *matHeaderCellDef>11th upto Polytechnic</th>
              <td mat-cell *matCellDef="let element">
                {{ element.filter_acad_11_12_iti_poly }}
              </td>
            </ng-container>

            <ng-container matColumnDef="filter_degree_pg">
              <th mat-header-cell *matHeaderCellDef>Degree/ PG</th>
              <td mat-cell *matCellDef="let element">
                {{ element.filter_degree_pg }}
              </td>
            </ng-container>

            <ng-container matColumnDef="gender_eligibility">
              <th mat-header-cell *matHeaderCellDef>Gender Eligibility</th>
              <td mat-cell *matCellDef="let element">
                {{ element.gender_eligibility }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
    <ngb-pagination
      class="d-flex justify-content-center pagination_theme"
      [maxSize]="5"
      [collectionSize]="collectionSize"
      [pageSize]="pageSize"
      [(page)]="currentPage"
      (pageChange)="getScholarshipsData(currentPage, false)"
    >
      <ng-template ngbPaginationPrevious>
        <img
          src="/assets/Dashboard/pageArrowPrev.svg"
          [ngClass]="[collectionSize] < '5' ? '' : ''"
        />
      </ng-template>
      <ng-template ngbPaginationNext>
        <img
          src="/assets/Dashboard/pageArrowNext.svg"
          [ngClass]="[collectionSize] < '5' ? '' : ''"
        />
      </ng-template>
    </ngb-pagination>
  </div>
  <div *ngIf="selectedDisplayView === 1">
    <app-admin-scholarship-mappings></app-admin-scholarship-mappings>
  </div>
</div>
