<div class="col-md-12 mt-3" style="height: 117.62px !important">
  <div>
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
      <span class="backTitle"> Career List</span>
    </div>
  </div>
  <mat-form-field appearance="outline" class="searchField">
    <input
      type="text"
      matInput
      placeholder="Search Careers"
      [formControl]="myControl"
      [(ngModel)]="searchText"
    />
    <!-- [formControl]="myControl" -->
    <!-- <mat-autocomplete>
        <mat-option *ngFor="let option of options; index as j;let last=last" [value]="option">{{
          option
        }}</mat-option>
      </mat-autocomplete> -->
    <mat-icon matSuffix class="magBag"> search </mat-icon>
  </mat-form-field>
</div>

<div class="col-md-12">
  <div class="row" *ngIf="!deviceXs">
    <div class="col-md-2" style="height: 77.64px !important">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Stream</mat-label>
        <mat-select
          placeholder="Stream"
          (selectionChange)="filterCareer()"
          [(ngModel)]="streamFilter"
        >
          <span class="forAlignments">
            <mat-option value="all">All </mat-option>
            <mat-option
              [value]="j"
              *ngFor="let stream of streams; index as j"
              >{{ stream }}</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Preferred Group</mat-label>
        <mat-select
          placeholder="Preferred Group"
          (selectionChange)="filterCareer()"
          [(ngModel)]="prefferdGroupFilter"
        >
          <span class="forAlignments">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Maths, Physics, Chemistry</mat-option>
            <mat-option [value]="1">Biology, Physics, Chemistry</mat-option>
            <mat-option [value]="2">Commerce, Economics, Civics</mat-option>
            <mat-option [value]="3">History, Economics, Civics</mat-option>
            <mat-option [value]="5">Maths, Economics, Commerce</mat-option>
            <mat-option [value]="4">Vocational</mat-option>
            <mat-option [value]="9">Any Group</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Category</mat-label>
        <mat-select
          placeholder="Category"
          (selectionChange)="filterCareer()"
          [(ngModel)]="categoryFilter"
        >
          <span class="forAlignments">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Vocational</mat-option>
            <mat-option [value]="1">Professional</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Salary</mat-label>
        <mat-select
          placeholder="Salary"
          (selectionChange)="filterCareer()"
          [(ngModel)]="salaryFilter"
        >
          <span class="forAlignments">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="10000">Above 10000</mat-option>
            <mat-option [value]="20000">Above 20000</mat-option>
            <mat-option [value]="30000">Above 30000</mat-option>
            <mat-option [value]="40000">Above 40000</mat-option>
            <mat-option [value]="50000">Above 50000</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- for mobile app filter -->

  <div class="row" *ngIf="deviceXs">
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Stream</mat-label>
        <mat-select
          placeholder="Stream"
          (selectionChange)="filterCareer()"
          [(ngModel)]="streamFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option
              [value]="j"
              *ngFor="let stream of streams; index as j"
              >{{ stream }}</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">Preferred Group</mat-label>
        <mat-select
          placeholder="Preferred Group"
          (selectionChange)="filterCareer()"
          [(ngModel)]="prefferdGroupFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Maths, Physics, Chemistry</mat-option>
            <mat-option [value]="1">Biology, Physics, Chemistry</mat-option>
            <mat-option [value]="2">Commerce, Economics, Civics</mat-option>
            <mat-option [value]="3">History, Economics, Civics</mat-option>
            <mat-option [value]="5">Maths, Economics, Commerce</mat-option>
            <mat-option [value]="4">Vocational</mat-option>
            <mat-option [value]="9">Any Group</mat-option>
            <!-- <mat-option [value]="6">MBiPC</mat-option>
          <mat-option [value]="7">MPC or BiPC</mat-option>
          <mat-option [value]="8">MEC or CEC</mat-option>
          <mat-option [value]="10">Not Applicable</mat-option> -->
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Category</mat-label>
        <mat-select
          placeholder="Category"
          (selectionChange)="filterCareer()"
          [(ngModel)]="categoryFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Vocational</mat-option>
            <mat-option [value]="1">Professional</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">Salary</mat-label>
        <mat-select
          placeholder="Salary"
          (selectionChange)="filterCareer()"
          [(ngModel)]="salaryFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="10000">Above 10000</mat-option>
            <mat-option [value]="20000">Above 20000</mat-option>
            <mat-option [value]="30000">Above 30000</mat-option>
            <mat-option [value]="40000">Above 40000</mat-option>
            <mat-option [value]="50000">Above 50000</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="BG mt-1 table-responsive">
    <table class="table">
      <tr class="colorWaraper">
        <th class="coloumnSeperator">
          <div class="col tableHItem">Career Name</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Required Course</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Specialization</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">
            Preferred Group in 10+2 /PUC/Intermediate
          </div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Expected Starting Salary(Monthly)</div>
        </th>
      </tr>
      <ng-container
        *ngFor="
          let option of filterOptions | filter : searchText;
          index as j;
          let Last = last
        "
      >
        <tr class="tableHItem4" (click)="coToCareerDetails(option['id'])">
          <td>
            <div
              class="col tableHItem2 py-2"
              style="margin: 0"
              [title]="option['name']"
            >
              {{ option["name"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["required_course"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["specialization"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 tableHItem4 py-2" style="margin: 0">
              {{
                option["prefered_group"] != null
                  ? groupValues[option["prefered_group"]]
                  : "-"
              }}
            </div>
          </td>

          <td>
            <div class="col tableHItem2 pt-3" style="margin: 0">
              {{
                option["salary_range"] != null
                  ? option["salary_range"] != ""
                    ? [option["salary_range"]]
                    : "-"
                  : "-"
              }}
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
