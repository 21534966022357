import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-open-road-map',
  templateUrl: './open-road-map.component.html',
  styleUrls: ['./open-road-map.component.css'],
})
export class OpenRoadMapComponent implements OnInit {
  roadmapImage = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<OpenRoadMapComponent>
  ) {}

  ngOnInit(): void {
    this.roadmapImage = this.data.roadmapSource;
    console.log(this.roadmapImage);
  }
}
