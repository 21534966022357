import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '../api-calls.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CareerlistServiceService } from '../careerlist-service.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-careesr-list',
  templateUrl: './careesr-list.component.html',
  styleUrls: ['./careesr-list.component.css'],
})
export class CareesrListComponent implements OnInit {
  @Input() deviceXs: boolean;
  //Vars
  options: any = [];
  filterOptions: any = [];
  streams: any = [
    'Agriculture & Related Fields',
    'Armed Forces & Central Police',
    'Arts, Design, Creative',
    'Banking & Finance',
    'Building & Construction',
    'Clothing & Accessories',
    'Education & Training',
    'Hardware, Electronics & Telecommunications',
    'Engineering & Manufacturing',
    'Fitness & Leisure',
    'Healthcare & Life Sciences',
    'IT & ITES',
    'Social Sciences & Human Services',
    'Law & Order',
    'Management & Marketing',
    'Media & Entertainment',
    'Public Administration & Government',
    'Retail, Tourism and Hospitality',
    'Self Employment',
    'Transportation, Logistics, Warehousing',
  ];
  searchText;

  streamFilter: any = '';
  prefferdGroupFilter: any = '';
  categoryFilter: any = '';
  salaryFilter: any = '';

  choiceFilter = '';
  choiceScreenFilter = 'all';
  message: string;
  choiceFiltersubscription: Subscription;
  queryParamId = null;
  // after_10 = ''

  // // Default value filters
  // streamCFilter = ''
  // prefferdGroupCFilter = ''
  // categoryCFilter = ''
  // salaryCFilter = ''

  groupValues = {
    0: 'MPC',
    1: 'BiPC',
    2: 'CEC',
    3: 'HEC',
    4: 'Vocational',
    5: 'MEC',
    6: 'MBiPC',
    7: 'MPC or BiPC',
    8: 'MEC or CEC',
    9: 'Any Group',
    10: 'Not Applicable',
  };

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver,
    private activateRoute: ActivatedRoute,
    public choiceScreenFilterService: CareerlistServiceService,
    public globalSservice: GlobalService,
    public route: ActivatedRoute
  ) {}

  //Form Control
  mediaSub: Subscription;
  myControl = new FormControl();
  // ten_filter = "";

  streamFilterSub: Subscription;
  preferredGroupFilterSub: Subscription;
  categoryFilterSub: Subscription;
  salaryFilterSub: Subscription;
  queryParam: Subscription;

  getAllJobs() {
    console.log('hello');
    this.apiCallService.getCareers().subscribe(
      (res) => {
        console.log(res);
        this.options = res;
        this.filterCareer();
        this.choiceScreenFilterService.updatechoiceScreenFilter('all');
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllSubjects() {
    console.log('hello');
    console.log(this.queryParamId);
    this.apiCallService.getCareerSubjects(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        this.options = res;
        this.filterCareer();
        this.choiceScreenFilterService.updatechoiceScreenFilter('all');
      },
      (err) => {
        console.log(err);
      }
    );
  }

  coToCareerDetails(jobId) {
    // let jobId = this.options[j]['id']
    // alert(jobId)
    window.scroll(0, 0);
    this.router.navigate(['/dashboard/careerDetail/'], {
      queryParams: { id: jobId },
    });
  }

  ngOnInit(): void {
    // this.apiCallService.verifyLogin();
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );

    this.activateRoute.queryParams.subscribe((params) => {
      // console.log(params['selectedtIndexed']);
      this.queryParamId = params['selectedtIndexed'];
      console.log(this.queryParamId);
    });

    this.choiceFiltersubscription =
      this.choiceScreenFilterService.choiceScreenFilter.subscribe(
        (message) => (this.message = message)
      );
    console.log(this.message);

    if (this.queryParamId == null) {
      this.getAllJobs();
    }

    if (this.queryParamId !== null && this.queryParamId !== undefined) {
      this.getAllSubjects();
    }

    // console.log(this.ten_filter)
    this.queryParam = this.route.queryParams.subscribe((params: Params) => {
      // this.streamFilter = params.stream ? params.stream : ""
      // this.prefferdGroupFilter = params.preferredGroup ? params.preferredGroup : ""
      // this.categoryFilter = params.category ? params.category : ""
      // this.salaryFilter = params.salary? params.salary: ""

      const stream = params.stream ? parseInt(params.stream) : parseInt('');
      this.streamFilter = isNaN(stream)
        ? params.stream === 'all'
          ? 'all'
          : ''
        : stream;

      const prefereedGroup = params.prefereedGroup
        ? parseInt(params.prefereedGroup)
        : parseInt('');
      this.prefferdGroupFilter = isNaN(prefereedGroup)
        ? params.prefereedGroup === 'all'
          ? 'all'
          : ''
        : prefereedGroup;

      const category = params.category
        ? parseInt(params.category)
        : parseInt('');
      this.categoryFilter = isNaN(category)
        ? params.category === 'all'
          ? 'all'
          : ''
        : category;

      const salary = params.salary ? parseInt(params.salary) : parseInt('');
      this.salaryFilter = isNaN(salary)
        ? params.salary === 'all'
          ? 'all'
          : ''
        : salary;
    });
  }

  filterCareer() {
    console.log(this.streamFilter);
    let filteredCareers: any = [...this.options];

    this.globalSservice.setStreamFilter(this.streamFilter);
    this.globalSservice.setPreferredGroupFilter(this.prefferdGroupFilter);
    this.globalSservice.setCategoryFilter(this.categoryFilter);
    this.globalSservice.setSalaryFilter(this.salaryFilter);
    if (
      this.streamFilter !== '' ||
      this.prefferdGroupFilter !== '' ||
      this.categoryFilter !== '' ||
      this.salaryFilter !== ''
    ) {
      this.router.navigate(['/dashboard/careerList'], {
        queryParams: {
          stream: this.streamFilter,
          prefereedGroup: this.prefferdGroupFilter,
          category: this.categoryFilter,
          salary: this.salaryFilter,
        },
      });
    }

    if (this.message == 'after_10') {
      console.log('after 10 filter');
      filteredCareers = filteredCareers.filter(
        (option) => option.filter_10 === 0
      );
    }
    if (this.message == 'after_12') {
      // console.log("after 10 filter")
      filteredCareers = filteredCareers.filter(
        (option) => option.filter_12 === 0
      );
    }
    if (this.message == 'filter_10_can_study') {
      filteredCareers = filteredCareers.filter(
        (option) => option.filter_10_can_study === 0
      );
    }

    if (this.message == '0') {
      filteredCareers = filteredCareers.filter(
        (option) => option.prefered_group === 0 || option.prefered_group === 9
      );
    }
    if (this.message == '1') {
      filteredCareers = filteredCareers.filter(
        (option) => option.prefered_group === 1 || option.prefered_group === 9
      );
    }
    if (this.message == '2') {
      filteredCareers = filteredCareers.filter(
        (option) => option.prefered_group === 2 || option.prefered_group === 9
      );
    }
    if (this.message == '3') {
      filteredCareers = filteredCareers.filter(
        (option) => option.prefered_group === 3 || option.prefered_group === 9
      );
    }
    if (this.message == '5') {
      filteredCareers = filteredCareers.filter(
        (option) => option.prefered_group === 5 || option.prefered_group === 9
      );
    }
    if (this.message == '4') {
      filteredCareers = filteredCareers.filter(
        (option) => option.prefered_group === 4 || option.prefered_group === 9
      );
    }
    if (this.message == '9') {
      filteredCareers = filteredCareers.filter(
        (option) => option.prefered_group === 9 || option.prefered_group === 9
      );
    }

    if (this.streamFilter !== '' && this.streamFilter !== 'all') {
      console.log('stramd data');
      filteredCareers = filteredCareers.filter(
        (option) => option.stream === this.streamFilter
      );
    }
    // if(this.prefferdGroupFilter !== ''  && this.prefferdGroupFilter !== 'all')
    // {
    //   filteredCareers = filteredCareers.filter(option => option.prefered_group === this.prefferdGroupFilter)
    // }

    if (this.prefferdGroupFilter !== '' && this.prefferdGroupFilter !== 'all') {
      // filteredCareers = filteredCareers.filter(option => option.prefered_group === this.prefferdGroupFilter)
      if (this.prefferdGroupFilter == '0') {
        filteredCareers = filteredCareers.filter(
          (option) => option.prefered_group === 0 || option.prefered_group === 9
        );
      }
      if (this.prefferdGroupFilter == '1') {
        filteredCareers = filteredCareers.filter(
          (option) => option.prefered_group === 1 || option.prefered_group === 9
        );
      }
      if (this.prefferdGroupFilter == '2') {
        filteredCareers = filteredCareers.filter(
          (option) => option.prefered_group === 2 || option.prefered_group === 9
        );
      }
      if (this.prefferdGroupFilter == '3') {
        filteredCareers = filteredCareers.filter(
          (option) => option.prefered_group === 3 || option.prefered_group === 9
        );
      }
      if (this.prefferdGroupFilter == '5') {
        filteredCareers = filteredCareers.filter(
          (option) => option.prefered_group === 5 || option.prefered_group === 9
        );
      }
      if (this.prefferdGroupFilter == '4') {
        filteredCareers = filteredCareers.filter(
          (option) => option.prefered_group === 4 || option.prefered_group === 9
        );
      }
      if (this.prefferdGroupFilter == '9') {
        filteredCareers = filteredCareers.filter(
          (option) => option.prefered_group === 9 || option.prefered_group === 9
        );
      }
    }

    if (this.categoryFilter !== '' && this.categoryFilter !== 'all') {
      filteredCareers = filteredCareers.filter(
        (option) => option.category === this.categoryFilter
      );
    }
    if (this.salaryFilter !== '' && this.salaryFilter !== 'all') {
      console.log(this.salaryFilter);
      filteredCareers = filteredCareers.filter(
        (option) => option.maximum_salary > this.salaryFilter
      );
    }
    this.filterOptions = filteredCareers;
    console.log(filteredCareers);
  }
}
