import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
declare var $: any;
import { Router } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { GlobalService } from '../global.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() deviceXs: boolean;
  @ViewChild('sidenav') sidenav: MatSidenav;
  constructor(
    private router: Router,
    private apiCaller: ApiCallsService,
    public mediaObserver: MediaObserver,
    public globalService: GlobalService
  ) {}

  //Vars
  responseVar;
  // notificationIconImageSrc3="/assets/Dashboard/bell1.svg";
  // notificationIconImageSrc1 = "/assets/Dashboard/dashIco.svg";
  // notificationIconImageSrc2 = "/assets/Dashboard/dashIco.svg"
  // notificationIconImageSrc4 = "/assets/Dashboard/shield .svg"
  public screenWidth: any;
  public screenHeight: any;

  optionActive: any;

  mediaSub: Subscription;
  // opened: boolean;
  // deviceXs : boolean;

  reason = '';

  tokenSub: Subscription;
  isTokenVerified: boolean = false;

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }

  logout() {
    this.apiCaller.logout().subscribe(
      (res) => {
        console.log(res);
        localStorage.clear();
        this.router.navigate(['/auth']);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }

  gotoDashboard() {
    this.router.navigate(['/dashboard']);
  }

  getUserInfo() {
    this.apiCaller.getUserInfo().subscribe(
      (res) => {
        console.log(res);
        this.responseVar = res['data']['name'];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getTokenInfo() {
    this.apiCaller.verifyToken();
    this.tokenSub = this.globalService.isTokenVerified.subscribe(
      (result: boolean) => {
        console.log(result);
        this.isTokenVerified = result;
      }
    );
  }

  takeTest() {
    window.open('http://bestcareer.vidyahelpline.org/#/login');
  }

  giveFeedBack() {
    window.open(
      'https://docs.google.com/forms/d/1D2XF6bU6Lq_3LWBDALpahQzKbfGVDIMdOBo_7Piqym4/edit'
    );
  }

  verifyLogin() {
    this.apiCaller.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }

  ngOnInit(): void {
    // this.isTokenVerified = this.apiCaller.verifyToken();
    // console.log(this.apiCaller.verifyToken());
    // this.verifyLogin();
    this.getUserInfo();
    //console.log('hello kooow')
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    console.log(this.screenWidth, this.screenHeight);
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.getTokenInfo();

    const firstTime = localStorage.getItem('key');
    // if(!firstTime){
    //  localStorage.setItem('key','loaded')
    //  location.reload()
    //  }
    //  else
    //  {
    //   localStorage.removeItem('key')
    //  }
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
    this.tokenSub.unsubscribe();
  }

  trimToThreeAlphabet(responseVar) {
    if (responseVar != null && responseVar != undefined) {
      if (responseVar.length > 8) {
        return responseVar.substring(0, 14) + '...';
      } else {
        return responseVar;
      }
    } else {
      return '';
    }
  }
}
