import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';
import { OpenRoadMapComponent } from './open-road-map/open-road-map.component';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-career-detail',
  templateUrl: './career-detail.component.html',
  styleUrls: ['./career-detail.component.css'],
})
export class CareerDetailComponent implements OnInit {
  @Input() deviceXs: boolean;
  constructor(
    private activateRoute: ActivatedRoute,
    private api: ApiCallsService,
    public dialog: MatDialog,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService,
    public router: Router
  ) {}

  roadmapdisabled = true;
  careerpathurl = null;
  //Vars
  streamFilter = '';
  prefferdGroupFilter = '';
  categoryFilter = '';
  salaryFilter = '';

  //Sub Vars
  mediaSub: Subscription;
  streamFilterSub: Subscription;
  preferredGroupFilterSub: Subscription;
  categoryFilterSub: Subscription;
  salaryFilterSub: Subscription;
  queryParam: Subscription;

  queryParamId = null;
  careerDetailResponse: any;
  alternateCareers: any;
  streamValues = {
    0: 'Agriculture & Related Fields',
    1: 'Armed Forces & Central Police',
    2: 'Arts, Design, Creative',
    3: 'Banking & Finance',
    4: 'Building & Construction',
    5: 'Clothing & Accessories',
    6: 'Education & Training',
    7: 'Hardware, Electronics & Telecommunications',
    8: 'Engineering & Manufacturing',
    9: 'Fitness & Leisure',
    10: 'Healthcare & Life Sciences',
    11: 'IT & ITES',
    12: 'Social Sciences & Human Services',
    13: 'Law & Order',
    14: 'Management & Marketing',
    15: 'Media & Entertainment',
    16: 'Public Administration & Government',
    17: 'Retail, Tourism and Hospitality',
    18: 'Self Employment',
    19: 'Transportation, Logistics, Warehousing',
  };

  categoryValues = {
    0: 'Vocational',
    1: 'Professional',
  };

  groupValues = {
    0: 'MPC',
    1: 'BiPC',
    2: 'CEC',
    3: 'HEC',
    4: 'Vocational',
    5: 'MEC',
    6: 'MBiPC',
    7: 'MPC or BiPC',
    8: 'MEC or CEC',
    9: 'Any Group',
    10: 'Not Applicable',
  };

  ngOnInit(): void {
    // this.api.verifyLogin();
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );

    this.activateRoute.queryParams.subscribe((params) => {
      console.log(params['id']);
      this.queryParamId = params['id'];
    });

    this.streamFilterSub = this.globalSservice
      .getStreamFilter()
      .subscribe((value) => (this.streamFilter = value));
    this.preferredGroupFilterSub = this.globalSservice
      .getPreferredGroupFilter()
      .subscribe((value) => (this.prefferdGroupFilter = value));
    this.categoryFilterSub = this.globalSservice
      .getCategoryFilter()
      .subscribe((value) => (this.categoryFilter = value));
    this.salaryFilterSub = this.globalSservice
      .getSalaryFilter()
      .subscribe((value) => (this.salaryFilter = value));
    this.getCareerDetailsApi();
  }

  getCareerDetailsApi() {
    this.api.getCareerDetail(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        this.careerDetailResponse = res;
        let altC = res['alternate_careers'];
        this.alternateCareers = altC.split(',');
        console.log(this.careerDetailResponse);
        console.log(this.alternateCareers);
        if (this.careerDetailResponse['career_path']) {
          this.roadmapdisabled = true; //here we need to change to false once api is ready for roadmap
          this.careerpathurl = this.careerDetailResponse['career_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /* Function to open road map */
  openRoadMap() {
    this.dialog.open(OpenRoadMapComponent, {
      data: { roadmapSource: this.careerpathurl },
    });
  }

  ngOnDestroy() {
    this.streamFilterSub.unsubscribe();
    this.preferredGroupFilterSub.unsubscribe();
    this.categoryFilterSub.unsubscribe();
    this.salaryFilterSub.unsubscribe();
  }
}
