import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivateChild {
  constructor(private router: Router) {}
  canActivateChild(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ): boolean | UrlTree {
    console.log(route?.routeConfig?.path);
    if (
      localStorage.getItem('Token') &&
      route?.routeConfig?.path !== 'changepassword'
    ) {
      this.router.navigate(['/master-dashboard']);
      return this.router.createUrlTree(['/dashboard']);
    } else {
      return true;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivateChild {
  constructor(private router: Router) {}
  canActivateChild(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ): boolean | UrlTree {
    if (localStorage.getItem('Token')) {
      this.router.navigate(['/adminDashboard']);
      return this.router.createUrlTree(['/adminDashboard']);
    } else {
      this.router.navigate(['/admin']);
      this.router.dispose();
      return true;
    }
  }
}
