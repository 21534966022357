import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiCallsService } from '../api-calls.service';
import { TrainingInstituteData } from '../models.model';

@Component({
  selector: 'app-training-institute-detail',
  templateUrl: './training-institute-detail.component.html',
  styleUrls: ['./training-institute-detail.component.css'],
})
export class TrainingInstituteDetailComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiCaller: ApiCallsService
  ) {}

  trainingInstituteDetail: TrainingInstituteData | null = null;

  ngOnInit(): void {
    this.getTrainingInstituteUsingParam();
  }

  getTrainingInstituteUsingParam() {
    console.log(
      this.activatedRoute.params.subscribe(
        (paramObj) => {
          console.log(paramObj);
          this.apiCaller.getTrainingInstituteDetail(paramObj.id).subscribe(
            (res: TrainingInstituteData) => {
              console.log(res);
              const resKeys = Object.keys(res);
              for (const key of resKeys) {
                console.log(key);
                if (key === 'state' || key === 'district') {
                  if (
                    res[key].name === null ||
                    res[key].name === undefined ||
                    res[key].name === ''
                  ) {
                    res[key].name = 'N/A';
                  }
                } else if (key === 'trainingInstituteCourses') {
                  const courseKeys = Object.keys(res[key][0]);
                  for (const course of res[key]) {
                    for (const courseKey of courseKeys) {
                      if (
                        course[courseKey] === null ||
                        course[courseKey] === undefined ||
                        course[courseKey] === ''
                      ) {
                        course[courseKey] = 'N/A';
                      }
                    }
                  }
                } else {
                  if (
                    res[key] === null ||
                    res[key] === undefined ||
                    res[key] === ''
                  ) {
                    res[key] = 'N/A';
                  }
                }
              }
              this.trainingInstituteDetail = res;
            },
            (err) => {
              console.log(err);
            }
          );
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  openTrainingInstituteWebsite(trainingInstituteUrl: string) {
    if (trainingInstituteUrl !== 'N/A') {
      window.open(trainingInstituteUrl, '_blank');
    }
  }
}
