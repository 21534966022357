import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { ViewportScroller } from '@angular/common';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-auth-module',
  templateUrl: './auth-module.component.html',
  styleUrls: ['./auth-module.component.css'],
})
export class AuthModuleComponent implements OnInit, OnDestroy {
  @Input() deviceXs: boolean;
  isSignup = false;
  routerSubscription: Subscription;
  isCapacitor: boolean = false;
  constructor(
    private router: Router,
    public mediaObserver: MediaObserver,
    private viewportScroller: ViewportScroller
  ) {}

  currentPath;
  mediaSub: Subscription;

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.currentPath = this.router.url;
    this.isSignup = this.router?.url === '/auth/signup';
    this.routerSubscription = this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.isSignup = route?.url === '/auth/signup';
      }
    });
  }

  loginScroll() {
    if (this.deviceXs) {
      window.scroll(0, 0);
      // this.viewportScroller.scrollToAnchor("my_anchor");
      document
        .getElementById('my_anchor')
        .scrollIntoView({ behavior: 'smooth' });
    }
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
