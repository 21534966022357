import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { GlobalService } from '../global.service';
import { formatDate } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-entrance-detail',
  templateUrl: './entrance-detail.component.html',
  styleUrls: ['./entrance-detail.component.css'],
})
export class EntranceDetailComponent implements OnInit, OnDestroy {
  @Input() deviceXs: boolean;

  //Vars
  queryParamId = null;
  EntranceDetailResponse: any;
  entrancepathurl = null;
  mediaSub: Subscription;
  entryToCourseSub: Subscription;
  stateFilterSub: Subscription;
  entryCourseFilter = '';
  nationalFilter: any = '';
  published: number = null;
  applicationStatus = '';

  stateValues = {
    0: 'National',
    1: 'Andra Pradesh',
    2: 'Telangana',
    3: 'Karnataka',
  };

  applicationValues = {
    0: 'Active',
    1: 'Inactive',
  };

  groupTagValues = {
    0: 'Entrance Examination',
    1: 'Scholarship',
    2: 'Admission',
    3: 'Training/Skill Development',
    4: 'Government Jobs',
    5: 'Others',
  };

  constructor(
    private activateRoute: ActivatedRoute,
    private api: ApiCallsService,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService
  ) {}

  statustext(item) {
    if (item != null && item != undefined) {
      return this.groupTagValues[item];
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.activateRoute.queryParams.subscribe((params) => {
      console.log(params['id']);
      this.queryParamId = params['id'];
    });
    // this.api.verifyLogin();
    this.entryToCourseSub = this.globalSservice
      .getEntryToCourse()
      .subscribe((value) => (this.entryCourseFilter = value));
    this.stateFilterSub = this.globalSservice
      .getStateFilter()
      .subscribe((value) => (this.nationalFilter = value));
    this.getEntranceDetailsApi();
  }

  getEntranceDetailsApi() {
    this.api.getEntranceDetail(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        this.EntranceDetailResponse = res;
        if (res['notification'] !== null) {
          let date1 = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
          let date2 = formatDate(
            res['notification'].active_until,
            'yyyy-MM-dd',
            'en_US'
          );
          if (date1 > date2) {
            console.log('---date1 is greater----');
            this.applicationStatus = 'Inactive';
          } else {
            console.log('---date2 is greater-----');
            this.applicationStatus = 'Active';
          }
          this.published = res['notification'].published;
          if (this.published == 0 && this.applicationStatus == 'Active') {
            $('#terms').modal('toggle');
          }
        } else {
          this.applicationStatus = 'NA';
        }
        if (this.EntranceDetailResponse['entrance_path']) {
          // this.roadmapdisabled = false
          this.entrancepathurl = this.EntranceDetailResponse['entrance_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  trimToThreeAlphabet(responseVar) {
    if (responseVar != null && responseVar != undefined) {
      if (responseVar.length > 0) {
        return responseVar.substring(0, 170) + '...';
      } else {
        return responseVar;
      }
    } else {
      return '';
    }
  }

  ngOnDestroy() {
    this.entryToCourseSub.unsubscribe();
    this.stateFilterSub.unsubscribe();
  }
}
