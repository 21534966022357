import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '../api-calls.service';
import {
  filterListObj,
  filterOptionObj,
  filterParamsObj,
} from '../models.model';

@Component({
  selector: 'app-admin-colleges-course-filter',
  templateUrl: './admin-colleges-course-filter.component.html',
  styleUrls: ['./admin-colleges-course-filter.component.css'],
})
export class AdminCollegesCourseFilterComponent implements OnInit {
  collegesCourseFilterArr: any[] = [];
  // statesList: any[] = [];
  displayedColumns: string[] = [
    'id',
    // 'college',
    'college_id',
    'college_course_filter_id',
    'course_filter',
  ];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  hideOptions: boolean = false;

  searchFormControl: FormControl = new FormControl();
  courseFormControl: FormControl = new FormControl();

  courseOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Polytechnic and other Diploma' },
    {
      filterOptionKey: 1,
      filterOptionValue:
        'BE/BTech, BTech (Integrated), B.Tech Lateral Entry, B.Arch',
    },
    { filterOptionKey: 2, filterOptionValue: 'Pharmacy' },
    { filterOptionKey: 3, filterOptionValue: 'Agriculture and Related' },
    { filterOptionKey: 4, filterOptionValue: 'B.Ed, Diploma in Education' },
    { filterOptionKey: 5, filterOptionValue: 'BA, BSc, BCom' },
    { filterOptionKey: 6, filterOptionValue: 'BPEd, Diploma in Phys Ed' },
    { filterOptionKey: 7, filterOptionValue: 'Law' },
    { filterOptionKey: 8, filterOptionValue: 'MA, MSc, MCom' },
    { filterOptionKey: 9, filterOptionValue: 'MTech, M.Pharm, M. Arch' },
    { filterOptionKey: 10, filterOptionValue: 'MBA, MCA' },
    { filterOptionKey: 11, filterOptionValue: 'Other Graduate' },
    { filterOptionKey: 12, filterOptionValue: 'Other Post Graduate' },
    { filterOptionKey: 13, filterOptionValue: 'Medical and Related' },
    { filterOptionKey: 14, filterOptionValue: 'CA and Related' },
    { filterOptionKey: 15, filterOptionValue: 'phd' },
  ];

  filtersList: filterListObj[] = [
    {
      filterName: 'Courses',
      filterOptions: this.courseOptions,
      filterFormControl: this.courseFormControl,
    },
  ];

  searchParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  courseParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.setDefaultControlValues();
  }

  setDefaultControlValues() {
    this.courseFormControl.patchValue(this.courseOptions[0]);
    this.getCollegesCourseFilterData(1, false);
  }

  hideOptionsFunc() {
    this.hideOptions = !this.hideOptions;
  }

  callCollegesCourseFilterApi(page: number) {
    this.apiCaller
      .getCollegesCourseFilters(page, [this.searchParam, this.courseParam])
      .subscribe(
        (res: any) => {
          console.log(res);
          this.collegesCourseFilterArr = res.results;
          this.collectionSize = res.count;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getCollegesCourseFilterData(page: number, isSearch: boolean) {
    this.makeParams();
    if (isSearch) {
      let searchValue: string = this.searchParam.filterParamValue as string;
      if (searchValue.length >= 3) {
        this.callCollegesCourseFilterApi(page);
      }
    } else {
      this.callCollegesCourseFilterApi(page);
    }
  }

  clearSearch() {
    this.searchFormControl.reset();
    this.getCollegesCourseFilterData(this.currentPage, false);
  }

  makeParams() {
    let searchValue: string = this.searchFormControl.value;
    if (searchValue) {
      if (searchValue.length > 3) {
        this.searchParam.filterParamActive = true;
        this.searchParam.filterParamValue = searchValue;
        this.searchParam.filterParamName = 'search';
        this.currentPage = 1;
      } else {
        this.searchParam.filterParamActive = false;
      }
    } else {
      this.searchParam.filterParamActive = false;
    }

    let courseLevel: filterOptionObj = this.courseFormControl.value;
    if (courseLevel.filterOptionKey !== -1) {
      this.courseParam.filterParamActive = true;
      this.courseParam.filterParamValue = courseLevel.filterOptionKey;
      this.courseParam.filterParamName = 'course_filter';
      this.currentPage = 1;
    } else {
      this.courseParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }
}
