import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';
import { Subscription } from 'rxjs';
import { GlobalService } from '../global.service';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-hostel-detail',
  templateUrl: './hostel-detail.component.html',
  styleUrls: ['./hostel-detail.component.css'],
})
export class HostelDetailComponent implements OnInit {
  //Vars
  queryParamId = null;
  HostelDetailResponse: any;
  hostelepathurl = null;
  academicFilterSub: Subscription;
  genderFilterSub: Subscription;
  casteFilterSub: Subscription;
  stateFilterSub: Subscription;
  districtFilterSub: Subscription;
  queryParam: Subscription;

  academicFilter1 = '';
  genderFilter: any = '';
  casteFilter: any = '';
  stateFilter: any = '';
  districtFilter = '';

  govtValues = {
    0: 'Government',
    1: 'Private',
    2: 'NGO',
  };

  genderValues = {
    0: 'Boys',
    1: 'Girls',
    3: 'Both',
  };

  casteValues = {
    0: 'SC',
    1: 'ST',
    2: 'BC',
    3: 'Other Caste',
    4: 'Minority religion',
  };

  groupValues = {
    0: 'National',
    1: 'Andhra Pradesh',
    2: 'Telangana',
    3: 'Karnataka',
  };

  constructor(
    private activateRoute: ActivatedRoute,
    private api: ApiCallsService,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService
  ) {}

  ngOnInit(): void {
    // this.api.verifyLogin();
    this.activateRoute.queryParams.subscribe((params) => {
      console.log(params['id']);
      this.queryParamId = params['id'];
    });
    this.academicFilterSub = this.globalSservice
      .getAcademicHostelFilter()
      .subscribe((value) => (this.academicFilter1 = value));
    this.genderFilterSub = this.globalSservice
      .getGenderHostelilter()
      .subscribe((value) => (this.genderFilter = value));
    this.casteFilterSub = this.globalSservice
      .getCasteHostelilter()
      .subscribe((value) => (this.casteFilter = value));
    this.stateFilterSub = this.globalSservice
      .getStateHostelilter()
      .subscribe((value) => (this.stateFilter = value));
    this.districtFilterSub = this.globalSservice
      .getDistrictHostelilter()
      .subscribe((value) => (this.districtFilter = value));
    this.getHostelDetailsApi();
  }

  getHostelDetailsApi() {
    this.api.getHostelDetail(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        this.HostelDetailResponse = res;
        if (this.HostelDetailResponse['hostel_path']) {
          // this.roadmapdisabled = false
          this.hostelepathurl = this.HostelDetailResponse['hostel_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy() {
    this.academicFilterSub.unsubscribe();
    this.genderFilterSub.unsubscribe();
    this.casteFilterSub.unsubscribe();
    this.stateFilterSub.unsubscribe();
    this.districtFilterSub.unsubscribe();
  }

  webLink(url) {
    // window.location.href='www.cnn.com/';

    if (url !== null && url !== undefined) {
      if (url.includes('https')) {
        window.open(url, '_blank');
      } else {
        url = '//' + url;
        window.open(url, '_blank');
      }
    }
  }
}
