import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApiCallsService } from './../api-calls.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password-root',
  templateUrl: './forgot-password-root.component.html',
  styleUrls: ['./forgot-password-root.component.css'],
})
export class ForgotPasswordRootComponent implements OnInit {
  constructor(
    private apicaller: ApiCallsService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  // Variables
  data = {
    username: '',
  };

  phoneLinkedUsernames: any = [];

  linkedMessage: string = '';

  authDetailErrMessage: string = '';

  form = new FormGroup({
    authDetail: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9_-]*$'),
    ]),
    linkedUserId: new FormControl('', [Validators.required]),
    authDetailType: new FormControl('username', [Validators.required]),
  });

  iffail() {
    // Swal.fire({
    // title: 'Invalid OTP. Please enter valid OTP',
    // showClass: {
    //   popup: 'animate__animated animate__fadeInDown'
    // },
    // hideClass: {
    //   popup: 'animate__animated animate__fadeOutUp'
    // }
    // })
    Swal.fire('User not found. Please Sign Up', '', 'error');
  }
  formGroupValidator = false;
  ngOnInit(): void {}

  authTypeValidator(event) {
    this.linkedMessage = '';
    console.log(event);
    let phoneArr = [];
    phoneArr.push(event.target.value);
    for (let i = 0; i < event.target.value.length; i++) {
      console.log(event.target.value[i]);
      if (
        Number.isInteger(parseInt(event.target.value[i])) &&
        Number.isInteger(parseInt(event.target.value))
      ) {
        console.log('in if');
        this.form.controls['authDetail'].clearValidators();
        this.form.controls['authDetail'].setValidators([
          Validators.required,
          Validators.minLength(10),
        ]);
        console.log(phoneArr);
      } else {
        phoneArr = [];
        this.form.controls['authDetail'].clearValidators();
        this.form.controls['authDetail'].setValidators([
          Validators.required,
          Validators.minLength(6),
        ]);
        if (
          Number.isInteger(parseInt(this.form.controls['authDetail'].value[0]))
        ) {
          this.form.controls['authDetail'].setErrors({ notValid: true });
        }
        if (event.target.value.includes('.')) {
          console.log("contains '.'");
          this.form.controls['authDetail'].setErrors({ notValid: true });
        }
        if (this.form.controls['authDetail'].invalid) {
          console.log('checking validatidity .. .invalid');
          this.authDetailErrMessage = 'Invalid Username/Phone';
        }
      }
    }

    if (phoneArr.length > 0) {
      if (phoneArr[0].length == 10) {
        let reqData = {
          phone: event.target.value,
        };
        this.apicaller.getPhoneLinkedUsernames(reqData).subscribe(
          (res) => {
            if (res.toString().length < 1) {
              this.linkedMessage = 'No matching users found';
            }
            this.phoneLinkedUsernames = res;
            console.log(this.phoneLinkedUsernames);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  forgot() {
    let data = this.form.value;
    console.log('The vals are', data);
    if (Number.isInteger(parseInt(data.authDetail[0]))) {
      data.authDetailType = 'phone';
    } else {
      data.authDetailType = 'username';
      this.form.controls['linkedUserId'].setValue('-1');
      data.linkedUserId = '-1';
    }
    console.log('The vals after manip', data);
    console.log(this.form);
    if (this.form.valid) {
      this.apicaller.forgotpasswordApi(data).subscribe(
        (res) => {
          console.log(res['data']['id']);
          localStorage.setItem('id', res['data']['id']);
          this.router.navigate(['/auth/otpverify']);
        },
        (err) => {
          console.log(err);

          // console.log("User not found")
          // alert('Invalid OTP')

          let message = err['error']['message'];
          if (message == 'user_not_found') {
            console.log('user not fund');
            this.iffail();
          }
        }
      );
    } else {
      this.formGroupValidator = true;
    }
  }
}
