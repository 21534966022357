import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { GlobalService } from '../global.service';
import { formatDate } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-scholarship-detail',
  templateUrl: './scholarship-detail.component.html',
  styleUrls: ['./scholarship-detail.component.css'],
})
export class ScholarshipDetailComponent implements OnInit {
  @Input() deviceXs: boolean;
  //Vars
  queryParamId = null;
  ScholarshipDetailResponse: any;
  alternateCourses: any;
  scholarshippathurl = null;
  mediaSub: Subscription;
  academicFilterSub: Subscription;
  genderFilterSub: Subscription;
  handicappedFilterSub: Subscription;
  applicationFilterSub: Subscription;
  stateFilterSub: Subscription;
  queryParam: Subscription;

  academicFilter = '';
  genderFilter: any = '';
  handicappedFilter: any = '';
  applicationFilter: any = '';
  stateFilter: any = '';
  published: number = null;
  applicationStatus: string;

  constructor(
    private activateRoute: ActivatedRoute,
    private api: ApiCallsService,
    public dialog: MatDialog,
    public mediaObserver: MediaObserver,
    private router: Router,
    public globalSservice: GlobalService
  ) {}

  statusValues = {
    0: 'Active',
    1: 'Inactive',
  };

  handicappedValues = {
    0: 'Yes',
    1: 'No',
  };

  genderValues = {
    0: 'Male',
    1: 'Female',
    2: 'Others',
    3: 'Male and Female',
  };

  groupTagValues = {
    0: 'Entrance Examination',
    1: 'Scholarship',
    2: 'Admission',
    3: 'Training/Skill Development',
    4: 'Government Jobs',
    5: 'Others',
  };

  ngOnInit(): void {
    // this.api.verifyLogin();
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.activateRoute.queryParams.subscribe((params) => {
      console.log(params['id']);
      this.queryParamId = params['id'];
    });
    this.academicFilterSub = this.globalSservice
      .getAcademicScholarshipFilter()
      .subscribe((value) => (this.academicFilter = value));
    this.genderFilterSub = this.globalSservice
      .getGenderScholarshipFilter()
      .subscribe((value) => (this.genderFilter = value));
    this.handicappedFilterSub = this.globalSservice
      .getHandicappedScholarshipFilter()
      .subscribe((value) => (this.handicappedFilter = value));
    this.applicationFilterSub = this.globalSservice
      .getApplicationScholarshipFilter()
      .subscribe((value) => (this.applicationFilter = value));
    this.stateFilterSub = this.globalSservice
      .getstateScholarshipFilter()
      .subscribe((value) => (this.stateFilter = value));
    this.getScholarshipDetailsApi();
  }

  getScholarshipDetailsApi() {
    this.api.getScholarshipDetail(this.queryParamId).subscribe(
      (res) => {
        console.log(res);
        this.ScholarshipDetailResponse = res;
        if (res['notification'] !== null) {
          let date1 = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
          let date2 = formatDate(
            res['notification'].active_until,
            'yyyy-MM-dd',
            'en_US'
          );
          if (date1 > date2) {
            console.log('---date1 is greater----');
            this.applicationStatus = 'Inactive';
          } else {
            console.log('---date2 is greater-----');
            this.applicationStatus = 'Active';
          }
          this.published = res['notification'].published;
          if (this.published == 0 && this.applicationStatus === 'Active') {
            $('#terms').modal('toggle');
          }
        } else {
          this.applicationStatus = 'NA';
        }
        if (this.ScholarshipDetailResponse['scholarship_path']) {
          // this.roadmapdisabled = false
          this.scholarshippathurl =
            this.ScholarshipDetailResponse['scholarship_path'];
        }
        Object.keys(this.ScholarshipDetailResponse).forEach((key, keyIndex) => {
          if (
            this.ScholarshipDetailResponse[key] == null ||
            this.ScholarshipDetailResponse[key] == undefined
          ) {
            this.ScholarshipDetailResponse[key] = 'N/A';
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  statustext(item) {
    if (item != null && item != undefined) {
      return this.groupTagValues[item];
    } else {
      return '';
    }
  }

  ngOnDestroy() {
    this.academicFilterSub.unsubscribe();
    this.genderFilterSub.unsubscribe();
    this.handicappedFilterSub.unsubscribe();
    this.applicationFilterSub.unsubscribe();
    this.stateFilterSub.unsubscribe();
  }
}
