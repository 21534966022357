<div class="col-md-12" *ngIf="!deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/bankloanlist"
        [queryParams]="{
          course: prefferdGroupFilter,
          study: study_location_filter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ BankLoanDetailResponse?.loan_name }} </span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 wrapperLeft1">
      <div class="row">
        <div class="col-md-12 sc2Parent2 mt-1">
          <div class="row mb-2">
            <div class="col-md-12">
              <span class="secondContainer2">Loan Details</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/student-hat.png" />
              </span>
            </div>
            <div class="col mt-3">
              <div>
                <span class="streamA">Bank Name: </span>
                <span class="reqta">{{
                  BankLoanDetailResponse?.bank_name
                }}</span>
              </div>
            </div>
            <div class="col mt-3">
              <div>
                <span class="streamA">Study in(India/Abroad): </span>
                <span class="reqta">{{
                  BankLoanDetailResponse?.study_location != null
                    ? groupValues[BankLoanDetailResponse?.study_location]
                    : "-"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-4">
        <div class="col-md-12 sc2Parent2 mt-1">
          <div class="row">
            <div class="col-md-12">
              <span class="secondContainer2">About Loan</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/surface1.png" />
              </span>

              <div class="reqt1 mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Loan Amount</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{ BankLoanDetailResponse?.loan_amount }}
                </p>
              </div>
              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Collateral Requirements</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{ BankLoanDetailResponse?.collateral_requirements }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Expenses Considered for Loan</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    BankLoanDetailResponse?.expenses_considered != (null || "")
                      ? [BankLoanDetailResponse?.expenses_considered]
                      : "NA"
                  }}
                </p>
              </div>

              <span class="secondContainer2">Loan Conditions</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/skills.png" />
              </span>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Eligible Courses</span>
                <p
                  class="mt-3 overviewText pl-3 pb-2"
                  *ngFor="let courses of alternateCourses"
                >
                  {{ courses }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2">
                  Any Condition On Educational Institution</span
                >
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{ BankLoanDetailResponse?.conditions }}
                </p>
              </div>

              <div class="reqt1 mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Special Criteria For Students</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    BankLoanDetailResponse?.special_criteria != null && ""
                      ? [BankLoanDetailResponse?.special_criteria]
                      : "NA"
                  }}
                </p>
              </div>

              <span class="secondContainer2">Other Details</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/open-book.png" />
              </span>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Source Of Data</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    BankLoanDetailResponse?.source_data != null && ""
                      ? [BankLoanDetailResponse?.source_data]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="reqt1 mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Date Of Data Gathered</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    BankLoanDetailResponse?.date_of_data != null
                      ? [BankLoanDetailResponse?.date_of_data]
                      : "NA"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5 wrapperRight1">
      <div class="row">
        <div class="col-md-12 sc2Parent2 mt-1">
          <div class="row">
            <div class="col-md-12">
              <span class="secondContainer2">Application Info</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/desktop.png" />
              </span>
            </div>

            <div class="col">
              <div class="reqt1 mt-3">
                <span style="align-items: left"> How To Apply: </span>
                <span class="mt-3 overviewText" style="word-wrap: break-word">
                  <a
                    href="{{ BankLoanDetailResponse?.how_to_apply }}"
                    target="_blank"
                    *ngIf="BankLoanDetailResponse?.how_to_apply?.length > 0"
                  >
                    {{
                      BankLoanDetailResponse?.how_to_apply != ""
                        ? [BankLoanDetailResponse?.how_to_apply]
                        : "NA"
                    }}
                  </a>
                </span>
              </div>

              <div class="reqt1 mt-3">
                <span style="align-items: left"> More Info:</span>
                <span class="mt-3 overviewText" style="word-wrap: break-word">
                  <a
                    href="{{ BankLoanDetailResponse?.more_info }}"
                    target="_blank"
                    *ngIf="BankLoanDetailResponse?.more_info?.length > 0"
                  >
                    {{
                      BankLoanDetailResponse?.more_info != ""
                        ? [BankLoanDetailResponse?.more_info]
                        : "NA"
                    }}
                  </a>
                </span>
              </div>

              <div class="reqt1 mt-3 mb-3">
                <span style="align-items: left"> Important Websites</span>
                <span class="mt-3 overviewText" style="word-wrap: break-word">
                  <a
                    href="{{ BankLoanDetailResponse?.important_websites }}"
                    target="_blank"
                    *ngIf="
                      BankLoanDetailResponse?.important_websites?.length > 0
                    "
                  >
                    {{
                      BankLoanDetailResponse?.important_websites != ""
                        ? [BankLoanDetailResponse?.important_websites]
                        : "NA"
                    }}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- For MobileApp -->

<div class="col-md-12" *ngIf="deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/bankloanlist"
        [queryParams]="{
          course: prefferdGroupFilter,
          study: study_location_filter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ BankLoanDetailResponse?.loan_name }} </span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 wrapperLeft1">
      <div class="row">
        <div class="col-md-12 sc2Parent2 mt-1">
          <div class="row mb-2">
            <div class="col-md-12">
              <span class="secondContainer2">Loan Details</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/student-hat.png" />
              </span>
            </div>
            <div class="col mt-3">
              <div class="mb-2">
                <span class="streamA">Bank Name: </span>
                <span class="reqta pl-1">{{
                  BankLoanDetailResponse?.bank_name
                }}</span>
              </div>

              <div class="mb-2">
                <span class="streamA">Study in(India/Abroad): </span>
                <span class="reqta pl-1">{{
                  BankLoanDetailResponse?.study_location != null
                    ? groupValues[BankLoanDetailResponse?.study_location]
                    : "-"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-4">
        <div class="col-md-12 sc2Parent2 mt-1">
          <div class="row">
            <div class="col-md-12">
              <span class="secondContainer2">About Loan</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/surface1.png" />
              </span>

              <div class="reqt1 mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Loan Amount</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{ BankLoanDetailResponse?.loan_amount }}
                </p>
              </div>
              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Collateral Requirements</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{ BankLoanDetailResponse?.collateral_requirements }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Expenses Considered for Loan</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    BankLoanDetailResponse?.expenses_considered != null && ""
                      ? [BankLoanDetailResponse?.expenses_considered]
                      : "NA"
                  }}
                </p>
              </div>

              <span class="secondContainer2">Loan Conditions</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/skills.png" />
              </span>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Eligible Courses</span>
                <p
                  class="mt-3 overviewText pl-3 pb-2"
                  *ngFor="let courses of alternateCourses"
                >
                  {{ courses }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2">
                  Any Condition On Educational Institution</span
                >
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{ BankLoanDetailResponse?.conditions }}
                </p>
              </div>

              <div class="reqt1 mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Special Criteria For Students</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    BankLoanDetailResponse?.special_criteria != null && ""
                      ? [BankLoanDetailResponse?.special_criteria]
                      : "NA"
                  }}
                </p>
              </div>

              <span class="secondContainer2">Other Details</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/open-book.png" />
              </span>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Source Of Data</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    BankLoanDetailResponse?.source_data != null && ""
                      ? [BankLoanDetailResponse?.source_data]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="reqt1 mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="ml-2"> Date Of Data Gathered</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    BankLoanDetailResponse?.date_of_data != null
                      ? [BankLoanDetailResponse?.date_of_data]
                      : "NA"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5 wrapperRight1">
      <div class="row">
        <div class="col-md-12 sc2Parent2 mt-1">
          <div class="row">
            <div class="col-md-12">
              <span class="secondContainer2">Application Info</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/desktop.png" />
              </span>
            </div>

            <div class="col">
              <div class="reqt1 mt-3">
                <span style="align-items: left"> How To Apply: </span>
                <span class="mt-3 overviewText" style="word-wrap: break-word">
                  <a
                    href="{{ BankLoanDetailResponse?.how_to_apply }}"
                    target="_blank"
                    *ngIf="BankLoanDetailResponse?.how_to_apply?.length > 0"
                  >
                    {{
                      BankLoanDetailResponse?.how_to_apply != ""
                        ? [BankLoanDetailResponse?.how_to_apply]
                        : "NA"
                    }}
                  </a>
                </span>
              </div>

              <div class="reqt1 mt-3">
                <span style="align-items: left"> More Info:</span>
                <span class="mt-3 overviewText" style="word-wrap: break-word">
                  <a
                    href="{{ BankLoanDetailResponse?.more_info }}"
                    target="_blank"
                    *ngIf="BankLoanDetailResponse?.more_info?.length > 0"
                  >
                    {{
                      BankLoanDetailResponse?.more_info != ""
                        ? [BankLoanDetailResponse?.more_info]
                        : "NA"
                    }}
                  </a>
                </span>
              </div>

              <div class="reqt1 mt-3 mb-3">
                <span style="align-items: left"> Important Websites</span>
                <span class="mt-3 overviewText" style="word-wrap: break-word">
                  <a
                    href="{{ BankLoanDetailResponse?.important_websites }}"
                    target="_blank"
                    *ngIf="
                      BankLoanDetailResponse?.important_websites?.length > 0
                    "
                  >
                    {{
                      BankLoanDetailResponse?.important_websites != ""
                        ? [BankLoanDetailResponse?.important_websites]
                        : "NA"
                    }}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
