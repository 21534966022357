import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from './../api-calls.service';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgModel,
  Validators,
} from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-mobile',
  templateUrl: './login-mobile.component.html',
  styleUrls: ['./login-mobile.component.css'],
})
export class LoginMobileComponent implements OnInit {
  hide = true;
  isSignup = false;
  validatedEmail = false;
  routerSubscription: Subscription;

  constructor(
    private apicaller: ApiCallsService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  currentPath;

  // showTT() {
  //   this.toastr.success('some messages', 'title');
  //   timeout:3000;
  // }

  loginerr() {
    Swal.fire('Invalid credentials!', '', 'error');
  }

  // Variables
  data = {
    username: '',
    password: '',
  };

  form = new FormGroup({
    // username: new FormControl('', [Validators.required]),
    authDetail: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    authDetailType: new FormControl('username', [Validators.required]),
  });

  formGroupValidator = false;

  ngOnInit(): void {
    this.currentPath = this.router.url;
    this.isSignup = this.router?.url === '/auth/signup';
    this.routerSubscription = this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.isSignup = route?.url === '/auth/signup';
      }
    });
  }

  login() {
    let data = this.form.value;
    if (Number.isInteger(parseInt(data.authDetail[0]))) {
      data.authDetailType = 'phone';
    } else if (this.validatedEmail === true) {
      data.authDetailType = 'email';
    } else {
      data.authDetailType = 'username';
    }
    console.log('The vals are', data);
    // this.apicaller.loginApi(data).subscribe(
    this.apicaller.multiLoginApi(data).subscribe(
      (res) => {
        console.log(res);
        localStorage.setItem('Token', res['token']);

        console.log('Check it man');
        //  console.log['res']
        //  if(res == true) {
        //   console.log("Routing to dashboard   ")
        //   this.router.navigate(['/dashboard'])
        // }

        // this.router.navigate(['/dashboard'])
        // Callsecond api
        let message = res['message'];
        console.log['message'];
        this.apicaller.verifyUser().subscribe(
          (res) => {
            console.log(res, message);
            if (res['message'] == 'user_verified') {
              console.log('Routing to dashboard page');
              this.router.navigate(['/dashboard']);
            }
          },
          (err) => {
            console.log(err['error']['message']);
            let message = err['error']['message'];
            if (message == 'first_time_login. need_to_set_password') {
              console.log('Routing to change password page');
              this.router.navigate(['/auth/changepassword']);
            }
          }
        );
      },
      (err) => {
        console.log(err);
        this.formGroupValidator = true;
        this.loginerr();
      }
    );
  }
}
