<div id="wrapper" class="d-flex">
  <div id="sidebar-wrapper" *ngIf="!deviceXs">
    <!-- <div
      class="d-flex flex-column justify-content-between"
    > -->
    <!-- <div class="sidebar-heading ddd mt-4">
            Career Advancement Platform (CAP)
      </div> -->
    <div class="mt-3">
      <div class="d-flex justify-content-center">
        <img src="/assets/Dashboard/cap.png" />
      </div>
      <div class="options-holder">
        <div
          class="d-flex pl-4 justify-content-left sidebarOptions"
          [ngClass]="
            router.url == '/dashboard' ? 'selectedClassRemoveThis' : ''
          "
        >
          <div class="row">
            <div class="col-3 lh-ele">
              <img
                [src]="
                  router.url == '/dashboard'
                    ? '/assets/Dashboard/dashIco.svg'
                    : '/assets/Dashboard/dashIcoLight.svg'
                "
              />
            </div>
            <div
              class="col-9 lh-ele"
              style="padding-left: 19px; inline-size: max-content"
              routerLink="/dashboard"
              [ngClass]="router.url == '/dashboard' ? 'bold' : 'normal'"
            >
              Career Wiki
            </div>
          </div>
        </div>
        <div
          class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
          (click)="physcometricClick()"
        >
          <div class="row">
            <div class="col-3 lh-ele">
              <img src="/assets/Dashboard/exam.svg" />
            </div>
            <div
              class="col-9 lh-ele"
              (click)="takeTest()"
              [ngClass]="optionActive == 4 ? 'bold' : 'normal'"
            >
              Psychometric Assessment
            </div>
          </div>
        </div>
        <div
          class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
          [ngClass]="
            router.url == '/dashboard/notifications'
              ? 'selectedClassRemoveThis'
              : ''
          "
        >
          <div class="row">
            <div class="col-3 lh-ele">
              <img
                [src]="
                  router.url == '/dashboard/notifications'
                    ? '/assets/Dashboard/darkBell.svg'
                    : '/assets/Dashboard/lightBell.svg'
                "
              />
            </div>
            <div
              class="col-9 lh-ele"
              routerLink="/dashboard/notifications"
              [ngClass]="
                router.url == '/dashboard/notifications' ? 'bold' : 'normal'
              "
            >
              Notifications
            </div>
          </div>
        </div>

        <div
          class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
          [ngClass]="
            router.url == '/dashboard/resources'
              ? 'selectedClassRemoveThis'
              : ''
          "
        >
          <div class="row">
            <div class="col-3 lh-ele">
              <img
                [src]="
                  router.url == '/dashboard/resources'
                    ? '/assets/Dashboard/darkResource.svg'
                    : '/assets/Dashboard/lightResource.svg'
                "
              />
            </div>
            <div
              class="col-9 lh-ele"
              routerLink="/dashboard/resources"
              style="padding-left: 18px"
              [ngClass]="
                router.url == '/dashboard/resources' ? 'bold' : 'normal'
              "
            >
              Resources
            </div>
          </div>
        </div>

        <div
          class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
          [ngClass]="
            router.url == '/dashboard/careermithra'
              ? 'selectedClassRemoveThis'
              : ''
          "
        >
          <div class="row">
            <div class="col-3 lh-ele">
              <img
                [src]="
                  router.url == '/dashboard/careermithra'
                    ? '/assets/Dashboard/darkCmithra.svg'
                    : '/assets/careerMithra/lightcm.svg'
                "
              />
            </div>
            <div
              class="col-9 lh-ele"
              routerLink="/dashboard/careermithra"
              style="
                text-align: center;
                inline-size: max-content;
                padding-left: 13px;
              "
              [ngClass]="
                router.url == '/dashboard/careermithra' ? 'bold' : 'normal'
              "
            >
              Career Mithra
            </div>
          </div>
        </div>

        <div
          class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
          [ngClass]="
            router.url == '/dashboard/mentorship'
              ? 'selectedClassRemoveThis'
              : ''
          "
        >
          <div class="row">
            <div class="col-3 lh-ele">
              <img
                [src]="
                  router.url == '/dashboard/mentorship'
                    ? '/assets/mentorship/whitementor.svg'
                    : '/assets/mentorship/lightmentor.svg'
                "
              />
            </div>
            <div
              class="col-9 lh-ele"
              routerLink="/dashboard/mentorship"
              style="
                text-align: center;
                inline-size: max-content;
                padding-left: 17px;
              "
              [ngClass]="
                router.url == '/dashboard/mentorship' ? 'bold' : 'normal'
              "
            >
              Mentorship
              <!-- Changed speling "Mentoship" to "Mentorship" #G-->
            </div>
          </div>
        </div>

        <div
          class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
          [ngClass]="
            router.url == '/dashboard/profile' ? 'selectedClassRemoveThis' : ''
          "
        >
          <div class="row">
            <div class="col-3 lh-ele">
              <img
                style="padding-inline-start: 3px"
                [src]="
                  router.url == '/dashboard/profile'
                    ? '/assets/Dashboard/darkShield.svg'
                    : '/assets/Dashboard/shield .svg'
                "
              />
            </div>
            <div
              class="col-9 lh-ele"
              style="padding-left: 23px"
              routerLink="/dashboard/profile"
              [ngClass]="router.url == '/dashboard/profile' ? 'bold' : 'normal'"
            >
              Profile
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row logoutBlock mx-0">
      <div class="disclaimerHolder" (click)="tooltip.show()">
        <span
          class="disclaimerBlock"
          #tooltip="matTooltip"
          matTooltip="We have taken efforts to gather and verify the information we have provided in the Career Advancement Portal. However it is possible that some errors are there in the data, or there may be changes in the data since the time we collected. Please make your own inquiries before taking actions based on the information provided."
          matTooltipPosition="above"
          matTooltipHideDelay="1500"
          matTooltipClass="disclaimerTooltip"
        >
          <mat-icon class="disclaimerIcon">info</mat-icon>
          <span class="disclaimerText">Disclaimer</span>
        </span>
      </div>
      <div class="tollFreeBlock">
        <div class="d-flex justify-content-center">
          Toll Free Career Helpline :
        </div>
        <div class="d-flex justify-content-center tollFreeNumber">
          1800 425 2425
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center">Powered by</div>
        <div class="col-md-12 d-flex justify-content-center">
          <img src="/assets/Dashboard/nSide.png" />
        </div>
        <div class="col-md-12 d-flex justify-content-center stikyfoot1">
          <span> V 4.2 </span>
        </div>
        <!-- <div class="col-md-12 mb-2 d-flex justify-content-center py-2">
          <span>
            <img
              *ngIf="isTokenVerified"
              src="/assets/Dashboard/turn-off.svg"
              style="cursor: pointer"
              (click)="logout()"
            />
          </span>
        </div> -->
      </div>
    </div>
    <!-- </div> -->
  </div>
  <div
    id="page-content-wrapper"
    class="dash-nav"
    [ngClass]="{
      'dash-nav-mobile': screenWidth < 450,
      'dash-nav': screenWidth >= 450
    }"
  >
    <div
      class="nav-bar-dash"
      [ngClass]="{
        'nav-bar-dash-mobile': screenWidth < 450,
        'nav-bar-dash': screenWidth >= 450
      }"
    >
      <!-- <div class="row d-flex justify-content-end align-center"> -->
      <!-- <div
          class="align-items-center cwikitxt"
          [ngClass]="{
            'col-md-7': isTokenVerified,
            'col-md-8': !isTokenVerified
          }"
          *ngIf="!deviceXs"
        >
          <span *ngIf="router.url != '/dashboard/careermithra'">
            Career Wiki
          </span>
        </div> -->
      <!-- <div class="d-flex justify-content-center"> -->
      <!-- <div class="d-flex"> -->
      <div class="navbar-dash-contents" *ngIf="!deviceXs">
        <span (click)="giveFeedBack()" class="Feedback">
          <span class="feedBackStyling"> Feedback </span>
        </span>
        <span
          *ngIf="isTokenVerified"
          class="userNametxt"
          [innerHtml]="trimToThreeAlphabet(responseVar)"
        ></span>
        <span class="homeIconAndTxt">
          <img
            src="assets/Auth/home.svg"
            routerLink="/master-dashboard"
            alt="home_icon"
          />
          <span class="userNametxt" routerLink="/master-dashboard">Home</span>
        </span>
        <!-- <span>
          <img
            *ngIf="isTokenVerified"
            src="/assets/Dashboard/turn-off.svg"
            style="cursor: pointer"
            (click)="logout()"
          />
        </span> -->
        <span class="homeIconAndTxt" (click)="logout()">
          <mat-icon *ngIf="isTokenVerified" class="logoutIcon"
            >power_settings_new</mat-icon
          >
          <span class="userNametxt">Logout</span>
        </span>
      </div>
      <!-- <div class="d-flex justify-content-end"> -->
      <!-- <div class="col"> -->
      <!-- <div class="homeIco"> -->

      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->

      <!-- ** mobile -->

      <div class="cwikitxt mt-2" *ngIf="deviceXs">
        <mat-toolbar>
          <mat-sidenav-container
            class="example-container"
            (backdropClick)="close('backdrop')"
          >
            <mat-sidenav
              #sidenav
              (keydown.escape)="close('escape')"
              disableClose
            >
              <div class="h-100 d-flex flex-column justify-content-between">
                <!-- ** side bar flex holder (mobile) -->
                <!-- ** sidenav bar options -->
                <div>
                  <!-- ** logo (mobile) -->
                  <div class="d-flex justify-content-center mt-3">
                    <img src="/assets/Dashboard/cap.png" />
                  </div>
                  <!-- ** options start (mobile) -->
                  <div
                    class="d-flex pl-4 justify-content-left sidebarOptions"
                    [ngClass]="
                      router.url == '/dashboard'
                        ? 'selectedClassRemoveThis'
                        : ''
                    "
                  >
                    <div class="row">
                      <div class="col-3 lh-ele">
                        <img
                          [src]="
                            router.url == '/dashboard'
                              ? '/assets/Dashboard/dashIco.svg'
                              : '/assets/Dashboard/dashIcoLight.svg'
                          "
                        />
                      </div>
                      <div
                        class="col-9 lh-ele"
                        style="padding-left: 19px"
                        (click)="close('toggle button')"
                        (click)="gotoDashboard()"
                        [ngClass]="
                          router.url == '/dashboard' ? 'bold' : 'normal'
                        "
                      >
                        Career Wiki
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
                  >
                    <div class="row">
                      <div class="col-2 lh-ele">
                        <img src="/assets/Dashboard/exam.svg" />
                      </div>
                      <div
                        class="col-9 lh-ele"
                        (click)="close('toggle button')"
                        (click)="takeTest()"
                        [ngClass]="optionActive == 4 ? 'bold' : 'normal'"
                      >
                        <div class="lh-ele-mobile">
                          Psychometric <br />
                          Assessment
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
                    [ngClass]="
                      router.url == '/dashboard/notifications'
                        ? 'selectedClassRemoveThis'
                        : ''
                    "
                  >
                    <div class="row">
                      <div class="col-3 lh-ele">
                        <img
                          [src]="
                            router.url == '/dashboard/notifications'
                              ? '/assets/Dashboard/darkBell.svg'
                              : '/assets/Dashboard/lightBell.svg'
                          "
                        />
                      </div>
                      <div
                        class="col-9 lh-ele"
                        style="padding-left: 16px"
                        (click)="close('toggle button')"
                        routerLink="/dashboard/notifications"
                        [ngClass]="
                          router.url == '/dashboard/notifications'
                            ? 'bold'
                            : 'normal'
                        "
                      >
                        Notifications
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
                    [ngClass]="
                      router.url == '/dashboard/resources'
                        ? 'selectedClassRemoveThis'
                        : ''
                    "
                  >
                    <div class="row">
                      <div class="col-4 lh-ele">
                        <img
                          [src]="
                            router.url == '/dashboard/resources'
                              ? '/assets/Dashboard/darkResource.svg'
                              : '/assets/Dashboard/lightResource.svg'
                          "
                        />
                      </div>
                      <div
                        class="col-8 lh-ele"
                        style="padding-left: 10px"
                        routerLink="/dashboard/resources"
                        (click)="close('toggle button')"
                        [ngClass]="
                          router.url == '/dashboard/resources'
                            ? 'bold'
                            : 'normal'
                        "
                      >
                        Resources
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
                    [ngClass]="
                      router.url == '/dashboard/careermithra'
                        ? 'selectedClassRemoveThis'
                        : ''
                    "
                  >
                    <div class="row">
                      <div class="col-4 lh-ele">
                        <img
                          [src]="
                            router.url == '/dashboard/careermithra'
                              ? '/assets/Dashboard/darkCmithra.svg'
                              : '/assets/careerMithra/lightcm.svg'
                          "
                        />
                      </div>
                      <div
                        class="col-8 lh-ele"
                        style="padding-left: 4px"
                        (click)="close('toggle button')"
                        routerLink="/dashboard/careermithra"
                        [ngClass]="
                          router.url == '/dashboard/careermithra'
                            ? 'bold'
                            : 'normal'
                        "
                      >
                        Career Mithra
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
                    [ngClass]="
                      router.url == '/dashboard/mentorship'
                        ? 'selectedClassRemoveThis'
                        : ''
                    "
                  >
                    <div class="row">
                      <div class="col-4 lh-ele">
                        <img
                          [src]="
                            router.url == '/dashboard/mentorship'
                              ? '/assets/mentorship/whitementor.svg'
                              : '/assets/mentorship/lightmentor.svg'
                          "
                        />
                      </div>
                      <div
                        class="col-8 lh-ele"
                        style="padding-left: 9px"
                        (click)="close('toggle button')"
                        routerLink="/dashboard/mentorship"
                        [ngClass]="
                          router.url == '/dashboard/mentorship'
                            ? 'bold'
                            : 'normal'
                        "
                      >
                        Mentorship
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex pl-4 justify-content-left sidebarOptions mt-4"
                    [ngClass]="
                      router.url == '/dashboard/profile'
                        ? 'selectedClassRemoveThis'
                        : ''
                    "
                  >
                    <div class="row">
                      <div class="col-3 lh-ele">
                        <img
                          [src]="
                            router.url == '/dashboard/profile'
                              ? '/assets/Dashboard/darkShield.svg'
                              : '/assets/Dashboard/shield .svg'
                          "
                        />
                      </div>
                      <div
                        class="col-9 lh-ele"
                        style="padding-left: 26px"
                        routerLink="/dashboard/profile"
                        (click)="close('toggle button')"
                        [ngClass]="
                          router.url == '/dashboard/profile' ? 'bold' : 'normal'
                        "
                      >
                        Profile
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ** bottom block (Disclaimer, tollfree and powered by) -->
                <div class="row logoutBlock1 mx-0">
                  <div class="disclaimerHolder" (click)="tooltip.show()">
                    <span
                      class="disclaimerBlock"
                      #tooltip="matTooltip"
                      matTooltip="We have taken efforts to gather and verify the information we have provided in the Career Advancement Portal. However it is possible that some errors are there in the data, or there may be changes in the data since the time we collected. Please make your own inquiries before taking actions based on the information provided"
                      matTooltipPosition="above"
                      matTooltipHideDelay="1500"
                      matTooltipClass="disclaimerTooltip"
                    >
                      <mat-icon class="disclaimerIcon">info</mat-icon>
                      <span class="disclaimerText">Disclaimer</span>
                    </span>
                  </div>
                  <div class="tollFreeBlock">
                    <div class="d-flex justify-content-center">
                      Toll Free Career Helpline :
                    </div>
                    <div class="d-flex justify-content-center tollFreeNumber">
                      1800 425 2425
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12 d-flex justify-content-center stikyfoot"
                    >
                      Powered by
                    </div>
                    <div class="col-md-12 d-flex justify-content-center">
                      <img src="/assets/Dashboard/nSide.png" />
                    </div>
                    <div
                      class="col-md-12 d-flex justify-content-center stikyfoot1 mt-1"
                    >
                      <span> V 4.2 </span>
                    </div>
                    <div
                      class="col-md-12 d-flex justify-content-center mt-2 mb-2 logoutButton py-2"
                    >
                      <span>
                        <img
                          *ngIf="isTokenVerified"
                          src="/assets/Dashboard/turn-off.svg"
                          (click)="logout()"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <p><button mat-button (click)="close('toggle button')">Toggle</button></p> -->
            </mat-sidenav>

            <mat-sidenav-content>
              <button mat-icon-button>
                <mat-icon class="sideicon-pos" (click)="sidenav.open()"
                  >menu</mat-icon
                >
              </button>
            </mat-sidenav-content>
          </mat-sidenav-container>
        </mat-toolbar>

        <!-- <div class="homeIco col-8">
          <div class="row">
            <span class="col-3 lh-ele"
              ><img src="assets/Auth/home.svg" *ngIf = "deviceXs" routerLink="/dashboard" alt="home_icon"
            /></span>
          </div>
        </div> -->
      </div>
      <!-- <span
          class="cwikitxt"
          style="margin-left: -10px; margin-top: 5px"
          *ngIf="deviceXs"
          >Career Wiki</span
        > -->
      <!-- <span (click)="giveFeedBack()" class="Feedback">
          <span class="feedBackStyling" *ngIf="deviceXs"> Feedback </span>
        </span>
        <span class="lh-ele1"
          ><img
            src="assets/Auth/home.svg"
            *ngIf="deviceXs"
            routerLink="/master-dashboard"
            alt="home_icon"
        /></span> -->

      <!-- <div class="col-md-5 d-flex justify-content-center">
          <div class="d-flex justify-content-end"> -->
      <div class="topNavRightOption" *ngIf="deviceXs">
        <span (click)="giveFeedBack()" class="Feedback">
          <span class="feedBackStyling"> Feedback </span>
        </span>
        <span class=""
          ><img
            src="assets/Auth/home.svg"
            routerLink="/master-dashboard"
            alt="home_icon"
        /></span>
        <!-- <span> -->
        <mat-icon *ngIf="isTokenVerified" class="logoutIcon" (click)="logout()"
          >power_settings_new</mat-icon
        >
        <!-- </span> -->
      </div>
      <!-- </div>
        </div> -->

      <!-- ** mobile app part done -->
      <!-- </div> -->
    </div>
    <div class="route-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
