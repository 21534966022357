<div class="col-md-12 mt-3" style="height: 117.62px !important">
  <!-- <div class="row d-flex px-3"> -->
  <div>
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
      <span class="backTitle"> Course List</span>
    </div>
  </div>
  <mat-form-field appearance="outline" class="searchField">
    <input
      type="text"
      matInput
      placeholder="Search Courses"
      [formControl]="myControl"
      [(ngModel)]="searchText"
    />
    <!-- [formControl]="myControl" -->
    <!-- <mat-autocomplete>
        <mat-option *ngFor="let option of options; index as j;let last=last" [value]="option">{{
          option
        }}</mat-option>
      </mat-autocomplete> -->
    <mat-icon matSuffix class="magBag"> search </mat-icon>
  </mat-form-field>
</div>

<div class="col-md-12">
  <div class="row" *ngIf="!deviceXs">
    <div class="col-md-2" style="height: 77.64px !important">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Stream</mat-label>
        <mat-select
          placeholder="Stream"
          (selectionChange)="filterCourse()"
          [(ngModel)]="streamFilter"
        >
          <span class="forAlignments">
            <mat-option value="all">All </mat-option>
            <mat-option
              [value]="j"
              *ngFor="let stream of streams; index as j"
              >{{ stream }}</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Eligibility</mat-label>
        <mat-select
          placeholder="Eligibility"
          (selectionChange)="filterCourse()"
          [(ngModel)]="eligibilityFilter"
        >
          <span class="forAlignments">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">8th Class Passed</mat-option>
            <mat-option [value]="1">10th Class Passed</mat-option>
            <mat-option [value]="2">10+2/Inter/PUC any subjects</mat-option>
            <mat-option [value]="3">10+2/Inter/PUC with Bi.P.C</mat-option>
            <mat-option [value]="4">10+2/Inter/PUC with M.P.C</mat-option>
            <mat-option [value]="5">10+2/Inter/PUC with Arts</mat-option>
            <mat-option [value]="6">10+2/Inter/PUC with Humanities</mat-option>
            <mat-option [value]="7">Degree</mat-option>
            <mat-option [value]="8">Diploma</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="col-md-2 ml-3">
        <mat-form-field appearance="outline">
          <mat-select placeholder="State">
            <mat-option [value]="'0'">Above 10000</mat-option>
            <mat-option [value]="'1'">Above 20000</mat-option>
            <mat-option [value]="'2'">Above 30000</mat-option>
            <mat-option [value]="'3'">Above 40000</mat-option>
            <mat-option [value]="'4'">Above 50000</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Category</mat-label>
        <mat-select
          placeholder="Category"
          (selectionChange)="filterCourse()"
          [(ngModel)]="categoryFilter"
        >
          <span class="forAlignments">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Vocational</mat-option>
            <mat-option [value]="1">Professional</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- for mobile app filters -->

  <div class="row" *ngIf="deviceXs">
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Stream</mat-label>
        <mat-select
          placeholder="Stream"
          (selectionChange)="filterCourse()"
          [(ngModel)]="streamFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option
              [value]="j"
              *ngFor="let stream of streams; index as j"
              >{{ stream }}</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">Eligibility</mat-label>
        <mat-select
          placeholder="Eligibility"
          (selectionChange)="filterCourse()"
          [(ngModel)]="eligibilityFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">8th Class Passed</mat-option>
            <mat-option [value]="1">10th Class Passed</mat-option>
            <mat-option [value]="2">10+2/Inter/PUC any subjects</mat-option>
            <mat-option [value]="3">10+2/Inter/PUC with Bi.P.C</mat-option>
            <mat-option [value]="4">10+2/Inter/PUC with M.P.C</mat-option>
            <mat-option [value]="5">10+2/Inter/PUC with Arts</mat-option>
            <mat-option [value]="6">10+2/Inter/PUC with Humanities</mat-option>
            <mat-option [value]="7">Degree</mat-option>
            <mat-option [value]="8">Diploma</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="col-md-2 ml-3">
        <mat-form-field appearance="outline">
          <mat-select placeholder="State">
            <mat-option [value]="'0'">Above 10000</mat-option>
            <mat-option [value]="'1'">Above 20000</mat-option>
            <mat-option [value]="'2'">Above 30000</mat-option>
            <mat-option [value]="'3'">Above 40000</mat-option>
            <mat-option [value]="'4'">Above 50000</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Category</mat-label>
        <mat-select
          placeholder="Category"
          (selectionChange)="filterCourse()"
          [(ngModel)]="categoryFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Vocational</mat-option>
            <mat-option [value]="1">Professional</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="BG mt-1 table-responsive">
    <table class="table">
      <tr class="colorWaraper">
        <th class="coloumnSeperator">
          <div class="col tableHItem">Course Name</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Stream</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Duration</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Qualification for Eligibility</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Careers</div>
        </th>
      </tr>
      <ng-container
        *ngFor="
          let option of filterOptions | filter : searchText;
          index as j;
          let Last = last
        "
      >
        <tr class="tableHItem4" (click)="coToCareerDetails(option['id'])">
          <td>
            <div
              class="col tableHItem2 py-2"
              style="margin: 0"
              [title]="option['name']"
            >
              {{ option["name"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ groupValues[option["stream"]] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 tableHItem4 py-2" style="margin: 0">
              {{ option["duration"] != null ? [option["duration"]] : "-" }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["eligibility_string"] }}
            </div>
          </td>
          <td>
            <div
              class="col tableHItem2 tableHItem4 py-2"
              style="margin: 0"
              (click)="coToCareerDetails(j)"
            >
              {{
                option["careers"] != null
                  ? option["careers"] != ""
                    ? [option["careers"]]
                    : "-"
                  : "-"
              }}
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
