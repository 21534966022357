<div class="row mt-6">
  <div class="col-md-12 d-flex justify-content-center cent-fgt">
    <img src="/assets/Auth/welcome.png" style="padding-right: 32px" />
  </div>
  <div class="col-md-12 loginText-fgt2">Forgot Password</div>
  <div class="col-md-12 d-flex justify-content-center mt-4 enterid-fgt">
    Enter your Username
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-4">
    <form [formGroup]="form" (ngSubmit)="forgot()">
      <div class="col-md-12 d-flex justify-content-center mt-4 xyz">
        <mat-form-field
          appearance="outline"
          class="authInput-fgt"
          (keyup)="authTypeValidator($event)"
          (focusout)="authTypeValidator($event)"
        >
          <mat-label class="selectFontText">Username/Phone</mat-label>
          <input formControlName="authDetail" matInput placeholder="Username" />
          <mat-icon matSuffix> <span class="eyeFake"></span></mat-icon>
          <mat-hint
            *ngIf="
              (form.controls.authDetail.touched || formGroupValidator) &&
              form.controls.authDetail.invalid
            "
            class="red"
            >{{ authDetailErrMessage }}</mat-hint
          >
        </mat-form-field>
      </div>
      <div
        *ngIf="phoneLinkedUsernames.length > 0"
        class="col-md-12 d-flex justify-content-center mt-4"
      >
        <mat-form-field appearance="outline" class="authInput">
          <mat-label class="selectFontText">Select Username</mat-label>
          <mat-select
            placeholder="Username"
            formControlName="linkedUserId"
            autoWidth="true"
            class="selectFontText"
          >
            <mat-option
              *ngFor="let user of phoneLinkedUsernames"
              [value]="user.id"
              class="selectFontText"
              >{{ user.username }}</mat-option
            >
          </mat-select>
          <mat-hint
            *ngIf="
              (form.controls.authDetail.touched || formGroupValidator) &&
              form.controls.authDetail.invalid
            "
            class="red"
            >Select Username*</mat-hint
          >
        </mat-form-field>
      </div>
      <div
        class="col-md-12 justify-content-center mt-4"
        [ngClass]="linkedMessage === '' ? 'd-none' : 'linkedMessageText'"
      >
        {{ linkedMessage }}
      </div>
      <div class="col-md-12 loginText-fgt mt-5">
        <button class="loginBtn-fgt" mat-raised-button>Next</button>
      </div>
    </form>
  </div>
  <div class="col-md-12 loginText-fgt">
    <span class="newMemberTxt-fgt">
      <a class="newMemberTxt-fgt" routerLink="/auth">Login </a>
    </span>
  </div>
</div>
