<div class="col-md-12 careerDetails" *ngIf="!deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/entrancelist"
        [queryParams]="{
          entryCourse: entryCourseFilter,
          national: nationalFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle">{{ EntranceDetailResponse?.name }} </span>
    </div>
    <div class="col-md-12 mt-2 careerReq mt-3">
      <div class="requirementsText mt-2">
        <span>Exam Application Details</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/student-hat.png" />
        </span>
      </div>
      <div class="col-md-12 d-flex justify-content-between px-0 mt-4">
        <div>
          <span class="reqt1">Application Open?: </span>
          <span class="reqta">
            <!-- {{ applicationValues[EntranceDetailResponse?.application_status] }} -->
            {{ applicationStatus }}
          </span>
        </div>
        <div>
          <span class="reqt1">Last Date: </span>
          <span class="reqta">
            {{
              EntranceDetailResponse?.notification != null
                ? [EntranceDetailResponse?.notification.active_until]
                : "-"
            }}
          </span>
        </div>
        <div>
          <span class="reqt1">National/State: </span>
          <span class="reqta">
            {{
              EntranceDetailResponse?.state?.name != null
                ? [EntranceDetailResponse?.state?.name]
                : "-"
            }}
          </span>
        </div>
        <div>
          <span class="reqt1">Application Process: </span>
          <span class="reqta">
            {{ [EntranceDetailResponse?.application_process] }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 wrapperLeft">
      <div class="col-md-12 sc2Parent2 mt-1">
        <div class="row">
          <div class="col-md-12">
            <span class="secondContainer2">Other details</span>
            <span class="pl-2">
              <img src="/assets/careerDetail/open-book.png" />
            </span>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Entry to Which Courses</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.entry_to_courses != null
                    ? [EntranceDetailResponse?.entry_to_courses]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Academic requirement to take exam</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.academic_requirements != null
                    ? [EntranceDetailResponse?.academic_requirements]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Syllabus</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.syllabus != null
                    ? [EntranceDetailResponse?.syllabus]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Approximate Notification time</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.approx_notification != null
                    ? [EntranceDetailResponse?.approx_notification]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Examination Fees</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.examination_fees != null
                    ? [EntranceDetailResponse?.examination_fees]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Source of Data</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.source_data != null
                    ? [EntranceDetailResponse?.source_data]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Date of data Gathered</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.date_of_data != null
                    ? [EntranceDetailResponse?.date_of_data]
                    : "NA"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5 wrapperRight">
      <div class="row">
        <div
          class="col-md-12 salaryDetails pb-3 mt-1"
          *ngIf="published == 0 && applicationStatus == 'Active'"
        >
          <div class="mt-3">
            <span class="roadMapText">Notification Details</span>
            <span class="pl-2">
              <img src="/assets/Dashboard/notification.svg" />
            </span>
          </div>
          <div class="mt-3">
            <div
              class="notificationDescription"
              [class.show]="show"
              [innerHTML]="EntranceDetailResponse?.notification?.name"
            >
              <!-- {{ EntranceDetailResponse?.notification?.name }} -->
            </div>
            <span class="viewText" (click)="show = !show">{{
              show ? "View less" : "View More"
            }}</span>
          </div>
          <div class="bottomTagsStyling mt-2">
            <span class="expectedSalary mr-2">tags: </span>
            <div
              *ngFor="let item of EntranceDetailResponse?.notification?.tags"
            >
              <div
                [innerHTML]="statustext(item)"
                [ngClass]="{
                  admissionTag: item === 2,
                  entranceTag: item === 0,
                  scholarshipTag: item === 1,
                  trainingTag: item === 3,
                  govtJobsTag: item === 4,
                  otherTag: item === 5
                }"
              ></div>
            </div>
          </div>
          <!-- <div class="mt-3">
            <span class="expectedSalary">Date information was obtained: </span>
            <span class="expectedSalary2 ml-1" style="word-wrap: break-word">
              {{
                EntranceDetailResponse?.date_of_data != null
                  ? [EntranceDetailResponse?.date_of_data]
                  : "NA"
              }}
            </span>
          </div> -->
          <div class="mt-3">
            <span class="expectedSalary">Attachments: </span>
            <span class="expectedSalary2 ml-1" style="word-wrap: break-word">
              <a
                href="{{
                  EntranceDetailResponse?.notification?.attachment_link
                }}"
                target="_blank"
                *ngIf="
                  EntranceDetailResponse?.notification?.attachment_link
                    ?.length > 0
                "
              >
                <img src="/assets/Dashboard/Icon metro-attachment.svg" />
              </a>
              <a
                *ngIf="
                  EntranceDetailResponse?.notification?.attachment_name != '' &&
                  EntranceDetailResponse?.notification?.attachment_link
                    ?.length > 0
                "
                href="{{
                  EntranceDetailResponse?.notification?.attachment_link
                }}"
                target="_blank"
                class="attachment"
              >
                {{ EntranceDetailResponse?.notification?.attachment_name }}
              </a>
              <a
                *ngIf="
                  EntranceDetailResponse?.notification?.attachment_name == '' &&
                  EntranceDetailResponse?.notification?.attachment_link == null
                "
                class="unDecorated"
              >
                NA
              </a>
              <a
                *ngIf="
                  EntranceDetailResponse?.notification?.attachment_name !==
                    '' &&
                  EntranceDetailResponse?.notification?.attachment_link
                    ?.length < 1
                "
                class="unDecorated"
              >
                {{ EntranceDetailResponse?.notification?.attachment_name }}
              </a>
            </span>
          </div>
        </div>
        <div class="col-md-12 salaryDetails pb-3 mt-3">
          <div class="mt-3">
            <span class="roadMapText">Contact Information</span>
            <span class="pl-2">
              <img src="/assets/careerDetail/desktop.png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Website: </span>
            <span class="expectedSalary2" style="word-wrap: break-word">
              <a
                href="{{ EntranceDetailResponse?.website }}"
                target="_blank"
                *ngIf="EntranceDetailResponse?.website?.length > 0"
              >
                {{
                  EntranceDetailResponse?.website != null
                    ? [EntranceDetailResponse?.website]
                    : "NA"
                }}
              </a>
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Useful Link: </span>
            <span class="expectedSalary2 ml-1" style="word-wrap: break-word">
              <a
                href="{{ EntranceDetailResponse?.useful_links }}"
                target="_blank"
                *ngIf="EntranceDetailResponse?.useful_links?.length > 0"
              >
                {{
                  EntranceDetailResponse?.useful_links != null
                    ? [EntranceDetailResponse?.useful_links]
                    : "NA"
                }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- For MobileApp -->
<div class="col-md-12 careerDetails" *ngIf="deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/entrancelist"
        [queryParams]="{
          entryCourse: entryCourseFilter,
          national: nationalFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle">{{ EntranceDetailResponse?.name }} </span>
    </div>
    <div class="col-md-12 mt-2 careerReq mt-3">
      <div class="requirementsText mt-2">
        <span>Exam Application Details</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/student-hat.png" />
        </span>
      </div>
      <div class="col-md-12 px-0 mt-3">
        <div class="mb-2">
          <span class="reqt1">Application Open?: </span>
          <span class="reqta">
            <!-- {{ applicationValues[EntranceDetailResponse?.application_status] }} -->
            {{ applicationStatus }}
          </span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Last Date: </span>
          <span class="reqta">
            {{
              EntranceDetailResponse?.application_last_date != null
                ? [EntranceDetailResponse?.application_last_date]
                : "-"
            }}
          </span>
        </div>
        <div class="mb-2">
          <span class="reqt1">National/State: </span>
          <span class="reqta">
            {{
              EntranceDetailResponse?.state?.name != null
                ? [EntranceDetailResponse?.state?.name]
                : "-"
            }}
          </span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Application Process: </span>
          <span class="reqta">
            {{ [EntranceDetailResponse?.application_process] }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 wrapperLeft">
      <div class="col-md-12 sc2Parent2 mt-1">
        <div class="row">
          <div class="col-md-12">
            <span class="secondContainer2">Other details</span>
            <span class="pl-2">
              <img src="/assets/careerDetail/open-book.png" />
            </span>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Entry to Which Courses</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.entry_to_courses != null
                    ? [EntranceDetailResponse?.entry_to_courses]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Academic requirement to take exam</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.academic_requirements != null
                    ? [EntranceDetailResponse?.academic_requirements]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Syllabus</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.syllabus != null
                    ? [EntranceDetailResponse?.syllabus]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Approximate Notification time</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.approx_notification != null
                    ? [EntranceDetailResponse?.approx_notification]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Examination Fees</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.examination_fees != null
                    ? [EntranceDetailResponse?.examination_fees]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Source of Data</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.source_data != null
                    ? [EntranceDetailResponse?.source_data]
                    : "NA"
                }}
              </p>
            </div>

            <div class="mt-3">
              <span>
                <img src="/assets/careerDetail/ellipse.png" />
              </span>
              <span class="ml-2"> Date of data Gathered</span>
              <p class="mt-3 overviewText pl-3 pb-2">
                {{
                  EntranceDetailResponse?.date_of_data != null
                    ? [EntranceDetailResponse?.date_of_data]
                    : "NA"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5 wrapperRight pt-0">
      <div class="row">
        <div class="col-md-12 salaryDetails pb-3 mt-1">
          <div class="mt-3">
            <span class="roadMapText">Contact Information</span>
            <span class="pl-2">
              <img src="/assets/careerDetail/desktop.png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Website: </span>
            <span class="expectedSalary2" style="word-wrap: break-word">
              <a
                href="{{ EntranceDetailResponse?.website }}"
                target="_blank"
                *ngIf="EntranceDetailResponse?.website?.length > 0"
              >
                {{
                  EntranceDetailResponse?.website != null
                    ? [EntranceDetailResponse?.website]
                    : "NA"
                }}
              </a>
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Useful Link: </span>
            <span class="expectedSalary2 ml-1" style="word-wrap: break-word">
              <a
                href="{{ EntranceDetailResponse?.useful_links }}"
                target="_blank"
                *ngIf="EntranceDetailResponse?.useful_links?.length > 0"
              >
                {{
                  EntranceDetailResponse?.useful_links != null
                    ? [EntranceDetailResponse?.useful_links]
                    : "NA"
                }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal boxModal bd-example-modal-lg"
  id="terms"
  data-keyboard="false"
  data-backdrop="static"
>
  <div class="modal-dialog modal-lg dialogStyling">
    <div class="modal-content" style="border-radius: 8px">
      <div
        class="modal-header"
        style="
          background-color: #fff4f2;
          border-bottom: none;
          padding-bottom: 7px;
        "
      >
        <span class="modal-title deleteNotificationStyling"
          >Notification alert</span
        >
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body p-0">
        <div class="col-md-12 col-xs-12 col-sm-12">
          <div
            class="textline py-3"
            [innerHTML]="EntranceDetailResponse?.notification?.name"
          >
            <!-- {{ EntranceDetailResponse?.notification?.name }} -->
          </div>
          <div class="tags pt-3 pb-3">Tags:</div>
          <div class="bottomTagsStyling">
            <div
              class="mobileTag"
              *ngFor="let item of EntranceDetailResponse?.notification?.tags"
            >
              <div
                [innerHTML]="statustext(item)"
                [ngClass]="{
                  admissionTag: item === 2,
                  entranceTag: item === 0,
                  scholarshipTag: item === 1,
                  trainingTag: item === 3,
                  govtJobsTag: item === 4,
                  otherTag: item === 5
                }"
              ></div>
            </div>
          </div>
          <!-- <div class="tags pt-3">Date information was obtained:</div>
          <div class="date py-3">
            {{
              EntranceDetailResponse?.notification?.active_until != (null || "")
                ? [EntranceDetailResponse?.notification?.active_until]
                : "NA"
            }}
          </div> -->
          <div class="tags pt-3">Attachments:</div>
          <div class="date py-3">
            <a
              href="{{ EntranceDetailResponse?.notification?.attachment_link }}"
              target="_blank"
              *ngIf="
                EntranceDetailResponse?.notification?.attachment_link?.length >
                0
              "
            >
              <img src="/assets/Dashboard/Icon metro-attachment.svg" />
            </a>

            <a
              *ngIf="
                EntranceDetailResponse?.notification?.attachment_name != '' &&
                EntranceDetailResponse?.notification?.attachment_link?.length >
                  0
              "
              href="{{ EntranceDetailResponse?.notification?.attachment_link }}"
              target="_blank"
              class="attachment"
            >
              {{ EntranceDetailResponse?.notification?.attachment_name }}
            </a>
            <a
              *ngIf="
                EntranceDetailResponse?.notification?.attachment_name == '' &&
                EntranceDetailResponse?.notification?.attachment_link == null
              "
              class="unDecorated"
            >
              NA
            </a>
            <a
              *ngIf="
                EntranceDetailResponse?.notification?.attachment_name !== '' &&
                EntranceDetailResponse?.notification?.attachment_link?.length <
                  1
              "
              class="unDecorated"
            >
              {{ EntranceDetailResponse?.notification?.attachment_name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
