import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skill-based-mentoring',
  templateUrl: './skill-based-mentoring.component.html',
  styleUrls: ['./skill-based-mentoring.component.css'],
})
export class SkillBasedMentoringComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
