<div class="p-5">
  <div class="d-flex totalHolder">
    <div class="d-flex fixedHolder">
      <div class="d-flex titleAndBackHolder">
        <div class="d-flex titleAndBackSection">
          <mat-icon
            class="backArrow cursor-pointer"
            routerLink="/adminDashboard"
            >arrow_back</mat-icon
          >
          <div class="">Entrance Exams</div>
        </div>
        <div class="d-flex displayOptions">
          <div class="cursor-pointer">Hide Options</div>
          <mat-slide-toggle color="primary" [(ngModel)]="hideOptions">
          </mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="!hideOptions" class="d-flex optionsHolder">
        <div class="d-flex align-center">
          <input
            class="searchInput"
            (keyup)="getEntrancesData(currentPage, true)"
            placeholder="Search Entrances"
            [formControl]="searchFormControl"
          />
          <mat-icon
            *ngIf="searchFormControl.value?.length > 0"
            class="exportClearBtn"
            (click)="clearSearch()"
            >close</mat-icon
          >
        </div>
        <div class="filterHolder d-flex">
          <div *ngFor="let filters of filtersList">
            <mat-form-field class="filterFormField">
              <mat-label class="filterLabel">{{
                filters.filterName
              }}</mat-label>
              <mat-select
                [placeholder]="filters.filterName"
                [formControl]="filters.filterFormControl"
                (selectionChange)="getEntrancesData(currentPage, false)"
              >
                <mat-option
                  *ngFor="let filterOption of filters.filterOptions"
                  [value]="filterOption"
                  >{{ filterOption.filterOptionValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="filterFormField">
              <mat-label class="filterLabel">States</mat-label>
              <mat-select
                placeholder="States"
                [formControl]="stateFormControl"
                (selectionChange)="getEntrancesData(currentPage, false)"
              >
                <mat-option
                  *ngFor="let stateObj of statesList"
                  [value]="stateObj.state_id"
                >
                  {{ stateObj.name }}</mat-option
                ></mat-select
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div [ngClass]="hideOptions ? 'tableHolder-full' : 'tableHolder'">
        <table
          mat-table
          [dataSource]="entrancesArr"
          class="mat-elevation-z8 w-full"
        >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="entry_to_courses">
            <th mat-header-cell *matHeaderCellDef>Entry To Courses</th>
            <td mat-cell *matCellDef="let element">
              {{ element.entry_to_courses }}
            </td>
          </ng-container>

          <ng-container matColumnDef="approx_notification">
            <th mat-header-cell *matHeaderCellDef>Approximate Notification</th>
            <td mat-cell *matCellDef="let element">
              {{ element.approx_notification }}
            </td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let element">
              {{ element.state?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="notification">
            <th mat-header-cell *matHeaderCellDef>Notification ID</th>
            <td mat-cell *matCellDef="let element">
              {{ element.notification?.id }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
  <ngb-pagination
    class="d-flex justify-content-center pagination_theme"
    [maxSize]="5"
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    (pageChange)="getEntrancesData(currentPage, false)"
  >
    <ng-template ngbPaginationPrevious>
      <img
        src="/assets/Dashboard/pageArrowPrev.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
    <ng-template ngbPaginationNext>
      <img
        src="/assets/Dashboard/pageArrowNext.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
  </ngb-pagination>
</div>
