<div class="col-md-12 mt-3" style="height: 117.62px !important">
  <div>
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
      <span class="backTitle"> EntranceExam List</span>
    </div>
  </div>
  <mat-form-field appearance="outline" class="searchField">
    <input
      type="text"
      matInput
      placeholder="Search EntranceExams"
      [formControl]="myControl"
      [(ngModel)]="searchText"
    />
    <!-- [formControl]="myControl" -->
    <!-- <mat-autocomplete>
        <mat-option *ngFor="let option of options; index as j;let last=last" [value]="option">{{
          option
        }}</mat-option>
      </mat-autocomplete> -->
    <mat-icon matSuffix class="magBag"> search </mat-icon>
  </mat-form-field>
</div>

<div class="col-md-12">
  <div class="row" *ngIf="!deviceXs">
    <div class="col-md-2" style="height: 77.64px !important">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Entry to Which Course</mat-label>
        <mat-select
          placeholder="Entry to Which Course"
          (selectionChange)="filterCareer()"
          [(ngModel)]="entryCourseFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'0'">10th Grade and Lower Grades</mat-option>
            <mat-option [value]="'1'">10+2/Intermediate/PUC</mat-option>
            <!-- <mat-option [value]="'2'">Arts, Design, Creative</mat-option> -->
            <mat-option [value]="'2'">Polytechnic Diploma</mat-option>
            <mat-option [value]="'3'"
              >BE/BTech, BTech (Integrated), B.Tech Lateral Entry,
              B.Arch</mat-option
            >
            <mat-option [value]="'Pharmacy'">Pharmacy</mat-option>
            <mat-option [value]="'Agriculture'"
              >Agriculture and Related</mat-option
            >
            <mat-option [value]="'4'">B.Ed, Diploma in Education</mat-option>
            <mat-option [value]="'5'">BA, BSc, BCom</mat-option>
            <mat-option [value]="'6'">BPEd, Diploma in Phys Ed</mat-option>
            <mat-option [value]="'7'">Law</mat-option>
            <mat-option [value]="'8'">MA, MSc, MCom</mat-option>
            <mat-option [value]="'9'">MTech, M.Pharm, M. Arch</mat-option>
            <mat-option [value]="'10'">MBA, MCA</mat-option>
            <mat-option [value]="'11'">Other Graduate</mat-option>
            <mat-option [value]="'12'">Other Post Graduate</mat-option>
            <mat-option [value]="'13'">Medical and Related</mat-option>
          </span>
          <mat-option [value]="'14'">Ph.D</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">State/National</mat-label>
        <mat-select
          placeholder="State/National"
          (selectionChange)="filterCareer()"
          [(ngModel)]="nationalFilter"
        >
          <mat-option value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let state of states">
            <mat-option [value]="state.state_id" *ngIf="state.state_id != 99">{{
              state.name
            }}</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="col-md-2 ml-3">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Handicapped Eligibility" (selectionChange) = "filterScholarship()" [(ngModel)] = "handicappedFilter">
            <mat-option value='all'>All </mat-option>
            <mat-option [value]="0">Yes</mat-option>
            <mat-option [value]="1">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 ml-3">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Application Eligibility" (selectionChange) = "filterScholarship()" [(ngModel)] = "applicationFilter">
            <mat-option value='all'>All </mat-option>
            <mat-option [value]="0">Active</mat-option>
            <mat-option [value]="1">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
  </div>

  <!-- for mobile app filter -->

  <div class="row" *ngIf="deviceXs">
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Entry to Which Course</mat-label>
        <mat-select
          placeholder="Entry to Which Course"
          (selectionChange)="filterCareer()"
          [(ngModel)]="entryCourseFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'0'">10th Grade and Lower Grades</mat-option>
            <mat-option [value]="'1'">10+2/Intermediate/PUC</mat-option>
            <!-- <mat-option [value]="'2'">Arts, Design, Creative</mat-option> -->
            <mat-option [value]="'2'">Polytechnic Diploma</mat-option>
            <mat-option [value]="'3'"
              >BE/BTech, BTech (Integrated), B.Tech Lateral Entry,
              B.Arch</mat-option
            >
            <mat-option [value]="'Pharmacy'">Pharmacy</mat-option>
            <mat-option [value]="'Agriculture'"
              >Agriculture and Related</mat-option
            >
            <mat-option [value]="'4'">B.Ed, Diploma in Education</mat-option>
            <mat-option [value]="'5'">BA, BSc, BCom</mat-option>
            <mat-option [value]="'6'">BPEd, Diploma in Phys Ed</mat-option>
            <mat-option [value]="'7'">Law</mat-option>
            <mat-option [value]="'8'">MA, MSc, MCom</mat-option>
            <mat-option [value]="'9'">MTech, M.Pharm, M. Arch</mat-option>
            <mat-option [value]="'10'">MBA, MCA</mat-option>
            <mat-option [value]="'11'">Other Graduate</mat-option>
            <mat-option [value]="'12'">Other Post Graduate</mat-option>
            <mat-option [value]="'13'">Medical and Related</mat-option>
            <mat-option [value]="'14'">Ph.D</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">State/National</mat-label>
        <mat-select
          placeholder="State/National"
          (selectionChange)="filterCareer()"
          [(ngModel)]="nationalFilter"
        >
          <mat-option value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let state of states">
            <mat-option [value]="state.state_id" *ngIf="state.state_id != 99">{{
              state.name
            }}</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="col-6">
        <mat-form-field appearance="outline" class="auth">
          <mat-select placeholder="Handicapped Eligibility" (selectionChange) = "filterScholarship()" [(ngModel)] = "handicappedFilter">
            <mat-option value='all'>All </mat-option>
            <mat-option [value]="0">Yes</mat-option>
            <mat-option [value]="1">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline" class="auth">
          <mat-select placeholder="Application Eligibility" (selectionChange) = "filterScholarship()" [(ngModel)] = "applicationFilter">
            <mat-option value='all'>All </mat-option>
            <mat-option [value]="0">Active</mat-option>
            <mat-option [value]="1">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
  </div>

  <div class="BG mt-1 table-responsive">
    <table class="table">
      <tr class="colorWaraper">
        <th class="coloumnSeperator"><div class="col tableHItem">Name</div></th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Entry to Course</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">National/State</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Approximate Notification Time</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Applications Open ?</div>
        </th>
      </tr>
      <ng-container
        *ngFor="
          let option of filterOptions | filter : searchText;
          index as j;
          let Last = last
        "
      >
        <tr class="tableHItem4" (click)="coToCareerDetails(option['id'])">
          <td>
            <div
              class="col tableHItem2 py-2"
              style="margin: 0"
              [title]="option['name']"
            >
              {{ option["name"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["entry_to_courses"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["state"].name != null ? [option["state"].name] : "-" }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 tableHItem4 py-2" style="margin: 0">
              {{
                option["approx_notification"] != null
                  ? [option["approx_notification"]]
                  : "-"
              }}
            </div>
          </td>

          <td>
            <div class="col tableHItem2 pt-3" style="margin: 0">
              {{
                option["application_status"] != null
                  ? applicationValues[option["application_status"]]
                  : "-"
              }}
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
