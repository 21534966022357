import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '../api-calls.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-scholarshiplist',
  templateUrl: './scholarshiplist.component.html',
  styleUrls: ['./scholarshiplist.component.css'],
})
export class ScholarshiplistComponent implements OnInit {
  @Input() deviceXs: boolean;
  //Vars
  options: any = [];
  filterOptions: any = [];
  academicFilter = '';
  genderFilter: any = '';
  handicappedFilter: any = '';
  applicationFilter: any = '';
  nationalFilter: any;
  searchText;

  states: any = [];

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver,
    public globalSservice: GlobalService,
    public route: ActivatedRoute
  ) {}

  //Form Control
  myControl = new FormControl();
  mediaSub: Subscription;
  academicFilterSub: Subscription;
  genderFilterSub: Subscription;
  handicappedFilterSub: Subscription;
  applicationFilterSub: Subscription;
  queryParam: Subscription;

  getAllJobs() {
    // console.log("hello")
    // console.log(this.nationalFilter)
    this.apiCallService
      .getScholarships(this.nationalFilter, this.applicationFilter)
      .subscribe(
        (res) => {
          console.log(res);
          this.options = res;
          this.filterScholarship();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getAllStates() {
    this.apiCallService.getStates().subscribe(
      (res) => {
        console.log(res);
        this.states = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  coToScholarshipDetails(jobId) {
    // let jobId = this.options[j]['id']
    // alert(jobId)
    window.scroll(0, 0);
    this.router.navigate(['/dashboard/scholarshiplistDetail/'], {
      queryParams: { id: jobId },
    });
  }

  ngOnInit(): void {
    // this.apiCallService.verifyLogin();
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    this.queryParam = this.route.queryParams.subscribe((params: Params) => {
      this.academicFilter = params.academic ? params.academic : '';

      const gender = params.gender ? parseInt(params.gender) : parseInt('');
      this.genderFilter = isNaN(gender)
        ? params.gender === 'all'
          ? 'all'
          : ''
        : gender;

      const handicapped = params.handicapped
        ? parseInt(params.handicapped)
        : parseInt('');
      this.handicappedFilter = isNaN(handicapped)
        ? params.handicapped === 'all'
          ? 'all'
          : ''
        : handicapped;

      const application = params.application
        ? parseInt(params.application)
        : parseInt('');
      this.applicationFilter = isNaN(application)
        ? params.application === 'all'
          ? 'all'
          : ''
        : application;

      const state = params.state ? parseInt(params.state) : parseInt('');
      this.nationalFilter = isNaN(state)
        ? params.state === 'all'
          ? 'all'
          : ''
        : state;
    });
    this.getAllJobs();
    this.getAllStates();
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  filterScholarship() {
    console.log('Works !!');
    let filteredScholarship: any = [...this.options];

    this.globalSservice.setAcademicScholarshipFilter(this.academicFilter);
    this.globalSservice.setGenderScholarshipFilter(this.genderFilter);
    this.globalSservice.setHandicappedScholarshipFilter(this.handicappedFilter);
    this.globalSservice.setApplicationScholarshipFilter(this.applicationFilter);
    this.globalSservice.setstateScholarshipFilter(this.nationalFilter);
    if (
      this.academicFilter !== '' ||
      this.genderFilter !== '' ||
      this.handicappedFilter !== '' ||
      this.applicationFilter !== '' ||
      this.nationalFilter !== ''
    ) {
      this.router.navigate(['/dashboard/scholarshiplist'], {
        queryParams: {
          academic: this.academicFilter,
          gender: this.genderFilter,
          handicapped: this.handicappedFilter,
          application: this.applicationFilter,
          state: this.nationalFilter,
        },
      });
    }

    if (this.academicFilter !== '' && this.academicFilter !== 'all') {
      if (this.academicFilter === '10') {
        filteredScholarship = filteredScholarship.filter(
          (option) => option.filter_acad_10_and_below === 0
        );
      }
      if (this.academicFilter === '12') {
        filteredScholarship = filteredScholarship.filter(
          (option) => option.filter_acad_11_12_iti_poly === 0
        );
      }
      if (this.academicFilter === 'degree') {
        filteredScholarship = filteredScholarship.filter(
          (option) => option.filter_degree_pg === 0
        );
      }
    }

    if (this.genderFilter !== '' && this.genderFilter !== 'all') {
      filteredScholarship = filteredScholarship.filter(
        (option) =>
          option.gender_eligibility === this.genderFilter ||
          option.gender_eligibility === 3
      );
    }

    if (this.handicappedFilter !== '' && this.handicappedFilter !== 'all') {
      filteredScholarship = filteredScholarship.filter(
        (option) => option.physically_handicaped === this.handicappedFilter
      );
    }
    // if(this.applicationFilter !== ''  && this.applicationFilter !== 'all')
    // {
    //   filteredScholarship = filteredScholarship.filter(option => option.status === this.applicationFilter)
    // }
    this.filterOptions = filteredScholarship;
    console.log(filteredScholarship);
  }
}
