<div class="d-flex viewOptionsHolder pt-5 px-4">
  <div class="font-600">Select a view</div>
  <mat-radio-group
    [(ngModel)]="selectedDisplayView"
    aria-label="Select an option"
    class="d-flex scholarshipViewSelector"
  >
    <mat-radio-button
      *ngFor="let displayObj of notificationsDisplayList"
      [value]="displayObj.displayValue"
      >{{ displayObj.displayName }}</mat-radio-button
    >
  </mat-radio-group>
</div>
<div *ngIf="selectedDisplayView === 0" class="col-md-12" id="wrapper">
  <div class="d-flex" style="padding-top: 1rem">
    <div class="col-md-8 mb-3 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/adminDashboard"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> Notification</span>
    </div>
    <div class="col-md-4 d-flex justify-content-end">
      <mat-form-field appearance="outline">
        <mat-select
          (selectionChange)="filterNotificationType()"
          [(ngModel)]="notificationTypeFilter"
        >
          <mat-option value="1">Active Notifications</mat-option>
          <mat-option value="5">Inactive Notifications</mat-option>
          <mat-option value="2">Expired Notifications</mat-option>
          <mat-option value="3">Unpublished Notifications</mat-option>
          <mat-option value="4">published Notifications</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex" style="padding-top: 0.1rem">
    <div class="col-md-10 d-flex justify-content-start">
      <mat-form-field
        appearance="outline"
        class="searchField"
        style="width: 80%"
      >
        <input
          type="text"
          matInput
          placeholder="Search Notifications"
          [formControl]="myControl"
          (input)="filterNotificationType()"
          [(ngModel)]="searchText"
        />
        <mat-icon
          matSuffix
          src="/assets/Dashboard/pageArrowNext.svg"
          class="magBag"
        >
          search
        </mat-icon>
      </mat-form-field>
    </div>
    <div class="col-md-1 pt-2 mb-5 d-flex justify-content-end">
      <span>
        <button
          class="loginBtn"
          mat-raised-button
          type="submit"
          routerLink="/adminDashboard/deleteNotifications"
        >
          Delete Notifications
        </button>
      </span>
    </div>
    <div class="col-md-1 pt-2 mb-5 d-flex justify-content-end">
      <span>
        <button
          (click)="openDialog()"
          class="loginBtn"
          mat-raised-button
          type="submit"
        >
          Add
        </button>
      </span>
    </div>
  </div>

  <div class="col-md-12">
    <div class="BG table-responsive">
      <table class="table">
        <tr class="colorWaraper">
          <th class="coloumnSeperator">
            <div class="col tableHItem">Publish/unpublish</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">ID</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Notification ID</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Notification Description</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Notification Type</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Active Until</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Academic Level</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">State Name</div>
          </th>
          <th class="coloumnSeperator">
            <div class="col tableHItem">Attachment Name/Upload</div>
          </th>
        </tr>
        <ng-container
          *ngFor="
            let option of notificationResponse | filter : searchText;
            index as j;
            let Last = last
          "
        >
          <tr class="tableHItem4">
            <td>
              <div
                class="col tableHItem2 py-2"
                style="margin: 0"
                [title]="option['name']"
              >
                <label class="switch" *ngIf="[option['published']] == 1">
                  <input
                    type="checkbox"
                    unchecked
                    (change)="postcallId(option['id'])"
                  />
                  <span class="slider round"></span>
                </label>

                <label class="switch" *ngIf="[option['published']] == 0">
                  <input
                    type="checkbox"
                    checked
                    (change)="postcallId(option['id'])"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
            <td>
              <div
                class="col tableHItem2 py-2"
                style="margin: 0"
                [title]="option['name']"
              >
                {{ option["id"] }}
              </div>
            </td>
            <td>
              <div
                class="col tableHItem2 py-2"
                style="margin: 0"
                [title]="option['name']"
              >
                {{ option["notification_id"] }}
              </div>
            </td>
            <td>
              <div
                class="col tableHItem2 py-2"
                style="margin: 0"
                [title]="option['name']"
              >
                {{ option["name"] }}
              </div>
            </td>
            <td>
              <div
                class="col tableHItem2 py-2"
                style="margin: 0"
                *ngFor="let item of option?.tags"
              >
                <div
                  [innerHTML]="statustext(item)"
                  [ngClass]="{
                    admissionTag: item === 2,
                    entranceTag: item === 0,
                    scholarshipTag: item === 1,
                    trainingTag: item === 3,
                    govtJobsTag: item === 4,
                    otherTag: item === 5
                  }"
                ></div>
              </div>
            </td>
            <td>
              <div class="col tableHItem2 py-2" style="margin: 0">
                {{ option["active_until"] }}
              </div>
            </td>
            <td>
              <div
                class="col tableHItem2 tableHItem4 py-2"
                style="margin: 0"
                *ngFor="let academic of option?.academic_levels"
              >
                {{ academic !== null ? groupAcademicValues[academic] : "" }}
              </div>
            </td>

            <td>
              <div
                class="col tableHItem2 pt-3"
                style="margin: 0"
                *ngFor="let State of option?.states"
              >
                {{ State.name }}
              </div>
            </td>
            <td>
              <div class="col tableHItem2 py-2" style="margin: 0">
                {{ option["attachment_name"] }}
              </div>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>

  <ngb-pagination
    class="d-flex justify-content-center pagination_theme"
    [maxSize]="5"
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    (pageChange)="onPageChange(currentPage)"
  >
    <ng-template ngbPaginationPrevious>
      <img
        src="/assets/Dashboard/pageArrowPrev.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
    <ng-template ngbPaginationNext>
      <img
        src="/assets/Dashboard/pageArrowNext.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
  </ngb-pagination>
</div>
<div *ngIf="selectedDisplayView === 1">
  <app-admin-notification-mappings></app-admin-notification-mappings>
</div>
