<div class="row p-0 m-0">
  <div class="col-md-12">
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/mentorship/"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> Project Based Mentoring</span>
    </div>
  </div>
</div>
<!-- <div class="row"> -->

<div class="col-12 px-4">
  <div
    class="row p-0 m-0"
    style="
      background-color: white;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    "
  >
    <!-- <div class="row d-flex pb-5"> -->
    <div class="col-md-3 imageStyling">
      <!-- added margin top , bottom - 3 #G -->
      <img src="/assets/mentorship/project.png" width="100%" />
      <!-- added width: 100% #G-->
    </div>
    <div class="col-md-8 ml-xl-3 boxClass">
      <!-- added "ml-xl-3" #G-->
      <div class="col-12 careerClass">Project Based Mentoring</div>
      <div class="col-12 careerMatter pt-2 pb-3">
        <div>
          Project Mentoring refers to providing technical mentoring support to
          the students of the graduation level & above. The major aim is to
          inspire students to take up the projects and complete them with the
          guidance of domain experts so that students would get hands-on
          experience and become future ready.

          <div class="row d-flex pt-4">
            <div class="col-md-3 col-5 key">Time Period</div>
            <span class="colon"> : </span>
            <div class="col-md-7 col-6 value">
              3-6 Months Based on the project
            </div>

            <div class="col-md-3 col-5 key pt-2">Eligibility</div>
            <span class="colon pt-2"> : </span>
            <div class="col-md-7 col-6 value pt-2">Graduation & above</div>

            <div class="col-md-3 col-5 key pt-2">Areas of mentoring</div>
            <span class="colon pt-2"> : </span>
            <div class="col-md-7 col-6 value pt-2">
              largely focusing on the technical aspects, project development &
              execution
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
