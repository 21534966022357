import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '../api-calls.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Districts } from './districts';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GlobalService } from '../global.service';
import { param } from 'jquery';

@Component({
  selector: 'app-hostels-list',
  templateUrl: './hostels-list.component.html',
  styleUrls: ['./hostels-list.component.css'],
})
export class HostelsListComponent implements OnInit {
  @Input() deviceXs: boolean;
  //Vars
  options: any = [];
  filterOptions: any = [];

  searchText;

  academicFilter1 = '';
  genderFilter: any = '';
  casteFilter: any = '';
  stateFilter: any = '';
  districtFilter: any = '';
  id = '';

  loadedDistricts: any = Districts['Andhra_Pradesh']
    .concat(Districts['Telangana'])
    .concat(Districts['Karnataka'])
    .sort();

  states: any = [];
  districts: any = [];

  groupValues = {
    0: 'National',
    1: 'Andhra Pradesh',
    2: 'Telangana',
    3: 'Karnataka',
  };

  groupValuesGender = {
    0: 'Boys',
    1: 'Girls',
    2: 'Others',
  };

  collectionSize: number = 0;
  // itemsPerPage=10;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  currentPageURL: string = null;
  currentFilterURL: string = null;
  filter: serverFilter[] = [];
  // searching = false;

  //Form Control
  myControl = new FormControl();
  mediaSub: Subscription;
  academicFilterSub: Subscription;
  genderFilterSub: Subscription;
  casteFilterSub: Subscription;
  stateFilterSub: Subscription;
  districtFilterSub: Subscription;
  queryParam: Subscription;
  isBack: boolean = false;

  dasboardQueryparamStateId = null;

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver,
    private SpinnerService: NgxSpinnerService,
    public globalSservice: GlobalService,
    public route: ActivatedRoute
  ) {}

  getAllJobs() {
    console.log('hello');
    //  this.SpinnerService.show();
    this.apiCallService.getHostels().subscribe(
      (res) => {
        console.log(res);
        this.options = res['results'];
        this.collectionSize = res['count'];
        this.filterOptions = [...this.options];
        // this.SpinnerService.show();
      },
      (err) => {
        console.log(err);
        //  this.SpinnerService.show();
      }
    );
  }

  coHostelDetails(jobId) {
    // let jobId = this.options[j]['id']
    // alert(jobId)
    window.scroll(0, 0);
    this.router.navigate(['/dashboard/hostellistdetail/'], {
      queryParams: { id: jobId },
    });
  }

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );

    // this.apiCallService.verifyLogin();

    this.route.queryParams.subscribe((param) => {
      console.log(param.state);
      this.dasboardQueryparamStateId = param.state;
      console.log(this.dasboardQueryparamStateId);
    });
    this.getAllDistricts(this.dasboardQueryparamStateId);

    //  this.SpinnerService.show();
    //  setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.SpinnerService.hide()
    // }, 3000);

    this.queryParam = this.route.queryParams.subscribe((params: Params) => {
      this.academicFilter1 = params.academic ? params.academic : '';

      const gender = params.gender ? parseInt(params.gender) : parseInt('');
      this.genderFilter = isNaN(gender)
        ? params.gender === 'all'
          ? 'all'
          : ''
        : gender;

      const caste = params.caste ? parseInt(params.caste) : parseInt('');
      this.casteFilter = isNaN(caste)
        ? params.caste === 'all'
          ? 'all'
          : ''
        : caste;

      const state = params.state ? parseInt(params.state) : parseInt('');
      this.stateFilter = isNaN(state)
        ? params.state === 'all'
          ? 'all'
          : ''
        : state;

      // this.districtFilter = params.districti ? params.districti : ""

      const district = params.district
        ? parseInt(params.district)
        : parseInt('');
      this.districtFilter = isNaN(district)
        ? params.district === 'all'
          ? 'all'
          : ''
        : district;
    });

    var isParam = false;

    if (this.academicFilter1 !== '') {
      this.filterAcademicLevel(true);
      isParam = true;
    }

    if (this.genderFilter !== '') {
      this.filterGenderRestriction(true);
      isParam = true;
    }

    if (this.casteFilter !== '') {
      this.filterCaste(true);
      isParam = true;
    }

    if (this.districtFilter !== '') {
      this.isBack = true;
    }

    if (this.stateFilter !== '') {
      this.filterState(true);
      isParam = true;
    }

    if (this.districtFilter !== '') {
      this.filterDistrict(true);
      isParam = true;
    }

    if (isParam) {
      this.filterData();
    } else {
      this.getAllJobs();
    }

    this.getAllStates();
    // this.getAllDistricts(this.id)
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  getAllStates() {
    this.apiCallService.getStates().subscribe(
      (res) => {
        console.log(res);
        this.states = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllDistricts(id) {
    this.apiCallService.getDistricts(id).subscribe(
      (res) => {
        console.log(res);
        this.districts = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  filterSearch() {
    this.addOrUpdateFilter({ name: 'name', value: this.searchText });
    // console.log("searchfilter");
    // this.searching =  true;

    this.filterData();
  }

  filterAcademicLevel(dontFilter?: boolean) {
    this.globalSservice.setAcademicHostelFilter(this.academicFilter1);
    this.globalSservice.setGenderHostelilter(this.genderFilter);
    this.globalSservice.setCasteHostelilter(this.casteFilter);
    this.globalSservice.setStateHostelilter(this.stateFilter);
    this.globalSservice.setDistrictHostelilter(this.districtFilter);
    if (
      this.academicFilter1 !== '' ||
      this.genderFilter !== '' ||
      this.casteFilter !== '' ||
      this.stateFilter !== '' ||
      this.districtFilter !== ''
    ) {
      this.router.navigate(['/dashboard/hostellist'], {
        queryParams: {
          academic: this.academicFilter1,
          gender: this.genderFilter,
          caste: this.casteFilter,
          state: this.stateFilter,
          district: this.districtFilter,
        },
      });
    }
    console.log('TESTING ', this.academicFilter1);
    if (this.academicFilter1 == '0') {
      this.addOrUpdateFilter({ name: 'plus2_academic_filter', value: 0 });
    } else if (this.academicFilter1 == '1') {
      this.addOrUpdateFilter({ name: 'degree_academic_filter', value: 0 });
    } else if (this.academicFilter1 == '2') {
      this.addOrUpdateFilter({ name: 'pg_academic_filter', value: 0 });
    } else {
      this.addOrUpdateFilter();
    }

    if (!dontFilter) {
      this.filterData();
    }
  }
  filterGenderRestriction(dontFilter?: boolean) {
    this.globalSservice.setAcademicHostelFilter(this.academicFilter1);
    this.globalSservice.setGenderHostelilter(this.genderFilter);
    this.globalSservice.setCasteHostelilter(this.casteFilter);
    this.globalSservice.setStateHostelilter(this.stateFilter);
    this.globalSservice.setDistrictHostelilter(this.districtFilter);
    if (
      this.academicFilter1 !== '' ||
      this.genderFilter !== '' ||
      this.casteFilter !== '' ||
      this.stateFilter !== '' ||
      this.districtFilter !== ''
    ) {
      this.router.navigate(['/dashboard/hostellist'], {
        queryParams: {
          academic: this.academicFilter1,
          gender: this.genderFilter,
          caste: this.casteFilter,
          state: this.stateFilter,
          district: this.districtFilter,
        },
      });
    }

    this.addOrUpdateFilter({
      name: 'gender_restrictions',
      value: this.genderFilter,
    });
    if (!dontFilter) {
      this.filterData();
    }
  }
  filterCaste(dontFilter?: boolean) {
    this.globalSservice.setAcademicHostelFilter(this.academicFilter1);
    this.globalSservice.setGenderHostelilter(this.genderFilter);
    this.globalSservice.setCasteHostelilter(this.casteFilter);
    this.globalSservice.setStateHostelilter(this.stateFilter);
    this.globalSservice.setDistrictHostelilter(this.districtFilter);
    if (
      this.academicFilter1 !== '' ||
      this.genderFilter !== '' ||
      this.casteFilter !== '' ||
      this.stateFilter !== '' ||
      this.districtFilter !== ''
    ) {
      this.router.navigate(['/dashboard/hostellist'], {
        queryParams: {
          academic: this.academicFilter1,
          gender: this.genderFilter,
          caste: this.casteFilter,
          state: this.stateFilter,
          district: this.districtFilter,
        },
      });
    }

    this.addOrUpdateFilter({
      name: 'caste_restrictions',
      value: this.casteFilter,
    });
    if (!dontFilter) {
      this.filterData();
    }
  }
  filterState(dontFilter?: boolean) {
    if (!this.isBack) {
      this.districtFilter = '';
      this.addOrUpdateFilter({
        name: 'district_id',
        value: this.districtFilter,
      });
    } else {
      this.isBack = false;
    }
    this.globalSservice.setAcademicHostelFilter(this.academicFilter1);
    this.globalSservice.setGenderHostelilter(this.genderFilter);
    this.globalSservice.setCasteHostelilter(this.casteFilter);
    this.globalSservice.setStateHostelilter(this.stateFilter);
    this.globalSservice.setDistrictHostelilter(this.districtFilter);
    if (
      this.academicFilter1 !== '' ||
      this.genderFilter !== '' ||
      this.casteFilter !== '' ||
      this.stateFilter !== '' ||
      this.districtFilter !== ''
    ) {
      this.router.navigate(['/dashboard/hostellist'], {
        queryParams: {
          academic: this.academicFilter1,
          gender: this.genderFilter,
          caste: this.casteFilter,
          state: this.stateFilter,
          district: this.districtFilter,
        },
      });
    }
    this.addOrUpdateFilter({ name: 'state', value: this.stateFilter });
    if (!dontFilter) {
      this.filterData();
    }
    if (this.stateFilter === 'all') {
      this.getAllDistricts(this.id);
    }
  }
  filterDistrict(dontFilter?: boolean) {
    this.globalSservice.setAcademicHostelFilter(this.academicFilter1);
    this.globalSservice.setGenderHostelilter(this.genderFilter);
    this.globalSservice.setCasteHostelilter(this.casteFilter);
    this.globalSservice.setStateHostelilter(this.stateFilter);
    this.globalSservice.setDistrictHostelilter(this.districtFilter);
    if (
      this.academicFilter1 !== '' ||
      this.genderFilter !== '' ||
      this.casteFilter !== '' ||
      this.stateFilter !== '' ||
      this.districtFilter !== ''
    ) {
      this.router.navigate(['/dashboard/hostellist'], {
        queryParams: {
          academic: this.academicFilter1,
          gender: this.genderFilter,
          caste: this.casteFilter,
          state: this.stateFilter,
          district: this.districtFilter,
        },
      });
    }
    this.addOrUpdateFilter({ name: 'district_id', value: this.districtFilter });
    if (!dontFilter) {
      this.filterData();
    }
  }

  addOrUpdateFilter(data?: serverFilter) {
    let done = false;
    let af1Index = this.filter.findIndex(
      (x) => x.name == 'plus2_academic_filter'
    );
    let af2Index = this.filter.findIndex(
      (x) => x.name == 'degree_academic_filter'
    );
    let af3Index = this.filter.findIndex((x) => x.name == 'pg_academic_filter');
    if (!data) {
      af1Index > -1 ? this.filter.splice(af1Index, 1) : '';
      af2Index > -1 ? this.filter.splice(af2Index, 1) : '';
      af3Index > -1 ? this.filter.splice(af3Index, 1) : '';
      done = true;
    }

    if (!done) {
      if (
        data.name == 'plus2_academic_filter' ||
        data.name == 'degree_academic_filter' ||
        data.name == 'pg_academic_filter'
      ) {
        if (af1Index == -1 && af2Index == -1 && af3Index == -1) {
          this.filter.push(data);
          done = true;
        }
      }
    }

    if (!done) {
      if (data.name == 'plus2_academic_filter') {
        af2Index > -1
          ? (this.filter[af2Index].name = 'plus2_academic_filter')
          : (this.filter[af3Index].name = 'plus2_academic_filter');
      } else if (data.name == 'degree_academic_filter') {
        af1Index > -1
          ? (this.filter[af1Index].name = 'degree_academic_filter')
          : (this.filter[af3Index].name = 'degree_academic_filter');
      } else if (data.name == 'pg_academic_filter') {
        af1Index > -1
          ? (this.filter[af1Index].name = 'pg_academic_filter')
          : (this.filter[af2Index].name = 'pg_academic_filter');
      } else {
        let found: boolean = false;
        this.filter.some((i, x) => {
          if (i.name === data.name) {
            this.filter[x].value = data.value;
            found = true;
            return true;
          }
        });

        if (!found) {
          this.filter.push(data);
        }
      }
    }
  }

  filterData() {
    let filters: string = '';
    this.filter.forEach((i) => {
      if (i.value !== '' && i.value !== 'all') {
        filters = `${filters}&${i.name}=${i.value}`;
      }
    });
    this.currentFilterURL = filters;
    let url = `?${filters.substring(1, filters.length)}`;
    console.log(url);
    this.apiCallService.getHostels(url).subscribe(
      (res) => {
        this.options = res['results'];
        this.collectionSize = res['count'];
        this.filterOptions = [...this.options];
        // console.log(this.filterOptions)
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // filterHostel() {
  //   //  console.log(this.streamFilter)
  //    let filteredHostels :any = [...this.options]
  //    if(this.academicFilter1 !== ''  && this.academicFilter1 !== 'all')
  //     {
  //       if(this.academicFilter1 === '0'){
  //         console.log('works')
  //         filteredHostels = filteredHostels.filter(option => option.plus2_academic_filter === 0)
  //       }
  //       if(this.academicFilter1 === '1'){
  //         filteredHostels = filteredHostels.filter(option => option.degree_academic_filter === 0)
  //       }
  //       if(this.academicFilter1 === '2'){
  //         filteredHostels = filteredHostels.filter(option => option.pg_academic_filter === 0)
  //       }
  //     }

  //    if(this.genderFilter !== ''  && this.genderFilter !== 'all')
  //    {
  //      filteredHostels = filteredHostels.filter(option => option.gender_restrictions === this.genderFilter)
  //    }

  //    if(this.casteFilter !== ''  && this.casteFilter !== 'all')
  //    {
  //      filteredHostels = filteredHostels.filter(option => option.caste_restrictions === this.casteFilter)
  //    }

  //    if(this.stateFilter !== ''  && this.stateFilter !== 'all')
  //    {
  //      filteredHostels = filteredHostels.filter(option => option.state === this.stateFilter)

  //      if(this.stateFilter == "1" ){
  //       this.loadedDistricts = Districts["Andhra_Pradesh"];
  //       // console.log(this.loadedDistricts)
  //      }
  //      if(this.stateFilter == "2" ){
  //       this.loadedDistricts = Districts["Telangana"];
  //       // console.log(this.loadedDistricts)
  //      }
  //      if(this.stateFilter == "3" ){
  //       this.loadedDistricts = Districts["Karnataka"];
  //       // console.log(this.loadedDistricts)
  //      }
  //    }

  //    if(this.districtFilter !== ''  && this.districtFilter !== 'all')
  //    {
  //      console.log(this.districtFilter)
  //      filteredHostels = filteredHostels.filter(option => option.district === this.districtFilter)
  //    }
  //   //  if(this.districtFilter !== ''  && this.districtFilter !== 'all')
  //   //  {
  //   //    filteredHostels = filteredHostels.filter(option => option.salary_range === this.districtFilter)
  //   //  }
  //    this.filterOptions = filteredHostels
  //    console.log(filteredHostels)
  //    }

  onPageChange(currentPage) {
    let url = null;

    if (currentPage != 0) {
      url = `page=${currentPage}`;
      this.currentPageURL = url;
      if (this.currentFilterURL !== null) {
        url = `?${url}&${this.currentFilterURL}`;
      } else {
        url = `?${url}`;
      }
    }

    this.previousPage = currentPage;
    this.apiCallService.getHostels(url).subscribe(
      (res) => {
        console.log(res);
        this.options = res['results'];
        this.collectionSize = res['count'];
        this.filterOptions = [...this.options];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}

class serverFilter {
  name: string;
  value: any;
}
