<div class="row d-flex">
  <div class="col-md-8 align-items-center cwikitxt">Career Wiki</div>
</div>

<div
  class="col-md-12"
  style="
    background-color: whitesmoke;
    padding-top: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
  "
>
  <!-- <div class="col-md-12 d-flex justify-content-center mt-3">
        <img src="/assets/Auth/bg-mobile.svg" />
    </div> -->
  <div class="row">
    <div class="col-md-12 d-flex justify-content-center mt-3">
      <img src="/assets/Auth/leftTopLogo.jpg" />
    </div>
    <div class="col-md-12 d-flex justify-content-center mt-3">
      <span class="capTxt-auth"> Career Advancement Platform(CAP) </span>
    </div>
    <!-- <div class="rcol-md-12 d-flex justify-content-center  mt-3 mb-4">
            <span class="vrgladChild-auth px-4" style="text-align: center;">
              We’d be glad to guide you in this journey ahead
              <span class="primaryText">{{
                isSignup ? "Login" : "Sign up"
              }}</span>
              and get started now!
            </span>
          </div> -->

    <div class="col-md-12 d-flex justify-content-center vrglad-auth mt-3 mb-4">
      <span class="vrgladChild-auth px-4" style="text-align: center">
        We’d be glad to guide you in this journey ahead
        <span class="primaryText">{{ isSignup ? "Login" : "Sign up" }}</span>
        and get started now!
      </span>
    </div>
  </div>
  <div class="" style="background-color: #ffffff; height: 500px">
    <div class="col-md-12 d-flex justify-content-center cent">
      <img src="/assets/Auth/welcomeLogo.svg" />
    </div>
    <div class="col-md-12 loginText">Login</div>
    <div class="col-md-12 d-flex justify-content-center mt-5">
      <form [formGroup]="form" (ngSubmit)="login()">
        <div class="col-md-12 d-flex justify-content-center mt-4">
          <mat-form-field appearance="outline" class="authInput">
            <mat-label>Username / Email / Phone</mat-label>
            <!-- <input formControlName="username" matInput placeholder="Username" /> -->
            <input
              formControlName="authDetail"
              [email]="validatedEmail"
              matInput
              placeholder="Username / Email / Phone"
            />
            <mat-icon matSuffix> <span class="eyeFake"></span></mat-icon>
            <!-- <mat-hint
              *ngIf="
                (form.controls.username.touched || formGroupValidator) &&
                form.controls.username.invalid
              "
              class="red"
              >Please enter username</mat-hint
            > -->
            <mat-hint
              *ngIf="
                (form.controls.authDetail.touched || formGroupValidator) &&
                form.controls.authDetail.invalid
              "
              class="red"
              >Please enter the details</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-4">
          <mat-form-field appearance="outline" class="authInput">
            <mat-label>Password</mat-label>
            <input
              formControlName="password"
              matInput
              placeholder="Password"
              [type]="hide ? 'password' : 'text'"
            />

            <i class="material-icons" matSuffix (click)="hide = !hide">
              {{ hide ? "visibility" : "visibility_off" }}
            </i>
            <mat-hint
              *ngIf="
                (form.controls.password.touched || formGroupValidator) &&
                form.controls.password.invalid
              "
              class="red"
              >Please enter password</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="newMemberTxt col-md-12 d-flex justify-content-center mt-2">
          <div>
            <a
              class="authInput1 d-flex justify-content-end"
              routerLink="/auth/forgotpassword"
              >Forgot password</a
            >
          </div>
        </div>

        <div class="col-md-12 loginText mt-3">
          <button class="loginBtn" mat-raised-button type="submit">
            Login
          </button>
        </div>
        <div class="col-md-12 loginText2">
          <span class="newMemberTxt">
            <a class="forgotNextBtn" routerLink="/auth/signup"
              >New member? Click to Sign Up
            </a>
          </span>
        </div>
      </form>
    </div>
  </div>
  <div
    class="col-md-12 d-flex justify-content-center"
    style="padding-top: 1rem"
  >
    <img src="/assets/Auth/undraw_book_lover_mkck.svg" />
  </div>
  <div
    class="col-md-12 d-flex justify-content-center mt-3"
    style="font-size: 8px; font-weight: 500; color: rgba(47, 63, 67, 0.8)"
  >
    <span>Powered by</span>
  </div>
  <div class="col-md-12 d-flex justify-content-center">
    <span class="px-2" style="padding-bottom: 1rem">
      <img src="/assets/Auth/vidya helpline logo-mobile.png" />
    </span>
    <span class="px-2">
      <img src="/assets/Auth/vmware-mobile.png" />
    </span>
  </div>
</div>
