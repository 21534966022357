import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { GetStartedModalComponent } from './../get-started-modal/get-started-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';
import { CareerlistServiceService } from '../careerlist-service.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-start-now',
  templateUrl: './start-now.component.html',
  styleUrls: ['./start-now.component.css'],
})
export class StartNowComponent implements OnInit {
  @Input() deviceXs: boolean;

  //vars

  choiceScreenFilter = 'all';
  message: string;
  choiceFiltersubscription: Subscription;

  mediaSub: Subscription;
  adapter: any = null;
  adapter1 = false;

  afterTenth = [
    'I need a job immediately',
    'I can study for 2 or 3 more years',
  ];
  afterTenthPlusInter = [
    'Maths, Physics, Chemistry',
    'Biology, Physics, Chemistry',
    'Commerce, Economics, Civics',
    'History, Economics, Civics',
    'Maths, Economics, Commerce',
    'Vocational',
    'Any Group',
  ];
  afterDegree = ['aa', 'ba'];

  openDialog() {
    this.dialog.open(GetStartedModalComponent, {
      disableClose: true,
      closeOnNavigation: true,
    });
  }

  constructor(
    // public careerListService: CareerlistServiceService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private apiCaller: ApiCallsService,
    public mediaObserver: MediaObserver,
    public choiceScreenFilterService: CareerlistServiceService
  ) {}

  form = new FormGroup({
    years: new FormControl('', [Validators.required]),
    subYears: new FormControl('', [Validators.required]),
  });

  triggerSubMenu2() {
    console.log('triggeered');
    // console.log(this.form.controls.years.value)
    // this.careerListService.updateQualificatinFilter(this.form.controls.years.value)
    if (this.form.controls.years.value == 0) {
      // console.log(this.afterTenth)
      this.adapter = this.afterTenth;
      console.log(this.afterTenth);
      console.log(this.adapter);
    }
    if (this.form.controls.years.value == 1) {
      this.adapter = null;
      this.adapter1 = true;
      console.log(this.afterTenthPlusInter);
    }
    if (this.form.controls.years.value == 2) {
      this.adapter = this.afterDegree;
      console.log(this.afterDegree);
    }
  }

  showOptions() {
    if (this.form.value.years !== '') {
      if (this.form.value.years === '2' || this.form.value.subYears !== '') {
        console.log('showinmg options');
        // let related_subjects = 'all';
        // if condition when 2nd dropdown 1st option (I need a job imm)
        if (
          this.form.value.years === '0' &&
          this.form.value.subYears === 'I need a job immediately'
        ) {
          this.choiceScreenFilterService.updatechoiceScreenFilter('after_10');
        }
        // if condition when 2nd dropdown 2nd option (I can wait for 2 3)
        if (
          this.form.value.years === '0' &&
          this.form.value.subYears === 'I can study for 2 or 3 more years'
        ) {
          this.choiceScreenFilterService.updatechoiceScreenFilter(
            'filter_10_can_study'
          );
        }
        // if condition when 1st dropdown 2nd option (10+2)
        if (this.form.value.years === '1') {
          this.choiceScreenFilterService.updatechoiceScreenFilter(
            this.form.value.subYears
          );
          console.log(this.form.value.subYears);
        }

        // if condition when 1st dropdown 3rd option (Degree)
        if (this.form.value.years === '2') {
          this.choiceScreenFilterService.updatechoiceScreenFilter('all');
        }
        console.log(this.message);

        this.router.navigate(['/dashboard/careerList']);
      }
    }
  }

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );

    this.openDialog();
    this.verifyLogin();
    this.choiceFiltersubscription =
      this.choiceScreenFilterService.choiceScreenFilter.subscribe(
        (message) => (this.message = message)
      );
    this.choiceScreenFilterService.updatechoiceScreenFilter('all');
    console.log(this.message);
  }

  verifyLogin() {
    this.apiCaller.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }
}
