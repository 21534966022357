import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '../api-calls.service';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  @Input() deviceXs: boolean;

  maxDate: Date;
  optionActive = 1;
  studyEnabler = true;
  userInfoResponse: any;
  userInfourl: any;
  mediaSub: Subscription;

  simplifySignUp: boolean = true;

  groupDisabilityValues = {
    0: 'No disability',
    1: 'Visually handicapped',
    2: 'Hearing impaired',
    3: 'Orthopedically handicapped',
  };

  groupGernderValues = {
    0: 'Male',
    1: 'Female',
    2: 'Other',
  };

  groupQualificationValues = {
    0: 'Below 10th',
    1: 'Studying 10th',
    2: 'Completed 10th',
    3: 'Studying Inter/12th/PUC',
    4: 'Completed Inter/12th/PUC',
    5: 'Studying ITI',
    6: 'Completed ITI',
    7: 'Studying Diploma',
    8: 'Completed Diploma',
    9: 'Studying Graduation',
    10: 'Completed Graduation',
    11: 'Studying Technical Graduation',
    12: 'Completed Technical Graduation',
    13: 'Studying PG',
    14: 'Completed PG',
    15: 'Other',
  };

  // groupInstituteValues = {
  //   0 :	"Government Day Scholar",
  //   1 :	"Government Residential",
  //   2 : "Government Aided",
  //   3	: "Private",
  // }

  groupStatusValues = {
    0: 'Studying',
    1: 'Working',
    2: 'None of the above',
  };

  groupResidentialValues = {
    0: 'Village',
    1: 'Town',
    2: 'City',
  };

  groupStateValues = {
    0: 'Andhra Pradesh',
    1: 'Telangana',
    2: 'Karnataka',
    all: 'Others',
  };

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router,
    public mediaObserver: MediaObserver
  ) {
    const currentYear = new Date().getFullYear();

    this.maxDate = new Date(currentYear - 1, 11, 31);
  }

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
    // this.apiCallService.verifyLogin();
    this.getUserInfo();
  }

  instituteMethod() {
    if (this.userInfoResponse?.data?.institute_category != null) {
      // this.groupInstituteValues[this.userInfoResponse?.data?.institute_category]
      if (this.userInfoResponse?.data?.institute_category == 0) {
        return 'Government Day Scholar';
      }
      if (this.userInfoResponse?.data?.institute_category == 1) {
        return 'Government Residential';
      }
      if (this.userInfoResponse?.data?.institute_category == 2) {
        return 'Government Aided';
      }
      if (this.userInfoResponse?.data?.institute_category == 3) {
        return 'Private';
      }
    } else {
      return 'NA';
    }
  }

  instituteName() {
    if (this.userInfoResponse?.data?.institute_name == null) {
      return 'NA';
    } else {
      return this.userInfoResponse?.data?.institute_name;
    }
  }

  courseMethod() {
    if (this.userInfoResponse?.data?.course == null) {
      return 'NA';
    } else {
      return this.userInfoResponse?.data?.course;
    }
  }

  professionMethod() {
    if (this.userInfoResponse?.data?.profession == null) {
      return 'NA';
    } else {
      return this.userInfoResponse?.data?.profession;
    }
  }

  orgMethod() {
    if (this.userInfoResponse?.data?.organization_name == null) {
      return 'NA';
    } else {
      return this.userInfoResponse?.data?.organization_name;
    }
  }

  emailValidity() {
    if (this.userInfoResponse?.data?.email == null) {
      return 'NA';
    } else {
      return this.userInfoResponse?.data?.email;
    }
  }

  getUserInfo() {
    console.log('hello');

    this.apiCallService.getUserInfo().subscribe(
      (res) => {
        console.log(res);
        this.userInfoResponse = res;
        if (this.userInfoResponse['userinfo_path']) {
          // this.roadmapdisabled = false
          this.userInfourl = this.userInfoResponse['userinfo_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  personalInfoClick() {
    this.optionActive = 1;
  }

  academicInfoClick() {
    this.optionActive = 2;
  }

  residentInfoClick() {
    this.optionActive = 3;
  }
}
