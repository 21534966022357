<div class="col-md-12 careerDetails" *ngIf="!deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/courseList"
        [queryParams]="{
          stream: this.streamFilter,
          elgibility: this.elgibilityFilter,
          category: this.categoryFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ CourseDetailResponse?.name }} </span>
    </div>
    <div class="col-md-12 mt-2 careerReq mt-3">
      <div class="requirementsText mt-2">
        <span>Course Details</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/student-hat.png" />
        </span>
      </div>
      <div class="col-md-12 d-flex justify-content-between px-0 mt-4">
        <div>
          <span class="reqt1">Stream: </span>
          <span class="reqta">{{
            CourseDetailResponse?.stream != null
              ? streamValues[CourseDetailResponse?.stream]
              : "-"
          }}</span>
        </div>
        <div>
          <span class="reqt1">Duration: </span>
          <span class="reqta">{{ CourseDetailResponse?.duration }} </span>
        </div>
        <div>
          <span class="reqt1">Category: </span>
          <span class="reqta mr-5">{{
            CourseDetailResponse?.category != null
              ? categoryValues[CourseDetailResponse?.category]
              : "-"
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-7 wrapperLeft">
          <div class="row">
            <div class="col-md-12 secondContainer">
              <div class="row">
                <div class="col-md-12 sc2Parent">
                  <span class="secondContainer2">Requirements</span>
                  <span class="pl-2">
                    <img src="/assets/careerDetail/open-book.png" />
                  </span>
                </div>
                <!-- <div class="col mt-4">
              <div>
                <span class="streamA">Entrance Exam: </span>
                <span class="reqta">{{ CourseDetailResponse?.entrance_exam }}</span>
              </div>
            </div> -->
                <div class="col mt-4 mb-2">
                  <div>
                    <span class="streamA">Eligibility Criteria: </span>
                    <span class="reqta">{{
                      CourseDetailResponse?.eligibility_string
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 sc2Parent2 mt-4">
              <div class="row mb-3">
                <div class="col-md-12 secondContainer">
                  <div class="row">
                    <div class="col-md-12 pt-1">
                      <span class="secondContainer2">Careers</span>
                      <span class="pl-2">
                        <img src="/assets/careerDetail/career.png" />
                      </span>
                    </div>
                    <div class="col mt-2">
                      <div
                        class="mt-1"
                        *ngFor="let careers of alternateCourses"
                      >
                        <span>
                          <img src="/assets/careerDetail/ellipse.png" />
                        </span>
                        <span class="ml-2" *ngIf="alternateCourses?.length > 0">
                          {{ careers }}

                          <!-- {{ careers }} -->
                        </span>
                        <span class="ml-2" *ngIf="alternateCourses == ''">
                          {{ "NA" }}

                          <!-- {{ careers }} -->
                        </span>
                      </div>

                      <!--  -->

                      <div class="mt-1" *ngIf="alternateCourses?.length === 0">
                        <span>
                          <img src="/assets/careerDetail/ellipse.png" />
                        </span>
                        <span class="ml-2">
                          NA

                          <!-- {{ careers }} -->
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 wrapperRight">
          <div class="row">
            <div class="col-md-12 roadMap">
              <div class="mt-3">
                <span class="roadMapText">Higher Education Options</span>
                <span class="pl-2">
                  <img src="/assets/careerDetail/surface1.png" />
                </span>
              </div>
              <div class="mt-4 mb-2">
                <span class="expectedSalary">Course Name: </span>
                <span class="expectedSalary2 mb-5">{{
                  CourseDetailResponse?.higher_education
                    ? CourseDetailResponse?.higher_education
                    : "Unknown"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mobile App -->

<div class="col-md-12 careerDetails" *ngIf="deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/courseList"
        [queryParams]="{
          stream: this.streamFilter,
          elgibility: this.elgibilityFilter,
          category: this.categoryFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ CourseDetailResponse?.name }} </span>
    </div>
    <div class="col-md-12 mt-2 careerReq mt-3">
      <div class="requirementsText mt-2">
        <span>Course Details</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/student-hat.png" />
        </span>
      </div>
      <div class="col-md-12 px-0 mt-4">
        <div class="mb-2">
          <span class="reqt1">Stream: </span>
          <span class="reqta">{{
            CourseDetailResponse?.stream != null
              ? streamValues[CourseDetailResponse?.stream]
              : "-"
          }}</span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Duration: </span>
          <span class="reqta">{{ CourseDetailResponse?.duration }} </span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Category: </span>
          <span class="reqta mr-5">{{
            CourseDetailResponse?.category != null
              ? categoryValues[CourseDetailResponse?.category]
              : "-"
          }}</span>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3 careerReq mt-3 pb-3">
      <div class="requirementsText mt-2">
        <span>Requirements</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/open-book.png" />
        </span>
      </div>
      <div class="col-md-12 px-0 mt-3">
        <!-- <div class="mb-2">
          <span class="streamA">Entrance Exam: </span> -->
        <!-- <span class="reqta">{{
            careerDetailResponse?.prefered_group != null
              ? groupValues[careerDetailResponse?.prefered_group]
              : "-"
          }}</span> -->
        <!-- </div> -->
        <div class="mb-2">
          <span class="streamA">Eligibility Criteria: </span>
          <span class="reqta">{{
            CourseDetailResponse?.eligibility_string
          }}</span>
        </div>
        <!-- <div class="mb-2">
          <span class="reqt1">Specialisation: </span>
          <span class="reqta">{{ careerDetailResponse?.specialization }}</span>
        </div> -->
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-7 wrapperLeft1">
          <!-- <div class="row">
            
          <div class="col-md-12 secondContainer">
          <div class="row">
          <div class="col-md-12 sc2Parent">
            <span class="secondContainer2">Requirements</span>
            <span class="pl-2">
              <img src="/assets/careerDetail/open-book.png" />
            </span>
          </div>
          <div class="col mt-4">
            <div>
              <span class="streamA">Entrance Exam: </span>
              <span class="reqta">{{ CourseDetailResponse?.id }}</span>
            </div>
          </div>
          <div class="col mt-4">
            <div>
              <span class="streamA">Eligibility Criteria: </span>
              <span class="reqta">{{ CourseDetailResponse?.eligibility_string }}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->

          <div class="row">
            <div class="col-md-12 sc2Parent2">
              <div class="row mb-3">
                <div class="col-md-12 secondContainer">
                  <div class="row">
                    <div class="col-md-12 pt-1">
                      <span class="secondContainer2">Careers</span>
                      <span class="pl-2">
                        <img src="/assets/careerDetail/career.png" />
                      </span>
                    </div>
                    <div class="col mt-2">
                      <div
                        class="mt-1 mb-2"
                        *ngFor="let careers of alternateCourses"
                      >
                        <span>
                          <img src="/assets/careerDetail/ellipse.png" />
                        </span>
                        <span class="ml-2" *ngIf="alternateCourses?.length > 0">
                          {{ careers }}

                          <!-- {{ careers }} -->
                        </span>
                        <span class="ml-2" *ngIf="alternateCourses == ''">
                          {{ "NA" }}

                          <!-- {{ careers }} -->
                        </span>
                      </div>

                      <!--  -->

                      <div
                        class="mt-1 mb-2"
                        *ngIf="alternateCourses?.length === 0"
                      >
                        <span>
                          <img src="/assets/careerDetail/ellipse.png" />
                        </span>
                        <span class="ml-2"> NA </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 wrapperRight1">
          <div class="row">
            <div class="col-md-12 roadMap">
              <div class="mt-3">
                <span class="roadMapText">Higher Education Options</span>
                <span class="pl-2">
                  <img src="/assets/careerDetail/surface1.png" />
                </span>
              </div>
              <div class="mt-3 mb-2">
                <span class="expectedSalary">Course Name: </span>
                <span class="expectedSalary2">{{
                  CourseDetailResponse?.higher_education
                    ? CourseDetailResponse?.higher_education
                    : "Unknown"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
