<!-- <p>training-institute-list works!</p> -->
<div>
  <!-- ** Heading and back -->
  <div class="col-md-12 pt-4 mb-3 d-flex align-items-center">
    <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
    <span class="backTitle"> Training & Institute List</span>
  </div>

  <div class="px-3 trainingContentHolder">
    <!-- ** Search Bar -->
    <div class="d-flex">
      <input
        class="py-3 px-3 searchBar"
        placeholder="Search Training Institiutes"
        [formControl]="searchFormControl"
        (keyup)="searchTrigger(false)"
      />
      <mat-icon class="searchIcon" (click)="searchTrigger(true)">{{
        searchFormControl.value.length > 0 ? "close" : "search"
      }}</mat-icon>
    </div>

    <!-- ** filters section -->
    <div class="filtersSection">
      <!-- ** filters -->
      <mat-form-field
        *ngFor="let choice of choicesList"
        appearance="outline"
        class="filterFormField"
      >
        <!-- <mat-label
          *ngIf="
            choice.choiceName == 'District' && isStatesFilterDisabled;
            else districtsLoaded
          "
        >
          <div class="select-placeholder-container">
            <span>Loading...</span>
            <mat-spinner class="spinner" diameter="20"></mat-spinner>
          </div>
        </mat-label> -->
        <!-- <ng-template #districtsLoaded> -->
        <mat-label class="px-2">{{ choice.choiceName }}</mat-label>
        <!-- </ng-template> -->
        <mat-select
          (mouseover)="printVal(isStatesFilterDisabled)"
          class="px-2"
          [formControl]="choice.choiceFormControl"
          (selectionChange)="
            choice.choiceName === 'State'
              ? getDistricts(choice, true)
              : getTrainingInstitutesData()
          "
        >
          <mat-option
            *ngFor="let choiceOptions of choice.choices"
            [value]="
              choice.choiceName === 'State'
                ? choiceOptions.state_id
                : choice.choiceName === 'District'
                ? choiceOptions.district_id
                : choiceOptions.choiceKey
            "
            >{{
              choice.choiceName === "State" || choice.choiceName === "District"
                ? choiceOptions.name
                : choiceOptions.choiceValue
            }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <!-- ** Table -->

    <div class="trainingTableHolder">
      <div class="trainingTable">
        <table style="width: 100%">
          <tr class="trainingTableHeadingsRow">
            <td
              *ngFor="let tableColumn of tableColumns"
              class="trainingTableColumn"
            >
              {{ tableColumn }}
            </td>
          </tr>
          <tr
            *ngIf="
              trainingInstitutesData.length === 0 &&
              gettingTrainingInstitutesData === true
            "
          >
            <td colspan="12" align="center" class="trainingTableNoRecordsRow">
              Loading Data...
            </td>
          </tr>
          <tr
            *ngIf="
              trainingInstitutesData.length === 0 &&
                gettingTrainingInstitutesData === false;
              else elseBlock
            "
          >
            <td colspan="12" align="center" class="trainingTableNoRecordsRow">
              No Records found
            </td>
          </tr>
          <ng-template #elseBlock>
            <tr
              *ngFor="let trainingInstituteData of trainingInstitutesData"
              (click)="setFiltersAndNavigateToDetail(trainingInstituteData.id)"
              class="trainingTableValueRow"
            >
              <td class="trainingTableValue" style="min-width: 20rem">
                {{ trainingInstituteData.institute_name }}
              </td>
              <td class="trainingTableValue" style="min-width: 20rem">
                {{
                  joinAllRelatedTrainingCourses(
                    trainingInstituteData.trainingInstituteRelatedCourses
                  )
                }}
              </td>
              <td
                class="trainingTableValue"
                style="min-width: 2rem; max-width: 2rem"
              >
                {{ trainingInstituteData.minimum_qualification }}
              </td>
              <!-- <td class="trainingTableValue">
                {{ trainingInstituteData.gender }}
              </td>
              <td class="trainingTableValue">
                {{ trainingInstituteData.only_physically_handicapped }}
              </td>
              <td class="trainingTableValue">
                {{ trainingInstituteData.age_restriction }}
              </td>
              <td class="trainingTableValue" style="min-width: 20rem">
                {{ trainingInstituteData.accommodation }}
              </td> -->
              <td class="trainingTableValue" style="min-width: 20rem">
                {{ trainingInstituteData.approx_notification_time }}
              </td>
              <!-- <td class="trainingTableValue" style="min-width: 20rem">
                {{ trainingInstituteData.stipend }}
              </td> -->
              <td class="trainingTableValue">
                {{ trainingInstituteData.state.name }}
              </td>
              <!-- <td class="trainingTableValue">
                {{ trainingInstituteData.district.name }}
              </td>
              <td class="trainingTableValue" style="min-width: 20rem">
                {{ trainingInstituteData.address_contact }}
              </td>
              <td class="trainingTableValue" style="min-width: 20rem">
                {{ trainingInstituteData.website_address }}
              </td> -->
            </tr>
          </ng-template>
        </table>
      </div>
      <ngb-pagination
        class="d-flex justify-content-center pagination_theme"
        [collectionSize]="collectionSize"
        [maxSize]="5"
        [pageSize]="pageSize"
        [(page)]="currentPage"
        (pageChange)="pageChanger(currentPage)"
      >
        <ng-template ngbPaginationPrevious>
          <img src="/assets/Dashboard/pageArrowPrev.svg" />
        </ng-template>
        <ng-template ngbPaginationNext>
          <img src="/assets/Dashboard/pageArrowNext.svg" />
        </ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
