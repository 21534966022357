<div
  *ngIf="!deviceXs"
  class="col-md-12"
  style="padding-top: 1rem; padding-left: 3rem; padding-right: 3rem"
>
  <!-- <div class="col-md-12 d-flex justify-content-center mt-3">
        <img src="/assets/Auth/bg-mobile.svg" />
    </div> -->

  <div class="" style="background-color: #ffffff; height: 500px">
    <div class="col-md-12 loginText mt-5">Admin Login</div>
    <div class="col-md-12 d-flex justify-content-center mt-4">
      <form [formGroup]="form" (ngSubmit)="login()">
        <div class="col-md-12 d-flex justify-content-center mt-4">
          <mat-form-field appearance="outline" class="authInput">
            <mat-label>Username</mat-label>
            <input formControlName="username" matInput placeholder="Username" />
            <mat-icon matSuffix> <span class="eyeFake"></span></mat-icon>
            <mat-hint
              *ngIf="
                (form.controls.username.touched || formGroupValidator) &&
                form.controls.username.invalid
              "
              class="red"
              >Please enter username</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-4">
          <mat-form-field appearance="outline" class="authInput">
            <mat-label>Password</mat-label>
            <input
              formControlName="password"
              matInput
              placeholder="Password"
              [type]="hide ? 'password' : 'text'"
            />

            <i class="material-icons" matSuffix (click)="hide = !hide">
              {{ hide ? "visibility" : "visibility_off" }}
            </i>
            <mat-hint
              *ngIf="
                (form.controls.password.touched || formGroupValidator) &&
                form.controls.password.invalid
              "
              class="red"
              >Please enter password</mat-hint
            >
          </mat-form-field>
        </div>

        <div class="col-md-12 loginText mt-3">
          <button class="loginBtn" mat-raised-button type="submit">
            Login
          </button>
        </div>
        <div
          class="col-md-12 d-flex justify-content-center newMemberTxt mt-3"
          *ngIf="!deviceXs"
        >
          <div>
            <a class="authInput1" routerLink="/" style="font-size: 16px"
              >user login</a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div>
  <div
    class="col-md-12 d-flex justify-content-center"
    style="padding-top: 20rem; padding-bottom: 20rem"
    *ngIf="deviceXs"
  >
    <span style="color: red; font-size: 20px; font-family: Montserrat">
      admin panel not suported in mobile
    </span>
  </div>
</div>
