import { Component, Input, OnInit } from '@angular/core';
import { ApiCallsService } from './../api-calls.service';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgModel,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { state } from '@angular/animations';

@Component({
  selector: 'app-login-root',
  templateUrl: './login-root.component.html',
  styleUrls: ['./login-root.component.css'],
})
export class LoginRootComponent implements OnInit {
  @Input() deviceXs: boolean;
  hide = true;
  mediaSub: Subscription;
  userInfoResponse: any;
  userInfourl: any;
  stateValue = null;
  academicValue = null;

  validatedEmail: string | boolean = false;

  linkedMessage: string = '';

  authDetailErrMessage: string = '';

  constructor(
    private apicaller: ApiCallsService,
    private formBuilder: FormBuilder,
    private router: Router,
    public mediaObserver: MediaObserver
  ) {}

  // showTT() {
  //   this.toastr.success('some messages', 'title');
  //   timeout:3000;
  // }

  loginerr(errorMessage, errorDetail) {
    Swal.fire(errorDetail, '', 'error');
  }

  // Variables
  data = {
    username: '',
    password: '',
  };

  phoneLinkedUsernames: any = [];

  form = new FormGroup({
    authDetail: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
    ]),
    linkedUserId: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    authDetailType: new FormControl('username', [Validators.required]),
  });

  formGroupValidator = false;

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        // console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
  }

  authTypeValidator(event) {
    this.linkedMessage = '';
    console.log(event);
    let phoneArr = [];
    phoneArr.push(event.target.value);
    for (let i = 0; i < event.target.value.length; i++) {
      console.log(event.target.value[i]);

      if (
        Number.isInteger(parseInt(event.target.value[i])) &&
        Number.isInteger(parseInt(event.target.value))
      ) {
        console.log('in if');
        this.form.controls['authDetail'].setValidators([
          Validators.required,
          Validators.minLength(10),
        ]);
        console.log(phoneArr);
      } else {
        phoneArr = [];
        this.form.controls['authDetail'].clearValidators();
        this.form.controls['authDetail'].setValidators([
          Validators.required,
          Validators.minLength(6),
        ]);
        if (
          Number.isInteger(parseInt(this.form.controls['authDetail'].value[0]))
        ) {
          this.form.controls['authDetail'].setErrors({ notValid: true });
        }
        if (event.target.value.includes('.')) {
          console.log("contains '.'");
          if (!event.target.value.includes('@')) {
            this.form.controls['authDetail'].setErrors({ notValid: true });
          }
        }
        if (this.form.controls['authDetail'].invalid) {
          console.log('checking validatidity .. .invalid email');
          this.authDetailErrMessage = 'Invalid Username/Email';
        }
      }
    }
    if (phoneArr.length > 0) {
      if (phoneArr[0].length == 10) {
        let reqData = {
          phone: event.target.value,
        };
        this.apicaller.getPhoneLinkedUsernames(reqData).subscribe(
          (res) => {
            console.log(res);
            if (res.toString().length < 1) {
              this.linkedMessage = 'No matching users found';
            }
            this.phoneLinkedUsernames = res;
            console.log(this.phoneLinkedUsernames);
          },
          (err) => {
            console.log(err);
            this.loginerr(err.error.message, err.error.detailMessage);
          }
        );
      }
    }
  }

  checkIsEmail(uname: string) {
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    this.validatedEmail = regexExp.test(uname);
  }

  login() {
    let data = this.form.value;
    console.log(data);
    console.log('Before checking: ', data.authDetailType);
    if (Number.isInteger(parseInt(data.authDetail[0]))) {
      data.authDetailType = 'phone';
    } else if (data.authDetail.includes('@')) {
      if (data.authDetail.includes('.')) {
        data.authDetailType = 'email';
        data.linkedUserId = '-1';
      }
    } else {
      data.authDetailType = 'username';
      data.linkedUserId = '-1';
    }
    console.log('After checking: ', data.authDetailType);
    console.log('The vals are', data);
    // data.authDetailType = 'email';
    // this.apicaller.loginApi(data).subscribe(
    this.apicaller.multiLoginApi(data).subscribe(
      (res) => {
        console.log(res);
        localStorage.setItem('Token', res['token']);

        console.log('Check it man');
        //  console.log['res']
        //  if(res == true) {
        //   console.log("Routing to dashboard   ")
        //   this.router.navigate(['/dashboard'])
        // }

        // this.router.navigate(['/dashboard'])
        // Callsecond api

        let message = res['message'];
        console.log['message'];
        this.apicaller.verifyUser().subscribe(
          (res) => {
            console.log(res, message);
            if (res['message'] == 'user_verified') {
              console.log('Routing to dashboard page');
              window.scroll(0, 0);
              this.router.navigate(['/master-dashboard']);

              this.apicaller.getUserinfo().subscribe(
                (res) => {
                  console.log(res['data'].state);
                  this.stateValue = res;
                  localStorage.setItem(
                    'stateValue',
                    res['data'].state.state_id
                  );
                  localStorage.setItem(
                    'academicValue',
                    res['data'].qualification
                  );
                },
                (err) => {
                  console.log(err);
                }
              );
            }
          },
          (err) => {
            console.log(err['error']['message']);
            let message = err['error']['message'];
            // if(message == 'first_time_login. need_to_set_password') {
            //   console.log("Routing to change password page")
            //   this.router.navigate(['/auth/changepassword'])
            // }
          }
        );
      },
      (err) => {
        console.log(err);
        this.formGroupValidator = true;
        this.loginerr(err.error.message, err.error.detailMessage);
      }
    );
  }
}
