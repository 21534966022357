<div class="cc-h" *ngIf="!deviceXs">
  <div class="row">
    <!-- <span class=""><input type="radio" /></span> -->
    <img
      src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
      class="Group-9614"
    />
    <span class="col-md-11 pl-2">
      Please select your favourite subjects to get career recommendations based
      on them:
    </span>
  </div>
  <div class="primaryText maxSubjects">Max 3 subjects only</div>
  <div class="row pl-2">
    <div
      class="mt-4 pr-1 mx-2"
      *ngFor="let subject of subjects; index as j"
      (click)="processSubject(j)"
    >
      <div
        [ngClass]="[
          selectedtIndexed.indexOf(j) == -1 ? 'chooseOption' : 'chooseOption2',
          selectedtIndexed.length === 3 && selectedtIndexed.indexOf(j) < 0
            ? 'disabledOption'
            : ''
        ]"
        value="2"
        [disabled]="selectedtIndexed.indexOf(j) == -1 && count == 3"
      >
        {{ subject }}
      </div>
    </div>
    <div class="col mt-4"></div>
    <div class="col mt-4"></div>
    <div class="col mt-4"></div>
    <div class="col mt-4"></div>
  </div>

  <div class="row mt-4 pl-3">
    <button
      class="loginBtn"
      mat-raised-button
      type="submit"
      (click)="navigatingToMethod()"
      [disabled]="selectedtIndexed.length === 0"
    >
      Next
    </button>
  </div>
</div>

<!-- For Mobile App -->

<div class="cc-h1" *ngIf="deviceXs">
  <div class="row">
    <!-- <span class=""><input type="radio" /></span> -->
    <img
      class="Group-9614"
      style="width: fit-content"
      src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
    />
    <span class="col">
      Please select your favourite subjects to get career recommendations based
      on them:
    </span>
  </div>
  <div class="primaryText maxSubjects">Max 3 subjects only</div>
  <div class="row pl-2">
    <div
      class="mt-4 pr-1 mx-2"
      *ngFor="let subject of subjects; index as j"
      (click)="processSubject(j)"
    >
      <div
        [ngClass]="[
          selectedtIndexed.indexOf(j) == -1 ? 'chooseOption' : 'chooseOption2',
          selectedtIndexed.length === 3 && selectedtIndexed.indexOf(j) < 0
            ? 'disabledOption'
            : ''
        ]"
        value="2"
        [disabled]="selectedtIndexed.indexOf(j) == -1 && count == 3"
      >
        {{ subject }}
      </div>
    </div>
    <div class="col mt-4"></div>
    <div class="col mt-4"></div>
    <div class="col mt-4"></div>
    <div class="col mt-4"></div>
  </div>

  <div class="row mt-4 pl-3 pb-3">
    <button
      class="loginBtn"
      mat-raised-button
      type="submit"
      (click)="navigatingToMethod()"
      [disabled]="selectedtIndexed.length === 0"
    >
      Next
    </button>
  </div>
</div>
