<!-- <p>notifications works!</p>` -->
<div style="overflow-x: hidden">
  <div class="col-md-12 spacing pt-4 mb-3 d-flex align-items-center">
    <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
    <span class="backTitle"> Notification</span>
  </div>

  <div class="col pb-4" style="padding-left: 2.1rem" *ngIf="!deviceXs">
    <span
      [ngClass]="optionActive == 1 ? 'bold' : 'normal'"
      (click)="activeClick()"
    >
      Active Notifications
    </span>
    <span style="padding-left: 1rem"></span>
    <span
      [ngClass]="optionActive == 2 ? 'bold' : 'normal'"
      (click)="inactiveClick()"
      >Inactive Notifications</span
    >
  </div>

  <div
    class="col-12 d-flex pb-4"
    style="justify-content: space-evenly"
    *ngIf="deviceXs"
  >
    <span
      [ngClass]="optionActive == 1 ? 'bold' : 'normal'"
      (click)="activeClick()"
    >
      Active Notifications
    </span>

    <span
      [ngClass]="optionActive == 2 ? 'bold' : 'normal'"
      (click)="inactiveClick()"
      >Inactive Notifications</span
    >
  </div>

  <div class="col-md-12">
    <div class="row" style="padding-left: 1rem !important" *ngIf="!deviceXs">
      <div class="col-md-2" style="height: 77.64px !important">
        <mat-form-field appearance="outline" class="forAlignments">
          <mat-label>Academic level</mat-label>
          <mat-select
            placeholder="Academic level"
            (selectionChange)="filterAcademicLevel()"
            [(ngModel)]="academicFilter1"
          >
            <span class="optionStyling">
              <mat-option value="all">All </mat-option>
              <!-- <mat-option [value]="j" *ngFor="let stream of streams; index as j"
            >{{ stream }}
          </mat-option> -->
              <mat-option value="0">Below 10th</mat-option>
              <mat-option value="1">Studying 10th</mat-option>
              <mat-option value="2">Completed 10th</mat-option>
              <mat-option value="3">Studying Inter/12th/PUC</mat-option>
              <mat-option value="4">Completed Inter/12th/PUC</mat-option>
              <mat-option value="5">Studying ITI</mat-option>
              <mat-option value="6">Completed ITI</mat-option>
              <mat-option value="7">Studying Diploma</mat-option>
              <mat-option value="8">Completed Diploma'</mat-option>
              <mat-option value="9">Studying Graduation</mat-option>
              <mat-option value="10">Completed Graduation</mat-option>
              <mat-option value="11">Studying Technical Graduation</mat-option>
              <mat-option value="12">Completed Technical Graduation</mat-option>
              <mat-option value="13">Studying PG</mat-option>
              <mat-option value="14">Completed PG</mat-option>
              <mat-option value="15">Other</mat-option>
            </span>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 ml-2">
        <mat-form-field appearance="outline" class="forAlignments">
          <mat-label>State</mat-label>
          <mat-select
            placeholder="State"
            (selectionChange)="filterState()"
            [(ngModel)]="stateFilter"
          >
            <mat-option value="all">All </mat-option>
            <span class="optionStyling" *ngFor="let state of states">
              <mat-option value="{{ state.state_id }}">{{
                state.name
              }}</mat-option>
            </span>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 ml-2">
        <mat-form-field appearance="outline" class="forAlignments">
          <mat-label>Notification type</mat-label>
          <mat-select
            placeholder="Notification type"
            (selectionChange)="filterNotificationTag()"
            [(ngModel)]="notificationTag"
          >
            <span class="optionStyling">
              <mat-option value="all">All </mat-option>
              <mat-option [value]="0">Entrance Examination</mat-option>
              <mat-option [value]="1">Scholarship</mat-option>
              <mat-option [value]="2">Admission</mat-option>
              <mat-option [value]="3">Training/Skill Development</mat-option>
              <mat-option [value]="4">Government Jobs</mat-option>
              <mat-option [value]="5">Others</mat-option>
            </span>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- reset filter -->

      <!-- <img
        src="/assets/Notifications/removeFilter.svg"

        style="padding-left: 2rem; padding-bottom: 15px; cursor: pointer; position: relative;"
        (click)="resetFilter()"
      /> -->

      <div class="col-md-4 ml-4">
        <mat-form-field
          appearance="outline"
          class="searchField"
          style="width: 127%"
        >
          <input
            type="text"
            matInput
            placeholder="Search Notifications"
            [formControl]="myControl"
            (input)="filterSearch()"
            [(ngModel)]="searchText"
          />
          <!-- [formControl]="myControl" -->
          <!-- <mat-autocomplete>
          <mat-option *ngFor="let option of options; index as j;let last=last" [value]="option">{{
            option
          }}</mat-option>
        </mat-autocomplete> -->
          <!-- <img
          matSuffix
          mat-card-image
          src="/assets/Notifications/notificationBg.svg"
          style="display: flex; height: 50px"
        /> -->

          <mat-icon
            matSuffix
            src="/assets/Dashboard/pageArrowNext.svg"
            class="magBag"
          >
            search
          </mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- for mobile responsive -->
  <div
    class="col mt-3"
    style="
      height: 82.62px !important;
      padding-inline-start: 1.5rem !important;
      min-width: 790px !important;
    "
    *ngIf="deviceXs"
  >
    <mat-form-field appearance="outline" class="searchField">
      <input
        type="text"
        matInput
        placeholder="Search Notifications"
        [formControl]="myControl"
        (input)="filterSearch()"
        [(ngModel)]="searchText"
      />
      <!-- [formControl]="myControl" -->
      <!-- <mat-autocomplete>
        <mat-option *ngFor="let option of options; index as j;let last=last" [value]="option">{{
          option
        }}</mat-option>
      </mat-autocomplete> -->
      <mat-icon matSuffix class="magBag"> search </mat-icon>
    </mat-form-field>
  </div>
  <div class="row" *ngIf="deviceXs">
    <div class="col-6" style="height: 77.13px !important; padding-left: 2.5rem">
      <mat-form-field appearance="outline" class="auth">
        <mat-label>Academic level</mat-label>
        <mat-select
          placeholder="Academic level"
          (selectionChange)="filterAcademicLevel()"
          [(ngModel)]="academicFilter1"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option value="0">Below 10th</mat-option>
            <mat-option value="1">Studying 10th</mat-option>
            <mat-option value="2">Completed 10th</mat-option>
            <mat-option value="3">Studying Inter/12th/PUC</mat-option>
            <mat-option value="4">Completed Inter/12th/PUC</mat-option>
            <mat-option value="5">Studying ITI</mat-option>
            <mat-option value="6">Completed ITI</mat-option>
            <mat-option value="7">Studying Diploma</mat-option>
            <mat-option value="8">Completed Diploma'</mat-option>
            <mat-option value="9">Studying Graduation</mat-option>
            <mat-option value="10">Completed Graduation</mat-option>
            <mat-option value="11">Studying Technical Graduation</mat-option>
            <mat-option value="12">Completed Technical Graduation</mat-option>
            <mat-option value="13">Studying PG</mat-option>
            <mat-option value="14">Completed PG</mat-option>
            <mat-option value="15">Other</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label>State</mat-label>
        <mat-select
          placeholder="State"
          (selectionChange)="filterState()"
          [(ngModel)]="stateFilter"
        >
          <mat-option value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let state of states">
            <mat-option value="{{ state.state_id }}">{{
              state.name
            }}</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-6" style="height: 77.13px !important; padding-left: 2.5rem">
      <mat-form-field appearance="outline" class="auth">
        <mat-label>Notification type</mat-label>
        <mat-select
          placeholder="Notification type"
          (selectionChange)="filterNotificationTag()"
          [(ngModel)]="notificationTag"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Entrance Examination</mat-option>
            <mat-option [value]="1">Scholarship</mat-option>
            <mat-option [value]="2">Admission</mat-option>
            <mat-option [value]="3">Training/Skill Development</mat-option>
            <mat-option [value]="4">Government Jobs</mat-option>
            <mat-option [value]="5">Others</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="col px-5 pb-4" *ngIf="optionActive == 1 || optionActive == 2">
    <div
      class="row py-3 px-2"
      style="background-color: white; padding-bottom: 0rem !important"
      *ngFor="
        let notificatiionitem of filterOptions | filter : searchText;
        index as j;
        let Last = last
      "
    >
      <div class="col-md-12 d-flex col-xs-12 col-sm-12">
        <!-- <div class="row" style="padding-inline-start: 5px"> -->
        <div class="img p-0" style="margin-bottom: auto">
          <img src="/assets/careerDetail/ellipse.png" />
        </div>
        <div class="text col-md-12 col-xs-8 col-sm-8">
          <div
            [innerHTML]="notificatiionitem.name"
            style="word-wrap: break-word; cursor: pointer"
            (click)="coToNotificationeDetails(notificatiionitem['id'])"
          >
            <!-- {{ notificatiionitem.name }} -->
          </div>
          <div style="padding-top: 0.5rem">
            <span style="padding-top: 0.5rem; font-weight: 500"
              >Active Until :
            </span>
            {{
              notificatiionitem?.active_until !== null || ""
                ? [notificatiionitem?.active_until]
                : "NA"
            }}
            <span *ngIf="notificatiionitem.attachment_link?.length > 0">
              <a href="{{ notificatiionitem.attachment_link }}" target="_blank">
                <img
                  src="/assets/Dashboard/Icon metro-attachment.svg"
                  style="padding-inline-start: 3px"
                />
              </a>
            </span>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <!-- <span> {{ last_date }} </span> -->

      <div class="bottomTagsStyling col-12 col-xs-12 col-sm-12">
        <div *ngFor="let item of notificatiionitem.tags">
          <div
            [innerHTML]="statustext(item)"
            [ngClass]="{
              admissionTag: item === 2,
              entranceTag: item === 0,
              scholarshipTag: item === 1,
              trainingTag: item === 3,
              govtJobsTag: item === 4,
              otherTag: item === 5
            }"
          ></div>
        </div>
      </div>
    </div>
    <ngb-pagination
      class="d-flex justify-content-center pagination_theme"
      [maxSize]="5"
      [collectionSize]="collectionSize"
      [pageSize]="pageSize"
      [(page)]="currentPage"
      (pageChange)="onPageChange(currentPage)"
    >
      <ng-template ngbPaginationPrevious>
        <img
          src="/assets/Dashboard/pageArrowPrev.svg"
          [ngClass]="[collectionSize] < '5' ? '' : ''"
        />
      </ng-template>
      <ng-template ngbPaginationNext>
        <img
          src="/assets/Dashboard/pageArrowNext.svg"
          [ngClass]="[collectionSize] < '5' ? '' : ''"
        />
      </ng-template>
    </ngb-pagination>
  </div>
</div>
