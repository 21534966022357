import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiCallsService } from '../api-calls.service';

declare var $: any;
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css'],
})
export class ResourcesComponent implements OnInit {
  @ViewChild('inputUrl') inputUrl: ElementRef;

  searchText;
  myControl = new FormControl();
  ResourcesResponse: any;
  link: any;
  responseLinkTriming: any;
  items = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25,
  ];

  constructor(
    private apiCallService: ApiCallsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.apiCallService.verifyLogin();
    this.getAllResources();
  }

  // for looping over based on input
  counter(i: number) {
    return new Array(i);
  }

  getAllResources() {
    console.log('hello');

    this.apiCallService.getResources().subscribe(
      (res) => {
        console.log(res);
        this.ResourcesResponse = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  viewDialogClick(index) {
    $('#terms').modal('toggle');
    this.link = this.ResourcesResponse[index].resource_link;
    this.responseLinkTriming = this.link;
    if (
      this.responseLinkTriming != null &&
      this.responseLinkTriming != undefined
    ) {
      return (this.responseLinkTriming =
        this.responseLinkTriming.substring(0, 35) + '...');
    }
  }

  closeDialogBox() {
    $('#terms').modal('toggle');
  }

  copy(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    $('#terms').modal('toggle');
  }
}
