import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mentoship',
  templateUrl: './mentoship.component.html',
  styleUrls: ['./mentoship.component.css'],
})
export class MentoshipComponent implements OnInit {
  @Input() deviceXs: boolean;

  constructor(private router: Router, public mediaObserver: MediaObserver) {}

  mediaSub: Subscription;

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  routingToMetorfaqs() {
    window.scroll(0, 0);
    this.router.navigate(['/dashboard/mentorfaqs']);
  }
}
