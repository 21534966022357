import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mentor-faqs',
  templateUrl: './mentor-faqs.component.html',
  styleUrls: ['./mentor-faqs.component.css'],
})
export class MentorFaqsComponent implements OnInit {
  constructor() {}

  details = [];
  data = [
    {
      name: 'Who can register as a mentor?',
      details:
        'Any working professional with a professional degree, who is willing to commit 2 hours of time weekly to uplift a rural underprivileged child.',
    },
    {
      name: 'What is the duration of the program?',
      details:
        'Each kind of mentorship has a different duration: Holistic mentoring: 2- years of time Project mentoring: 3 months- 6 months depending on the completion duration Skilling mentorship: 3 months',
    },
    {
      name: 'Who will be my student?',
      details:
        'Students will get registered separately, and based on the interest area of the mentee, the mentors will be appropriately chosen',
    },
    {
      name: 'What is the background of the student?',
      details:
        'Our students, aged 15-22 years, are extremely meritorious but socio-economically distressed chosen from rural, urban, and semi-urban locations of Andhra and Telangana. Every year, students who have passed their SSC are invited for a serious round of 5 step selection process, and the selected students form a cohort.',
    },
    {
      name: 'Will I be able to choose my mentee?',
      details:
        'No. At VHL will be following the manual mentor-mentee mapping based on their interests, domain, background, etc.,',
    },
    {
      name: 'What if my mentee leaves in the middle of the program duration?',
      details:
        'Under non-negotiable circumstances, a student could leave the program which is a rare occurrence. But in such a case, if you are willing to still provide your valuable support in the form of mentorship, we shall provide you with another mentee upon availability.',
    },
    {
      name: 'What if I am not able to continue as a mentor?',
      details: `We recommend you to thoroughly evaluate before you can commit to the program. Since , it's not just discussion that happens in mentorship, it's bonding, beyond the ambit of Nirmaan. However, we understand, you could face circumstances that  wouldn't support mentorship. In such cases, we request you to inform us at least a month in advance, so we look for another mentor to replace you. Also, it is highly recommended to mentally prepare your mentee for the change`,
    },
    {
      name: 'What if the project is not successful?',
      details: `It's not always the destination that defines the success, it's most often the journey. Even if the end result may not seem extremely appealing, but on the go, we believe there will be immense learning`,
    },
    {
      name: 'What if the project is not being completed by said timelines?',
      details:
        'At present, due to the COVID, there is a lot of uncertainty in the student academics. So, will be looking out for the scope of the project & roadblocks. Based on the mutual agreement of mentor & mentees will be taking the final decision only for a certain period of time.',
    },
    {
      name: 'The project incurs certain expenditure and my student is unable to pay, should i pay on his behalf?',
      details:
        'NO. Firstly, all the needs of the students during the project should be verified by the mentor & it should also be highlighted to the CSP team. Based on the requirement the CSP team would be providing the same.',
    },
    {
      name: 'Will there be a training and orientation for the mentorship process?',
      details:
        'YES. There will be an orientation session soon after Onboarding & going forward series of training modules will be discussed in a few weeks of the timeline.',
    },
    {
      name: 'Will we be monitored?',
      details:
        'The VHL team members will be reaching out to mentors on a monthly & quarterly basis to check the pair status & discuss various other interventions as needed. This interaction will help us know about the progress of the mentors.',
    },
    {
      name: 'Can we continue our relation further post the relationship cycle ends?',
      details: 'Sure.',
    },
    {
      name: 'I am confused what these types of mentorship are?',
      details:
        'Based on the student needs the mentorships types were classified.After understanding each type of mentorship in detail, the mentor can enroll for the same.  ',
    },
    {
      name: 'I would like to be a part of all the 3 mentorships, can I?',
      details:
        'YES, you can be a part of 3 types of mentorship programs as long as you are stick to your commitment & promised timelines.',
    },
    {
      name: 'What support do I get from VHL?',
      details:
        '"VHL’s strength is its information wealth of information that aids in career progress. Courses, training institutes, scholarships, admission, hostels colleges, In-depth career counseling, etc."',
    },
    {
      name: 'How do we know if our mentee mentor pair is doing well?',
      details:
        'Performance in academics and otherwise Enhanced spirit of both the parties involved Mentee-Mentor connects itself talks volumes."',
    },
    {
      name: 'In case of situations, where I may not continue as a mentor, whats my exit strategy?',
      details:
        'Please understand that it’s an emotional bond you share with a mentee and the mentee would have shared some most confidential information. Hence we recommend that unless you are sure about your support and commitment, please do not walk into the program. However, there could be certain non-negotiable conditions, under which, mentorship may not be possible. Only and only under such conditions, do we insist that you bring in a mentor to replace you. After the mentor gets selected and sits through the orientation, we request you to do the complete knowledge transfer to the new mentor and connect the mentee to the new mentor.  Inform the mentee about the transition and also keep the parents informed.',
    },
    {
      name: 'Will I formally evaluate my mentee?',
      details:
        'Yes, you will certainly evaluate your mentee formally and frequently- You could also do a baseline assessment of mentees on various parameters and frequently evaluate the child on the same and revise the action plan to achieve the objectives agreed on. Team CSP also will evaluate the mentee and mentor individually and as a pair and provide timely feedback to enhance the productivity of mentorship.',
    },
    {
      name: 'How will I be monitored and evaluated?',
      details:
        '"Periodic feedback from students and mentors both Growth observed in Students Periodic feedback from Cohort leads"',
    },
  ];

  ngOnInit(): void {}
}
