import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallsService } from '../api-calls.service';

import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-admin-master-dashboard',
  templateUrl: './admin-master-dashboard.component.html',
  styleUrls: ['./admin-master-dashboard.component.css'],
})
export class AdminMasterDashboardComponent implements OnInit {
  minDate: Date;

  constructor(
    private router: Router,
    private apiCaller: ApiCallsService,
    public dialog: MatDialog
  ) {
    this.minDate = new Date();
  }

  count = null;
  careerWikiSection: string = '';
  dbOptionsRequired: boolean = false;
  dbOperation = 'Insert';
  dbTables = [
    {
      dbMainTable: 'Careers',
      dbSubTables: [
        { name: 'Careers (Default)', value: 'careersUpload' },
        { name: 'Subjects', value: 'careersSubjectsUpload' },
      ],
    },
    {
      dbMainTable: 'Colleges',
      dbSubTables: [
        { name: 'Colleges (Default)', value: 'collegesUpload' },
        {
          name: 'Colleges Course Mapping',
          value: 'collegesCourseMappingUpload',
        },
        { name: 'Colleges Course Filter', value: 'collegesCourseFilterUpload' },
        { name: 'Colleges Stream Filter', value: 'collegesStreamFilterUpload' },
      ],
    },
    {
      dbMainTable: 'Scholarships',
      dbSubTables: [
        { name: 'Scholarships (Default)', value: 'scholarshipsUpload' },
        { name: 'Scholarships Mapping', value: 'scholarshipsMappingUpload' },
      ],
    },
    {
      dbMainTable: 'Notifications',
      dbSubTables: [
        { name: 'Notifications (Default)', value: 'notificationsUpload' },
        { name: 'Notifications Mapping', value: 'notificationsMappingUpload' },
      ],
    },
  ];
  dbSubTables = [];
  selectedDbTable: string = '';
  fileName = '';
  uploadFile: File;
  spinnerMode: string = 'indeterminate';
  uploading: boolean = false;
  failedRows = [];
  resultMessage = ``;
  successMessage = `No errors ${this.dbOperation} operation successful`;
  unsuccessfulMessage = `Some error has occured ${this.dbOperation} unsuccessful`;
  displayedColumns: string[] = [
    'rowId',
    'column_name',
    'errMessage',
    'process',
  ];
  noFile: boolean = false;
  resultsLink: string = '';

  notificationFiles: File[] = [];
  notificationFilesAllowed: boolean = true;
  notificationsUploading: boolean = false;
  notificationsFailedRows = [];
  notificationsResultMessage = '';
  notificationsDisplayedColumns: string[] = [
    'serialNo',
    'fileName',
    'fileUrl',
    'message',
  ];

  ngOnInit(): void {}

  formGroupValidator = false;

  form1 = new FormGroup({
    fromDate: new FormControl('', [Validators.required]),
    toDate: new FormControl('', [Validators.required]),
  });

  getUsersApiCall() {
    if (this.form1.valid) {
      let fromMonth = this.form1.controls.fromDate.value;
      let fromDay = this.form1.controls.fromDate.value;
      let fromYear = this.form1.controls.fromDate.value;
      let fromDate = `${fromYear.getFullYear()}-${
        fromMonth.getMonth() + 1
      }-${fromDay.getDate()}`;

      let toMonth = this.form1.controls.toDate.value;
      let toDay = this.form1.controls.toDate.value;
      let toYear = this.form1.controls.toDate.value;
      let toDate = `${toYear.getFullYear()}-${toMonth.getMonth() + 1}-${
        toDay.getDate() + 1
      }`;

      console.log(fromDate, toDate);

      this.apiCaller.userList(fromDate, toDate).subscribe(
        (res) => {
          console.log(res);
          this.downloadFile(res, 'usersData');
          $('#deleleDialog').modal('toggle');
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.formGroupValidator = true;
    }
  }

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, [
      'id',
      'email',
      'username',
      'name',
      'date_joined',
      'is_active',
      'mobile',
      'gender',
      'disability',
      'date_of_birth',
      'current_status',
      'qualification',
      'institute_category',
      'institute_name',
      'course',
      'residential_category',
      'city_name',
      'mandal_name',
      'state',
      'district',
      'district_id',
      'profession',
      'organization_name',
    ]);
    // console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + '';

      for (let index in headerList) {
        let head = headerList[index];

        // below if condition is for, if we have one object(state) inside one object(entire user object), if we wanna take value which is
        // inside of state object we mak if condition

        let value: any = array[i][head];
        if (head === 'state' || head === 'district_id') {
          if (value !== null) {
            value = array[i][head]['name'];
          }
        }
        // console.log("value", value);

        // line += ',' + array[i][head]; if we dont have multiple comma separated values unser single key ex cityname = 'vizag'
        // below '"' is place inside single quotes and comma to take entite key as string ex cityname = "arilova,vizag" here problem
        // is csv sheet data mapped to another column like state data moved to district.....

        line += ',"' + value + '"';
      }
      str += line + '\r\n';
    }
    return str;
  }

  checkDbTable(): void {
    this.dbOptionsRequired = false;
    this.dbSubTables = [];
    console.log('in checkdb');
    for (let i = 0; i < this.dbTables.length; i++) {
      if (this.dbTables[i].dbMainTable === this.careerWikiSection) {
        this.dbOptionsRequired = true;
        this.dbSubTables = this.dbTables[i].dbSubTables;
        this.selectedDbTable = this.dbSubTables[0].value;
        console.log(this.selectedDbTable);
        break;
      }
    }
  }

  uploadModalToggle(whichSection: string) {
    this.noFile = false;
    this.careerWikiSection = whichSection;
    this.checkDbTable();
    console.log(this.careerWikiSection);
    this.fileName = 'No file uploaded yet ';
    this.uploadFile = null;
    $('#uploadData').modal('toggle');
  }

  showResultModal() {
    this.uploading = true;
    $('#uploadData').modal('hide');
    $('#uploadResult').modal('toggle');
  }

  changeData(event) {
    this.uploadFile = event.target.files[0];
    // console.log(event.target.files);
    event.target.value = null;
    // console.log(event.target.files);
    console.log(this.uploadFile);
    this.noFile = false;
    this.fileName = this.uploadFile.name;
  }

  uploadNotificationFilesToggle() {
    this.notificationsUploading = false;
    this.notificationsFailedRows = [];
    this.notificationFilesAllowed = true;
    this.notificationFiles = [];
    $('#uploadNotifications').modal('toggle');
  }

  showNotificationsResultModal() {
    this.notificationsUploading = true;
    $('#uploadNotifications').modal('hide');
    $('#notificationsUploadResult').modal('toggle');
  }

  changeNotificationFiles(event) {
    console.log(event.target.files);
    for (
      let notificationFileIndex = 0;
      notificationFileIndex < event.target.files.length;
      notificationFileIndex++
    ) {
      this.notificationFiles.push(event.target.files[notificationFileIndex]);
    }
    if (this.notificationFiles.length > 0) {
      this.notificationFilesAllowed = true;
    }
    event.target.value = null;
    console.log(this.notificationFiles);
  }

  clearNotificationFiles() {
    this.notificationFiles = [];
  }

  uploadNotifcationAttachments() {
    if (this.notificationFiles.length > 0) {
      this.notificationFilesAllowed = true;
      let attachmentData = [];
      const formData = new FormData();
      this.notificationFiles.forEach(
        (notificationFile, notificationFileIndex) => {
          console.log(notificationFile);
          formData.append('file', notificationFile);
          // attachmentData.push(notificationFile);
        }
      );
      console.log(attachmentData);
      this.apiCaller.uploadNotificationAttachments(formData).subscribe(
        (res: []) => {
          console.log('yay', res);
          this.notificationsFailedRows = res;
          this.notificationsUploading = false;
        },
        (err) => {
          console.log(err);
          this.showNotificationsResultModal();
        }
      );
      this.showNotificationsResultModal();
    } else {
      this.notificationFilesAllowed = false;
    }
  }

  showNotificationsUploadModal() {
    // this.fileName = 'No file uploaded yet ';
    this.notificationFiles = [];
    this.notificationsUploading = false;
    $('#notificationsUploadResult').modal('hide');
    $('#uploadNotifications').modal('toggle');
  }

  uploadData() {
    if (this.uploadFile) {
      this.noFile = false;
      this.failedRows = [];
      const formData = new FormData();
      formData.append('file', this.uploadFile);
      switch (this.careerWikiSection) {
        case 'Loans':
          this.uploadLoansDataApi(formData);
          break;
        case 'Hostels':
          this.uploadHostelDataApi(formData);
          break;
        case 'Careers':
          this.uploadCareerDataApi(formData);
          break;
        case 'Courses':
          this.uploadCoursesDataApi(formData);
          break;
        case 'Colleges':
          this.uploadCollegesDataApi(formData);
          break;
        case 'Scholarships':
          this.uploadScholarshipsDataApi(formData);
          break;
        case 'Entrances':
          this.uploadEntrancesDataApi(formData);
          break;
        case 'Notifications':
          this.uploadNotificationsDataApi(formData);
          break;
        case 'Districts':
          this.uploadDistrictsDataApi(formData);
          break;
        case 'States':
          this.uploadStatesDataApi(formData);
          break;
      }
      this.showResultModal();
    } else {
      this.noFile = true;
      this.fileName = 'Please select a file!!';
    }
  }

  uploadLoansDataApi(formData: FormData) {
    this.apiCaller.uploadLoansData(formData, this.dbOperation).subscribe(
      (res) => {
        this.failedRows = res['failedRows'];
        this.resultMessage = this.successMessage;
        this.uploading = false;
      },
      (err) => {
        this.resultMessage = this.unsuccessfulMessage;
        console.log(err);
      }
    );
  }

  uploadHostelDataApi(formData: FormData) {
    this.apiCaller.uploadHostelData(formData, this.dbOperation).subscribe(
      (res) => {
        console.log(res);
        console.log(res['failedRows']);
        this.failedRows = res['failedRows'];
        console.log('the operation is: ', this.dbOperation);
        this.resultMessage = this.successMessage;
        this.uploading = false;
      },
      (err) => {
        this.resultMessage = this.unsuccessfulMessage;
        console.log(err);
      }
    );
  }

  uploadCareerDataApi(formData: FormData) {
    this.apiCaller
      .uploadCareersData(formData, this.selectedDbTable, this.dbOperation)
      .subscribe(
        (res) => {
          this.failedRows = res['failedRows'];
          this.resultMessage = this.successMessage;
          this.uploading = false;
          console.log(res);
          console.log(res['failedRows']);
        },
        (err) => {
          this.resultMessage = this.unsuccessfulMessage;
          this.uploading = false;
          console.log(err);
        }
      );
  }

  uploadCoursesDataApi(formData: FormData) {
    this.apiCaller.uploadCoursesData(formData, this.dbOperation).subscribe(
      (res) => {
        this.failedRows = res['failedRows'];
        this.resultMessage = this.successMessage;
        this.uploading = false;
        console.log(res);
        console.log(res['failedRows']);
      },
      (err) => {
        this.resultMessage = this.unsuccessfulMessage;
        this.uploading = false;
        console.log(err);
      }
    );
  }

  uploadCollegesDataApi(formData: FormData) {
    this.apiCaller
      .uploadCollegesData(formData, this.selectedDbTable, this.dbOperation)
      .subscribe(
        (res) => {
          this.failedRows = res['failedRows'];
          console.log('the operation is: ', this.dbOperation);
          this.resultMessage = this.finalMsgMaker(true);
          this.resultsLink = '/adminDashboard/colleges';
          this.uploading = false;
          console.log(res);
          console.log(res['failedRows']);
        },
        (err) => {
          this.resultMessage = this.finalMsgMaker(false);
          this.uploading = false;
          console.log(err);
        }
      );
  }

  uploadScholarshipsDataApi(formData: FormData) {
    this.apiCaller
      .uploadScholarshipsData(formData, this.selectedDbTable, this.dbOperation)
      .subscribe(
        (res) => {
          this.failedRows = res['failedRows'];
          console.log('the operation is: ', this.dbOperation);
          this.resultMessage = this.finalMsgMaker(true);
          this.resultsLink = '/adminDashboard/scholarships';
          this.uploading = false;
          console.log(res);
          console.log(res['failedRows']);
        },
        (err) => {
          this.resultMessage = this.finalMsgMaker(false);
          this.uploading = false;
          console.log(err);
        }
      );
  }

  uploadEntrancesDataApi(formData: FormData) {
    this.apiCaller.uploadEntrancesData(formData, this.dbOperation).subscribe(
      (res) => {
        this.failedRows = res['failedRows'];
        this.resultMessage = this.finalMsgMaker(true);
        this.resultsLink = '/adminDashboard/entrances';
        this.uploading = false;
      },
      (err) => {
        this.resultMessage = this.finalMsgMaker(false);
        this.uploading = false;
      }
    );
  }

  uploadNotificationsDataApi(formData: FormData) {
    this.apiCaller
      .uploadNotificationsData(formData, this.selectedDbTable, this.dbOperation)
      .subscribe(
        (res) => {
          this.failedRows = res['failedRows'];
          this.resultMessage = this.finalMsgMaker(true);
          this.resultsLink = '/adminDashboard/notifications';
          this.uploading = false;
        },
        (err) => {
          this.resultMessage = this.finalMsgMaker(false);
          this.uploading = false;
        }
      );
  }

  uploadDistrictsDataApi(formData: FormData) {
    this.apiCaller.uploadDistrictsData(formData, this.dbOperation).subscribe(
      (res) => {
        this.failedRows = res['failedRows'];
        this.resultMessage = this.finalMsgMaker(true);
        this.resultsLink = '/adminDashboard/districts';
        this.uploading = false;
      },
      (err) => {
        this.resultMessage = this.finalMsgMaker(false);
        this.uploading = false;
      }
    );
  }

  uploadStatesDataApi(formData: FormData) {
    this.apiCaller.uploadStatesData(formData, this.dbOperation).subscribe(
      (res) => {
        this.failedRows = res['failedRows'];
        this.resultMessage = this.finalMsgMaker(true);
        this.resultsLink = '/adminDashboard/states';
        this.uploading = false;
      },
      (err) => {
        this.resultMessage = this.finalMsgMaker(false);
        this.uploading = false;
      }
    );
  }

  showUploadModal() {
    this.fileName = 'No file uploaded yet ';
    this.uploadFile = null;
    this.uploading = false;
    $('#uploadResult').modal('hide');
    $('#uploadData').modal('toggle');
  }

  finalMsgMaker(isSuccess: boolean): string {
    let returnMsg = '';
    if (isSuccess) {
      returnMsg = `No errors ${this.dbOperation.toUpperCase()} operation successful`;
    } else if (!isSuccess) {
      returnMsg = `Some error has occured ${this.dbOperation.toUpperCase()} unsuccessful`;
    }
    return returnMsg;
  }

  navigateUsingResults() {
    $('#uploadResult').modal('hide');
    this.router.navigateByUrl(this.resultsLink);
  }

  deleteExpiredNotifications() {
    this.apiCaller.deleteExpiredNotifications().subscribe(
      (res) => {
        console.log(res);
        this.count = res['count'];
        console.log(this.count);
        if (res['message'] == 'Notifications deleted Successfully') {
          console.log($('#terms'));
          $('#terms').modal('hide');
          // this.terms.nativeElement.click();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // goToLanding() {
  //   console.log('closing');
  //   console.log('closing');
  //   this.dialogRef.close(true);
  //   }

  deleteDialogClick() {
    let URL = '?expired=0';
    this.apiCaller.adminActiveInactiveNotifications(URL).subscribe((res) => {
      this.count = res['count'];
      $('#terms').modal('toggle');
    });
  }

  generateDialogClick() {
    $('#deleleDialog').modal('toggle');
  }
}
