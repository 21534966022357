import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { AdminAddNotificationsComponent } from './../admin-add-notifications/admin-add-notifications.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';
import { CareerlistServiceService } from '../careerlist-service.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-notifications',
  templateUrl: './admin-notifications.component.html',
  styleUrls: ['./admin-notifications.component.css'],
})
export class AdminNotificationsComponent implements OnInit {
  searchText;
  myControl = new FormControl();
  selected = '0';

  notificationTypeFilter: any = '1';

  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  currentPageURL: string = null;
  currentFilterURL: string = null;

  notificationsDisplayList: any[] = [
    { displayName: 'Notifications', displayValue: 0 },
    { displayName: 'Notifiations Mappings', displayValue: 1 },
  ];

  selectedDisplayView: any = 0;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private apiCaller: ApiCallsService,
    public mediaObserver: MediaObserver
  ) {}

  notificationIdName = {
    id: null,
  };
  notificationResponse: any = [];
  optionActive: number;

  openDialog() {
    this.dialog.open(AdminAddNotificationsComponent, {
      disableClose: true,
      closeOnNavigation: true,
    });
  }

  groupTagValues = {
    0: 'Entrance Examination',
    1: 'Scholarship',
    2: 'Admission',
    3: 'Training/Skill Development',
    4: 'Government Jobs',
    5: 'Others',
  };

  groupStateValues = {
    0: 'National',
    1: 'Andhra Pradesh',
    2: 'Telangana',
    3: 'Karnataka',
  };

  groupPublishedValues = {
    0: 'published',
    1: 'unpublished',
  };

  groupAcademicValues = {
    0: 'Below 10th',
    1: 'Studying 10th',
    2: 'Completed 10th',
    3: 'Studying Inter/12th/PUC',
    4: 'Completed Inter/12th/PUC',
    5: 'Studying ITI',
    6: 'Completed ITI',
    7: 'Studying Diploma',
    8: 'Completed Diploma',
    9: 'Studying Graduation',
    10: 'Completed Graduation',
    11: 'Studying Technical Graduation',
    12: 'Completed Technical Graduation',
    13: 'Studying PG',
    14: 'Completed PG',
    15: 'Others',
  };

  statustext(item) {
    if (item != null && item != undefined) {
      return this.groupTagValues[item];
    } else {
      return '';
    }
  }

  filterNotificationType() {
    console.log('in filter');
    console.log(this.searchText);
    let URL = '';
    if (this.notificationTypeFilter !== null) {
      if (this.notificationTypeFilter === '1') {
        URL = '?active=1';
        this.currentPage = 1;

        if (this.searchText !== null && this.searchText !== undefined) {
          URL = `?active=1&search=${this.searchText}`;
        }

        console.log(this.notificationTypeFilter);
        this.apiCaller
          .adminActiveInactiveNotifications(URL)
          .subscribe((res) => {
            // console.log(res["results"]);
            this.collectionSize = res['count'];
            this.notificationResponse = res['results'];
            console.log(this.notificationResponse);
          });
      }
      if (this.notificationTypeFilter === '5') {
        URL = '?active=0';
        this.currentPage = 1;

        if (this.searchText !== null && this.searchText !== undefined) {
          URL = `?active=0&search=${this.searchText}`;
        }
        console.log(this.notificationTypeFilter);
        this.apiCaller
          .adminActiveInactiveNotifications(URL)
          .subscribe((res) => {
            // console.log(res["results"]);
            this.collectionSize = res['count'];
            this.notificationResponse = res['results'];
            console.log(this.notificationResponse);
          });
      }

      if (this.notificationTypeFilter === '2') {
        URL = '?expired=0';
        this.currentPage = 1;

        if (this.searchText !== null && this.searchText !== undefined) {
          URL = `?expired=0&search=${this.searchText}`;
        }
        console.log(this.notificationTypeFilter);
        this.apiCaller
          .adminActiveInactiveNotifications(URL)
          .subscribe((res) => {
            // console.log(res["results"]);
            this.collectionSize = res['count'];
            this.notificationResponse = res['results'];
            console.log(this.notificationResponse);
          });
      }

      if (this.notificationTypeFilter === '3') {
        URL = '?published=1';
        this.currentPage = 1;

        if (this.searchText !== null && this.searchText !== undefined) {
          URL = `?published=1&search=${this.searchText}`;
        }

        console.log(this.notificationTypeFilter);
        this.apiCaller
          .adminActiveInactiveNotifications(URL)
          .subscribe((res) => {
            this.collectionSize = res['count'];
            // console.log(res["results"]);
            this.notificationResponse = res['results'];
            console.log(this.notificationResponse);
          });
      }
      if (this.notificationTypeFilter === '4') {
        URL = '?published=0';
        this.currentPage = 1;

        if (this.searchText !== null && this.searchText !== undefined) {
          URL = `?published=0&search=${this.searchText}`;
        }

        console.log(this.notificationTypeFilter);
        this.apiCaller
          .adminActiveInactiveNotifications(URL)
          .subscribe((res) => {
            // console.log(res["results"]);
            this.collectionSize = res['count'];
            this.notificationResponse = res['results'];
            console.log(this.notificationResponse);
          });
      }
    }
  }

  getDefaultPageAdminNotifications() {
    let defaultURL = '?active=1';
    this.apiCaller
      .adminActiveInactiveNotifications(defaultURL)
      .subscribe((res) => {
        // console.log(res["results"]);
        this.collectionSize = res['count'];
        this.notificationResponse = res['results'];
        console.log(this.notificationResponse);
      });
  }

  postcallId(notificationId) {
    console.log(notificationId);
    //  payload or body
    this.notificationIdName = {
      id: notificationId,
    };

    console.log(this.notificationIdName);

    // publishing notification

    this.apiCaller.publishNotifications(this.notificationIdName).subscribe(
      (publishCallResponse) => {
        console.log(publishCallResponse['message']);
        // if(publishCallResponse["message"] == "Notification Updated Successfully") {
        //   this.optionActive = 1;
        // }
        // if(publishCallResponse["message"] == "Notification unpublished Successfully") {
        //   this.optionActive = 2;
        // }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onPageChange(currentPage) {
    let url = null;
    if (this.notificationTypeFilter === '1') {
      if (currentPage != 0) {
        url = `page=${currentPage}&active=1`;
        this.currentPageURL = url;
        if (this.currentFilterURL !== null) {
          url = `?${url}&${this.currentFilterURL}`;
        } else {
          url = `?${url}`;
        }
      }
      this.previousPage = currentPage;
      this.apiCaller.adminActiveInactiveNotifications(url).subscribe((res) => {
        // console.log(res["results"]);
        this.collectionSize = res['count'];
        this.notificationResponse = res['results'];
        console.log(this.notificationResponse);
      });
    }
    if (this.notificationTypeFilter === '5') {
      if (currentPage != 0) {
        url = `page=${currentPage}&active=0`;
        this.currentPageURL = url;
        if (this.currentFilterURL !== null) {
          url = `?${url}&${this.currentFilterURL}`;
        } else {
          url = `?${url}`;
        }
      }
      this.previousPage = currentPage;
      this.apiCaller.adminActiveInactiveNotifications(url).subscribe((res) => {
        // console.log(res["results"]);
        this.collectionSize = res['count'];
        this.notificationResponse = res['results'];
        console.log(this.notificationResponse);
      });
    }
    if (this.notificationTypeFilter === '3') {
      if (currentPage != 0) {
        url = `page=${currentPage}&published=1`;
        this.currentPageURL = url;
        if (this.currentFilterURL !== null) {
          url = `?${url}&${this.currentFilterURL}`;
        } else {
          url = `?${url}`;
        }
      }
      this.previousPage = currentPage;
      this.apiCaller.adminActiveInactiveNotifications(url).subscribe((res) => {
        // console.log(res["results"]);
        this.collectionSize = res['count'];
        this.notificationResponse = res['results'];
        console.log(this.notificationResponse);
      });
    }
    if (this.notificationTypeFilter === '4') {
      if (currentPage != 0) {
        url = `page=${currentPage}&published=0`;
        this.currentPageURL = url;
        if (this.currentFilterURL !== null) {
          url = `?${url}&${this.currentFilterURL}`;
        } else {
          url = `?${url}`;
        }
      }
      this.previousPage = currentPage;
      this.apiCaller.adminActiveInactiveNotifications(url).subscribe((res) => {
        // console.log(res["results"]);
        this.collectionSize = res['count'];
        this.notificationResponse = res['results'];
        console.log(this.notificationResponse);
      });
    }
    if (this.notificationTypeFilter === '2') {
      if (currentPage != 0) {
        url = `page=${currentPage}&expired=0`;
        this.currentPageURL = url;
        if (this.currentFilterURL !== null) {
          url = `?${url}&${this.currentFilterURL}`;
        } else {
          url = `?${url}`;
        }
      }
      this.previousPage = currentPage;
      this.apiCaller.adminActiveInactiveNotifications(url).subscribe((res) => {
        // console.log(res["results"]);
        this.collectionSize = res['count'];
        this.notificationResponse = res['results'];
        console.log(this.notificationResponse);
      });
    }
  }

  ngOnInit(): void {
    this.getDefaultPageAdminNotifications();
  }
}
