<div class="pt-3">
  <div class="d-flex totalHolder">
    <div class="d-flex fixedHolder">
      <div class="d-flex titleAndBackHolder">
        <div class="d-flex titleAndBackSection">
          <mat-icon
            class="backArrow cursor-pointer"
            routerLink="/adminDashboard"
            >arrow_back</mat-icon
          >
          <div class="">Colleges Course Mappings</div>
        </div>
        <div class="d-flex displayOptions">
          <div class="cursor-pointer">Hide Options</div>
          <mat-slide-toggle color="primary" [(ngModel)]="hideOptions">
          </mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="!hideOptions" class="d-flex optionsHolder">
        <div class="d-flex align-center">
          <input
            class="searchInput"
            (keyup)="getCollegesCourseMappingData(currentPage, true)"
            placeholder="Search Course Mappings"
            [formControl]="searchFormControl"
          />
          <mat-icon
            *ngIf="searchFormControl.value?.length > 0"
            class="exportClearBtn"
            (click)="clearSearch()"
            >close</mat-icon
          >
        </div>
      </div>
      <div>
        <div [ngClass]="hideOptions ? 'tableHolder-full' : 'tableHolder'">
          <table
            mat-table
            [dataSource]="collegesCourseMappingsArr"
            class="mat-elevation-z8 w-full"
          >
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="college_id">
              <th mat-header-cell *matHeaderCellDef>College ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.college?.college_id }}
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="college_name">
              <th mat-header-cell *matHeaderCellDef>College Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.college?.name }}
              </td>
            </ng-container> -->

            <ng-container matColumnDef="entrance_exam">
              <th mat-header-cell *matHeaderCellDef>Entrance Exam</th>
              <td mat-cell *matCellDef="let element">
                {{ element.entrance_exam }}
              </td>
            </ng-container>

            <ng-container matColumnDef="enrolment_size">
              <th mat-header-cell *matHeaderCellDef>Enrollment Size</th>
              <td mat-cell *matCellDef="let element">
                {{ element.enrolment_size }}
              </td>
            </ng-container>

            <ng-container matColumnDef="fee">
              <th mat-header-cell *matHeaderCellDef>Fee</th>
              <td mat-cell *matCellDef="let element">
                {{ element.fee }}
              </td>
            </ng-container>

            <ng-container matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef>Duration</th>
              <td mat-cell *matCellDef="let element">
                {{ element.duration }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ngb-pagination
    class="d-flex justify-content-center pagination_theme"
    [maxSize]="5"
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    (pageChange)="getCollegesCourseMappingData(currentPage, false)"
  >
    <ng-template ngbPaginationPrevious>
      <img
        src="/assets/Dashboard/pageArrowPrev.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
    <ng-template ngbPaginationNext>
      <img
        src="/assets/Dashboard/pageArrowNext.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
  </ngb-pagination>
</div>
