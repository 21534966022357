import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-get-otp',
  templateUrl: './get-otp.component.html',
  styleUrls: ['./get-otp.component.css'],
})
export class GetOTPComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, private router: Router) {}

  form = new FormGroup({
    otp: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(6),
      Validators.pattern('^(0|[1-9][0-9]*)$'),
    ]),
  });

  formGroupValidator = false;

  ngOnInit(): void {}
  otpvalidation() {
    // let idi = this.data.id
    // console.log(idi)

    localStorage.setItem('otp', this.form.value.otp);

    if (this.form.valid) {
      console.log('success');
      this.router.navigate(['/auth/resetpassword']);
    } else {
      this.formGroupValidator = true;
    }
  }
}
