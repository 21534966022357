<div class="trainingInstituteDetailHolder">
  <!-- ** head -->
  <div class="trainingInstituteDetailhead">
    <!-- ** back arrow -->
    <mat-icon class="backArrow" routerLink="/dashboard/trainingInstituteList"
      >arrow_back</mat-icon
    >
    <!-- ** title -->
    <span class="trainingInstituteName">{{
      trainingInstituteDetail?.institute_name
    }}</span>
  </div>
  <!-- ** detail row 1 -->
  <div class="trainingInstituteDetailsCard">
    <!-- ** heading with icon -->
    <div class="trainingInstituteCardTitleHolder">
      <!-- ** heading -->
      <span class="trainingInstituteCardTitle">Institute Details</span>
      <img
        src="../../assets/careerDetail/student-hat.png"
        alt="Institute Details"
      />
    </div>
    <!-- ** Actual Training Institute details holder-->
    <div class="trainingInstituteOtherDetailsHolder">
      <!-- ** Actual Training Institute details -->
      <div>
        <b class="trainingDetailsOtherDetailsSubHeading">Institute Type : </b
        >{{ trainingInstituteDetail?.institute_type }}
      </div>
      <div>
        <b class="trainingDetailsOtherDetailsSubHeading"
          >Minimum Qualification :
        </b>
        {{ trainingInstituteDetail?.minimum_qualification }}
      </div>
      <div>
        <b class="trainingDetailsOtherDetailsSubHeading">Age Restriction : </b>
        {{ trainingInstituteDetail?.age_restriction }}
      </div>
    </div>
  </div>
  <!-- ** detail row 2 -->
  <div class="trainingInstituteDetailsRow">
    <!-- ** detail left section -->
    <div class="trainingInstituteDetailsRowLeftSection">
      <!-- ** detail left top -->
      <div class="trainingInstituteDetailsCard">
        <div class="trainingInstituteCardTitleHolder">
          <span class="trainingInstituteCardTitle">Other Details</span>
          <!-- ** icon -->
          <img
            src="../../assets/careerDetail/open-book.png"
            alt="Other Details"
          />
        </div>
        <div>
          <div class="trainingDetailsOtherDetails">
            <span class="greenCircle"></span>
            <div class="trainingDetailsOtherDetailsSubHolder">
              <span class="trainingDetailsOtherDetailsSubHeading"
                >Only Physically Handicapped</span
              >
              <span class="trainingDetailsOtherDetailsSubValue">{{
                trainingInstituteDetail?.only_physically_handicapped
              }}</span>
            </div>
          </div>
        </div>
        <!-- <div>
          <div class="trainingDetailsOtherDetails">
            <span class="greenCircle"></span>
            <div class="trainingDetailsOtherDetailsSubHolder">
              <span class="trainingDetailsOtherDetailsSubHeading"
                >Category 1</span
              >
              <span class="trainingDetailsOtherDetailsSubValue">{{
                trainingInstituteDetail?.category_1
              }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="trainingDetailsOtherDetails">
            <span class="greenCircle"></span>
            <div class="trainingDetailsOtherDetailsSubHolder">
              <span class="trainingDetailsOtherDetailsSubHeading"
                >Category 2</span
              >
              <span class="trainingDetailsOtherDetailsSubValue">{{
                trainingInstituteDetail?.category_2
              }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="trainingDetailsOtherDetails">
            <span class="greenCircle"></span>
            <div class="trainingDetailsOtherDetailsSubHolder">
              <span class="trainingDetailsOtherDetailsSubHeading"
                >Category 3</span
              >
              <span class="trainingDetailsOtherDetailsSubValue">{{
                trainingInstituteDetail?.category_3
              }}</span>
            </div>
          </div>
        </div> -->
        <div>
          <div class="trainingDetailsOtherDetails">
            <span class="greenCircle"></span>
            <div class="trainingDetailsOtherDetailsSubHolder">
              <span class="trainingDetailsOtherDetailsSubHeading">Gender</span>
              <span class="trainingDetailsOtherDetailsSubValue">{{
                trainingInstituteDetail?.gender
              }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="trainingDetailsOtherDetails">
            <span class="greenCircle"></span>
            <div class="trainingDetailsOtherDetailsSubHolder">
              <span class="trainingDetailsOtherDetailsSubHeading"
                >Accommodation</span
              >
              <span class="trainingDetailsOtherDetailsSubValue">{{
                trainingInstituteDetail?.accommodation
              }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="trainingDetailsOtherDetails">
            <span class="greenCircle"></span>
            <div class="trainingDetailsOtherDetailsSubHolder">
              <span class="trainingDetailsOtherDetailsSubHeading"
                >Approximate Notification time</span
              >
              <span class="trainingDetailsOtherDetailsSubValue">{{
                trainingInstituteDetail?.approx_notification_time
              }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="trainingDetailsOtherDetails">
            <span class="greenCircle"></span>
            <div class="trainingDetailsOtherDetailsSubHolder">
              <span class="trainingDetailsOtherDetailsSubHeading">Stipend</span>
              <span class="trainingDetailsOtherDetailsSubValue">{{
                trainingInstituteDetail?.stipend
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- ** detail left botton -->
      <div class="trainingInstituteDetailsCard">
        <div class="trainingInstituteCardTitleHolder">
          <span class="trainingInstituteCardTitle"
            >List Of Courses Offered</span
          >
          <img
            src="../../assets/careerDetail/surface.svg"
            alt="courses offered"
          />
        </div>
        <!-- ** courses -->
        <div
          *ngIf="
            trainingInstituteDetail?.trainingInstituteRelatedCourses.length > 0;
            else elseBlock
          "
          class="trainingInstituteCoursesHolder"
        >
          <div
            *ngFor="
              let trainingInstituteCourse of trainingInstituteDetail?.trainingInstituteRelatedCourses
            "
          >
            <div class="trainingInstituteCourseHolder">
              <div>
                <span class="greenCircle"></span>
              </div>
              <div class="trainingInstituteDetailsHolder">
                <span class="trainingInstituteCourseHeading">{{
                  trainingInstituteCourse?.course?.course_name
                }}</span>
                <div>
                  <div class="trainingInstituteDetail">
                    <span class="trainingInstituteSubHeading"
                      >Course Duration :
                    </span>
                    <span class="trainingDetailsOtherDetailsSubValue">{{
                      trainingInstituteCourse?.course?.course_duration
                    }}</span>
                  </div>
                  <div class="trainingInstituteDetail">
                    <span class="trainingInstituteSubHeading"
                      >Enrollment Size :
                    </span>
                    <span class="trainingDetailsOtherDetailsSubValue">{{
                      trainingInstituteCourse?.course?.enrollment_size
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #elseBlock>
          <span class="trainingDetailsOtherDetailsSubValue">N/A</span>
        </ng-template>
      </div>
    </div>
    <!-- ** detail right section -->
    <div class="trainingInstituteDetailsRowRightSection">
      <!-- ** detail right top -->
      <div class="trainingInstituteDetailsCard">
        <div class="trainingInstituteCardTitleHolder">
          <span class="trainingInstituteCardTitle">Address Details</span>
          <img
            src="../../assets/careerDetail/skills.png"
            alt="address_details"
          />
        </div>
        <!-- ** address details -->
        <div class="trainingInstituteDetailsHolder">
          <div class="trainingInstituteDetail">
            <span class="trainingInstituteSubHeading">Address/Contact : </span>
            <span class="trainingDetailsOtherDetailsSubValue">{{
              trainingInstituteDetail?.address_contact
            }}</span>
          </div>
          <div class="trainingInstituteDetail">
            <span class="trainingInstituteSubHeading">State : </span>
            <span class="trainingDetailsOtherDetailsSubValue">{{
              trainingInstituteDetail?.state.name
            }}</span>
          </div>
          <div class="trainingInstituteDetail">
            <span class="trainingInstituteSubHeading">District : </span>
            <span class="trainingDetailsOtherDetailsSubValue">{{
              trainingInstituteDetail?.district.name
            }}</span>
          </div>
        </div>
        <div></div>
      </div>
      <!-- ** detail right botton -->
      <div class="trainingInstituteDetailsCard">
        <!-- ** Contact Heading -->
        <div class="trainingInstituteCardTitleHolder">
          <span class="trainingInstituteCardTitle">Website Information</span>
          <img
            src="../../assets/careerDetail/desktop.png"
            alt="contact Information"
          />
        </div>
        <div class="trainingInstituteDetailsHolder">
          <div class="trainingInstituteDetail">
            <span class="trainingInstituteSubHeading">Website Info : </span>
            <a
              class="trainingDetailsOtherDetailsSubValue"
              [ngClass]="{
                'hyperlink': trainingInstituteDetail?.website_address !== 'N/A'
              }"
              trainingDetailsOtherDetailsSubValue
              (click)="
                openTrainingInstituteWebsite(
                  trainingInstituteDetail?.website_address
                )
              "
              >{{ trainingInstituteDetail?.website_address }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
