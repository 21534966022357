<div class="col-md-12 mt-3" style="height: 117.62px !important">
  <div>
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
      <span class="backTitle"> BankLoan List</span>
    </div>
  </div>
  <mat-form-field appearance="outline" class="searchField">
    <input
      type="text"
      matInput
      placeholder="Search BankLoans"
      [formControl]="myControl"
      [(ngModel)]="searchText"
    />
    <!-- [formControl]="myControl" -->
    <!-- <mat-autocomplete>
        <mat-option *ngFor="let option of options; index as j;let last=last" [value]="option">{{
          option
        }}</mat-option>
      </mat-autocomplete> -->
    <mat-icon matSuffix class="magBag"> search </mat-icon>
  </mat-form-field>
</div>

<div class="col-md-12">
  <div class="row" *ngIf="!deviceXs">
    <div class="col-md-2" style="height: 77.64px !important">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Course</mat-label>
        <mat-select
          placeholder="Course"
          (selectionChange)="filterLoan()"
          [(ngModel)]="prefferdGroupFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'0'">School</mat-option>
            <mat-option [value]="'1'">Vocational</mat-option>
            <mat-option [value]="'2'">Graduate</mat-option>
            <mat-option [value]="'3'">Post Graduate</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Study in India/abroad</mat-label>
        <mat-select
          placeholder="Study in India/abroad"
          (selectionChange)="filterLoan()"
          [(ngModel)]="study_location_filter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <!-- <mat-option [value]="0">Both</mat-option> -->
            <mat-option [value]="1">India</mat-option>
            <mat-option [value]="2">Abroad</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="col-md-2 ml-3">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Handicapped Eligibility" (selectionChange) = "filterScholarship()" [(ngModel)] = "handicappedFilter">
            <mat-option value='all'>All </mat-option>
            <mat-option [value]="0">Yes</mat-option>
            <mat-option [value]="1">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 ml-3">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Application Eligibility" (selectionChange) = "filterScholarship()" [(ngModel)] = "applicationFilter">
            <mat-option value='all'>All </mat-option>
            <mat-option [value]="0">Active</mat-option>
            <mat-option [value]="1">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
  </div>

  <!-- for mobile app filter -->

  <div class="row" *ngIf="deviceXs">
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="forAlignments">Course</mat-label>
        <mat-select
          placeholder="Course"
          (selectionChange)="filterLoan()"
          [(ngModel)]="prefferdGroupFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'0'">School</mat-option>
            <mat-option [value]="'1'">Vocational</mat-option>
            <mat-option [value]="'2'">Graduate</mat-option>
            <mat-option [value]="'3'">Post Graduate</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="forAlignments">Study in India/abroad</mat-label>
        <mat-select
          placeholder="Study in India/abroad"
          (selectionChange)="filterLoan()"
          [(ngModel)]="study_location_filter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <!-- <mat-option [value]="0">Both</mat-option> -->
            <mat-option [value]="1">India</mat-option>
            <mat-option [value]="2">Abroad</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="col-6">
        <mat-form-field appearance="outline" class="auth">
          <mat-select placeholder="Handicapped Eligibility" (selectionChange) = "filterScholarship()" [(ngModel)] = "handicappedFilter">
            <mat-option value='all'>All </mat-option>
            <mat-option [value]="0">Yes</mat-option>
            <mat-option [value]="1">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline" class="auth">
          <mat-select placeholder="Application Eligibility" (selectionChange) = "filterScholarship()" [(ngModel)] = "applicationFilter">
            <mat-option value='all'>All </mat-option>
            <mat-option [value]="0">Active</mat-option>
            <mat-option [value]="1">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
  </div>

  <div class="BG mt-1 table-responsive">
    <table class="table">
      <tr class="colorWaraper">
        <th class="coloumnSeperator">
          <div class="col tableHItem">Loan Name</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Bank Name</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Eligibile Courses</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Eligibility Criteria</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Maximum Amount</div>
        </th>
      </tr>

      <ng-container
        *ngFor="
          let option of filterOptions | filter : searchText;
          index as j;
          let Last = last
        "
      >
        <tr class="tableHItem4" (click)="coToBankLoanDetails(option['id'])">
          <td>
            <div
              class="col tableHItem2 py-2"
              style="margin: 0"
              [title]="option['loan_name']"
            >
              {{ option["loan_name"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["bank_name"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["eligible_courses"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["conditions"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["loan_amount"] }}
            </div>
          </td>
          <!-- <td>
          <div class="col tableHItem2  pt-3"style="margin: 0;" (click)="coToCareerDetails(j)" >
            {{
              option["salary_range"] != null ? 
              option["salary_range"] != "" ?
              [option["salary_range"]] 
                 : "-"
                 : "-"
             }}
          </div>
        </td> -->
        </tr>
      </ng-container>
    </table>
  </div>
</div>
