<div class="col-md-12 careerDetails" *ngIf="!deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/collegelist"
        [queryParams]="{
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ collegeDetailResponse?.name }} </span>
    </div>
    <div class="col-md-12 careerReq">
      <div class="requirementsText mt-2">
        <span>Institute Details</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/student-hat.png" />
        </span>
      </div>
      <div class="col-md-12 d-flex justify-content-between px-0 mt-4">
        <div>
          <span class="reqt1">Institution Type: </span>
          <span class="reqta">
            {{
              collegeDetailResponse?.institution_type != null
                ? [collegeDetailResponse?.institution_type]
                : "-"
            }}
          </span>
        </div>
        <div>
          <span class="reqt1">Career Stream: </span>
          <span class="reqta">{{ collegeDetailResponse?.career }}</span>
        </div>
        <div>
          <span class="reqt1">Category: </span>
          <span class="reqta">{{
            collegeDetailResponse?.category != null
              ? groupCategoryValues[collegeDetailResponse?.category]
              : "-"
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 wrapperLeft1">
      <div class="row">
        <div class="col-md-12 sc2Parent2 mt-1">
          <div class="row">
            <div class="col-md-12">
              <span class="secondContainer2">Other details</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/open-book.png" />
              </span>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2">Institute Prominance </span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.institute_prominence != null
                      ? groupProminanceValues[
                          collegeDetailResponse?.institute_prominence
                        ]
                      : "NA"
                  }}
                </p>
              </div>
              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Rank Cuttoff</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.rank_cutoff != null && ""
                      ? [collegeDetailResponse?.rank_cutoff]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Enrolment Size(Per Batch)</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.enrollment_size != null
                      ? [collegeDetailResponse?.enrollment_size]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Hostel Facility</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.hostel_facility != null
                      ? groupHostelValues[
                          collegeDetailResponse?.hostel_facility
                        ]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Gender</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.gender != null
                      ? groupGenderValues[collegeDetailResponse?.gender]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Source of Data</span>
                <p
                  class="mt-3 overviewText pl-3 pb-2"
                  style="word-wrap: break-word"
                >
                  <a
                    (click)="webLink(collegeDetailResponse?.source_data)"
                    style="color: #007bff; cursor: pointer"
                    *ngIf="collegeDetailResponse?.source_data?.length > 0"
                  >
                    {{
                      collegeDetailResponse?.source_data != null
                        ? [collegeDetailResponse?.source_data]
                        : "-"
                    }}
                  </a>
                  <span *ngIf="collegeDetailResponse?.source_data?.length == 0">
                    {{ "NA" }}
                  </span>
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Date of data Gathered</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.date_of_data != null
                      ? [collegeDetailResponse?.date_of_data]
                      : "NA"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4 mb-4">
        <div class="col-md-12 sc2Parent2">
          <div class="mt-1 mb-2">
            <span class="secondContainer2"> List Of Courses Offered</span>
            <span class="pl-2">
              <img src="/assets/careerDetail/surface.svg" />
            </span>
            <ng-container *ngFor="let item of collegeCourses">
              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <!-- <span class="reqt1 ml-2 mb-2">
                  {{
                    collegeDetailResponse?.college_courses != null
                      ? [collegeDetailResponse?.college_courses]
                      : "NA"
                   }}
                </span> -->
                <span class="coursesHeading ml-2"> {{ item.name }} </span>
              </div>
              <div style="display: flex">
                <div class="subHeading pb-1">Course Duration:</div>
                <span class="overviewText pl-2 pb-2">
                  {{ item.duration }}
                </span>
              </div>
              <div style="display: flex">
                <div class="subHeading pb-1">Entrance By:</div>
                <span class="overviewText pl-2 pb-2">
                  {{ item.entrance_exam }}
                </span>
              </div>
              <div style="display: flex">
                <div class="subHeading pb-1">Enrolment Size (Per Batch):</div>
                <span class="overviewText pl-2 pb-2">
                  {{
                    item?.enrolment_size != null ? [item?.enrolment_size] : "NA"
                  }}
                </span>
              </div>
              <div style="display: flex">
                <div class="subHeading pb-1">Fees(subject to variation):</div>
                <span class="overviewText pl-2 pb-2"> {{ item.fee }} </span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 wrapperRight1">
      <div class="row">
        <div class="col-md-12 salaryDetails mt-4">
          <div class="mt-3">
            <span class="roadMapText">Address Details </span>
            <span class="pl-2">
              <img src="/assets/careerDetail/skills.png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Address: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.address
                ? collegeDetailResponse?.address
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">City/Town/Village: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.city ? collegeDetailResponse?.city : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">District: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.district_id?.name
                ? collegeDetailResponse?.district_id?.name
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">State: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.state != null
                ? [collegeDetailResponse?.state?.name]
                : "NA"
            }}</span>
          </div>
        </div>

        <div class="col-md-12 salaryDetails mt-4">
          <div class="mt-3">
            <span class="roadMapText">Contact Information </span>
            <span class="pl-2">
              <img src="/assets/careerDetail/desktop.png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Web Link: </span>
            <span class="expectedSalary2 ml-1">
              <a
                (click)="webLink(collegeDetailResponse?.website)"
                style="color: #007bff; cursor: pointer"
                *ngIf="collegeDetailResponse?.website?.length > 0"
              >
                {{
                  collegeDetailResponse?.website != null
                    ? [collegeDetailResponse?.website]
                    : "-"
                }}
              </a>
              <span *ngIf="collegeDetailResponse?.website?.length == 0">
                {{ "NA" }}
              </span>
              <span *ngIf="collegeDetailResponse?.website == null">
                {{ "NA" }}
              </span>
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Phone No: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.contact_phone
                ? collegeDetailResponse?.contact_phone
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Email: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.contact_email
                ? collegeDetailResponse?.contact_email
                : "NA"
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- For MobileApp -->

<div class="col-md-12 careerDetails" *ngIf="deviceXs">
  <div class="row d-flex px-3">
    <div class="col-md-12 mt-3 px-0 d-flex align-items-center">
      <mat-icon
        class="backArrow"
        routerLink="/dashboard/collegelist"
        [queryParams]="{
          stream: this.streamFilter,
          state: this.stateFilter,
          instituteType: this.instituteFilter,
          course: this.courseFilter,
          gender: this.genderFilter,
          district: this.districtFilter,
          institueProminence: this.intituteProvinanceFilter,
          category: this.CategoryFilter,
          hostelFacility: this.HostelFilter
        }"
        >arrow_back</mat-icon
      >
      <span class="backTitle"> {{ collegeDetailResponse?.name }} </span>
    </div>
    <div class="col-md-12 careerReq">
      <div class="requirementsText mt-2">
        <span>Institute Details</span>
        <span class="ml-1">
          <img src="/assets/careerDetail/student-hat.png" />
        </span>
      </div>
      <div class="col-md-12 px-0 mt-3">
        <div class="mb-2">
          <span class="reqt1">Institution Type: </span>
          <span class="reqta">
            {{
              collegeDetailResponse?.institution_type != null
                ? [collegeDetailResponse?.institution_type]
                : "-"
            }}
          </span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Career Stream: </span>
          <span class="reqta">{{ collegeDetailResponse?.career }}</span>
        </div>
        <div class="mb-2">
          <span class="reqt1">Category: </span>
          <span class="reqta">{{
            collegeDetailResponse?.category != null
              ? groupCategoryValues[collegeDetailResponse?.category]
              : "-"
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 wrapperLeft1">
      <div class="row">
        <div class="col-md-12 sc2Parent2 mt-1">
          <div class="row">
            <div class="col-md-12">
              <span class="secondContainer2">Other details</span>
              <span class="pl-2">
                <img src="/assets/careerDetail/open-book.png" />
              </span>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2">Institute Prominance </span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.institute_prominence != null
                      ? groupProminanceValues[
                          collegeDetailResponse?.institute_prominence
                        ]
                      : "NA"
                  }}
                </p>
              </div>
              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Rank Cuttoff</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.rank_cutoff != null && ""
                      ? [collegeDetailResponse?.rank_cutoff]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Enrolment Size(Per Batch)</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.enrollment_size != null
                      ? [collegeDetailResponse?.enrollment_size]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Hostel Facility</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.hostel_facility != null
                      ? groupHostelValues[
                          collegeDetailResponse?.hostel_facility
                        ]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Gender</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.gender != null
                      ? groupGenderValues[collegeDetailResponse?.gender]
                      : "NA"
                  }}
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Source of Data</span>
                <p
                  class="mt-3 overviewText pl-3 pb-2"
                  style="word-wrap: break-word"
                >
                  <a
                    (click)="webLink(collegeDetailResponse?.source_data)"
                    style="color: #007bff; cursor: pointer"
                    *ngIf="collegeDetailResponse?.source_data?.length > 0"
                  >
                    {{
                      collegeDetailResponse?.source_data != null
                        ? [collegeDetailResponse?.source_data]
                        : "-"
                    }}
                  </a>
                  <span *ngIf="collegeDetailResponse?.source_data?.length == 0">
                    {{ "NA" }}
                  </span>
                </p>
              </div>

              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <span class="reqt1 ml-2"> Date of data Gathered</span>
                <p class="mt-3 overviewText pl-3 pb-2">
                  {{
                    collegeDetailResponse?.date_of_data != null
                      ? [collegeDetailResponse?.date_of_data]
                      : "NA"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4 mb-3">
        <div class="col-md-12 sc2Parent2">
          <div class="mt-1 mb-2">
            <span class="secondContainer2"> List Of Courses Offered</span>
            <span class="pl-2">
              <img src="/assets/careerDetail/surface.svg" />
            </span>
            <ng-container *ngFor="let item of collegeCourses">
              <div class="mt-3">
                <span>
                  <img src="/assets/careerDetail/ellipse.png" />
                </span>
                <!-- <span class="reqt1 ml-2 mb-2">
                  {{
                    collegeDetailResponse?.courses_offered != null
                      ? [collegeDetailResponse?.courses_offered]
                      : "NA"
                   }}
                </span> -->
                <span class="coursesHeading ml-2"> {{ item.name }}</span>
              </div>
              <div style="display: flex">
                <div class="subHeading pb-1">Course Duration:</div>
                <span class="overviewText pl-2 pb-2">
                  {{ item.duration }}
                </span>
              </div>
              <div style="display: flex">
                <div class="subHeading pb-1">Entrance By:</div>
                <span class="overviewText pl-2 pb-2">
                  {{ item.entrance_exam }}
                </span>
              </div>
              <div style="display: flex">
                <div class="subHeading pb-1">Enrolment Size (Per Batch):</div>
                <span class="overviewText pl-2 pb-2">
                  {{
                    item?.enrolment_size != null ? [item?.enrolment_size] : "NA"
                  }}
                </span>
              </div>
              <div style="display: flex">
                <div class="subHeading pb-1">Fees(subject to variation):</div>
                <span class="overviewText pl-2 pb-2"> {{ item.fee }} </span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 wrapperRight1">
      <div class="row">
        <div class="col-md-12 salaryDetails mt-2">
          <div class="mt-3">
            <span class="roadMapText">Address Details </span>
            <span class="pl-2">
              <img src="/assets/careerDetail/skills.png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Address: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.address
                ? collegeDetailResponse?.address
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">City/Town/Village: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.city ? collegeDetailResponse?.city : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">District: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.district_id?.name
                ? collegeDetailResponse?.district_id?.name
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">State: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.state != null
                ? [collegeDetailResponse?.state?.name]
                : "NA"
            }}</span>
          </div>
        </div>

        <div class="col-md-12 salaryDetails mt-4">
          <div class="mt-3">
            <span class="roadMapText">Contact Information </span>
            <span class="pl-2">
              <img src="/assets/careerDetail/desktop.png" />
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Web Link: </span>
            <span class="expectedSalary2 ml-1">
              <a
                (click)="webLink(collegeDetailResponse?.website)"
                style="color: #007bff; cursor: pointer"
                *ngIf="collegeDetailResponse?.website?.length > 0"
              >
                {{
                  collegeDetailResponse?.website != null
                    ? [collegeDetailResponse?.website]
                    : "-"
                }}
              </a>
              <span *ngIf="collegeDetailResponse?.website?.length == 0">
                {{ "NA" }}
              </span>
              <span *ngIf="collegeDetailResponse?.website == null">
                {{ "NA" }}
              </span>
            </span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Phone No: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.contact_phone
                ? collegeDetailResponse?.contact_phone
                : "NA"
            }}</span>
          </div>
          <div class="mt-3">
            <span class="expectedSalary">Email: </span>
            <span class="expectedSalary2 ml-1">{{
              collegeDetailResponse?.contact_email
                ? collegeDetailResponse?.contact_email
                : "NA"
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
