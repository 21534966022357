import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  filterListObj,
  filterOptionObj,
  filterParamsObj,
} from '../models.model';
import { ApiCallsService } from '../api-calls.service';

@Component({
  selector: 'app-admin-colleges-stream-filter',
  templateUrl: './admin-colleges-stream-filter.component.html',
  styleUrls: ['./admin-colleges-stream-filter.component.css'],
})
export class AdminCollegesStreamFilterComponent implements OnInit {
  collegesStreamFilterArr: any[] = [];
  // statesList: any[] = [];
  displayedColumns: string[] = [
    'id',
    'college',
    'college_stream_filter_id',
    'stream_filter',
  ];
  collectionSize: number = 0;
  pageSize: number = 50;
  currentPage: number = 1;
  previousPage: number = 1;
  hideOptions: boolean = false;

  searchFormControl: FormControl = new FormControl();
  streamFormControl: FormControl = new FormControl();

  streamOptions: filterOptionObj[] = [
    { filterOptionKey: -1, filterOptionValue: 'All' },
    { filterOptionKey: 0, filterOptionValue: 'Agriculture & Related Fields' },
    {
      filterOptionKey: 1,
      filterOptionValue: 'Armed Forces & Central Police',
    },
    { filterOptionKey: 2, filterOptionValue: 'Arts, Design, Creative' },
    { filterOptionKey: 3, filterOptionValue: 'Banking & Finance' },
    { filterOptionKey: 4, filterOptionValue: 'Building & Construction' },
    { filterOptionKey: 5, filterOptionValue: 'Clothing & Accessories' },
    { filterOptionKey: 6, filterOptionValue: 'Education & Training' },
    {
      filterOptionKey: 7,
      filterOptionValue: 'Hardware, Electronics & Telecommunications',
    },
    { filterOptionKey: 8, filterOptionValue: 'Engineering & Manufacturing' },
    { filterOptionKey: 9, filterOptionValue: 'Fitness & Leisure' },
    { filterOptionKey: 10, filterOptionValue: 'Healthcare & Life Sciences' },
    { filterOptionKey: 11, filterOptionValue: 'IT & ITES' },
    {
      filterOptionKey: 12,
      filterOptionValue: 'Social Sciences & Human Services',
    },
    { filterOptionKey: 13, filterOptionValue: 'Law & Order' },
    { filterOptionKey: 14, filterOptionValue: 'Management & Marketing' },
    { filterOptionKey: 15, filterOptionValue: 'Media & Entertainment' },
    {
      filterOptionKey: 16,
      filterOptionValue: 'Public Administration & Government',
    },
    {
      filterOptionKey: 17,
      filterOptionValue: 'Retail, Tourism and Hospitality',
    },
    { filterOptionKey: 18, filterOptionValue: 'Self Employment' },
    {
      filterOptionKey: 19,
      filterOptionValue: 'Transportation, Logistics, Warehousing',
    },
  ];

  filtersList: filterListObj[] = [
    {
      filterName: 'Streams',
      filterOptions: this.streamOptions,
      filterFormControl: this.streamFormControl,
    },
  ];

  searchParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  streamParam: filterParamsObj = {
    filterParamName: '',
    filterParamActive: false,
    filterParamValue: '',
  };

  constructor(private apiCaller: ApiCallsService) {}

  ngOnInit(): void {
    this.setDefaultControlValues();
  }

  setDefaultControlValues() {
    this.streamFormControl.patchValue(this.streamOptions[0]);
    this.getCollegesStreamFilterData(1, false);
  }

  hideOptionsFunc() {
    this.hideOptions = !this.hideOptions;
  }

  callCollegesStreamFilterApi(page: number) {
    this.apiCaller
      .getCollegesStreamFilters(page, [this.searchParam, this.streamParam])
      .subscribe(
        (res: any) => {
          console.log(res);
          this.collegesStreamFilterArr = res.results;
          this.collectionSize = res.count;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getCollegesStreamFilterData(page: number, isSearch: boolean) {
    this.makeParams();
    if (isSearch) {
      let searchValue: string = this.searchParam.filterParamValue as string;
      if (searchValue.length >= 3) {
        this.callCollegesStreamFilterApi(page);
      }
    } else {
      this.callCollegesStreamFilterApi(page);
    }
  }

  clearSearch() {
    this.searchFormControl.reset();
    this.getCollegesStreamFilterData(this.currentPage, false);
  }

  makeParams() {
    let searchValue: string = this.searchFormControl.value;
    if (searchValue) {
      if (searchValue.length > 3) {
        this.searchParam.filterParamActive = true;
        this.searchParam.filterParamValue = searchValue;
        this.searchParam.filterParamName = 'search';
        this.currentPage = 1;
      } else {
        this.searchParam.filterParamActive = false;
      }
    } else {
      this.searchParam.filterParamActive = false;
    }

    let streamLevel: filterOptionObj = this.streamFormControl.value;
    if (streamLevel.filterOptionKey !== -1) {
      this.streamParam.filterParamActive = true;
      this.streamParam.filterParamValue = streamLevel.filterOptionKey;
      this.streamParam.filterParamName = 'stream_filter';
      this.currentPage = 1;
    } else {
      this.streamParam.filterParamActive = false;
    }

    if (this.currentPage > 1) {
      this.currentPage = this.previousPage;
    }
  }
}
