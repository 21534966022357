<div class="p-5">
  <div class="d-flex totalHolder">
    <div class="d-flex fixedHolder">
      <div class="d-flex titleAndBackHolder">
        <div class="d-flex titleAndBackSection">
          <mat-icon
            class="backArrow cursor-pointer"
            routerLink="/adminDashboard"
            >arrow_back</mat-icon
          >
          <div class="">Districts</div>
        </div>
        <div class="d-flex displayOptions">
          <div class="cursor-pointer">Hide Options</div>
          <mat-slide-toggle color="primary" [(ngModel)]="hideOptions">
          </mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="!hideOptions" class="d-flex optionsHolder">
        <div class="d-flex align-center">
          <input
            class="searchInput"
            (keyup)="getDistrictsData(currentPage, true)"
            placeholder="Search Districts"
            [formControl]="searchFormControl"
          />
          <mat-icon
            *ngIf="searchFormControl.value?.length > 0"
            class="exportClearBtn"
            (click)="clearSearch()"
            >close</mat-icon
          >
        </div>
        <div class="filterHolder d-flex">
          <div>
            <mat-form-field class="filterFormField">
              <mat-label class="filterLabel">States</mat-label>
              <mat-select
                placeholder="States"
                [formControl]="stateFormControl"
                (selectionChange)="getDistrictsData(currentPage, false)"
              >
                <mat-option
                  *ngFor="let stateObj of statesList"
                  [value]="stateObj.state_id"
                >
                  {{ stateObj.name }}</mat-option
                ></mat-select
              >
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <div [ngClass]="hideOptions ? 'tableHolder-full' : 'tableHolder'">
          <table
            mat-table
            [dataSource]="districtList"
            class="mat-elevation-z8 w-full"
          >
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="district_id">
              <th mat-header-cell *matHeaderCellDef>District ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.district_id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state_name">
              <th mat-header-cell *matHeaderCellDef>State Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.state_id.name }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <ngb-pagination
    class="d-flex justify-content-center pagination_theme"
    [maxSize]="5"
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    (pageChange)="getDistrictsData(currentPage, false)"
  >
    <ng-template ngbPaginationPrevious>
      <img
        src="/assets/Dashboard/pageArrowPrev.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
    <ng-template ngbPaginationNext>
      <img
        src="/assets/Dashboard/pageArrowNext.svg"
        [ngClass]="[collectionSize] < '5' ? '' : ''"
      />
    </ng-template>
  </ngb-pagination>
</div>
