import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-get-started-modal',
  templateUrl: './get-started-modal.component.html',
  styleUrls: ['./get-started-modal.component.css'],
})
export class GetStartedModalComponent implements OnInit {
  @Input() deviceXs: boolean;
  constructor(
    private router: Router,
    public mediaObserver: MediaObserver,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<GetStartedModalComponent>
  ) {}

  checkbox1 = new FormControl(false);
  checkbox2 = new FormControl(false);
  checkbox3 = new FormControl(false);
  mediaSub: Subscription;

  goToLanding() {
    console.log('closing');
    console.log('closing');
    this.dialogRef.close(true);
    this.router.navigate(['/dashboard/getstarted']);
  }

  onConfirmClick(): void {
    console.log('closing');
    this.dialogRef.close(false);
  }

  ngOnInit(): void {
    $('.mat-dialog-container:has(app-get-started-modal div.modalx)').addClass(
      'modalBorder'
    );

    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
  }
}
