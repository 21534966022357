import { Route } from '@angular/compiler/src/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallsService } from './../api-calls.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { ScrollingModule } from '@angular/cdk/scrolling';

declare var $: any;

@Component({
  selector: 'app-dashboard-landing',
  templateUrl: './dashboard-landing.component.html',
  styleUrls: ['./dashboard-landing.component.css'],
})
export class DashboardLandingComponent implements OnInit, OnDestroy {
  @Input() deviceXs: boolean;
  constructor(
    private router: Router,
    private apiCaller: ApiCallsService,
    public mediaObserver: MediaObserver
  ) {}

  //Vars
  responseVar;
  studyEnabler = true;
  mediaSub: Subscription;
  //Vars
  options: any = [];
  NotificationDetailResponse: any;
  notificationpathurl = null;
  optionActive = 1;
  defaultStateFilterValue = null;
  defaultAademicFilterValue = null;

  defaultAademicFilterValueForScholarshipList = null;
  defaultAademicFilterValueForEntranceList = null;
  defaultAademicFilterValueForCourseList = null;
  defaultCategoryfilterValueForCourseList = null;
  defaultCategoryfilterValueForHostelList = null;
  defaultCategoryfilterValueForBankList = null;

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );

    this.getAllJobs();
    // this.verifyLogin();
    this.getUserInfo();
  }
  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }
  verifyLogin() {
    this.apiCaller.verifyUser().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    );
  }

  getAllJobs() {
    console.log('hello');
    this.apiCaller.getDashboardNotifications().subscribe(
      (res) => {
        console.log(res);
        // this.options = res
        this.NotificationDetailResponse = res;
        if (this.NotificationDetailResponse['notification_path']) {
          // this.roadmapdisabled = false
          this.notificationpathurl =
            this.NotificationDetailResponse['notification_path'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  takeTest() {
    window.open('http://bestcareer.vidyahelpline.org/#/login');
  }

  begin() {
    this.router.navigate(['/dashboard/getstarted']);
  }

  scrollRight = () => {
    // $(#scrollRight).scrollTo(target)
    let element = document.getElementById('first');
    document.getElementById('iconWrapper').scrollLeft -= 170;
  };

  scrollLeft() {
    // console.log($('#iconWrapper'), $(id));
    // $('#iconWrapper')[0].scrollLeft($(id)[0].scrollLeft());
    let element = document.getElementById('last');
    document.getElementById('iconWrapper').scrollLeft += 170;
  }

  getUserInfo() {
    this.apiCaller.getUserInfo().subscribe(
      (res) => {
        console.log(res);
        this.responseVar = res['data']['name'];
        this.defaultStateFilterValue =
          res['data'].state.published !== 0 ? '0' : res['data'].state.state_id;
        switch (localStorage.getItem('academicValue')) {
          case '0': {
            this.defaultAademicFilterValueForCourseList = 'all';
            this.defaultCategoryfilterValueForCourseList = 'all';
            this.defaultAademicFilterValueForEntranceList = '0';
            this.defaultAademicFilterValueForScholarshipList = '10';
            this.defaultCategoryfilterValueForHostelList = 'all';
            this.defaultCategoryfilterValueForBankList = '0';
            break;
          }
          case '1': {
            this.defaultAademicFilterValueForCourseList = 'all';
            this.defaultCategoryfilterValueForCourseList = 'all';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = '10';
            this.defaultCategoryfilterValueForHostelList = 'all';
            this.defaultCategoryfilterValueForBankList = '0';
            break;
          }
          case '2': {
            this.defaultAademicFilterValueForCourseList = 'all';
            this.defaultCategoryfilterValueForCourseList = 'all';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = '12';
            this.defaultCategoryfilterValueForHostelList = 'all';
            this.defaultCategoryfilterValueForBankList = '0';
            break;
          }
          case '3': {
            this.defaultAademicFilterValueForCourseList = 'all';
            this.defaultCategoryfilterValueForCourseList = 'all';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = '12';
            this.defaultCategoryfilterValueForHostelList = '0';
            this.defaultCategoryfilterValueForBankList = '0';
            break;
          }
          case '4': {
            this.defaultAademicFilterValueForCourseList = '8';
            this.defaultCategoryfilterValueForCourseList = '0';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = '12';
            this.defaultCategoryfilterValueForHostelList = '0';
            this.defaultCategoryfilterValueForBankList = '2';
            break;
          }
          case '5': {
            this.defaultAademicFilterValueForCourseList = '8';
            this.defaultCategoryfilterValueForCourseList = '0';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = '12';
            this.defaultCategoryfilterValueForHostelList = '0';
            this.defaultCategoryfilterValueForBankList = '1';
            break;
          }
          case '6': {
            this.defaultAademicFilterValueForCourseList = '8';
            this.defaultCategoryfilterValueForCourseList = '0';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = '12';
            this.defaultCategoryfilterValueForHostelList = '0';
            this.defaultCategoryfilterValueForBankList = '1';
            break;
          }
          case '7': {
            this.defaultAademicFilterValueForCourseList = '8';
            this.defaultCategoryfilterValueForCourseList = '0';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = '12';
            this.defaultCategoryfilterValueForHostelList = '0';
            this.defaultCategoryfilterValueForBankList = '1';
            break;
          }

          case '8': {
            this.defaultAademicFilterValueForCourseList = '8';
            this.defaultCategoryfilterValueForCourseList = '0';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = '12';
            this.defaultCategoryfilterValueForHostelList = '0';
            this.defaultCategoryfilterValueForBankList = '1';
            break;
          }
          case '9': {
            this.defaultAademicFilterValueForCourseList = '7';
            this.defaultCategoryfilterValueForCourseList = '1';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = 'degree';
            this.defaultCategoryfilterValueForHostelList = '1';
            this.defaultCategoryfilterValueForBankList = '2';
            break;
          }
          case '10': {
            this.defaultAademicFilterValueForCourseList = '7';
            this.defaultCategoryfilterValueForCourseList = '1';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = 'degree';
            this.defaultCategoryfilterValueForHostelList = '1';
            this.defaultCategoryfilterValueForBankList = '3';
            break;
          }
          case '11': {
            this.defaultAademicFilterValueForCourseList = '7';
            this.defaultCategoryfilterValueForCourseList = '1';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = 'degree';
            this.defaultCategoryfilterValueForHostelList = '1';
            this.defaultCategoryfilterValueForBankList = '2';
            break;
          }
          case '12': {
            this.defaultAademicFilterValueForCourseList = '7';
            this.defaultCategoryfilterValueForCourseList = '1';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = 'degree';
            this.defaultCategoryfilterValueForHostelList = '1';
            this.defaultCategoryfilterValueForBankList = '3';
            break;
          }
          case '13': {
            this.defaultAademicFilterValueForCourseList = '7';
            this.defaultCategoryfilterValueForCourseList = '1';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = 'degree';
            this.defaultCategoryfilterValueForHostelList = '2';
            this.defaultCategoryfilterValueForBankList = '3';
            break;
          }
          case '14': {
            this.defaultAademicFilterValueForCourseList = '7';
            this.defaultCategoryfilterValueForCourseList = '1';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = 'degree';
            this.defaultCategoryfilterValueForHostelList = '2';
            this.defaultCategoryfilterValueForBankList = '3';
            break;
          }

          default: {
            this.defaultAademicFilterValueForCourseList = 'all';
            this.defaultCategoryfilterValueForCourseList = 'all';
            this.defaultAademicFilterValueForEntranceList = 'all';
            this.defaultAademicFilterValueForScholarshipList = 'all';
            this.defaultCategoryfilterValueForHostelList = 'all';
            this.defaultCategoryfilterValueForBankList = 'all';
            break;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  coToNotificationeDetails(jobId) {
    // let jobId = this.options[j]['id']
    //  alert(jobId)
    this.router.navigate(['/dashboard/notifications-detail/'], {
      queryParams: { id: jobId, Tag: '1', optionActive: this.optionActive },
    });
  }
}
