<div class="row p-0 m-0">
  <div class="col-md-12">
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/mentorship/"
        >arrow_back</mat-icon
      >
      <span class="backTitle">Student Faq's</span>
    </div>
  </div>
</div>

<!-- <div class="col-12"> -->
<div
  class="row m-3"
  style="
    background-color: white;
    padding: 36px 0 20px 0 !important;
    border-radius: 6px;
  "
>
  <div class="col-12">
    <div class="d-flex">
      <div class="col-7 header">Who can register as a mentee?</div>
      <div class="col-1" (click)="validator = !validator">
        <img
          src="/assets/mentorship/downArrow.svg"
          style="cursor: pointer; margin-left: 3rem"
          *ngIf="!validator"
        />
        <img
          src="/assets/mentorship/upArrow.svg"
          style="cursor: pointer; margin-left: 3rem"
          *ngIf="validator"
        />
      </div>
    </div>
    <div class="col-7 body" *ngIf="validator">
      <b>Holistic</b> : Any student who completes his/her 10th grade with 80% of
      marks
    </div>
    <div class="col-7 body" *ngIf="validator">
      <b>Project Based</b> : Only graduation students with 60 % of marks
    </div>
    <div class="col-8 body" *ngIf="validator">
      <b>Skill Based</b> : Starting from 8th grade, any student who wants to
      improve their skills in any aspect.
    </div>
    <div class="col-8">
      <img src="/assets/mentorship/Line.svg" />
    </div>
  </div>
  <div class="col-12" *ngFor="let item of data; let i = index">
    <div class="d-flex">
      <div class="col-7 header">
        {{ item.name }}
      </div>
      <div class="col-1" (click)="details[i] = !details[i]">
        <img
          src="/assets/mentorship/downArrow.svg"
          style="cursor: pointer; margin-left: 3rem"
          *ngIf="!details[i]"
        />
        <img
          src="/assets/mentorship/upArrow.svg"
          style="cursor: pointer; margin-left: 3rem"
          *ngIf="details[i]"
        />
      </div>
    </div>
    <div class="col-7 body" *ngIf="details[i]">{{ item.details }}</div>
    <div class="col-8">
      <img src="/assets/mentorship/Line.svg" />
    </div>
  </div>
</div>
<!-- </div> -->
