<div class="col-md-12 mt-2 dashboardTxt" *ngIf="!deviceXs">Career Wiki</div>
<div class="col-12 mt-2 dashboardTxt" *ngIf="!deviceXs">
  <div class="row">
    <div class="col-8">
      <div class="tr-cont">
        <div class="tr-c-h1 pt-3 pl-3">Hello {{ responseVar }}</div>
        <div class="row d-flex">
          <div class="col-9 tr-c-h2 pt-2 pl-3">
            <div class="col-12">
              Explore Career Wiki and plan your career. All the best!!!
            </div>
          </div>
          <div class="col-3 illustrationStyling">
            <img src="/assets/Dashboard/bookIl.svg" />
          </div>
        </div>
      </div>
      <div class="tr-cont2 mt-3">
        <div class="getStartedTxt mt-1">Get Started</div>
        <div class="variousQuestionsText">
          You may have various questions in your mind. We shall guide you.
        </div>
        <div class="iDoNotKnowTxt mt">
          <span class="cmNumber">1 : </span>
          <span class="cmDesc">“I do not know what career to choose” </span>
        </div>
        <div class="iDoNotKnowTxt mt-4">
          <span class="cmNumber">2 : </span>
          <span class="cmDesc"
            >“I have a favourite subject and I want to pick a career suited to
            that”
          </span>
        </div>
        <div class="iDoNotKnowTxt mt-4">
          <span class="cmNumber">3 : </span>
          <span class="cmDesc"
            >“I know what career professional I want to become. What steps
            should I follow?”
          </span>
        </div>
        <div class="iDoNotKnowTxt mt-4">
          <span class="cmNumber">4 : </span>
          <span class="cmDesc"
            >“I want to start earning as soon as possible”
          </span>
        </div>
        <div class="mt-1 d-flex justify-content-end pr-3">
          <button mat-raised-button class="beginCareer" (click)="begin()">
            Begin
          </button>
        </div>
      </div>
      <div class="bestCareerR mt-3">
        <!-- <div class="infoWiki">Career Wiki</div> -->
        <div class="d-flex justify-content-center">
          <span class="leftArrow" (click)="scrollRight()">
            <img src="/assets/Dashboard/left.svg" />
          </span>
          <div class="wikiOptions mb-4" id="iconWrapper">
            <figure class="ml-3">
              <img
                src="/assets/Dashboard/career.svg"
                id="first"
                routerLink="/dashboard/careerList"
              />
              <figcaption class="Career">Careers</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/courses.svg"
                routerLink="/dashboard/courseList"
                [queryParams]="{
                  stream: '',
                  elgibility: defaultAademicFilterValueForCourseList,
                  category: defaultCategoryfilterValueForCourseList
                }"
              />
              <figcaption class="Career">Courses</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/scholarship.svg"
                routerLink="/dashboard/scholarshiplist"
                [queryParams]="{
                  academic: defaultAademicFilterValueForScholarshipList,
                  gender: '',
                  handicapped: '',
                  application: '',
                  state: defaultStateFilterValue
                }"
              />
              <figcaption class="Career">Scholarships</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/exam2.svg"
                routerLink="/dashboard/entrancelist"
                [queryParams]="{
                  entryCourse: defaultAademicFilterValueForEntranceList,
                  national: defaultStateFilterValue
                }"
              />
              <figcaption class="Career">Entrance Examinations</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/coll.svg"
                routerLink="/dashboard/collegelist"
              />
              <figcaption class="Career">Colleges/ universities</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="./../../assets/Dashboard/hostel.svg"
                routerLink="/dashboard/hostellist"
                [queryParams]="{
                  academic: defaultCategoryfilterValueForHostelList,
                  gender: '',
                  caste: '',
                  state: defaultStateFilterValue,
                  district: ''
                }"
              />
              <figcaption class="Career">Hostels</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/loan.svg"
                routerLink="/dashboard/bankloanlist"
                [queryParams]="{
                  course: defaultCategoryfilterValueForBankList,
                  study: ''
                }"
              />
              <figcaption class="Career">Bank Loans</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/distance.svg"
                routerLink="/dashboard/distanceEducationList"
              />
              <figcaption class="Career">Distance Education</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/training.svg"
                routerLink="/dashboard/trainingInstituteList"
                id="last"
              />
              <figcaption class="Career">Training Institutes</figcaption>
            </figure>
          </div>
          <span id="scrollRight" class="leftArrow pl-3" (click)="scrollLeft()">
            <img src="/assets/Dashboard/right.svg" />
          </span>
        </div>
      </div>
    </div>
    <div class="col-4 notParent" *ngIf="!deviceXs">
      <div class="notificationC mr-2">
        <div class="bellh">
          <span>
            <img src="/assets/Dashboard/notBell.svg" />
          </span>
          <span class="notificationTxt" routerLink="/dashboard/notifications"
            >Notifications</span
          >
        </div>
        <div class="slider">
          <div class="notList" style="cursor: pointer">
            <div
              class="col-md-12 notColors"
              *ngIf="NotificationDetailResponse?.length === 0"
            >
              There are no notifications for you currently.
              <a
                routerLink="/dashboard/notifications"
                style="color: #00b1e3; text-decoration: underline"
              >
                Click here
              </a>
              to search notifications
            </div>
            <ul>
              <ng-container
                *ngFor="let notificatiionitem of NotificationDetailResponse"
              >
                <li
                  class="notItem"
                  (click)="coToNotificationeDetails(notificatiionitem['id'])"
                >
                  {{ notificatiionitem.name }}
                </li>
                <small class="notItemSmall"
                  >{{ notificatiionitem.active_until }}
                </small>
              </ng-container>
              <!-- <li class="mt-2 notItem">
                Graduate Pharmacy Aptitude Test (GPAT)-2021 - Exam Notification
              </li>
              <small class="notItemSmall">22 January 2021</small> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="bestCareerR mt-3">
        <div class="bclogo">
          <span>
            <img src="/assets/Dashboard/bestc.svg" />
          </span>
          <span class="bc-rH">Psychometric Assessment</span>
        </div>
        <div class="needHelp mt-2">Need help in selecting your career?</div>
        <div class="needHelp mt-2">
          Take our test to get best career suggestions!
        </div>
        <div class="beginButtnDiv mt-2 pb-3">
          <button mat-raised-button class="beginCareer" (click)="takeTest()">
            Take Test
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- for mobile app -->

<div class="ml-3 mt-3 dashboardTxt" *ngIf="deviceXs">Career Wiki</div>
<div class="col-12 mt-3 dashboardTxt" *ngIf="deviceXs">
  <div class="row">
    <div class="col-12">
      <div class="tr-cont">
        <div class="tr-c-h1 pt-3 pl-3">Hello {{ responseVar }}</div>
        <div class="tr-c-h2 pt-2 pl-3 pb-3 pr-2">
          Explore Career Wiki and plan your career. All the best!!!
        </div>
      </div>
      <div class="tr-cont2 mt-3">
        <div class="getStartedTxt mt-1">Get Started</div>
        <div class="variousQuestionsText">
          You may have various questions in your mind. We shall guide you.
        </div>
        <div class="iDoNotKnowTxt mt-4 row ml-0 mr-0">
          <div class="cmNumber2" style="width: fit-content">1 :</div>
          <div class="cmDesc col p-0 pl-1">
            “I do not know what career to choose”
          </div>
        </div>
        <div class="iDoNotKnowTxt mt-4 row ml-0 mr-0">
          <div class="cmNumber2" style="width: fit-content">2 :</div>
          <div class="cmDesc col p-0 pl-1">
            “I have a favourite subject and I want to pick a career suited to
            that"
          </div>
        </div>
        <div class="iDoNotKnowTxt mt-4 row ml-0 mr-0">
          <div class="cmNumber2" style="width: fit-content">3 :</div>
          <div class="cmDesc col p-0 pl-1">
            “I know what career professional I want to become. What steps should
            I follow?"
          </div>
        </div>
        <div class="iDoNotKnowTxt mt-4 row ml-0 mr-0">
          <span class="cmNumber2" style="width: fit-content">4 : </span>
          <span class="cmDesc col p-0 pl-1"
            >“I want to start earning as soon as possible”
          </span>
        </div>
        <div class="mt-1 d-flex justify-content-end pr-3 beginCareer1">
          <button mat-raised-button class="beginCareer" (click)="begin()">
            Begin
          </button>
        </div>
      </div>
      <div class="bestCareerR mt-3 pb-1">
        <!-- <div class="infoWiki">Career Wiki</div> -->
        <div class="d-flex justify-content-center">
          <span class="leftArrow" (click)="scrollRight()">
            <img src="/assets/Dashboard/left.svg" />
          </span>
          <div class="wikiOptions mt-4" id="iconWrapper">
            <figure class="ml-3">
              <img
                src="/assets/Dashboard/career.svg"
                id="first"
                routerLink="/dashboard/careerList"
              />
              <figcaption class="Career">Careers</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/courses.svg"
                routerLink="/dashboard/courseList"
                [queryParams]="{
                  stream: '',
                  elgibility: defaultAademicFilterValueForCourseList,
                  category: defaultCategoryfilterValueForCourseList
                }"
              />
              <figcaption class="Career">Courses</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/scholarship.svg"
                routerLink="/dashboard/scholarshiplist"
                [queryParams]="{
                  academic: defaultAademicFilterValueForScholarshipList,
                  gender: '',
                  handicapped: '',
                  application: '',
                  state: defaultStateFilterValue
                }"
              />
              <figcaption class="Career">Scholarships</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/exam2.svg"
                routerLink="/dashboard/entrancelist"
                [queryParams]="{
                  entryCourse: defaultAademicFilterValueForEntranceList,
                  national: defaultStateFilterValue
                }"
              />
              <figcaption class="Career">Entrance Examinations</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/coll.svg"
                routerLink="/dashboard/collegelist"
              />
              <figcaption class="Career">Colleges/ universities</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="./../../assets/Dashboard/hostel.svg"
                routerLink="/dashboard/hostellist"
                [queryParams]="{
                  academic: defaultCategoryfilterValueForHostelList,
                  gender: '',
                  caste: '',
                  state: defaultStateFilterValue,
                  district: ''
                }"
              />
              <figcaption class="Career">Hostels</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/loan.svg"
                routerLink="/dashboard/bankloanlist"
                [queryParams]="{
                  course: defaultCategoryfilterValueForBankList,
                  study: ''
                }"
              />
              <figcaption class="Career">Bank Loans</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/distance.svg"
                routerLink="/dashboard/distanceEducationList"
              />
              <figcaption class="Career">Distance Education</figcaption>
            </figure>

            <figure class="ml-3">
              <img
                src="/assets/Dashboard/training.svg"
                routerLink="/dashboard/trainingInstituteList"
                id="last"
              />
              <figcaption class="Career">Training Institutes</figcaption>
            </figure>
          </div>
          <span id="scrollRight" class="leftArrow pl-3" (click)="scrollLeft()">
            <img src="/assets/Dashboard/right.svg" />
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="col-4 notParent" *ngIf="!deviceXs">
      <div class="notificationC mr-2">
        <div class="bellh">
          <span>
            <img src="/assets/Dashboard/bell.svg" />
          </span>
          <span class="notificationTxt">Notifications</span>
        </div>
        <div class="slider">
          <div class="notList">
            <ul>
              <li class="notItem">
                National Institute of Fashion Technology - Admission
                Notification for Design Courses
              </li>
              <small class="notItemSmall">21 January 2021</small>
              <li class="mt-2 notItem">
                Graduate Pharmacy Aptitude Test (GPAT)-2021 - Exam Notification
              </li>
              <small class="notItemSmall">22 January 2021</small>
              <li class="mt-2 notItem">
                TISS-Tata Institute of Social Science - Admission Notification
                for PG Courses
              </li>
              <small class="notItemSmall">15 January 2021</small>
              <li class="mt-2 notItem">
                Telangana Social Welfare Institutions Notification - Free
                Admission for Intermediate (Residential)
              </li>
              <small class="notItemSmall">31 December 2020</small>
              <li class="mt-2 notItem">
                Joint Entrance Screening Test (JEST) for admission for Ph.D/
                Integrated Ph.D Programme - Exam Notification
              </li>
              <small class="notItemSmall">14 February 2021</small>
              <li class="mt-2 notItem">
                Telangana-MPHW Taining Course  - Admission Notification
              </li>
              <small class="notItemSmall">31 December 2020</small>
              <li class="mt-2 notItem">
                NOSMSJE Scholarship - Applications Open
              </li>
              <small class="notItemSmall">31 December 2020</small>
              <li class="mt-2 notItem">
                LIC–Golden Jubilee Scholarship Scheme 2020 -Applications Open
              </li>
              <small class="notItemSmall">31 December 2020</small>
              <li class="mt-2 notItem">
                Common Management Admission Test(C-MAT)-2020 - Exam Notification
              </li>
              <small class="notItemSmall">22 January 2021</small>
              <li class="mt-2 notItem">
                RAILTEL-Apprenticeship - Admission Notification
              </li>
              <small class="notItemSmall">11 January 2021</small>
            </ul>
          </div>
        </div>
      </div>
      <div class="bestCareerR mt-4">
        <div class="bclogo">
          <span>
            <img src="/assets/Dashboard/bestc.svg" />
          </span>
          <span class="bc-rH">Best Career</span>
        </div>
        <div class="needHelp">
          Need help in selecting your career? Take our test to get best career
          suggestions!
        </div>
        <div class="beginButtnDiv mt-2 pb-3">
          <button mat-raised-button class="beginCareer" (click)="takeTest()">
            Take Test
          </button>
        </div>
      </div>
    </div> -->
  </div>
</div>
