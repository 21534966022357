import { Component, Input, OnInit } from '@angular/core';
import { ApiCallsService } from './../api-calls.service';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgModel,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css'],
})
export class AdminLoginComponent implements OnInit {
  @Input() deviceXs: boolean;
  hide = true;
  mediaSub: Subscription;

  constructor(
    private apicaller: ApiCallsService,
    private formBuilder: FormBuilder,
    private router: Router,
    public mediaObserver: MediaObserver
  ) {}

  // showTT() {
  //   this.toastr.success('some messages', 'title');
  //   timeout:3000;
  // }

  loginerr() {
    Swal.fire('Invalid credentials!', '', 'error');
  }

  // Variables
  data = {
    username: '',
    password: '',
  };

  form = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  formGroupValidator = false;

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
  }
  login() {
    let data = this.form.value;
    console.log('The vals are', data);
    this.apicaller.adminLoginApi(data).subscribe(
      (res) => {
        console.log(res);
        localStorage.setItem('Token', res['token']);

        console.log('Check it man');
        let message = res['message'];
        console.log['message'];
        this.apicaller.verifyUser().subscribe(
          (res) => {
            console.log(res, message);
            if (res['message'] == 'user_verified') {
              console.log('Routing to dashboard page');
              this.router.navigate(['/adminDashboard']);
            }
          },
          (err) => {
            console.log(err['error']['message']);
            let message = err['error']['message'];
          }
        );
      },
      (err) => {
        console.log(err);
        this.formGroupValidator = true;
        this.loginerr();
      }
    );
  }
}
