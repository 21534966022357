<div class="cc-h" *ngIf="!deviceXs">
  <div class="row">
    <!-- <span class=""><input type="radio" /></span> -->
    <img
      class=" "
      src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
    />
    <span class="col-md-11">
      Click on Take Psychometric Test for taking the test to get the best career
      recommendations suited for you personally, or click on Continue Searching
      to browse through the careers list:
    </span>
  </div>
  <div
    class="row pl-4"
    *ngFor="let option of options; index as j"
    (click)="handleLogic(j)"
  >
    <div
      [ngClass]="
        selectedOptions.indexOf(j) == -1
          ? 'chooseOption mt-4'
          : 'chooseOption2 mt-4'
      "
    >
      {{ option }}
    </div>
  </div>
  <div class="row mt-5 pl-4">
    <button
      class="loginBtn"
      mat-raised-button
      (click)="HandleNext()"
      *ngIf="selectedOptions[0] !== 0 || selectedOptions[0] !== 1"
      [disabled]="studyEnabler"
    >
      Next
    </button>
  </div>
</div>

<!-- For Mobile App -->

<div class="cc-h1" *ngIf="deviceXs">
  <div class="row">
    <!-- <span class=""><input type="radio" /></span> -->
    <img
      class="grp"
      style="width: fit-content"
      src="https://cdn.zeplin.io/5fca395a454201514157bdb3/assets/E20C988F-628F-498E-8BFF-A07C39233086.svg "
    />
    <div class="col pt-5">
      Click on Take Psychometric Test for taking the test to get the best career
      recommendations suited for you personally, or click on Continue Searching
      to browse through the careers list:
    </div>
  </div>
  <div
    class="row pl-4"
    *ngFor="let option of options; index as j"
    (click)="handleLogic(j)"
  >
    <div
      [ngClass]="
        selectedOptions.indexOf(j) == -1
          ? 'chooseOption mt-4'
          : 'chooseOption2 mt-4'
      "
    >
      {{ option }}
    </div>
  </div>
  <div class="row mt-5 ml-3 mb-3">
    <button
      class="loginBtn"
      mat-raised-button
      (click)="HandleNext()"
      *ngIf="selectedOptions[0] !== 0 || selectedOptions[0] !== 1"
      [disabled]="studyEnabler"
    >
      Next
    </button>
  </div>
</div>
