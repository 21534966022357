<div class="row p-0 m-0">
  <div class="col-md-12" style="height: 117.62px !important">
    <div>
      <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
        <mat-icon class="backArrow" routerLink="/dashboard/"
          >arrow_back</mat-icon
        >
        <span class="backTitle"> All Resources</span>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-12 m-0 p-0">
        <mat-form-field appearance="outline" class="searchField col-12 m-0 p-0">
          <input
            type="text"
            matInput
            placeholder="Search Resources"
            [formControl]="myControl"
            [(ngModel)]="searchText"
          />
          <mat-icon matSuffix class="magBag"> search </mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="col-md-12 Featured-resources mt-3 mb-2">Featured resources</div>
  <div class="row p-0 m-0 cardWrap">
    <!-- *ngFor = 'let in of counter(5000) ;let i = index' uncomment place in below div when you detal with counter funtion in ts 23 line-->
    <div
      class="col-md-6 mt-3"
      *ngFor="
        let resource of ResourcesResponse | filter : searchText;
        index as j;
        let Last = last
      "
    >
      <div class="card cardLayoyt">
        <div class="row d-flex">
          <div class="col-8 pt-3">
            <div class="col featuredHeading">
              {{ resource.resource_name }}
            </div>
          </div>
          <div class="col-4 pt-3">
            <div class="col d-flex justify-content-end">
              <button
                class="loginBtn mb-3"
                (click)="viewDialogClick(j)"
                style="cursor: pointer"
                mat-raised-button
              >
                View
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 resouceFooter">
          {{ resource.resource_discription }}
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="terms"
      data-keyboard="false"
      data-backdrop="static"
      style="overflow: hidden"
    >
      <div class="modal-dialog dialogStyling">
        <div class="modal-content" style="border-radius: 8px">
          <div
            class="modal-header"
            style="
              background-color: #f2fdff;
              border-bottom: solid 1px var(--primary);
              padding-bottom: 7px;
            "
          >
            <span class="modal-title deleteNotificationStyling"
              >View resource</span
            >
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div style="padding-bottom: 1.5rem">
            <div class="modal-body dialogBodyStyling">
              To Share or view this resource please go through below.
            </div>
            <div class="row m-0" style="padding-inline-start: 1.2rem">
              <div class="col-9 attachmentStyle mb-1">
                <span>
                  <img
                    style="padding-top: 7px"
                    src="/assets/Dashboard/Icon metro-attachment.svg"
                  />
                </span>
                <span class="Additional_details_pdf">{{
                  responseLinkTriming
                }}</span>
              </div>
              <span class="openBoxStyling">
                <a href="{{ link }}" target="_blank">
                  <button
                    class="loginBtn mb-3"
                    style="cursor: pointer"
                    mat-raised-button
                    (click)="closeDialogBox()"
                  >
                    Open
                  </button></a
                >
              </span>
            </div>
            <div
              class="row m-0 openBoxStyling"
              style="padding-inline-start: 1.2rem"
            >
              <div class="col-9 attachmentStyle">
                <span>
                  <input
                    matInput
                    class="copyLinkStyle"
                    #inputUrl
                    placeholder="URL para importar"
                    value="http://cap.vidyahelpline.org/dashboard/resources"
                    [readonly]="true"
                  />
                </span>
              </div>
              <span class="openBoxStyling">
                <button
                  class="loginBtn mb-3"
                  style="cursor: pointer"
                  mat-raised-button
                  (click)="copy(inputUrl)"
                >
                  Copy
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
