import { Component, Input, OnInit } from '@angular/core';
import { HAMMER_LOADER } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { ApiCallsService } from '../api-calls.service';

declare var $: any;
@Component({
  selector: 'app-career-mithra',
  templateUrl: './career-mithra.component.html',
  styleUrls: ['./career-mithra.component.css'],
})
export class CareerMithraComponent implements OnInit {
  @Input() deviceXs: boolean;

  optionGroupFilter = '';
  subject = '';
  validator = true;
  emailField = '';
  mobileNumberField = '';
  bodyField = '';

  constructor(
    public router: Router,
    public mediaObserver: MediaObserver,
    public apiCaller: ApiCallsService
  ) {}

  mediaSub: Subscription;

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result: MediaChange) => {
        console.log(result.mqAlias);
        this.deviceXs = result.mqAlias === 'xs' ? true : false;
      }
    );
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  filterOption() {
    if (this.optionGroupFilter != null) {
      if (this.optionGroupFilter === '0') {
        this.subject = 'Request for a Career Workshop';
      } else if (this.optionGroupFilter === '1') {
        this.subject = 'Request for Best Career Coupon Code';
      } else if (this.optionGroupFilter === '2') {
        this.subject = 'Request for Financial Support for a needy student';
      } else if (this.optionGroupFilter === '3') {
        this.subject = 'Other';
      }
    }
    if (this.optionGroupFilter !== '' && this.bodyField !== '') {
      this.validator = false;
    } else {
      this.validator = true;
    }
    //     "{
    //     ""email"" : ""kowshik@gmail.com"",
    //     ""mobile"" : ""9491936612"",
    //     ""body"" : ""here is body"",
    //     ""subject"" : ""Feedback From Client""
    // }"
  }

  openDialogClick() {
    this.optionGroupFilter = '';
    this.subject = '';
    this.validator = true;
    this.emailField = '';
    this.mobileNumberField = '';
    this.bodyField = '';
    $('#terms').modal('toggle');
  }

  continueDialogClick() {
    let postEmailData = {
      email: this.emailField,
      mobile: this.mobileNumberField,
      body: this.bodyField,
      subject: this.subject,
    };
    console.log(postEmailData);
    this.apiCaller.postSendEmailData(postEmailData).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
    $('#terms').modal('toggle');
  }
}
