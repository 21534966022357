<div class="row">
  <div class="col-md-12 d-flex justify-content-center cent">
    <img src="/assets/Auth/welcomeLogo.svg" />
  </div>
  <div class="col-md-12 loginText mt-2">Signup</div>

  <div class="col-md-12 d-flex justify-content-center mt-4">
    <img src="/assets/Auth/indicate1.svg" />
    <img src="/assets/Auth/indicate1.svg" />
    <img src="/assets/Auth/indicate1.svg" />
  </div>

  <div class="col-md-12 fieldsMarked d-flex justify-content-center mt-3">
    <span class="newMemberTxt">
      The fields marked with * are mandatory to fill
    </span>
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-4">
    <mat-form-field appearance="outline" class="signupInput">
      <mat-select placeholder="Highest/Current Qualification*">
        <mat-option>Great Britain</mat-option>
        <mat-option>United States</mat-option>
        <mat-option>Canada</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-4">
    <mat-form-field appearance="outline" class="signupInput">
      <mat-select placeholder="Current Status*">
        <mat-option>Great Britain</mat-option>
        <mat-option>United States</mat-option>
        <mat-option>Canada</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-4">
    <mat-form-field appearance="outline" class="signupInput">
      <mat-select placeholder="Institute Category" class="">
        <mat-option>Great Britain</mat-option>
        <mat-option>United States</mat-option>
        <mat-option>Canada</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-4">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="authInput">
          <mat-label>Institute Name</mat-label>
          <input matInput placeholder="Institute Name" />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="authInput">
          <mat-label>Course Pursued</mat-label>
          <input matInput placeholder="Course Pursued" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-md-12 d-flex justify-content-center mt-4">
    <button class="loginBtn" mat-raised-button>Login</button>
  </div>
  <div class="col-md-12 loginText mt-3">
    <span class="newMemberTxt">New member? Click to Sign Up</span>
  </div>
</div>
