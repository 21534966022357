<div class="col-md-12 mt-3" style="height: 117.62px !important">
  <div>
    <div class="col-md-12 mt-4 mb-3 px-0 d-flex align-items-center">
      <mat-icon class="backArrow" routerLink="/dashboard/">arrow_back</mat-icon>
      <span class="backTitle"> Scholarship List</span>
    </div>
  </div>
  <mat-form-field appearance="outline" class="searchField">
    <input
      type="text"
      matInput
      placeholder="Search Scholarships"
      [formControl]="myControl"
      [(ngModel)]="searchText"
    />
    <!-- [formControl]="myControl" -->
    <!-- <mat-autocomplete>
        <mat-option *ngFor="let option of options; index as j;let last=last" [value]="option">{{
          option
        }}</mat-option>
      </mat-autocomplete> -->
    <mat-icon matSuffix class="magBag"> search </mat-icon>
  </mat-form-field>
</div>

<div class="col-md-12">
  <div class="row" *ngIf="!deviceXs">
    <div class="col-md-2" style="height: 77.64px !important">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Academic Eligibility</mat-label>
        <mat-select
          placeholder="Academic Eligibility"
          (selectionChange)="filterScholarship()"
          [(ngModel)]="academicFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'10'">10th and Below</mat-option>
            <mat-option [value]="'12'"
              >10+1, 10+2, PUC, Inter 1st & 2nd, Polytechnic, ITI</mat-option
            >
            <mat-option [value]="'degree'"
              >Degree and Post Graduation</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Gender Eligibility</mat-label>
        <mat-select
          placeholder="Gender Eligibility"
          (selectionChange)="filterScholarship()"
          [(ngModel)]="genderFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Male</mat-option>
            <mat-option [value]="1">Female</mat-option>
            <!-- <mat-option [value]="2">Others</mat-option> -->
            <!-- <mat-option [value]="3">Male and Female</mat-option> -->
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Handicapped Eligibility</mat-label>
        <mat-select
          placeholder="Handicapped Eligibility"
          (selectionChange)="filterScholarship()"
          [(ngModel)]="handicappedFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Only for Handicapped Students</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">Applications Open?</mat-label>
        <mat-select
          placeholder="Applications Open?"
          (selectionChange)="getAllJobs()"
          [(ngModel)]="applicationFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="1">Currently Open</mat-option>
            <mat-option [value]="0">Currently Not Open</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 ml-3">
      <mat-form-field appearance="outline" class="forAlignments">
        <mat-label class="forAlignments">State</mat-label>
        <mat-select
          placeholder="State"
          (selectionChange)="getAllJobs()"
          [(ngModel)]="nationalFilter"
        >
          <mat-option class="optionStyling" value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let state of states">
            <mat-option [value]="state.state_id" *ngIf="state.state_id != 99">{{
              state.name
            }}</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- for mobile app filter -->

  <div class="row" *ngIf="deviceXs">
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Academic Eligibility</mat-label>
        <mat-select
          placeholder="Academic Eligibility"
          (selectionChange)="filterScholarship()"
          [(ngModel)]="academicFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="'10'">10th and Below</mat-option>
            <mat-option [value]="'12'"
              >10+1, 10+2, PUC, Inter 1st & 2nd, Polytechnic, ITI</mat-option
            >
            <mat-option [value]="'degree'"
              >Degree and Post Graduation</mat-option
            >
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">Gender Eligibility</mat-label>
        <mat-select
          placeholder="Gender Eligibility"
          (selectionChange)="filterScholarship()"
          [(ngModel)]="genderFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Male</mat-option>
            <mat-option [value]="1">Female</mat-option>
            <!-- <mat-option [value]="2">Others</mat-option> -->
            <!-- <mat-option [value]="3">Male and Female</mat-option> -->
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">Handicapped Eligibility</mat-label>
        <mat-select
          placeholder="Handicapped Eligibility"
          (selectionChange)="filterScholarship()"
          [(ngModel)]="handicappedFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Only for Handicapped Students</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" class="auth pr-2">
        <mat-label class="auth">Applications Open?</mat-label>
        <mat-select
          placeholder="Applications Open?"
          (selectionChange)="getAllJobs()"
          [(ngModel)]="applicationFilter"
        >
          <span class="optionStyling">
            <mat-option value="all">All </mat-option>
            <mat-option [value]="0">Currently Open</mat-option>
            <mat-option [value]="1">Currently Not Open</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6" style="height: 77.13px !important">
      <mat-form-field appearance="outline" class="auth">
        <mat-label class="auth">State</mat-label>
        <mat-select
          placeholder="State"
          (selectionChange)="getAllJobs()"
          [(ngModel)]="nationalFilter"
        >
          <mat-option class="optionStyling" value="all">All </mat-option>
          <span class="optionStyling" *ngFor="let state of states">
            <mat-option [value]="state.state_id" *ngIf="state.state_id != 99">{{
              state.name
            }}</mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="BG mt-1 table-responsive">
    <table class="table">
      <tr class="colorWaraper">
        <th class="coloumnSeperator">
          <div class="col tableHItem">Scholarship Name</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Scholarship Amount</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Class Eligibility</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Age Eligibility</div>
        </th>
        <th class="coloumnSeperator">
          <div class="col tableHItem">Economic Eligibility</div>
        </th>
      </tr>

      <ng-container
        *ngFor="
          let option of filterOptions | filter : searchText;
          index as j;
          let Last = last
        "
      >
        <tr class="tableHItem4" (click)="coToScholarshipDetails(option['id'])">
          <td>
            <div
              class="col tableHItem2 py-2"
              style="margin: 0"
              [title]="option['name']"
            >
              {{ option["name"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["scholarship_details"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 tableHItem4 py-2" style="margin: 0">
              {{ option["academic_level"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 py-2" style="margin: 0">
              {{ option["age_eigibility"] }}
            </div>
          </td>
          <td>
            <div class="col tableHItem2 tableHItem4 py-2" style="margin: 0">
              {{ option["income_restrictions"] }}
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
